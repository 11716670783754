

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, Inject, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RefDataService } from '../../../core/services/common/ref-data.service'
import { getJSDocReturnTag } from 'typescript';
/**
 * @title Chips Autocomplete
 */
@Component({
  selector: 'app-tag-modal',
  templateUrl: './tag-modal.component.html',
  styleUrls: ['./tag-modal.component.scss']
})

export class TagModalComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredtags: Observable<string[]>;
  tags: string[] = [];
  tagData: string[] = [];
  alltags: string[] = []
  alltagsOrignal: string[] = []
  customTag: string[] = [];
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @Output() tagOutput = new EventEmitter<any>();
  @Input() tagInputData: string[];
  constructor (public dialogRef: MatDialogRef<TagModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private refDataService: RefDataService) {
    this.getData()
    this.alltagsOrignal = this.customTag
    this.alltags = this.customTag;


  }
  ngOnInit (): void {
    let tagSelectedData = this.tagInputData;


    // for (let i = 0; i < tagSelectedData.length; i++) {
    //   this.tags.push(tagSelectedData[i]);
    //   //  this.tagInput.nativeElement.value = '';
    //   //  this.tagCtrl.setValue(null);

    //   const index = this.alltags.indexOf(tagSelectedData[i]);
    //   if (index > -1) {
    //     this.alltags.splice(index, 1);
    //     this.gettagData();
    //   }
    // }

    // this.tags = this.tagInputData;
  }

  getData () {
    this.refDataService.tagsListData({ search: true }).subscribe(data => {
      const res = JSON.stringify(data);
      const resData = JSON.parse(res).tags;
      const resp = JSON.stringify(resData);
      this.alltagsOrignal = JSON.parse(resp).map(function (tag) {
        return tag.tag_name
      })
      this.alltags = [...this.alltagsOrignal]
      let tagSelectedData = this.tagInputData;


      for (let i = 0; i < tagSelectedData.length; i++) {
        this.tags.push(tagSelectedData[i]);
        //  this.tagInput.nativeElement.value = '';
        //  this.tagCtrl.setValue(null);

        const index = this.alltags.indexOf(tagSelectedData[i]);
        if (index > -1) {
          this.alltags.splice(index, 1);
          // this.gettagData();
        }
      }

      this.gettagData();

    });
  }

  gettagData () {
    this.filteredtags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.alltags.slice()));

    this.tagOutput.emit(this.tags);
  }

  add (event: MatChipInputEvent) {
    const input = event.input;
    let value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.tagCtrl.setValue(null);
    this.gettagData();
  }

  remove (tag: string) {
    const index = this.tags.indexOf(tag);
    const indexData = this.alltagsOrignal.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
      if (indexData >= 0) {
        this.alltags.push(tag)
        this.gettagData()
      }

    }
  }

  selected (event: MatAutocompleteSelectedEvent) {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);

    const index = this.alltags.indexOf(event.option.viewValue);
    if (index > -1) {
      this.alltags.splice(index, 1);
      this.gettagData();
    }
  }

  private _filter (value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.alltags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }


}

