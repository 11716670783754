import { Injectable } from '@angular/core';
import { refData } from '../../data/refData';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../../config';
@Injectable({
  providedIn: 'root'
})
export class RefDataService {

  constructor (private httpClient: HttpClient) { }

  getRefData () {
    return refData;
  }
  getRefAttData () {
    return refData;
  }


  getRefData1 () {
    const url = Endpoints.api.refferenceData.refDataApp
    const observable = this.httpClient.get(url);
    return observable;
  }
  refDataAll () {
    const url = Endpoints.api.refferenceData.refData;
    const observable = this.httpClient.get(url);
    return observable;
  }

  allowedCountries () {
    const url = Endpoints.api.common.allowedCountries;
    const observable = this.httpClient.get(url);
    return observable;
  }
  stateList (country_code) {
    const url = Endpoints.api.common.stateList.replace('{country_code}', country_code)
    const observable = this.httpClient.get(url);
    return observable;
  }
  tagsList (type) {
    const url = Endpoints.api.common.tags.replace('{type}', type)
    const observable = this.httpClient.get(url);
    return observable;
  }
  tagsListData (obj) {
    const offset = obj.offset || 0;
    const limit = obj.limit || 10
    const search = obj.search || true
    const url = Endpoints.api.common.tagsList.replace('{offset}', offset).
      replace('{limit}', limit).replace('{search}', search)
    const observable = this.httpClient.get(url);
    return observable;
  }
  fileUploadFun (data) {
    const url = Endpoints.api.common.fileUpload;
    const observable = this.httpClient.post(url, data);
    return observable;
  }
  seacrhCharacterData (characterInfo) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (characterInfo.search) {
      search = JSON.stringify(characterInfo.search);

    }
    if (characterInfo.sort) {
      sort = JSON.stringify(characterInfo.sort)
    }
    if (characterInfo.pagination) {
      pagination = JSON.stringify(characterInfo.pagination)
    }
    const url = Endpoints.api.characters.get
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url);
    return observable;


  }

  getFileJobStatus (jobId) {
    const url = Endpoints.api.common.fileUploadJobStatus.replace('{jobId}', jobId);
    const observable = this.httpClient.get(url);
    return observable;
  }

}
