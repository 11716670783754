import { Component, OnInit } from '@angular/core';
import lodash from 'lodash';
import { PaginationService } from '../../core/services/common/pagination.service';
import { SearchService } from '../../core/services/common/search.service';
import { GameBankService } from '../../core/services/game-bank/game-bank.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SiteConstants } from '../../../config/site-constants';

@Component({
  selector: 'app-category-tags-screen',
  templateUrl: './category-tags-screen.component.html',
  styleUrls: ['./category-tags-screen.component.scss']
})
export class CategoryTagsScreenComponent implements OnInit {

  cols = [];

  loading = false;

  tagList = [];

  totalRecords = 0;

  TimeoutSearch: any;

  selectedTag = null;

  filters = {};

  globalSort = '';

  constPage = 10;



  myForm = new FormGroup({
    tag_type: new FormControl('', [])
  });
  filter: any = {};
  constructor (
    private gameBankService: GameBankService,
    private paginationService: PaginationService,
    private searchService: SearchService,
  ) {
  }

  ngOnInit (): void {
    this.cols = [
      { field: 'id', header: 'Tag Id', width: '80px' },
      { field: 'display_tag', header: 'Display tag name', width: '190px' },
      { field: 'tag', header: 'Tag name', width: '140px' },
      { field: 'is_active', header: 'Status', width: '90px' },
      { field: 'index', header: 'Index', width: '90px' },
      { field: '', header: 'Actions', width: '80px' },
    ];
    this.myForm.get("tag_type").setValue(['premium']);
    this.GetData();

    this.GetData();
  }


  GetData () {


    //this.loading = true;
    this.gameBankService
      .getAllCategoryTags(this.filters).subscribe(data => {
        this.tagList = data['tags'];
        this.totalRecords = data['total'];
        this.loading = false;
      });

  }

  customSort (ev) {
    let obj = {};

    if (ev.order == 1) {
      obj[ev.field] = 'desc'
    } else {
      obj[ev.field] = 'asc'
    }

    const sortKey = ev.field + ' ' + ev.order;
    if (this.globalSort !== sortKey) {
      this.filters['sort'] = obj;
      this.GetData();
      this.globalSort = sortKey;
    }
  }

  customPagination (ev) {
    let data = this.paginationService.paginationFun(ev, this.globalSort, this.constPage, this.filters)
    this.filters = data.filter;
    this.globalSort = data.globalSort;
    this.constPage = data.defaultPage;

    this.GetData();
  }

  onRefresh (ev) {
    setTimeout(() => {
      this.GetData();
    }, 100);
  }
  tagsFilter (val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filters)
    this.filters = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData();
    }, SiteConstants.FiltertimeOut);
  }

  userFilter (val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData();
    }, SiteConstants.FiltertimeOut);
  }

}
