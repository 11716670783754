import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../../config';
@Injectable({
  providedIn: 'root'
})
export class UserPayoutService {

  constructor(private httpClient: HttpClient) { }
  createPayout(payoutObj) {
    const url = Endpoints.api.user.createPayout;

    const observable = this.httpClient.post<any>(url, payoutObj);

    return observable;


  }

}
