import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RefDataService } from '../../core/services/common/ref-data.service'




interface Food {
  value: string;
  viewValue: string;
}

interface PlayerType {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-create-league-category',
  templateUrl: './create-league-category.component.html',
  styleUrls: ['./create-league-category.component.scss']
})





export class CreateLeagueCategoryComponent implements OnInit {
  color_code: any;
  characterType: any;
  gameType: any;
  imageSrc: string;
  iconSrc: string;


  leagueForm = new FormGroup({

    category_name: new FormControl('', []),
    category_code: new FormControl('', []),
    color_code: new FormControl('', []),
    game_type: new FormControl('', []),
    active: new FormControl('', []),
    character_types: new FormControl('', []),
    order_index: new FormControl('', []),
    is_auto_game: new FormControl('', []),
    show_on_discover: new FormControl('', []),
    image_url: new FormControl('', []),
    icon_url: new FormControl('', []),
  });

  name = 'Angular';
  color: any;
  constructor(
    public dialogRef: MatDialogRef<CreateLeagueCategoryComponent>, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private refDataService: RefDataService) { }

  ngOnInit(): void {
    this.getCharAndGameType();

    if (this.data) {
      this.leagueForm.patchValue({
        category_name: this.data.category_name,
        category_code: this.data.category_code,
        color_code: this.data.color_code,
        game_type: this.data.game_type,
        active: this.data.active,
        character_types: this.data.character_types,
        order_index: this.data.order_index,
        is_auto_game: this.data.is_auto_game,
        show_on_discover: this.data.show_on_discover,

      });
      this.imageSrc = this.data.image_url;
      this.iconSrc = this.data.icon_url;


    } else {
      this.leagueForm.patchValue({
        category_name: '',
        category_code: '',
        color_code: '',
        game_type: '',
        active: '',
        character_types: '',
        order_index: '',
        is_auto_game: '',
        show_on_discover: '',

      });
      this.imageSrc = '';
      this.iconSrc = '';
      this.color_code = '';

    }
  }
  change(e) {
    // console.log(e.value);
    this.color_code = JSON.stringify(e.value);
    // console.log("color 2",this.color_code);

  }
  onFileChange(event) {
    const reader = new FileReader();
    alert("file 1");

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;

        this.leagueForm.patchValue({
          fileSource: reader.result
        });

      };

    }
  }

  onFileChange1(event) {
    const reader = new FileReader();
    alert("file 2");

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.iconSrc = reader.result as string;

        this.leagueForm.patchValue({
          fileSource: reader.result
        });

      };

    }
  }

  getCharAndGameType() {

    // this.characterType = JSON.parse(this.refDataService.getRefData()).characterTypes;
    // this.gameType = JSON.parse(this.refDataService.getRefAttData()).gameTypes;

    this.refDataService.getRefData1().subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).characterTypes;
      const getGame = (JSON.parse(res)).gameTypes;
      const getCharData = JSON.stringify(getData);
      const getGameData = JSON.stringify(getGame);
      this.characterType = JSON.parse(getCharData);
      this.gameType = JSON.parse(getGameData);
      // this.characterType = JSON.parse(this.refDataService.getRefData()).characterTypes;
      //  console.log("this is character", this.characterType);
      //  console.log('this is game type',this.gameType);
    });
  }

  //   onFileChangeIcon1(event) {
  //     const reader = new FileReader();
  // alert("cdsbm");
  //     if (event.target.files && event.target.files.length) {
  //       const [file] = event.target.files;
  //       reader.readAsDataURL(file);

  //       reader.onload = () => {


  //         this.iconSrc = reader.result as string;

  //         this.leagueForm.patchValue({
  //           fileSource: reader.result
  //         });

  //       };

  //     }
  //   }


  // foods: Food[] = [
  //   {value: 'Pinned', viewValue: 'Pinned'},
  //   {value: 'Custom', viewValue: 'Custom'},

  // ];

  // playertype: PlayerType[] = [
  //   {value: 'musician', viewValue: 'Musician'},
  //   {value: 'company', viewValue: 'Company'},
  //   {value: 'sports', viewValue: 'Sports'},
  //   {value: 'movie', viewValue: 'Movie'},


  // ];

  submitData() {
    //  console.log(this.leagueForm.value);
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close(true);
  }



}
