import { Injectable } from '@angular/core';
import {promoCodeData} from '../../data/promoCode'
import { Endpoints,SiteConstants } from '../../../../../../src/app/config';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {

  // constructor() { }

  // getPromoCode(){
  //   return promoCodeData;
  // }
  constructor(private httpClient: HttpClient) { }
  // getPromoCode() {
  //   const url = Endpoints.api.promoCode.getPromoCodeTable;
  //     const observable = this.httpClient.post(url,{})
  //     observable.subscribe(
  //       (val: any) => {
          
  //       },
  //       err => {
  //      //   console.log(err);
  //       }
  //     );
  //     return observable; 
  // }

  getPromoCode(levelFilter) {
    let search={};
    let pagination ={};
    let sort ={};
    if(levelFilter.search){
      search= levelFilter.search;
     
    } 
    // if(levelFilter.sort){
    //   sort = levelFilter.sort
    // }else{
    //   sort={id:'desc'}
    // }
    if (levelFilter.sort) {
      if (Object.keys(levelFilter.sort).length > 0) {
        sort = levelFilter.sort
      } else {
        sort ={ id: 'desc' }
      }
    } else {
      sort = { id: 'desc' }
    }

    if(levelFilter.pagination){
      pagination = levelFilter.pagination
    }else {
      pagination = {limit:10,page:1}
    }
         const url = Endpoints.api.promoCode.getPromoCodeTable;
      // .replace('{search}',search )
      // .replace('{sort}',sort )
      // .replace('{pagination}',pagination);
     
      const observable = this.httpClient.post(url,{sort,search,pagination})
     
      return observable; 
    
 
  }

  deletePromoCode(id){
    
    const url = Endpoints.api.promoCode.deletePromocodeTableRecord;
    const observable = this.httpClient.delete(url+'/'+id)
   
    return observable;
  }

  SearchUser(searchKeyword){
    
    const url = Endpoints.api.promoCode.userSearch;
    const observable = this.httpClient.post(url,searchKeyword);
    return observable;
  }

  createPromoCode(formValues,action){

    if(action == "create") {
      const url = Endpoints.api.promoCode.createPromocode;
      const observable = this.httpClient.post(url,formValues);
      return observable;
    } else if(action == "update"){
      const url = Endpoints.api.promoCode.updatePromoCode;
      const observable = this.httpClient.put(url,formValues);
      return observable;
    }
    
   
     
    
  }
    
 
    
}
