<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">


                <p-table #dt [columns]="cols" [value]="promoCode" [totalRecords]="totalRecords" [lazy]="true"
                    (onLazyLoad)="customPagination($event)" (sortFunction)="customSort($event)" [customSort]="true"
          [(selection)]="selectedPromoCode" [responsive]="true" dataKey="id" styleClass="ui-table-cars" [rows]="10"
          [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true">
                    <ng-template pTemplate="caption">
                        <app-action-button (onRefrashed)="onRefrash($event)" [data]="null" [width]="'650'"
                            [modaleId]="'EditPromoCodeModalComponent'">

                        </app-action-button>
                        <!-- <button type="button" class="btn btn-primary" (click)="openCreateDialog(null)"> Create</button> -->
                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr>

                            <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                                [ngStyle]="{'width': col.width,'text-align': col.textalign}">
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>
                            </th>

                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                                <input *ngSwitchCase="'promo_code'" pInputText type="text" placeholder="Promo Code"
                  class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'name'" pInputText type="text" placeholder="Name" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'value'" pInputText type="text" placeholder="Value" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">


                            </th>
                            <!-- <th style="width: 270px; text-align: center;">

                        </th> -->
                        </tr>
                    </ng-template>


                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>{{rowData.promo_code}}</td>

                            <td>{{rowData.name}}</td>
                            <td style="width: 200px;">{{getCouponTypeText(rowData.value_type)}}</td>
                            <td style="text-align: center;">{{rowData.value}}</td>

                            <td>{{rowData.start_date | date }}</td>
                            <td>{{rowData.end_date | date }}</td>
              <td style="width: 80px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <app-action-icon [data]="rowData" [width]="'650px'" [modaleId]="'EditPromoCodeModalComponent'"
                      (onRefrashed)="onRefrash($event)" icon="edit" tooltip="update">
                                </app-action-icon>
                    <app-action-icon [data]="rowData" [width]="'350px'" [modaleId]="'CommonDeleteModalComponent'"
                      (onRefrashed)="onRefrash($event)" icon="remove" tooltip="delete">
                                </app-action-icon>
                  </div>

                </mat-menu>
                            </td>

                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" style="text-align:left">No data found.</td>
                        </tr>
                    </ng-template>
                </p-table>

                <!-- <p-table #dt [columns]="cols" [loading]="loading"   (sortFunction)="customSort($event)" [customSort]="true" [frozenColumns]="frozenCols" [style]="{width:'100%'}" styleClass="ui-table-cars" [value]="promoCode" [totalRecords]="totalRecords" [lazy]="true" (onLazyLoad)="customPagination($event)" [paginator]="true" [rows]="10" dataKey="promo_code" selectionMode="single"  [rowsPerPageOptions]="[5,10]" [(selection)]="selectedPromoCode">
                    <ng-template pTemplate="caption">
                      Promo Code
                       <button type="button" class="btn btn-primary" (click)="openCreateDialog(null)"> Create</button>
                     </ng-template> 
                    
                        
                    
                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <col *ngFor="let col of columns" style="width:180px">
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" >
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>
                            </th>
                            
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                                <input *ngSwitchCase="'promo_code'" pInputText type="text" placeholder="Promo Code" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'name'" pInputText type="text" placeholder="Name" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'value'" pInputText type="text" placeholder="Value" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                               
                                <input *ngSwitchCase="'level_num'" pInputText type="text" placeholder="Level Number" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'game_num'" pInputText type="text" placeholder="Game Number" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'paid_game_num'" pInputText type="text" placeholder="Paid Game Number" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'bonus'" pInputText type="text" placeholder="Level completion bonus" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                            </th>
                            <th style="width: 270px; text-align: center;">

                            </th>
                        </tr>
                       
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>{{rowData.promo_code}}</td>

                            <td>{{rowData.name}}</td>
                            <td>{{rowData.value_type}}</td>
                            <td>{{rowData.value}}</td>

                            <td>{{rowData.start_date | date }}</td>
                            <td>{{rowData.end_date | date }}</td>
                            <td style="width: 300px;">
                                <button pButton type="submit"  (click)="openCreateDialog(rowData)" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em"></button>
                                <button pButton type="button"  (click)="openDialog(rowData)" class="ui-button-danger" icon="pi pi-pencil"></button>
                            </td>
                        </tr>
                    </ng-template> 
                </p-table>   
                 -->



            </div>

        </div>
    </div>
</div>