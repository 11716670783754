<div class="modal-header">
  <h5 *ngIf="isUpdate == false" class="modal-title" id="modal-basic-title">Create Promotion Banners </h5>
  <h4 *ngIf="isUpdate == true" class="modal-title" id="modal-basic-title">Update Promotion Banners</h4>

</div>

<mat-dialog-content>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="modal-body">


            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput name="Title" placeholder="Title" [(ngModel)]="promotionBannerObj.title"
                        name="title" type="text" class="form-control">
                    </mat-form-field>


                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput name="duration" [(ngModel)]="promotionBannerObj.play_duration"
                        name="play_duration" placeholder="Duration (Seconds)" type="number" class="form-control">
                    </mat-form-field>

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label for="end_time">start Date</label>
                  <div class="form-group">
                    <div class="ui-g-12 ui-md-12">
                      <p-calendar [showTime]="true" [showIcon]="true" [(ngModel)]="promotionBannerObj.start_time"
                        name="start_time" hourFormat="12" placeholder="Start Date">
                      </p-calendar>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="end_time">End Date</label>
                  <div class="form-group">
                    <div class="ui-g-12 ui-md-12">
                      <p-calendar [showTime]="true" [showIcon]="true" [(ngModel)]="promotionBannerObj.end_time"
                        name="end_time" hourFormat="12" placeholder="End Date">
                      </p-calendar>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="fill">
                    <mat-label>Select Screen</mat-label>
                    <mat-select [(ngModel)]="selected_screen" name="name" id="name"
                      (selectionChange)="changeWebsite($event)">
                      <mat-option *ngFor="let screen of screenName" [value]="screen">
                        {{screen}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <label for="backgroundType">Status</label>
                  <div class="form-group">
                    <div class="radiobu">

                      <div class="p-col-12 radiopadding">
                        <p-radioButton name="group2" [(ngModel)]="promotionBannerObj.status" value="1" label="Active"
                          inputId="opt1">
                        </p-radioButton>
                      </div>
                      <div class="p-col-12 radiopadding">
                        <p-radioButton name="group2" value="0" [(ngModel)]="promotionBannerObj.status" label="Inactive"
                          inputId="opt1">
                        </p-radioButton>
                      </div>

                    </div>
                  </div>
                </div>


              </div>

              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <p-checkbox [binary]="true" [(ngModel)]="promotionBannerObj.show_consent" name="show_consent"
                      value="show_consent" label="Show Button" inputId="ny">
                    </p-checkbox>

                  </div>

                </div>
                <div class="col-md-5" *ngIf="promotionBannerObj.show_consent">
                  <mat-form-field class="example-full-width">
                    <input matInput name="cta_url" placeholder="CTA : Click to action URL"
                      [(ngModel)]="promotionBannerObj.cta_url" (change)="urlCheck($event)" type="text"
                      class="form-control">
                  </mat-form-field>

                  <p [ngClass]="{'unsuccessError': !isVersusgameUrl}" style="display: none;">You enter outside URL</p>
                </div>
                <div class="col-md-5" *ngIf="promotionBannerObj.show_consent">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="bgBtnText" name="bgBtnText" placeholder="Button Text" type="text"
                        class="form-control">
                    </mat-form-field>

                  </div>

                </div>

              </div>
              <div class="row">

                <div class="col-md-12">
                  <label for="file_type">Promotion Banner Settings
                  </label>
                  <p-card>
                    <div class="form-group">
                      <app-promotion-banner-custom [data]="promotionBannerObj"
                        (promotionBannerSettings)="promotionBannerSettingsFun($event)">
                      </app-promotion-banner-custom>



                    </div>
                  </p-card>
                </div>



              </div>
              <!-- <div class="row">


                <div class="col-md-6">
                  <div class="form-group">
                    <input [(ngModel)]="promotionBannerObj.background.bgColor" name="bgColor"
                      [style.background]="promotionBannerObj.background.bgColor" [cpFallbackColor]="'#000000'"
                      (colorPickerChange)="onChangeColor($event)" [(colorPicker)]="color" style="width: 40px;" />
                    <input type="text" [(ngModel)]="promotionBannerObj.background.bgColor" name="bgColortxt" />
                  </div>
                </div>

              </div> -->
              <div class="row">

                <!-- <div class="col-md-5">
                  <div class="form-group">
                    <input [(ngModel)]="promotionBannerObj.background.bgColor" name="bgColor"
                      [style.background]="promotionBannerObj.background.bgColor" [cpFallbackColor]="'#000000'"
                      (colorPickerChange)="onChangeColor($event)" [(colorPicker)]="color" style="width: 40px;" />
                    <input type="text" [(ngModel)]="promotionBannerObj.background.bgColor" name="bgColortxt" />
                  </div>
                </div> -->
                <div class="col-md-6">

                  <div class="form-group">
                    <label for="file_type">File Type
                    </label>
                    <i class="{{icons['info']}} " matTooltip="{{tooltips['filetype']}}"></i>
                    <div>
                      <p-card id="file_type">


                        <div class="radiobu">

                          <div class="p-col-12 radiopadding">
                            <p-radioButton name="group" value="image" label="Image" (onClick)="fileTypeClick('image')"
                              [(ngModel)]="promotionBannerObj.background.file_type">
                            </p-radioButton>
                          </div>
                          <div class="p-col-12 radiopadding">
                            <p-radioButton name="group" value="video" label="Video"
                              [(ngModel)]="promotionBannerObj.background.file_type" (onClick)="fileTypeClick('video')">
                            </p-radioButton>
                          </div>

                        </div>
                      </p-card>
                    </div>




                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="file">Select {{promotionBannerObj.background.file_type}}
                    </label>
                    <div>
                      <div class="file-field" *ngIf="promotionBannerObj.background.file_type=='image'">
                        <input style="display: none" type="file" (change)="onFileChange($event)" #hiddenfileinput>
                        <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">
                          Choose
                          file</label>


                      </div>
                      <div class="file-field" *ngIf="promotionBannerObj.background.file_type=='video'">
                        <input style="display: none" type="file" (change)="onFileChangeVideo($event)" #hiddenfileinput>
                        <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">
                          Choose
                          file</label>


                      </div>
                    </div>




                  </div>
                </div>
                <div class="col-md-6" style="display: none;">

                  <div *ngIf="promotionBannerObj.background.file_type=='image'">


                    <mat-form-field style="width: 100%;" class="example-full-width">
                      <input matInput [(ngModel)]="image_url" name="image_url" id="image_url" placeholder="Image url"
                        (change)="imageChange($event)" type="text" class="form-control">
                    </mat-form-field>


                  </div>
                  <div *ngIf="promotionBannerObj.background.file_type=='video'">


                    <mat-form-field style="width: 100%;" class="example-full-width">
                      <input matInput [(ngModel)]="video_src" name="video_src" id="video_src" placeholder="Video Src"
                        (change)="videoChange($event)" type="text" class="form-control">
                    </mat-form-field>


                  </div>
                </div>

              </div>


              <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
                [fullScreen]="true">
                <p style="color: white"> Loading... </p>
              </ngx-spinner>
              <div class="row">
                <div class="col-md-6">
                  <div [ngxSummernote]="config" name="message_text" id="summernote"
                    [(ngModel)]="promotionBannerObj.message_text" (summernoteInit)="summernoteInit($event)">
                  </div>

                </div>
                <div class="col-md-5">

                  <!-- <div class="card-container game-card-box game-card-box1 ">
                    <div class="card-box card-box2 card-left">
                      <div *ngIf="promotionBannerObj.background.file_type=='image'" [ngStyle]="imagesCharacterSingle"
                        class="card-image card-left-image">
                      </div>
                      <div *ngIf="promotionBannerObj.background.file_type=='video'" class="card-image card-left-image"
                        style="background-color: #7b7b7b;">
                        <video autoplay muted loop id="myVideo" width="358" height="566" #videoPlayer
                          (loadedmetadata)="getDuration($event)">
                          <source src="{{video_src}}" type="video/mp4">

                        </video>

                      </div>

                    </div>



                  </div> -->
                  <div flex="100" style="padding-left: 5px;" class="mobile-container flex-100">
                    <div *ngIf="promotionBannerObj.background.file_type=='video'" class="mobile-main"
                      [ngStyle]="{'background-color':promotionBannerObj.background.bgColor ? promotionBannerObj.background.bgColor : '#000000', 'background-image': promotionBannerObj.background.url ? 'url(' + promotionBannerObj.background.url + ')' : ''}"
                      style="background-color: rgb(255, 255, 255); ">
                      <video autoplay muted loop id="myVideo" width="227" height="487" #videoPlayer
                        (loadedmetadata)="getDuration($event)" style="object-fit: contain;">
                        <source src="{{video_src}}" type="video/mp4"
                          *ngIf="promotionBannerObj.background.file_type=='video'">

                      </video>

                      <!-- <div class="cross-box"> <span> X </span> </div> -->

                      <!-- <div class="header-section ng-scope" *ngIf="affiliateData.logo_url">
                            <img src="{{affiliateData.logo_url}}"> </div> -->
                      <div Class="middleCls">

                        <div class="middle-section ">
                          <div class="sponsor-info">
                            <div class="">
                              <div class="loader" *ngIf="ismediaLoadding"></div>
                              <div class="loddingtxt" *ngIf="ismediaLoadding">
                                Uploading {{promotionBannerObj.background.file_type}} ...
                              </div>
                            </div>
                            <div [ngxSummernoteView]="promotionBannerObj.message_text">

                            </div>
                          </div>
                        </div> <!-- ngIf: affiliateInfo.show_consent -->
                      </div>
                      <!-- end ngIf: affiliateInfo.show_consent -->
                      <!-- ngIf: affiliateInfo.show_consent -->
                      <div class="footer-section ng-scope" *ngIf="promotionBannerObj.show_consent">
                        <button class="btn btn-full btn-rounded btn-red ng-binding">{{bgBtnText}}</button>
                      </div>

                    </div>
                    <div *ngIf="promotionBannerObj.background.file_type=='image'" class="mobile-main"
                      [ngStyle]="{'background-color':promotionBannerObj.background.bgColor ? promotionBannerObj.background.bgColor : '#000000', 'background-image': promotionBannerObj.background.url ? 'url(' + promotionBannerObj.background.url + ')' : ''}"
                      style="padding-left: 5px;background-color:  rgb(255, 255, 255);">
                      <!-- <div class="cross-box"> <span> X </span> </div> -->

                      <!-- <div class="header-section ng-scope" *ngIf="affiliateData.logo_url">
                          <img src="{{affiliateData.logo_url}}"> </div> -->

                      <div class="middle-section ">
                        <div class="sponsor-info">
                          <div class="">
                            <div class="loader" *ngIf="ismediaLoadding"></div>
                            <div class="loddingtxt" *ngIf="ismediaLoadding">
                              Uploading {{promotionBannerObj.background.file_type}} ...
                            </div>
                          </div>
                          <div [ngxSummernoteView]="promotionBannerObj.message_text" class="ng-binding">

                          </div>
                        </div>
                        <div class="footer-section ng-scope" *ngIf="promotionBannerObj.show_consent">
                          <button class="btn btn-full btn-rounded btn-red ng-binding">{{bgBtnText}}</button>
                        </div>
                      </div> <!-- ngIf: affiliateInfo.show_consent -->

                      <div class="footer-section ng-scope" *ngIf="promotionBannerObj.show_consent">
                        <button class="btn btn-full btn-rounded btn-red ng-binding">{{bgBtnText}}</button>
                      </div>
                    </div>
                    <!-- end ngIf: affiliateInfo.show_consent -->
                    <!-- ngIf: affiliateInfo.show_consent -->




                  </div>
                </div>


              </div>

            </form>


          </div>

        </div>

      </div>
    </div>
  </div>
</mat-dialog-content>
<div class="modal-footer">

  <button mat-raised-button class="btn btn-danger pull-right" (click)="close()">cancel</button>
  <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="saveData()">Save</button>
</div>