<div class="modal-header">
    <h5 *ngIf="isUpdate == false" class="modal-title" id="modal-basic-title">Create Affiliate </h5>
    <h4 *ngIf="isUpdate == true" class="modal-title" id="modal-basic-title">Update Affiliate</h4>

</div>

<mat-dialog-content>
    <div class="main-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="modal-body">
                        <!-- <form [formGroup]="AffiliateForm" (ngSubmit)="submitData()">

                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="email" id="email" placeholder="Email " type="text" class="form-control">
                                        </mat-form-field>
                                        

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="password" id="password" placeholder="Password" type="password" class="form-control">
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="full_name" id="" placeholder="Full Name " type="text" class="form-control">
                                        </mat-form-field>
                                        
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="logo_url" id="" placeholder="Logo Url" type="text" class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field class="example-full-width">
                                        <input matInput formControlName="term_url" id="" placeholder="Term Url" type="text" class="form-control">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="example-full-width">
                                        <input matInput formControlName="website" id="" placeholder="Website" type="text" class="form-control">
                                    </mat-form-field>
                                </div>
                            </div>

                          

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="consent" id="" placeholder="Consent Text " type="text" class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="additional_email" id="" placeholder="Additional Email " type="text" class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        
                                        <p-checkbox [binary]="true" [formControl]="AffiliateForm.controls['show_consent']" value="show_consent" (onChange)="consentFunction($event)" label="Show Consent"  inputId="ny"></p-checkbox>                                        

                                    </div>
                                    
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                           <input [value]="color"
                                           formControlName='color'
                                           [style.background]="color"
                                           [cpOutputFormat]="'rgba'"
                                           [cpFallbackColor]="'#000'" 
                                           (colorPickerChange)="onChangeColor($event)"
                                           [(colorPicker)]="color"/>
                                        </div>    
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="file">Background Image(Minimun size 375 × 812 px) </label>
                                       <p-fileUpload mode="basic" name="demo[]" url="./upload.php" accept="image/*"  (onUpload)="onBasicUpload($event)"></p-fileUpload>

                                    </div>  
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="playbtn" id="" placeholder="Button Text" type="text" class="form-control">
                                        </mat-form-field>
                                    </div>    
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div [ngxSummernote]="config" formControlName="editor" ></div>
                                    <quill-editor formControlName="editor" [modules]="config" (onContentChanged)="textShow($event)" [styles]="editorStyle" ></quill-editor>
                                </div>
                                <div class="col-md-6">
                                    <div [style.background]="color"  style="border-width: 0;  height: 500px;position: relative;">
                                        
                                        <div [ngxSummernoteView]="this.AffiliateForm.get('editor').value"  >
                                            
                                        </div>
                                       
                                          <div style="position: absolute;top: 50%;left: 15px;" *ngIf="this.AffiliateForm.value.show_consent" >
                                            <input type="checkbox" checked="true" ng-readonly="true"/>
                                            <label style="color: white; text-decoration: underline;">  {{this.AffiliateForm.get('consent').value}} </label>
                                           
                                          </div>
                                            
                                          
                                          <div style="position: absolute;bottom: 10px; left: 140px;" *ngIf="this.AffiliateForm.value.show_consent">
                                            <button class="btn btn-primary">{{this.AffiliateForm.get('playbtn').value}}</button>
                                        </div>
                                    </div>

                                </div>
                               
                                
                            </div>

                           
                            
                        </form> -->

                        <form>

                            <!-- <input type="text" name="email" [(ngModel)]="affiliateData.email"> -->
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="affiliateData.email" name="email"
                                                placeholder="Email " type="text" class="form-control">
                                        </mat-form-field>


                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="affiliateData.password" name="password"
                                                placeholder="Password" type="password" class="form-control">
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="affiliateData.full_name" name="full_name"
                                                placeholder="Full Name " type="text" class="form-control">
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="affiliateData.logo_url" name="logo_url"
                                                placeholder="Logo Url" type="text" class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field class="example-full-width">
                                        <input matInput [(ngModel)]="affiliateData.term_url" name="term_url"
                                            placeholder="Term Url" type="text" class="form-control">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="example-full-width">
                                        <input matInput [(ngModel)]="affiliateData.website" name="website"
                                            placeholder="Website" type="text" class="form-control">
                                    </mat-form-field>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="affiliateData.consent" name="consent"
                                                placeholder="Consent Text " type="text" class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="affiliateData.additional_email"
                                                name="additional_email" placeholder="Additional Email " type="text"
                                                class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">

                                        <p-checkbox [binary]="true" [(ngModel)]="affiliateData.show_consent"
                                            name="show_consent" value="show_consent" label="Show Consent" inputId="ny">
                                        </p-checkbox>

                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input [(ngModel)]="affiliateData.background.bgColor" name="bgColor"
                                            [style.background]="affiliateData.background.bgColor"
                                            [cpFallbackColor]="'#000'" (colorPickerChange)="onChangeColor($event)"
                                            [(colorPicker)]="color" style="width: 40px;" />
                                        <input type="text" [(ngModel)]="affiliateData.background.bgColor"
                                            name="bgColortxt" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="file">Background Image(Minimun size 375 × 812 px) </label>
                                        <div>
                                            <div class="file-field">
                                                <input style="display: none" type="file" (change)="onFileChange($event)"
                                                    #hiddenfileinput>
                                                <label for="modal-1" (click)="hiddenfileinput.click()"
                                                    class="modal-label" tabindex="1">Choose file</label>


                                            </div>
                                        </div>
                                        <!-- <p-fileUpload mode="basic" [showCancelButton]="true" [showUploadButton]="true" name="demo[]" cancelLabel="Cancel" [auto]="true" accept="image/*" multiple="multiple" onUpload="onuploadTest($event)" (onSelect)="onFileChange($event)"></p-fileUpload> -->
                                        <!-- <input type="file" name="file" id="file"  (change)=onFileChange($event)> -->



                                        <div *ngIf="affiliateData.background.bgImage"
                                            style="padding: 10px;position: relative;display: flex;margin-top: 45px;">
                                            <span style="position: absolute;
                                        top: 0px;
                                        right: -5px;
                                        font-size: 24px;
                                        color: red;
                                        width: 30px;
                                        height: 30px;
                                        text-align: center;" (click)="clearBgImage()"> &times;</span>
                                            {{affiliateData.background.bgImage}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [(ngModel)]="affiliateData['background'].bgBtnText"
                                                name="bgBtnText" placeholder="Button Text" type="text"
                                                class="form-control">
                                        </mat-form-field>

                                    </div>

                                </div>
                            </div>

                            <!-- <mat-progress-spinner mode="indeterminate" style="margin:0 auto;" *ngIf="IsWait">
                            </mat-progress-spinner> -->
                            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
                                [fullScreen]="true">
                                <p style="color: white"> Loading... </p>
                            </ngx-spinner>
                            <div class="row">
                                <div class="col-md-6">
                                    <div [ngxSummernote]="config" [(ngModel)]="affiliateData.display_text"
                                        name="display_text" id="summernote" (summernoteInit)="summernoteInit($event)">
                                    </div>

                                    <!-- <quill-editor formControlName="editor" [modules]="config" (onContentChanged)="textShow($event)" [styles]="editorStyle" ></quill-editor> -->
                                </div>
                                <div class="col-md-6">
                                    <div flex="100" style="padding-left:5px" class="mobile-container flex-100">
                                        <div class="mobile-main"
                                            [ngStyle]="{'background-color':affiliateData.background.bgColor ? affiliateData.background.bgColor : '#000000', 'background-image': affiliateData.background.bgImage ? 'url(' + affiliateData.background.bgImage + ')' : ''}"
                                            style="background-color: rgb(0, 0, 0); ">
                                            <div class="cross-box"> <span> X </span> </div>

                                            <div class="header-section ng-scope" *ngIf="affiliateData.logo_url">
                                                <img src="{{affiliateData.logo_url}}"> </div>
                                            <div class="middle-section">
                                                <div class="sponsor-info">
                                                    <div [ngxSummernoteView]="affiliateData.display_text"
                                                        class="ng-binding">

                                                    </div>
                                                </div>
                                            </div> <!-- ngIf: affiliateInfo.show_consent -->
                                            <div class="checkConsent ng-scope" *ngIf="affiliateData.show_consent">
                                                <input type="checkbox" checked="true" ng-readonly="true" />
                                                <label style="color: white; text-decoration: underline;">
                                                    {{this.affiliateData.consent}} </label> </div>
                                            <!-- end ngIf: affiliateInfo.show_consent -->
                                            <!-- ngIf: affiliateInfo.show_consent -->
                                            <div class="footer-section ng-scope" *ngIf="affiliateData.show_consent">
                                                <button
                                                    class="btn btn-full btn-rounded btn-red ng-binding">{{this.affiliateData.background.bgBtnText}}</button>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>

                        </form>


                    </div>

                </div>

            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer">

    <button mat-raised-button class="btn btn-danger pull-right" (click)="close()">cancel</button>
    <button mat-raised-button type="submit" class="btn btn-danger pull-right"
        (click)="saveAffiliateData()">Save</button>
</div>