<!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"> Transaction Info</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Transaction Info</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<mat-dialog-content>

    <div>


        <p-table [value]="alldata" [rows]="10" [rowsPerPageOptions]="[10,25,50]" [responsive]="true" [loading]="loading"
            [paginator]="true" styleClass="ui-table-cars">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:100px">Point</th>
                    <th style="width:350px">Description</th>
                    <th>Start Date</th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>

                    <td style="width:100px">{{rowData.amount}}</td>
                    <td style="width:350px">{{rowData.notes}}</td>
                    <td>{{rowData.createdAt | date:'mediumDate'}}</td>


                </tr>
            </ng-template>
        </p-table>

    </div>

</mat-dialog-content>

<!-- <mat-dialog-actions layout="row" class="layout-row">
    <button mat-button mat-dialog-close class='btn btn-primary'>Cancel</button>
</mat-dialog-actions> -->