<div class="row" *ngIf="is_parent">
  <div class="col-md-8">
    <mat-form-field class="example-full-width">
      <mat-label>{{label}}</mat-label>
      <input type="text" placeholder="" aria-label="Parent user" matInput (input)="searchUsers($event)"
        [(ngModel)]="parent_user_name" id="parent_user_name" [matAutocomplete]="auto" name="parent_user_name">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setUserName($event)">
        <mat-option *ngFor="let user of userList" [value]="user.user_name" [id]="user.id">
          {{user.user_name}}
          <span style="font-size: 11px;font-style: italic;">{{user.email}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <mat-form-field>
      <mat-label> {{txt_label}} </mat-label>
      <input matInput [(ngModel)]="this.parent.percentage" type="number" (change)=changePercentage() min="0"
        name="cpc" />
    </mat-form-field>
  </div>
  <div class="col-md-1">
    <div class="form-group">
      <button type="button" class="close" aria-label="Close" style="margin-top: -8px;" (click)="close(index)">
        <span aria-hidden="true">&times;</span>
      </button>

    </div>
  </div>
</div>

<div class="row" *ngIf="is_cpc_parent">
  <div class="col-md-8">
    <mat-form-field class="example-full-width">
      <mat-label>{{label}}</mat-label>
      <input type="text" placeholder="" aria-label="Parent user" matInput (input)="searchUsers($event)"
        [(ngModel)]="cpc_parent_user_name" id="cpc_parent_user_name" [matAutocomplete]="auto"
        name="cpc_parent_user_name">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setCPCUserName($event)">
        <mat-option *ngFor="let user of userList" [value]="user.user_name" [id]="user.id">
          {{user.user_name}}
          <span style="font-size: 11px;font-style: italic;">{{user.email}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="col-md-3">
    <mat-form-field>
      <mat-label> {{txt_label}} </mat-label>
      <input matInput [(ngModel)]="this.CPCParent.percentage" type="number" (change)=changeCPCPercentage() min="0"
        name="cpc" />
    </mat-form-field>
  </div>
  <div class="col-md-1">
    <div class="form-group">
      <button type="button" class="close" aria-label="Close" style="margin-top: -8px;" (click)="closeCPC(CPCIndex)">
        <span aria-hidden="true">&times;</span>
      </button>

    </div>
  </div>
</div>