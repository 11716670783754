import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SourceType } from '../../../core/models/api/source-type';
import { SourceTypeService } from '../../../core/services/source-type.service'
import { SourceTypeEditModalComponent } from '../../../modal/source-type-edit-modal/source-type-edit-modal.component'
import { SelectItem } from 'primeng/api';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-source-type',
  templateUrl: './source-type.component.html',
  styleUrls: ['./source-type.component.scss']
})
export class SourceTypeComponent implements OnInit {
  loading: Boolean;
  cols: any;
  sourceType: SourceType;
  closeResult: string;
  selectedSourceType: SourceType;
  isUpdate: Boolean = true;
  characterType: SelectItem;
  width: number = 750;
  height: number = 530;
  modaleId: string = "SourceTypeEditModalComponent";
  constructor(private sourceTypeService: SourceTypeService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loading = true;
    this.cols = [
      { field: 'source_name', header: 'Source Name', width: "500px" },
      // { field: 'brand', header: 'Brand' },
      { field: 'source_code', header: 'Source Code', textalign: 'center', width: "100px" },
      { field: 'weight', header: 'Weight', textalign: 'center', width: "100px" },
      { field: '', header: 'Action', textalign: 'center', width: "80px" }

    ];
    this.getData();
  }
  onRefrash(e) {
    setTimeout(() => {
      this.getData();
    }, 1500);

  }

  customSort(event) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }
  openDialog(characterData): void {
    const dialogRef = this.dialog.open(SourceTypeEditModalComponent, {
      width: '650px',
      data: characterData
    });

    dialogRef.afterClosed().subscribe(result => {
      //  console.log('The dialog was closed');
    });
  }
  getData() {
    this.sourceTypeService.getSourceTypeData().subscribe(data => {

      const resp = JSON.stringify(data);
      this.sourceType = JSON.parse(resp);
      this.loading = false;
    });
  }

}
