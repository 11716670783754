

import { Component, OnInit, Inject } from '@angular/core';
import { AffiliateServiceService } from '../../core/services/affiliate/affiliate-service.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { } from 'dns';
@Component({
  selector: 'app-aff-analytics',
  templateUrl: './aff-analytics.component.html',
  styleUrls: ['./aff-analytics.component.scss']
})
export class AffAnalyticsComponent implements OnInit {
  loading: Boolean;
  cols: any;
  affAnalyticsData: any;
  totalRecords: any;
  closeResult: string;
  selectedAffAnalyticsData: any;
  constructor (public dialogRef: MatDialogRef<AffAnalyticsComponent>, private modalService: NgbModal, private affiliateServiceService: AffiliateServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) { }
  ngOnInit (): void {
    this.loading = true;
    this.cols = [
      { field: 'req_url', header: 'URL', width: "250px" },

      { field: 'origin', header: 'Website', width: "200px" },
      // { field: 'brand', header: 'Brand' },

      { field: 'created_at', header: 'Created At', width: "150px" },


    ];
    this.getData(this.data.id)
  }
  onRefrash (e) {
    setTimeout(() => {
      this.getData(this.data.id)
    }, 1500);

  }

  customSort (event) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  getData (id) {
    this.affiliateServiceService.getAffiliateAnalytics(id).subscribe(data => {

      this.affAnalyticsData = data['result'];
      this.totalRecords = data['total'] || data['result'].length;
      this.loading = false;
    });
  }
  close (): void {
    this.dialogRef.close(true);
  }
}
