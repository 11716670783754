import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor() { }

  searchFun(val, field, filter) {
    let obj = {};
    let pagination = {};
    // if (field == 'first_name') {
    //   field = 'name'
    // }
    if (filter.search) {
      const searchObj = {};
      searchObj[field] = val;
      filter.search = Object.assign({}, filter.search, searchObj);

    } else {
      obj[field] = val;
      filter.search = obj;
    }
    if (filter.pagination) {

      pagination = { limit: filter.pagination.limit, page: 1 };
    }
    else {
      pagination = { limit: 10, page: 1 };
    }

    filter['pagination'] = pagination;

    return { filter: filter }
  }
}
