import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import lodash from 'lodash';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TostService } from '../../modules/core/services/common/tost.service';
import { AuthenticationService } from '../services/authentication.service';
@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService, private tostService: TostService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with basic auth credentials if available
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //  console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const errors = lodash.get(error, 'error.errors');
                let err;
                if (!errors || !errors.length) {
                    err = [{ message: error.statusText }];
                } else {
                    err = errors;
                }
                const msg = lodash.get(err, '0.message');
                this.tostService.tostrFun(msg, 'error');


                return throwError(error);

            }));
    }
}