<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">User Wallet Summary Report </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content>

  <div class="row">
    <div class="col-md-6">
      <div class="heading"> Wallet Amount Type Report:</div>
      <p-table [loading]="loading" [responsive]="true" [value]="walletAmtTypeResults">

        <ng-template pTemplate="header">
          <tr>

            <th style="width: 50px;">Amount Type</th>
            <th style="width: 60px;">Total Amount</th>
            <th style="width: 50px;">User Display Amount</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-walletAmtTypeResult>
          <tr>

            <td style="width: 50px;">{{walletAmtTypeResult.amount_type}}</td>
            <td style="width: 60px;">{{walletAmtTypeResult.sum}}</td>
            <td style="width: 50px;">{{walletAmtTypeResult.user_display_balance}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3" style="text-align:left">No data found.</td>
          </tr>
        </ng-template>
      </p-table>
      <button [disabled]="isWalletFix" class="btn btn-success heading" (click)="walletBalFixClick()">Wallet Bal
        Fix</button>
    </div>
    <div class="col-md-6">
      <div class="heading"> Pending Amount Type Report :</div>
      <p-table [loading]="loading" [responsive]="true" [value]="pendingAmtTypeResults">

        <ng-template pTemplate="header">
          <tr>

            <th style="width: 50px;">Amount Type</th>
            <th style="width: 60px;">Total Amount</th>
            <th style="width: 50px;">User Display Amount</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-walletAmtTypeResult>
          <tr>

            <td style="width: 50px;">{{walletAmtTypeResult.amount_type}}</td>
            <td style="width: 60px;">{{walletAmtTypeResult.sum}}</td>
            <td style="width: 50px;">{{walletAmtTypeResult.user_display_balance}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2" style="text-align:left">No data found.</td>
          </tr>
        </ng-template>
      </p-table>
      <button [disabled]="isPendingFix" class="btn btn-success heading" (click)="pendingBalFixClick()">Pending Bal
        Fix</button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="heading"> Wallet Summary Report :</div>

      <p-table [loading]="loading" [responsive]="true" [value]="walletSummeryResults">

        <ng-template pTemplate="header">
          <tr>

            <th style="width: 60px;">Amount Type</th>
            <th style="width: 100px;">Transaction Type</th>
            <th style="width: 60px;">Total Amount</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-walletSummeryResult>
          <tr>

            <td style="width: 60px;">{{walletSummeryResult.amount_type}}</td>
            <td style="width: 100px;">{{walletSummeryResult.tx_type}}</td>
            <td style="width: 60px;">{{walletSummeryResult.sum}}</td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3" style="text-align:left">No data found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
  <div class="row">
    <div class="col-md-12">

    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="heading"> Pending Summary Report :</div>

      <p-table [loading]="loading" [responsive]="true" [value]="pendingSummeryResults">

        <ng-template pTemplate="header">
          <tr>

            <th style="width: 60px;">Amount Type</th>
            <th style="width: 100px;">Transaction Type</th>
            <th style="width: 60px;">Total Amount</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-walletSummeryResult>
          <tr>

            <td style="width: 60px;">{{walletSummeryResult.amount_type}}</td>
            <td style="width: 100px;">{{walletSummeryResult.tx_type}}</td>
            <td style="width: 60px;">{{walletSummeryResult.sum}}</td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3" style="text-align:left">No data found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
  <p></p>
  <p></p>
</mat-dialog-content>
<div class="clearfix"></div>