import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GetTransactionService } from '../../../core/services/users/get-transaction.service';
import { Transaction } from '../../../../modules/core/models/api/transaction';
import { TostService } from '../../../core/services/common/tost.service';
import * as moment from 'moment';
@Component({
  selector: 'app-user-pending-modal',
  templateUrl: './user-pending-modal.component.html',
  styleUrls: ['./user-pending-modal.component.scss']
})
export class UserPendingModalComponent implements OnInit {

  cols: any;
  frozenCols: any[];
  userTransaction: Transaction;
  loading: boolean;

  constructor (private modalService: NgbModal, public dialogRef: MatDialogRef<UserPendingModalComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private transactionService: GetTransactionService) { }

  ngOnInit (): void {
    this.cols = [
      { field: 'id', header: 'Id', width: '80px' },
      { field: 'game_id', header: 'Game Id', width: '80px' },
      { field: 'game_title', header: 'Game Title', width: '100px' },
      { field: 'amount', header: 'Amount', width: '100px' },
      { field: 'created_at', header: 'Created Date', width: '100px' },
      { field: 'when_will_wallet', header: 'When Will In Wallet', width: '100px' },
      { field: 'status', header: 'Status', width: '90px' },

    ];
    if (this.data && this.data.id) {

      //   this.transactionService.getTransactionData(this.data.id).subscribe(data => {
      //     const res = JSON.stringify(data);
      //     const getData = (JSON.parse(res)).userTransactionList;
      //     const getUserTransactionData = JSON.stringify(getData);
      //     this.userTransaction = JSON.parse(getUserTransactionData);

      //   });
      this.loading = true;

      this.transactionService.getPendingTransactionData(this.data.id).subscribe(
        data => {
          const res = JSON.stringify(data);
          this.userTransaction = JSON.parse(res);
          this.loading = false;
        },
        err => {
          this.loading = false;
        });

    }



  }
  close (): void {
    this.dialogRef.close(true);
  }

  dateCovert (date) {
    return moment(date).format("DD/MM/YYYY HH:mm")
  }
}
