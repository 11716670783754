import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelfgameService } from '../../core/services/game/selfgame.service';
import { ActivatedRoute, Router } from '@angular/router';
import { idText } from 'typescript';

@Component({
  selector: 'app-selfgame-reported-modal',
  templateUrl: './selfgame-reported-modal.component.html',
  styleUrls: ['./selfgame-reported-modal.component.scss']
})
export class SelfgameReportedModalComponent implements OnInit {
  alldata: any = [];
  loading: boolean;
  status: any;
  constructor(private router: Router, private selfGameService: SelfgameService, public dialogRef: MatDialogRef<SelfgameReportedModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.loading = true;
    this.status = this.data.status;

    this.getReportedRecord(this.data.id);
  }

  redirectToUser() {

    //// this.router.navigate(['/user/', id]);
    this.dialogRef.close();
  }

  getReportedRecord(id) {

    this.selfGameService.getReportedRecorded(id).subscribe(data => {
      //  console.log("reported data ", data);
      const res = JSON.stringify(data);

      const getData = (JSON.parse(res).reportsList);
      const getCharData = JSON.stringify(getData);
      this.alldata = JSON.parse(getCharData);
      this.loading = false;
    },
      err => {
        // this.dialogRef.close()
        this.loading = false;
      });
    // this.close();
  }

  abortReport(event, rowData) {

  }
  close() {
    this.dialogRef.close(true);
  }
  onRefrash(e) {
    setTimeout(() => {
      this.getReportedRecord(this.data.id)
    }, 1500)

  }

  isStatus(e) {
    let status = (e == 0 || e == 4 || e == 7) ? true : false;
    return status
  }

}
