import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../../config';
import { PromoCode } from '../../models/api/dto/promoCode'
import { DataService } from '../../services/base-service'
import { BaseService } from '../../../../core/services/base.service'
@Injectable({
  providedIn: 'root'
})
export class InviteCodeService {

  constructor(private httpClient: HttpClient, private dataService: DataService, private baseService: BaseService) { }

  //   const url = Endpoints.api.user.inviteCode;
  // const data = this.dataService.post({ url: url, postObj: inviteObj, isHandleErr: true })
  //   const observable = this.dataService.post({ url: url, postObj: inviteObj, success: success ? success : this.isSuccess, failure: failure ? failure : this.isFailure })
  //   // const observable = this.httpClient.post<PromoCode>(url,inviteObj);

  //   // return observable;

  //   return observable;
  // }

  addInviteCode(inviteObj) {
    const url = Endpoints.api.user.inviteCode;

    const observable = this.baseService.post(url, inviteObj)
    // const observable = this.httpClient.post<PromoCode>(url,inviteObj);

    // return observable;

    return observable;
  }
}
