import { environment } from '../../environments/environment';

class Endpoints {
  static baseUrl = environment.baseUrl;
  static baseApiUrl = environment.baseApiUrl;

  static forntend = {
    singUp: 'sing-up',
    login: 'login',
    home: 'home',
    about: 'about',
    error: 'error',
  };


  static api = {
    files: {
      image: Endpoints.baseApiUrl + 'File/',
      byEntity: Endpoints.baseApiUrl + 'File/{entity}/{id}',
      addFile: Endpoints.baseApiUrl + 'File',
      file: Endpoints.baseApiUrl + 'File/{entity}/{business_ObjectId}',
      delete: Endpoints.baseApiUrl + 'File/{business_ObjectId}'
    },

    adminDashboard: {
      // getUser: Endpoints.baseApiUrl + 'adminDashboard'
      getUser: Endpoints.baseApiUrl + 'global/community-statistics',
      dashboardStatus: Endpoints.baseApiUrl + 'dashbordstats',
      getSkrillBal: Endpoints.baseApiUrl + 'skrill/balance'
    },
    adminLogin: {
      login: Endpoints.baseApiUrl + 'adminlogin'
    },
    adminUpdatePassword: {
      update: Endpoints.baseApiUrl + 'admin/update/password'
    },
    user: {
      getAllUser: Endpoints.baseApiUrl + 'getAllUsers?pagination={pagination}&search={search}&sort={sort}',
      transaction: Endpoints.baseApiUrl + 'getUserTransaction?id={userId}',
      userTransaction: Endpoints.baseApiUrl + 'user/transaction/{userId}?pagination={pagination}&search={search}&sort={sort}',
      userTransactionCSV: Endpoints.baseApiUrl + 'user/wallet/transaction/download/{userId}?&search={search}',
      inviteCode: Endpoints.baseApiUrl + 'user/invite-codes',
      getUserById: Endpoints.baseApiUrl + 'user/invite-codes/{id}',
      deleteInviteCodeById: Endpoints.baseApiUrl + 'user/invite-codes/{id}',
      unblockUser: Endpoints.baseApiUrl + 'unblockUser',
      blockUser: Endpoints.baseApiUrl + 'blockUser',
      createPayout: Endpoints.baseApiUrl + 'createPayout',
      createSkrillPayout: Endpoints.baseApiUrl + 'create-skrill-payout',
      getFollowCount: Endpoints.baseApiUrl + 'following/getfollowcount/{user_id}',
      getFollowerFollowingIds: Endpoints.baseApiUrl + 'following/getFollowerFollowingIds/{user_id}',
      getUserSettings: Endpoints.baseApiUrl + 'users/settings/{user_id}',
      updateUserSettings: Endpoints.baseApiUrl + 'users/settings',
      updateGameSettings: Endpoints.baseApiUrl + 'games/settings',
      impersonateUser: Endpoints.baseApiUrl + 'impersonateuser/password',
      getCountryList: Endpoints.baseApiUrl + 'user/country/list',
      getStateList: Endpoints.baseApiUrl + 'user/states/by_country_code/{country_code}',
      getBlackListCountryState: Endpoints.baseApiUrl + 'blacklist/country-state/all?pagination={pagination}&search={search}&sort={sort}',
      saveBlackListCountryState: Endpoints.baseApiUrl + 'blacklist/country-state/save',
      pendingTransaction: Endpoints.baseApiUrl + 'user/pending/wallet/{userId}',
      walletSummaryReport: Endpoints.baseApiUrl + 'user/wallet/summary/{userId}',
      fixWalletBalance: Endpoints.baseApiUrl + 'user/wallet/fix/{userId}',
      fixPendingBalance: Endpoints.baseApiUrl + 'user/pending/fix/{userId}',
      creatorSearch: Endpoints.baseApiUrl + 'creators/search',
      adminManager: {
        user: Endpoints.baseApiUrl + 'admin/manager/user/all?pagination={pagination}&search={search}&sort={sort}',
        userRoles: Endpoints.baseApiUrl + 'admin/user/roles',
        userCreate: Endpoints.baseApiUrl + 'admin/manager/user/create',
        userUpdate: Endpoints.baseApiUrl + 'admin/manager/user/update'
      },

    },
    payoutList: {
      getPayoutList: Endpoints.baseApiUrl + 'payout-requests/all?pagination={pagination}&search={search}&sort={sort}',
      getPayoutListStatus: Endpoints.baseApiUrl + 'payout-requests/get-status-list',
      getTransaction: Endpoints.baseApiUrl + 'getUserTransaction?id',
      payoutCancel: Endpoints.baseApiUrl + 'payout/cancel',
      initiatePayout: Endpoints.baseApiUrl + 'payout/initiate',
      getPayoutEmailByUserId: Endpoints.baseApiUrl + 'payout/emails/{userid}',
      getInstantPayoutList: Endpoints.baseApiUrl + 'instant/payout-requests/all?pagination={pagination}&search={search}&sort={sort}',
    },
    sourceType: {
      get: Endpoints.baseApiUrl + 'sourcetype/get',
      sourceTypeUpdate: Endpoints.baseApiUrl + 'sourcetype/update',

    },
    pinnedQuestion: {
      getPinnedQuestionList: Endpoints.baseApiUrl + 'questions/pinned/get?pagination={pagination}&search={search}&sort={sort}',

    },

    refferenceData: {
      refDataApp: Endpoints.baseApiUrl + 'app/refdata',
      refData: Endpoints.baseApiUrl + 'company/refdata'

    },
    gameData: {
      games: Endpoints.baseApiUrl + 'games',
      saveGameSlushFund: Endpoints.baseApiUrl + 'game/slush/save'

    },
    settingsData: {
      settings: Endpoints.baseApiUrl + 'system/settings',
      createSettings: Endpoints.baseApiUrl + 'system/settings/create',
      updateSettings: Endpoints.baseApiUrl + 'system/settings/update',

    },
    systemSettings: {
      getGlobalCutSettings: Endpoints.baseApiUrl + 'cut/settings/global',
      getUserGameCutSettings: Endpoints.baseApiUrl + '/cut/settings/user-game',
      saveCutSettings: Endpoints.baseApiUrl + '/cut/settings/save'
    },

    leagueCategory: {
      getLeaguecategoryList: Endpoints.baseApiUrl + 'gamecategories/get?pagination={pagination}&search={search}&sort={sort}',

    },
    pinnedGame: {
      getPinnedGameList: Endpoints.baseApiUrl + 'games/pinned/all?pagination={pagination}&search={search}&sort={sort}',

    },
    coinRate: {
      getCoinRateList: Endpoints.baseApiUrl + 'get/coin-rate',
      updateCoinRate: Endpoints.baseApiUrl + 'set/coin-rate'

    },
    common: {
      allowedCountries: Endpoints.baseApiUrl + 'allowed-countries',
      stateList: Endpoints.baseApiUrl + 'state-list?country_code={country_code}',
      tagsList: Endpoints.baseApiUrl + '/tags/list?offset={offset}&limit={limit}&search={search}',
      tags: Endpoints.baseApiUrl + 'tags/all?type={type}',
      fileUpload: Endpoints.baseApiUrl + 'file-upload',
      fileUploadJobStatus: Endpoints.baseApiUrl + 'job/status/{jobId}'
    },

    customGame: {
      getCustomGameList: Endpoints.baseApiUrl + 'games/custom/all?pagination={pagination}&search={search}&sort={sort}',

    },
    notification: {

      getNotificationList: Endpoints.baseApiUrl + 'system/notification/all',
      createNotification: Endpoints.baseApiUrl + 'system/notification/create',
      updateNotification: Endpoints.baseApiUrl + 'system/notification/update',
      deleteNotification: Endpoints.baseApiUrl + 'system/notification',
      getAllNotifyGroups: Endpoints.baseApiUrl + '/notifications/group/list',
      getAllNotifyTypes: Endpoints.baseApiUrl + '/notifications/type/list?pagination={pagination}&search={search}&sort={sort}',
      getAllNotifyTypeSave: Endpoints.baseApiUrl + '/notifications/type/save',


    },
    customQuestion: {
      getCustomQuestionList: Endpoints.baseApiUrl + 'questions/custom/all?pagination={pagination}&search={search}&sort={sort}',

    },
    feeCutAndSSG: {

      getFeeCutList: Endpoints.baseApiUrl + 'self/games/fees/getall?pagination={pagination}&sort={sort}',
      createFeeCut: Endpoints.baseApiUrl + 'self/games/fees/create',
      updateFeeCut: Endpoints.baseApiUrl + 'self/games/fees/update',
      deleteFeeCut: Endpoints.baseApiUrl + 'self/games/fees/delete'

    },
    selfGame: {
      getSelfGameList: Endpoints.baseApiUrl + 'self/games/usersGames?pagination={pagination}&search={search}&sort={sort}',
      selfGameDisputed: Endpoints.baseApiUrl + 'self/games/dispute',
      selfGameReported: Endpoints.baseApiUrl + 'self/games/report',
      selfGameCancel: Endpoints.baseApiUrl + 'self/games/cancel/{id}',
      selfGamePerformance: Endpoints.baseApiUrl + 'games/summary',
      selfGamesReportsIgnore: Endpoints.baseApiUrl + 'self/games/reports/ignore',
      selfGamesDisputesIgnore: Endpoints.baseApiUrl + 'self/games/disputes/ignore',
      selfGamesSwitchWinner: Endpoints.baseApiUrl + 'self/games/switch-winner',
      selfGameDeclareWinner: Endpoints.baseApiUrl + 'self/games/declare'

    },
    games: {
      toggleGowt: Endpoints.baseApiUrl + 'games/set-gowt/{id}',
      custom: {
        all: Endpoints.baseApiUrl + 'games/custom/all?pagination={pagination}&search={search}&sort={sort}',
        create: Endpoints.baseApiUrl + 'games/custom/create',
        update: Endpoints.baseApiUrl + 'games/custom/update',
        getById: Endpoints.baseApiUrl + 'games/{id}',
        declareWinner: Endpoints.baseApiUrl + '/customgame/declareWinner'
      },
      pinned: {
        all: Endpoints.baseApiUrl + 'games/pinned/all?pagination={pagination}&search={search}&sort={sort}',
        create: Endpoints.baseApiUrl + 'games/pinned/create',
        update: Endpoints.baseApiUrl + 'games/pinned/update',
        getById: Endpoints.baseApiUrl + 'games/{id}'
      },
      tagsList: Endpoints.baseApiUrl + 'tags/all?search=true&q={text}&type={type}&game_id={gameId}&sort={sort}&limit={limit}&offset={offset}',
      getGameTags: Endpoints.baseApiUrl + 'games/tags/all/{gameId}',
      updateGameTag: Endpoints.baseApiUrl + 'games/tag/update',
      toggleFreeonly: Endpoints.baseApiUrl + 'games/free-only/{id}',
      saveTag: Endpoints.baseApiUrl + 'tags/save',
      saveCategoryTag: Endpoints.baseApiUrl + 'category/tag/save',
      categoryTagList: Endpoints.baseApiUrl + 'category/tag/all?pagination={pagination}&search={search}&sort={sort}',
      categoryTagById: Endpoints.baseApiUrl + 'categorytags/{id}',
      categoryDelete: Endpoints.baseApiUrl + 'categorytags/delete/{id}',
      deleteTag: Endpoints.baseApiUrl + 'tags/delete',
      categoriesSearch: Endpoints.baseApiUrl + 'categories/search',
      trendingTagList: Endpoints.baseApiUrl + 'trending/tag/all?pagination={pagination}&search={search}&sort={sort}',
      saveTrendingTag: Endpoints.baseApiUrl + 'trending/tag/save',
      trendingDelete: Endpoints.baseApiUrl + '/trending/tag/delete/{id}',
      toggleBoostGame: Endpoints.baseApiUrl + '/games/boost-game/{id}',
      toggleBotsForGame: Endpoints.baseApiUrl + '/games/toggle-bots/{id}',
      superTagList: Endpoints.baseApiUrl + 'super/tag/all?pagination={pagination}&search={search}&sort={sort}',
      saveSuperTag: Endpoints.baseApiUrl + 'super/tag/save',
      removeSuperTag: Endpoints.baseApiUrl + 'super/tag/delete/{id}',
    },
    levels: {

      getLevelsList: Endpoints.baseApiUrl + 'level/all',
      createLevel: Endpoints.baseApiUrl + 'level/create',
      updateLevel: Endpoints.baseApiUrl + 'level/update',
    },
    characters: {
      get: Endpoints.baseApiUrl + 'characters/get?pagination={pagination}&search={search}&sort={sort}',
      characterImageUpload: Endpoints.baseApiUrl + 'characters/image/edit',
      getGallaryImage: Endpoints.baseApiUrl + 'characters/get',
      createCharacter: Endpoints.baseApiUrl + 'characters/create',
      updateCharacter: Endpoints.baseApiUrl + 'characters/update',
      removeCharacter: Endpoints.baseApiUrl + 'characters/delete'
    },
    promoCode: {
      getPromoCodeTable: Endpoints.baseApiUrl + 'promocode/all',
      deletePromocodeTableRecord: Endpoints.baseApiUrl + `promocode/delete`,
      createPromocode: Endpoints.baseApiUrl + `promocode/create`,
      updatePromoCode: Endpoints.baseApiUrl + `promocode/update`,
      userSearch: Endpoints.baseApiUrl + `user/search`,
    },
    affiliate: {
      getAffiliate: Endpoints.baseApiUrl + 'affiliates?pagination={pagination}&search={search}&sort={sort}',
      affiliateGameList: Endpoints.baseApiUrl + 'affiliate/games/{id}?pagination={pagination}&search={search}&sort={sort}',
      downloadUserList: Endpoints.baseApiUrl + 'affiliates/users/export?game_id={gameId}&token={token}',
      imageAffiliate: Endpoints.baseApiUrl + 'affiliates/image-upload',
      affiliateSummary: Endpoints.baseApiUrl + "affiliates-list/summary",
      getAffiliateApiKey: Endpoints.baseApiUrl + 'affiliate/api-key/{id}',
      updateAffiliateApiKey: Endpoints.baseApiUrl + 'affiliate/api-key/update',
      createAffiliate: Endpoints.baseApiUrl + 'affiliates/create',
      updateAffiliate: Endpoints.baseApiUrl + 'affiliates/update',
      getBanner: Endpoints.baseApiUrl + 'promotionbanners/getallbanners?pagination={pagination}&search={search}&sort={sort}',
      getBannerById: Endpoints.baseApiUrl + 'promotionbanners/getbanner/{id}',
      createUpdateBanner: Endpoints.baseApiUrl + 'promotionbanners/createupdate',
      deleteBanner: Endpoints.baseApiUrl + 'promotionbanners/deletebanner/{id}',
      getBannerGameId: Endpoints.baseApiUrl + 'banner/game/ids?search={search}',
      createPartnerAffiliate: Endpoints.baseApiUrl + 'partner/affiliate/create',
      updatePartnerAffiliate: Endpoints.baseApiUrl + 'partner/affiliate/update',
      getPartnerAffiliate: Endpoints.baseApiUrl + 'partner/affiliate/all?pagination={pagination}&search={search}&sort={sort}',
      getAffiliateAnalytics: Endpoints.baseApiUrl + 'partner/affiliate/analytics/get?aff_id={id}'
    }

  }
}

export { Endpoints };
