import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GamebanklistComponent } from './gamebanklist/gamebanklist.component';
import { ModalModule } from '../modal/modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CharctersRoutingModule } from '../charcters/charcters-routing.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext'
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from 'ngx-clipboard';
import { MultiSelectModule } from 'primeng/multiselect'
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { ActionComponentModule } from '../action-component/action-component.module'
import { MatCardModule } from '@angular/material/card';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TagsComponent } from './tags/tags.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CategoryTagsScreenComponent } from './category-tags-screen/category-tags-screen.component';
import { TrendingTagsScreenComponent } from './trending-tags-screen/trending-tags-screen.component';
import { SuperTagsScreenComponent } from './super-tags-screen/super-tags-screen.component';


@NgModule({
  declarations: [GamebanklistComponent, TagsComponent, CategoryTagsScreenComponent, TrendingTagsScreenComponent, SuperTagsScreenComponent],
  imports: [
    CommonModule,
    CharctersRoutingModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    InputTextModule,
    MatSelectModule,
    ClipboardModule,
    MatTooltipModule,
    HttpClientModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    ModalModule,
    RadioButtonModule,
    CalendarModule,
    InfiniteScrollModule,
    NgxSpinnerModule,
    ActionComponentModule

  ],
})
export class GameBankModule { }
