<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Declare Winner Note </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div [ngxSummernote]="config" [(ngModel)]="notes" name="display_text" id="summernote"></div>
</div>

<div class="modal-footer">

    <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="saveNotes()">Submit</button>
</div>