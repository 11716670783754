import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FeecutService } from '../../core/services/league/feecut.service';

import { TostService } from '../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-edit-feecut-modal',
  templateUrl: './create-edit-feecut-modal.component.html',
  styleUrls: ['./create-edit-feecut-modal.component.scss']
})
export class CreateEditFeecutModalComponent implements OnInit {
  action: string;
  feeCutForm = new FormGroup({

    entry_fee: new FormControl('', [Validators.required]),
    ugg_cut: new FormControl('', [Validators.required]),
    prize_money: new FormControl('', [Validators.required]),
    is_active: new FormControl(''),
    is_locked: new FormControl(''),
  });

  constructor(private spinner: NgxSpinnerService, private feecutService: FeecutService, public dialogRef: MatDialogRef<CreateEditFeecutModalComponent>, private modalService: NgbModal, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService) { }

  ngOnInit(): void {

    if (this.data) {
      //  console.log("ssg cute edit data",this.data)
      this.feeCutForm.patchValue({
        entry_fee: this.data.entry_fee,
        ugg_cut: this.data.ugg_cut,
        prize_money: this.data.prize_money,
        is_active: this.data.is_active,
        is_locked: this.data.is_locked,
      });

    } else {
      this.feeCutForm.patchValue({
        entry_fee: '',
        ugg_cut: '',
        prize_money: '',
        is_active: '',
        is_locked: '',

      });
    }
  }

  submitData() {
    this.spinner.show();

    //  console.log('feecut val',this.feeCutForm.value)
    if (this.data == null) {
      this.action = "create";
      //  console.log("action value on create",this.action);

    } else if (this.data.id) {
      this.action = "update";
      //  console.log("action value on update",this.action);
      // this.levelForm.value.id = this.data.id;
      let formValues = this.feeCutForm.value;
      Object.assign(formValues, {
        id: this.data.id,
        createdAt: this.data.createdAt,
        updatedAt: this.data.updatedAt,
        deletedAt: this.data.deletedAt

      });

    }

    let formValues = this.feeCutForm.value;
    this.feecutService.createUpdateFeecut(formValues, this.action).subscribe(data => {
      //   console.log("create feecut data",data);
      if (this.action == 'update') {
        this.tostService.tostrFun("Fee Cut updated Successfully", 'success');
        setTimeout(() => {
          this.spinner.hide();
          this.dialogRef.close(true);
        }, 3000)

      } else {
        this.tostService.tostrFun("Fee Cut Created Successfully", 'success');
        setTimeout(() => {
          this.spinner.hide();
          this.dialogRef.close(true);
        }, 3000)
      }

    },
      err => {
        this.tostService.tostrFun("Something Went Wrong", 'error');
        setTimeout(() => {
          this.spinner.hide();
          this.dialogRef.close();
        }, 3000)
      }
    )
    // this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close(true);
  }

}
