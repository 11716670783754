import { Injectable } from '@angular/core';
import { coinRate } from '../../data/coinRate';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../../config';
@Injectable({
  providedIn: 'root'
})
export class CoinRateService {

  constructor(private httpClient: HttpClient) { }
  getCoinRateData() {
    return coinRate;
  }
  getCoinRateData1() {
    const url = Endpoints.api.coinRate.getCoinRateList;

    const observable = this.httpClient.get(url)

    return observable;


  }
  updateCoinRate(conRateObj) {
    const url = Endpoints.api.coinRate.updateCoinRate;

    const observable = this.httpClient.post(url, conRateObj)

    return observable;


  }
}
