import { Component, OnInit, Inject } from '@angular/core';
import { RefDataService } from '../../../core/services/common/ref-data.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CharacterService } from '../../../core/services/character.service';
import { SelectItem } from 'primeng/api';
@Component({
  selector: 'app-pinned-questions-edit-modal',
  templateUrl: './pinned-questions-edit-modal.component.html',
  styleUrls: ['./pinned-questions-edit-modal.component.scss']
})
export class PinnedQuestionsEditModalComponent implements OnInit {
  isUpdate: Boolean = true;
  characterType: SelectItem;
  sourceType: SelectItem[];
  PinnedQuestion: String;
  selectedCharacterType: any;
  characterData: SelectItem[];
  isUpdateflag: Boolean = true;
  pQuestionForm = new FormGroup({
    question_text: new FormControl('', [Validators.required]),
    source_type: new FormControl('', []),
    character_type: new FormControl('', []),
    character_id_1_name: new FormControl('', []),
    character_id_2_name: new FormControl('', [])
  });
  constructor(public dialogRef: MatDialogRef<PinnedQuestionsEditModalComponent>,
    private modalService: NgbModal, private refDataService: RefDataService,
    private characterService: CharacterService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // this.characterType = JSON.parse(this.refDataService.getRefData()).characterTypes;
    // this.sourceType = JSON.parse(this.refDataService.getRefData()).attributes;

    // this.characterData = JSON.parse(this.characterService.getCharacterData());
    this.getCharAndSourceType();
    this.characterService.getCharacterData().subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).list;
      const getCharData = JSON.stringify(getData);
      this.characterData=JSON.parse(getCharData);

    });
    if (this.isUpdateflag === true) {
      this.isUpdate = true;
    } else {
      this.isUpdate = false;
    }
    if (this.data) {
      this.pQuestionForm.patchValue({
        question_text: this.data.question_text,
        source_type: this.data.source_type,
        character_id_1_name: this.data.character_id_1_name,
        character_type: this.data.character_type,
        character_id_2_name: this.data.character_id_2_name
      });

    } else {
      this.pQuestionForm.patchValue({
        question_text: '',
        source_type: '',
        character_type: '',
        character_id_1_name: '',
        character_id_2_name: ''
      });

    }
  }
  close(): void {
    this.dialogRef.close(true);
  }
  submitData() {
    console.log(this.pQuestionForm.value);
  }
  onChangeSource(e) {
    this.PinnedQuestion = e.value.question_text;
    this.pQuestionForm.patchValue({ question_text: this.PinnedQuestion });
  }
  get f() {
    return this.pQuestionForm.controls;
  }

  getCharAndSourceType(){

    // this.characterType = JSON.parse(this.refDataService.getRefData()).characterTypes;
  // this.gameType = JSON.parse(this.refDataService.getRefAttData()).gameTypes;

  this.refDataService.getRefData1().subscribe(data => {
    const res = JSON.stringify(data);
    const getData = (JSON.parse(res)).characterTypes;
    const getGame = (JSON.parse(res)).attributes;
    const getCharData = JSON.stringify(getData);
   const getGameData = JSON.stringify(getGame);
    this.characterType=JSON.parse(getCharData);
    this.sourceType = JSON.parse(getGameData);
  // this.characterType = JSON.parse(this.refDataService.getRefData()).characterTypes;
      console.log("this is character", this.characterType);
      console.log('this is source type',this.sourceType);
  });
}

}
