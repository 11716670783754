import { Component, OnInit, Inject } from '@angular/core';
import { AffiliateServiceService } from '../../core/services/affiliate/affiliate-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../core/services/common/tost.service'

@Component({
  selector: 'app-remove-promotion-banner-modal',
  templateUrl: './remove-promotion-banner-modal.component.html',
  styleUrls: ['./remove-promotion-banner-modal.component.scss']
})
export class RemovePromotionBannerModalComponent implements OnInit {

  constructor (public dialogRef: MatDialogRef<RemovePromotionBannerModalComponent>, private affiliateServiceService: AffiliateServiceService, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService) { }
  id = this.data.id;
  ngOnInit (): void {
    //  console.log("data from other", this.data);
  }
  // getData(){
  //   this.promoCodeService.getPromoCode(null).subscribe(receivedPromocodeData =>{})
  // }
  deleteRecord (id) {
    //    console.log("data from other",this.data);
    // this.promoCodeService.deletePromoCode().subscribe =>();
    this.affiliateServiceService.deleteBanner(id).subscribe(data => {
      this.tostService.tostrFun("PromoCode Deleted  Successfully", 'success');

    });
    this.dialogRef.close();
  }

  submitData () {
    // console.log(this.myForm.value);
  }
  close (): void {
    this.dialogRef.close(true);
  }

}
