import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { SiteConstants } from '../../../../config/site-constants';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';
import { TostService } from '../../../core/services/common/tost.service';
@Component({
  selector: 'app-category-tag-edit',
  templateUrl: './category-tag-edit.component.html',
  styleUrls: ['./category-tag-edit.component.scss']
})
export class CategoryTagEditComponent implements OnInit {

  category: any;
  action: any = "create";
  icons = SiteConstants.icons;

  tooltips = SiteConstants.tooltips;

  imagePreviewDeActive: any;
  imagePreviewActive: any;
  constructor (
    public dialogRef: MatDialogRef<CategoryTagEditComponent>,
    private gameBankService: GameBankService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private refDataService: RefDataService,
    private tostService: TostService
  ) { }

  ngOnInit (): void {
    if (this.data.category) {
      this.action = "update"
    } else {
      this.action = "create"
    }
    this.category = this.data.category || {
      tag: '',
      display_tag: '',
      is_active: false,
      active_icon: '',
      de_active_icon: ''

    };

    if (this.category.de_active_icon) {
      this.imagePreviewDeActive = { backgroundImage: 'url(' + this.category.de_active_icon + ')' };
    }
    if (this.category.active_icon) {
      this.imagePreviewActive = { backgroundImage: 'url(' + this.category.active_icon + ')' };
    }
  }

  close () {
    this.dialogRef.close(true);
  }

  saveCategory () {
    // console.log(this.category)
    this.category.action = this.action;
    var hasErr = '';
    if (!this.category.tag) {
      hasErr = "please enter category  name";
    } else if (!this.category.display_tag) {
      hasErr = "Please enter display category  name";
    } else if (!this.category.active_icon) {
      hasErr = "Please add active icon";
    } else if (!this.category.de_active_icon) {
      hasErr = "Please add de-active icon";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    this.spinner.show();
    this.category.tag = this.category.tag.toLowerCase();
    this.gameBankService
      .saveCategoryTag(this.category)
      .subscribe(res => {
        this.dialogRef.close();
        this.spinner.hide()
        this.tostService.tostrFun(`Tag category ${this.action} successfully`, 'success');
      }, () => {
        this.spinner.hide()
      });
  }

  onFileChangeActive (ev) {
    if (ev.target.files && ev.target.files.length) {
      const formData = new FormData();
      formData.append('file', ev.target.files[0]);
      this.spinner.show();
      this.refDataService
        .fileUploadFun(formData)
        .subscribe(data => {
          this.category.active_icon = data['picture'];
          this.imagePreviewActive = { backgroundImage: 'url(' + this.category.active_icon + ')' };
          this.spinner.hide()
        }, err => {
          this.spinner.hide()
        });
    }
  }
  onFileChangeDeActive (ev) {
    if (ev.target.files && ev.target.files.length) {
      const formData = new FormData();
      formData.append('file', ev.target.files[0]);
      this.spinner.show();
      this.refDataService
        .fileUploadFun(formData)
        .subscribe(data => {
          this.category.de_active_icon = data['picture'];
          this.imagePreviewDeActive = { backgroundImage: 'url(' + this.category.de_active_icon + ')' };
          this.spinner.hide()
        }, err => {
          this.spinner.hide()
        });
    }
  }

}
