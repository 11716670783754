<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Switch Game Winner </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="main-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">


                    <p-table #dt [columns]="cols" [value]="gameData" styleClass="ui-table-cars">

                        <ng-template pTemplate="header" let-columns>
                            <tr>

                                <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                                    [ngStyle]="{'width': col.width,'text-align': col.textalign}">
                                    <div class="header-title">
                                        {{col.header}}

                                    </div>
                                </th>

                            </tr>

                        </ng-template>


                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">



                                <td style="width: 200px;">{{rowData.game_title}}</td>
                                <td style="padding-top: 45px;">
                                    <p-checkbox name="score1" label=" {{rowData.player1_name}}" [(ngModel)]="score1"
                                        binary="true"> </p-checkbox>

                                </td>

                                <td style="padding-top: 45px;">
                                    <p-checkbox name="score2" label=" {{rowData.player2_name}}" [(ngModel)]="score2"
                                        binary="true">
                                    </p-checkbox>

                                </td>


                                <!-- <td style="width: 130px;"> -->
                                <!-- <app-action-icon [data]="rowData" [width]="'650px'"
                                                    [modaleId]="'EditPromoCodeModalComponent'" icon="edit" tooltip="update">
                                                </app-action-icon>
                                                <app-action-icon [data]="rowData" [width]="'350px'"
                                                    [modaleId]="'CommonDeleteModalComponent'" icon="remove" tooltip="delete">
                                                </app-action-icon> -->
                                <!--                           
                                            </td> -->

                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8" style="text-align:left">No data found.</td>
                            </tr>
                        </ng-template>
                    </p-table>


                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal-footer">

    <button mat-raised-button type="submit" class="btn  btn-danger pull-right"
        (click)="submitWinnerData()">Submit</button>
</div>