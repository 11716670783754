import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../../config';
@Injectable({
  providedIn: 'root'
})
export class UserBlockService {

  constructor(private httpClient: HttpClient) { }
  blockUser(blockObj) {
    const url = Endpoints.api.user.blockUser;

    const observable = this.httpClient.put<any>(url, blockObj);
  
    return observable;


  }
  unBlockUser(unBlockObj) {
    const url = Endpoints.api.user.unblockUser;

    const observable = this.httpClient.put<any>(url, unBlockObj);
    return observable;


  }
}
