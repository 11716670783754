import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../../core/services/common/tost.service'
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  constructor (public dialogRef: MatDialogRef<ConfirmationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService) { }
  userData = this.data;
  message: string;
  subMessgae: string;
  rowDataObj: any;
  ngOnInit (): void {


    this.rowDataObj = this.userData && this.userData.rowData;

    if (this.userData && this.userData.key == 'verifiedFlag') {
      if (this.rowDataObj.verified_flag == true || this.rowDataObj.verified_flag == 1) {
        this.subMessgae = "Are you sure, you want to mark this user as verified?";
      } else {
        this.subMessgae = "Are you sure, you want to mark this user as unverified ?"
      }
    } else if (this.userData && this.userData.key == 'isInfluencer') {
      if (this.userData && this.rowDataObj.is_influencer == true || this.userData && this.userData.is_influencer == 1) {
        this.message = "User will become a verified user, user can host games , his games will get a boost, and user will have more followers";
        this.subMessgae = "Are you sure, you want to give above privileges to user?";
      } else {
        this.message = "User can still host a game and will remain verified users, but all his games boost feature will reset to zero."
        this.subMessgae = " Are you sure, you want to do this?";
      }
    } else if (this.userData && this.userData.key == 'isCreateGame') {
      if (this.userData && this.rowDataObj.create_game == true || this.userData && this.rowDataObj.create_game == 1) {
        this.subMessgae = "Are you sure, you want to make this user as Host ?";
      } else {
        this.subMessgae = "Are you sure, you want to remove Host privileges for this user?";
      }
    }

  }

  save () {

    this.dialogRef.close({ save: true, rowData: this.rowDataObj, key: this.userData.key });
  }

  submitData () {

  }
  close (): void {
    this.dialogRef.close({ key: this.userData.key, rowData: this.rowDataObj, save: false });
  }

}
