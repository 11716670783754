import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-payout-list-payout',
  templateUrl: './payout-list-payout.component.html',
  styleUrls: ['./payout-list-payout.component.scss']
})
export class PayoutListPayoutComponent implements OnInit {
  levelForm = new FormGroup({
    amount: new FormControl('', []),
    game_num: new FormControl('', []),
    note: new FormControl('', [])
  });

  foods: Food[] = [
    { value: 'Debit', viewValue: 'debit' },
    { value: 'Credit', viewValue: 'credit' },

  ];
  constructor(public dialogRef: MatDialogRef<PayoutListPayoutComponent>, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  submitData() {
    // console.log(this.levelForm.value);
  }
  close(): void {
    this.dialogRef.close(true);
  }
}
