import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '../../core/services/settings.service'
import { TostService } from '../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-setting-price-option',
  templateUrl: './setting-price-option.component.html',
  styleUrls: ['./setting-price-option.component.scss']
})
export class SettingPriceOptionComponent implements OnInit {
  color: any = '#000000'
  action: string;
  priceOptionListArray: any = [];

  priceOptionForm = new FormGroup({

    default: new FormControl('', []),
    entry_fee: new FormControl('', []),
    color_code: new FormControl('#000000', []),
    prize_money: new FormControl('', []),

  });

  constructor(public dialogRef: MatDialogRef<SettingPriceOptionComponent>, private modalService: NgbModal, private settingServie: SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    // console.log('priceoption lis', this.data.priceOptionList)
    //  console.log('index is', this.data.index)
    this.priceOptionListArray = this.data.priceOptionList;

    if (this.data.data) {
      this.color = this.data.data.color_code
      this.priceOptionForm.patchValue({
        default: this.data.data.default,
        entry_fee: this.data.data.entry_fee,
        color_code: this.color,
        prize_money: this.data.data.prize_money,


      });



    } else {
      this.priceOptionForm.patchValue({
        default: 0,
        entry_fee: 0,
        color_code: '',
        prize_money: 0,


      });

      // this.color_code='';

    }
  }

  onChangeColor(e) {
    // console.log(e);
    // this.priceOptionForm.color_code= JSON.stringify(e.value);
    // console.log("color 2",this.color_code);
    this.priceOptionForm.patchValue({
      color_code: e
    })

  }

  submitData() {
    this.spinner.show();
    // console.log('price option', this.priceOptionForm.value);

    if (this.data.data == null) {
      this.action = "create";
      this.priceOptionListArray.push(this.priceOptionForm.value);

      //   console.log('test pushed data', this.priceOptionListArray);

    } else if (this.data.priceOptionList) {
      this.action = "update";
      //  console.log("action value on update", this.action);
      // this.levelForm.value.id = this.data.id;
      var indexval = this.data.index;
      var updateval = this.priceOptionListArray[indexval] = this.priceOptionForm.value;
      //  console.log('update values', updateval)
      let formValues = { "priceOptionsList": updateval }
      // Object.assign(formValues,{
      //   id:this.data.id,
      //   createdAt:this.data.createdAt,
      //   is_active:this.data.is_active,
      //   prev_version_id:this.data.prev_version_id,
      //   updatedAt:this.data.updatedAt,
      //   level_num:this.data.level_num
      // });

    }

    let formValues = { "priceOptionsList": this.priceOptionListArray }
    var tokenval = localStorage.getItem('crsf');
    this.settingServie.createUpdateSetting(formValues, this.action, tokenval).subscribe(data => {
      // console.log("create level data", data);
      if (this.action == 'update') {
        this.tostService.tostrFun("Price Options updated Successfully", 'success');

      } else {
        this.tostService.tostrFun("Price Options Created Successfully", 'success');

      }
      this.spinner.hide();

    },
      err => {

        this.spinner.hide();

      }
    )
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close(true);
  }

}
