import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LevelEditModalComponent } from '../../modal/level-edit-modal/level-edit-modal.component';
import { CharacterEditModalComponent } from '../../modal/character-edit-modal/character-edit-modal.component';
import { SettingPriceOptionComponent } from '../../modal/setting-price-option/setting-price-option.component';
import { CreateLeagueCategoryComponent } from '../../modal/create-league-category/create-league-category.component'
import { EditPromoCodeModalComponent } from '../../modal/edit-promo-code-modal/edit-promo-code-modal.component';
import { CreateAffiliateModalComponent } from "../../modal/create-affiliate-modal/create-affiliate-modal.component";
import { GameBankCreateEditModalComponent } from '../../modal/game-Bank/game-bank-create-edit-modal/game-bank-create-edit-modal.component'
import { CreateEditNotificationComponent } from '../../modal/create-edit-notification/create-edit-notification.component';
import { CreateEditFeecutModalComponent } from '../../modal/create-edit-feecut-modal/create-edit-feecut-modal.component'
import { GameConfigModalComponent } from '../../modal/game-Bank/game-config-modal/game-config-modal.component';
import { TagsEditModalComponent } from '../../modal/game-Bank/tags-edit-modal/tags-edit-modal.component';
import { PromotionBannersComponent } from '../../modal/promotion-banners/promotion-banners.component';
import { CategoryTagEditComponent } from "../../modal/game-Bank/category-tag-edit/category-tag-edit.component";
import { TrendingTagEditModalComponent } from "../../modal/game-Bank/trending-tag-edit-modal/trending-tag-edit-modal.component";
import { NotificationTypeCreateEditModalComponent } from "../../modal/notification-type-create-edit-modal/notification-type-create-edit-modal.component";
import { BlacklistCountryStateEditComponent } from "../../modal/user/blacklist-country-state-edit/blacklist-country-state-edit.component";
import { SuperTagEditModalComponent } from "../../modal/game-Bank/super-tag-edit-modal/super-tag-edit-modal.component";
import { RemoveSuperTagModalComponent } from "../../modal/game-Bank/remove-super-tag-modal/remove-super-tag-modal.component";
import { PartnerCreateUpdateModalComponent } from '../../modal/partner-create-update-modal/partner-create-update-modal.component';
import { CreateAdminManagerComponent } from '../../modal/user/create-admin-manager/create-admin-manager.component'
@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {

  constructor (public dialog: MatDialog,
    private modalService: NgbModal) { }
  @Input() data: string[];
  @Input() height: string;
  @Input() width: string;
  @Input() modaleId: string;
  @Output() onRefrashed = new EventEmitter<any>();
  dateFilterObj: any = {};
  componet: any = {};
  components: any = {
    "CharacterEditModalComponent": CharacterEditModalComponent,
    "SettingPriceOptionComponent": SettingPriceOptionComponent,
    "LevelEditModalComponent": LevelEditModalComponent,
    "CreateLeagueCategoryComponent": CreateLeagueCategoryComponent,
    "EditPromoCodeModalComponent": EditPromoCodeModalComponent,
    "CreateAffiliateModalComponent": CreateAffiliateModalComponent,
    "GameBankCreateEditModalComponent": GameBankCreateEditModalComponent,
    "CreateEditNotificationComponent": CreateEditNotificationComponent,
    "CreateEditFeecutModalComponent": CreateEditFeecutModalComponent,
    "GameConfigModalComponent": GameConfigModalComponent,
    "PromotionBannersComponent": PromotionBannersComponent,
    "TagsEditModalComponent": TagsEditModalComponent,
    "CategoryTagEditComponent": CategoryTagEditComponent,
    "TrendingTagEditModalComponent": TrendingTagEditModalComponent,
    "NotificationTypeCreateEditModalComponent": NotificationTypeCreateEditModalComponent,
    "BlacklistCountryStateEditComponent": BlacklistCountryStateEditComponent,
    "SuperTagEditModalComponent": SuperTagEditModalComponent,
    "RemoveSuperTagModalComponent": RemoveSuperTagModalComponent,
    "PartnerCreateUpdateModalComponent": PartnerCreateUpdateModalComponent,
    "CreateAdminManagerComponent": CreateAdminManagerComponent


  };

  ngOnInit (): void {
  }
  openDialog (characterData): void {

    let d = this.modaleId;
    const dialogRef = this.dialog.open(this.components[this.modaleId], {
      height: this.height,
      width: this.width,
      data: characterData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.onRefrashed.emit(result);
      }
    });
  }
}
