<div class="row">
  <div class="col-md-12">
    <tag-input [(ngModel)]="users" [identifyBy]="'user_name'" secondaryPlaceholder="Search Creators"
      [displayBy]="'user_name'" (onAdd)="onTagAdd($event)" (onRemove)="onTagRemove($event)" #tagsinput>
      <tag-input-dropdown [autocompleteObservable]="searchTags" [appendToBody]="false">
        <ng-template let-item="item" let-index="index">
          {{ item.display }}
        </ng-template>
      </tag-input-dropdown>
      <ng-template let-item="item" let-index="index">
        <div style="padding: 0 5px;font-size: 15px;">
          {{ item.display }}
          <delete-icon (click)="tagsinput.removeItem(item, index)"></delete-icon>
        </div>
      </ng-template>
    </tag-input>
  </div>

</div>