import { Injectable } from '@angular/core';
import { leagueCategoryData } from '../../data/league-category';
import { Endpoints } from '../../../../config';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class LeagueCategoryService {

  constructor(private httpClient: HttpClient) { }

  getLeagueCategory() {
    return leagueCategoryData;
  }

  getLeagueCategory1(legueFilter) {
    let search='';
    let pagination ='';
    let sort ='';
    if(legueFilter.search){
      search= JSON.stringify(legueFilter.search);
     
    }
    if(legueFilter.sort){
      sort = JSON.stringify(legueFilter.sort)
    }
    if(legueFilter.pagination){
      pagination = JSON.stringify(legueFilter.pagination)
    }
         const url = Endpoints.api.leagueCategory.getLeaguecategoryList
      .replace('{search}',search )
      .replace('{sort}',sort )
      .replace('{pagination}',pagination);
     
      const observable = this.httpClient.get(url)
    
      return observable; 
    
 
  }

}
