<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ blackListCountry && blackListCountry.id ? 'Update' : 'Create' }}
    BlackListed Country</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <form (ngSubmit)="openDialog($event)">
      <div class="modal-body">
        <div class="row">
          <div class="col col-5">
            <div class="form-group">
              <mat-form-field>
                <mat-select [(ngModel)]="selectedCountry" name="selectedCountry"
                  (selectionChange)="selectCountry($event)">
                  <mat-option *ngFor="let country of countryList" [value]="country.iso_code">
                    {{country.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col col-5" *ngIf="isStateFlag">
            <div class="form-group">
              <mat-form-field>
                <mat-select [(ngModel)]="selectedState" name="state" (selectionChange)="selectState($event)">
                  <mat-option *ngFor="let state of stateList" [value]="state.iso_code">
                    {{state.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col col-2">
            <div class="form-group">

              <mat-checkbox [checked]="blackListCountry.is_active" [(ngModel)]="blackListCountry.is_active"
                [ngModelOptions]="{standalone: true}"> Status
              </mat-checkbox>
            </div>
          </div>
        </div>


      </div>
      <div class="modal-footer">
        <a mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</a>
        <button mat-raised-button type="submit" class="btn btn-danger pull-right">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>