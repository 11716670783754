import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { InviteCodeService } from '../../../core/services/users/invite-code.service';
import { UserBlockService } from '../../../core/services/users/user-block.service';
import { TostService } from '../../../core/services/common/tost.service'
@Component({
  selector: 'app-block-user-modal',
  templateUrl: './block-user-modal.component.html',
  styleUrls: ['./block-user-modal.component.scss']
})
export class BlockUserModalComponent implements OnInit {
  isBlock: any;
  constructor(public dialogRef: MatDialogRef<BlockUserModalComponent>,
    private userBlockService: UserBlockService, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private inviteCodeService: InviteCodeService, private tostService: TostService) { }

  ngOnInit(): void {
    this.isBlock = this.data.is_blocked;
  }
  close(): void {
    this.dialogRef.close(true);
    //  console.log("object", this.data);
  }

  // checkIsBlock() {
  //   if (this.data.is_blocked) {
  //     this.unBlockUser(this.data.id);
  //   } else {
  //     this.blockUser(this.data.id);
  //   }
  // }
  blockUser() {
    const obj = { 'id': this.data.id };
    this.userBlockService.blockUser(obj).subscribe(
      res => {
        this.tostService.tostrFun("User blocked Successfully", 'success');
        this.dialogRef.close();

      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close();
      });

  }
  unBlockUser() {
    const obj = { 'id': this.data.id };
    this.userBlockService.unBlockUser(obj).subscribe(
      res => {
        this.tostService.tostrFun("User Unblocked Successfully", 'success');
        this.dialogRef.close(obj.id);

      },
      err => {
        /// this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close(obj.id);
      });
  }
}
