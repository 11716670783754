<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">

                <p-table #dt [columns]="cols" [value]="levels" (sortFunction)="customSort($event)" [customSort]="true"
                    [(selection)]="selectedLevels" dataKey="id" styleClass="ui-table-cars" [rows]="10"
                    [rowsPerPageOptions]="[10,25,50]" [responsive]="true" [loading]="loading" [paginator]="true">
                    <ng-template pTemplate="caption">
                        <app-action-button [data]="null" [width]="'400'" (onRefrashed)="onRefrash($event)"
                            [modaleId]="'LevelEditModalComponent'">
                        </app-action-button>
                        <!-- <button type="button" class="btn btn-primary" (click)="openDialog(null)"> Create</button> -->
                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr>

                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{width:col.width}">
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>
                            </th>

                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field">



                                <!-- <input *ngSwitchCase="'id'" pInputText type="text" placeholder="Id" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                            <input *ngSwitchCase="'user_name'" pInputText type="text" placeholder="user name" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                            <input *ngSwitchCase="'first_name'" pInputText type="text" placeholder="first name" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                            <input *ngSwitchCase="'email'" pInputText type="text" placeholder="email" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                            <input *ngSwitchCase="'balance'" pInputText type="text" placeholder="balance" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                            <input *ngSwitchCase="'winning_amount'" pInputText type="text" placeholder="winning_amount" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                            <input *ngSwitchCase="'payout_amount'" pInputText type="text" placeholder="payout_amount" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                            <input *ngSwitchCase="'referral_code'" pInputText type="text" placeholder="referral_code" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                        -->
                            </th>
                            <!-- <th style="width: 270px; text-align: center;">

                        </th> -->
                        </tr>
                    </ng-template>


                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>Level #{{rowData.level_num}}</td>
                            <td>{{rowData.label}}</td>

                            <td>{{rowData.game_num}}</td>
                            <td>{{rowData.paid_game_num}}</td>
                            <td>{{rowData.bonus}}</td>
                            <td>{{rowData.max_winning_amount}}</td>


              <td style="width: 80px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                                <app-action-icon [data]="rowData" (onRefrashed)="onRefrash($event)" [width]="'400'"
                                    [modaleId]="'LevelEditModalComponent'" icon="edit" tooltip="update">
                                </app-action-icon>
                  </div>
                </mat-menu>
                            </td>

                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" style="text-align:left">No data found.</td>
                        </tr>
                    </ng-template>
                </p-table>


                <!-- <p-table #dt [columns]="cols" [loading]="loading"   (sortFunction)="customSort($event)" [customSort]="true" [frozenColumns]="frozenCols" [style]="{width:'100%'}" styleClass="ui-table-cars" [value]="levels" [paginator]="true" [rows]="10" dataKey="level_num" selectionMode="single"  [rowsPerPageOptions]="[5,10]" [(selection)]="selectedLevels">
                    <ng-template pTemplate="caption">
                       Custom Questions
                       <button type="button" class="btn btn-primary" (click)="openDialog(null)"> Create</button>
                     </ng-template> 
                    
                        
                    
                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <col *ngFor="let col of columns" style="width:250px">
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" >
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>
                            </th>
                            
                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                                <input *ngSwitchCase="'level_num'" pInputText type="text" placeholder="Level Number" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'game_num'" pInputText type="text" placeholder="Game Number" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'paid_game_num'" pInputText type="text" placeholder="Paid Game Number" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'bonus'" pInputText type="text" placeholder="Level completion bonus" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                            </th>
                            <th style="width: 270px; text-align: center;">

                            </th>
                        </tr>
                       
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>Level #{{rowData.level_num}}</td>
                            <td>{{rowData.game_num}}</td>
                            <td>{{rowData.paid_game_num}}</td>
                            <td>{{rowData.bonus}}</td>


                            <td style="width: 150px;">
                                <button pButton type="button" (click)="openDialog(rowData)" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>    -->






            </div>

        </div>
    </div>
</div>