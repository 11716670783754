<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">


                <p-table #dt [columns]="cols" [value]="affiliateGameListData" [customSort]="true"
                    [(selection)]="selectedAffiliate" dataKey="id" styleClass="ui-table-cars" [rows]="10"
                    [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [totalRecords]="totalRecords" [lazy]="true"
                    (onLazyLoad)="customPagination($event)" [customSort]="true" [paginator]="true">
                    <ng-template pTemplate="caption">
                        Affiliate Game List

                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr>

                            <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                                [ngStyle]="{'width': col.width}">
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>
                            </th>

                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field">

                                <input *ngSwitchCase="'game_name'" pInputText type="text" class="ui-column-filter"
                                    style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                                <!--                             
                            <input *ngSwitchCase="'id'" pInputText type="number" placeholder="name" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                            <input *game_name="'character_type'" pInputText type="text" placeholder="character type" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                            <input *ngSwitchCase="'created_by'" pInputText type="number" placeholder="source type" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                             -->

                            </th>
                            <!-- <th style="width: 270px; text-align: center;">

                        </th> -->
                        </tr>
                    </ng-template>


                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>{{rowData.id}}</td>
                            <td>{{rowData.game_title}}</td>
                            <td>{{rowData.status.text}}</td>
                            <td>{{rowData.total_playing}}</td>
                            <!-- <td>{{rowData.start_time}}</td> -->

                            <td>{{rowData.start_time | date:'medium'}}</td>
                            <td>{{rowData.end_time | date:'medium'}}</td>
                            <td>{{rowData.last_join_time | date:'medium'}}</td>



                            <td>
                                <div style="min-width: 110px;">
                                    <button pButton style="margin: .3em" type="button"
                                        [routerLink]="['/user/game',userID,rowData.id]" class="ui-button-raised"
                                        matTooltip="View Usr List" icon="pi pi-list"></button>

                                    <button pButton type="button" (click)="downloadUserList(rowData.id)"
                                        class="ui-button-raised" matTooltip=" Download User list" icon="pi pi-download"
                                        style="margin: .3em"></button>
                                </div>
                            </td>

                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" style="text-align:left">No data found.</td>
                        </tr>
                    </ng-template>
                </p-table>




            </div>
        </div>
    </div>
</div>