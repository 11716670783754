import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../core/services/common/tost.service';
import { SettingsService } from '../../core/services/settings.service'

@Component({
  selector: 'app-setting-delete-modal',
  templateUrl: './setting-delete-modal.component.html',
  styleUrls: ['./setting-delete-modal.component.scss']
})
export class SettingDeleteModalComponent implements OnInit {
  priceOptionListArray: any = [];
  action: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private settingServie: SettingsService, public dialogRef: MatDialogRef<SettingDeleteModalComponent>) { }

  ngOnInit(): void {
    // console.log('delete priceoption lis', this.data.priceOptionList)
    // console.log('index is', this.data.index)
    this.priceOptionListArray = this.data.priceOptionList;

  }

  deleteSetting() {
    if (this.priceOptionListArray.length == 1) {
      this.tostService.tostrFun('you cannot delete all price option', 'error');
      return;
    }
    this.action = "update";
    var indexval = this.data.index;
    var priceOptionListArrayCopy = [...this.priceOptionListArray]
    priceOptionListArrayCopy.splice(indexval, 1);
    //   console.log('delete values', priceOptionListArrayCopy)
    let formValues = { "priceOptionsList": priceOptionListArrayCopy }

    var tokenval = localStorage.getItem('crsf');
    this.settingServie.createUpdateSetting(formValues, this.action, tokenval).subscribe(data => {
      //   console.log("create level data", data);

      this.tostService.tostrFun("Setting deleted successfully", 'success');

    },
      err => {
        // this.tostService.tostrFun("Something Went Wrong", 'error');

      }
    )
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close(true);
  }
}
