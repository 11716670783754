import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteConstants } from '../../../config/site-constants';
import { NotificationService } from '../../core/services/league/notification.service';
import { TostService } from '../../core/services/common/tost.service';

@Component({
  selector: 'app-notification-type-create-edit-modal',
  templateUrl: './notification-type-create-edit-modal.component.html',
  styleUrls: ['./notification-type-create-edit-modal.component.scss']
})
export class NotificationTypeCreateEditModalComponent implements OnInit {
  selectedCar: string;

  groupTypes: any[] = [];

  notificationType: any;
  action: any = "create";
  icons = SiteConstants.icons;
  tooltips = SiteConstants.tooltips;
  constructor (
    public dialogRef: MatDialogRef<NotificationTypeCreateEditModalComponent>,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private tostService: TostService
  ) {

    this.notificationService
      .getAllNotifyGroups({}).subscribe(data => {
        this.groupTypes = data['result'];
      });
  }

  ngOnInit (): void {
    if (this.data.notificationType) {
      this.action = "update"
    } else {
      this.action = "create"
    }
    this.notificationType = this.data.notificationType || {
      title: '',
      type: '',
      group_id: "1"
    };
  }


  selectGroup (e) {
    if (e.value) {
      this.notificationType.group_id = e.value;
    }
  }

  close () {
    this.dialogRef.close(true);
  }

  savenotificationType () {
    // console.log(this.notificationType)
    this.notificationType.action = this.action;

    var hasErr = '';
    if (!this.notificationType.title) {
      hasErr = "please enter title";
    } else if (!this.notificationType.type) {
      hasErr = "Please enter type";
    } else if (!this.notificationType.group_id) {
      hasErr = "Please select notification group";
    }

    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    this.spinner.show();
    this.notificationService
      .createUpdateNotifyType(this.notificationType)
      .subscribe(res => {
        this.dialogRef.close();
        this.spinner.hide()
        this.tostService.tostrFun(`Notification type ${this.action} successfully`, 'success');
      }, () => {
        this.spinner.hide()
      });
  }



}
