import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PayoutService } from '../../core/services/character/payout.service';
@Component({
  selector: 'app-payout-get-transaction',
  templateUrl: './payout-get-transaction.component.html',
  styleUrls: ['./payout-get-transaction.component.scss']
})
export class PayoutGetTransactionComponent implements OnInit {
  alldata: any = [];
  loading: boolean;

  constructor(private payoutService: PayoutService, public dialogRef: MatDialogRef<PayoutGetTransactionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // console.log("trans data", this.data);
    this.loading = true;
    this.getTransaction(this.data.user_id);
  }

  getTransaction(id) {

    this.payoutService.getPayoutTransaction(id).subscribe(data => {
      // console.log("transaction data ", data);
      const res = JSON.stringify(data);

      const getData = (JSON.parse(res).userTransactionList);
      const getCharData = JSON.stringify(getData);
      this.alldata = JSON.parse(getCharData);
      this.loading = false;
    });
    // this.close();
  }
  close(): void {
    this.dialogRef.close(true);
  }


}
