<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Custom Question Update</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="cQuestionForm" (ngSubmit)="submitData()">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <p-dropdown [options]="characterType" optionLabel="name" formControlName="character_type" placeholder="Select character type " dataKey="character_type" (onChange)="selectCharacterType($event)">
                    </p-dropdown>
                </div>
            </div>
            <!-- <div class="col-md-6">
                <div class="form-group">
                    <p-dropdown [options]="sourceType" optionLabel="source_code" formControlName="source_type" placeholder="Select source type " dataKey="source_code">
                    </p-dropdown>
                </div>
            </div> -->
        </div>
        <div class="row">

            <div class="col-md-12">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="question_text" id="question_text" placeholder="Question text" type="text" value="PinnedQuestion" class="form-control">
                    </mat-form-field>

                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <!-- <p-dropdown [options]="characterData" optionLabel="character_name" (onChange)="onChangeCharacter1($event)" formControlName="character_id_1_name" placeholder="Select character 1 " [filter]="true" dataKey="character_code">
                    </p-dropdown> -->

                    <p-autoComplete style="width: 230px" formControlName="character_id_1_name" [suggestions]="searchResult" (click)="search($event)" (completeMethod)="search($event)" [size]="30"
                    [minLength]="1" placeholder="type something" [dropdown]="true">
                    <ng-template let-brand pTemplate="item">
                        <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
                            <img [src]="brand.image_url" style="width:32px;display:inline-block;margin:5px 0 2px 5px"/>
                            <div style="font-size:18px;float:right;margin:10px 10px 0 0">{{brand.character_name}}</div>
                        </div>
                    </ng-template>
                </p-autoComplete>
                <!-- <span style="margin-left:50px">Brand: {{brand||'none'}}</span> -->

                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <!-- <p-dropdown [options]="characterData" optionLabel="character_name" (onChange)="onChangeCharacter2($event)" formControlName="character_id_2_name" placeholder="Select character 2 " [filter]="true" dataKey="character_code">
                    </p-dropdown> -->
                    <p-autoComplete style="width: 230px" formControlName="character_id_2_name" [suggestions]="searchResult" (click)="search($event)" (completeMethod)="search($event)" [size]="30"
                    [minLength]="1" placeholder="type something" [dropdown]="true">
                    <ng-template let-brand pTemplate="item">
                        <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
                            <img [src]="brand.image_url" style="width:32px;display:inline-block;margin:5px 0 2px 5px"/>
                            <div style="font-size:18px;float:right;margin:10px 10px 0 0">{{brand.character_name}}</div>
                        </div>
                    </ng-template>
                </p-autoComplete>

                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="close()">Save</button>
        </div>
        <div class="clearfix"></div>
    </form>

</div>