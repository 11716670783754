import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Levels } from '../../core/models/api/levels';
import { LevelsService } from '../../core/services/league/levels.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LevelEditModalComponent } from '../../modal/level-edit-modal/level-edit-modal.component';

@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.scss']
})
export class LevelsComponent implements OnInit {
  globalSort: any = "";
  filter: any = {};
  frozenCols: any[];
  loading: boolean;
  cols: any;
  closeResult: string;
  levels: Levels;
  selectedLevels: Levels;
  constructor(private modalService: NgbModal, private levelsService: LevelsService, public dialog: MatDialog) { }


  ngOnInit(): void {
    this.cols = [

      { field: 'level_num', header: 'Level number' },
      { field: 'label', header: 'Level name' },
      // { field: 'brand', header: 'Brand' },
      { field: 'game_num', header: 'Number of games to reach next level' },
      { field: 'paid_game_num', header: 'Minimum number of paid games to be played' },
      { field: 'bonus', header: 'Level completion bonus' },
      { field: 'max_winning_amount', header: 'Maximum winning price per game' },

      { field: '', header: 'Action', width: '80px' },

    ];
    // this.levels = JSON.parse(this.levelsService.getLevelsData());
    this.GetData({});
  }

  customSort(e) {
    let obj = {};
    // if(e.field=='first_name'){
    //   // e.field='first_name'
    // }
    if (e.order == 1) {
      obj[e.field] = 'desc'
    } else {
      obj[e.field] = 'asc'
    }

    if (this.globalSort != e.field + " " + e.order) {

      this.filter['sort'] = obj;
      this.GetData(this.filter);
      this.globalSort = e.field + " " + e.order
      //  console.log(this.filter);
    }
  }

  GetData(obj) {
    // if(!this.filter.sort){
    //   let sortObj = {};
    //   sortObj['id']='desc';
    //   obj.sort = sortObj;
    // }
    this.levelsService.getLevelsData1(obj).subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).list;
      const getCharData = JSON.stringify(getData);
      this.levels = JSON.parse(getCharData);
      this.loading = false;


    });
  }

  openDialog(levelData): void {
    const dialogRef = this.dialog.open(LevelEditModalComponent, {
      width: '400px',
      data: levelData
    });

    dialogRef.afterClosed().subscribe(result => {
      //   console.log('The dialog was closed');
    });
  }
  onRefrash(e) {
    setTimeout(() => {
      this.GetData(this.filter);
    }, 1500);

  }


}
