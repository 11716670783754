import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';

@Component({
  selector: 'app-tags-delete-modal',
  templateUrl: './tags-delete-modal.component.html',
  styleUrls: ['./tags-delete-modal.component.scss']
})
export class TagsDeleteModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TagsDeleteModalComponent>,
    private gameBankService: GameBankService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close(true);
  }

  deleteTag() {
    this.spinner.show();
    this.gameBankService
      .deleteTag(this.data.tag.tag_name)
      .subscribe(res => {
        this.spinner.hide();
        this.dialogRef.close();
      }, () => {
        this.spinner.hide();
      });
  }

}
