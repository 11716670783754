import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../../config';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GameBankService {

  constructor (private httpClient: HttpClient) { }
  getCustomAll (filter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (filter.search) {
      search = JSON.stringify(filter.search);

    }
    // if(filter.sort){
    //   sort = JSON.stringify(filter.sort)
    // }
    if (filter.sort) {
      if (Object.keys(filter.sort).length > 0) {
        sort = JSON.stringify(filter.sort)
      } else {
        sort = JSON.stringify({ id: 'desc' })
      }
    } else {
      sort = JSON.stringify({ id: 'desc' })
    }

    if (filter.pagination) {
      pagination = JSON.stringify(filter.pagination)
    }
    const url = Endpoints.api.games.custom.all
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);
    const observable = this.httpClient.get(url);

    return observable;


  }
  createCustomGame (characterFormData, action) {

    if (action == "create") {
      const url = Endpoints.api.games.custom.create;
      const observable = this.httpClient.post(url, characterFormData)

      return observable;
    } else if (action == "update") {
      const url = Endpoints.api.games.custom.update;
      const observable = this.httpClient.put(url, characterFormData)
      return observable;
    }
  }
  getPinnedAll (filter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (filter.search) {
      search = JSON.stringify(filter.search);

    }
    // if(filter.sort){
    //   sort = JSON.stringify(filter.sort)
    // }
    if (filter.sort) {
      if (Object.keys(filter.sort).length > 0) {
        sort = JSON.stringify(filter.sort)
      } else {
        sort = JSON.stringify({ id: 'desc' })
      }
    } else {
      sort = JSON.stringify({ id: 'desc' })
    }

    if (filter.pagination) {
      pagination = JSON.stringify(filter.pagination)
    }
    const url = Endpoints.api.games.pinned.all
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);
    const observable = this.httpClient.get(url);

    return observable;


  }
  createPinnedGame (characterFormData, action) {

    if (action == "create") {
      const url = Endpoints.api.games.pinned.create;
      const observable = this.httpClient.post(url, characterFormData)

      return observable;
    } else if (action == "update") {
      const url = Endpoints.api.games.pinned.update;
      const observable = this.httpClient.put(url, characterFormData)
      return observable;
    }
  }
  getGameById (id) {
    const url = Endpoints.api.games.custom.getById.replace('{id}', id);
    const observable = this.httpClient.get(url)
    return observable
  }

  declareWinner (Obj) {
    const url = Endpoints.api.games.custom.declareWinner;
    const observable = this.httpClient.post(url, Obj)
    return observable;
  }

  toggleGOWT (id) {
    const url = Endpoints.api.games.toggleGowt.replace('{id}', id);
    return this.httpClient.get(url)
  }

  getAllTags (params) {
    let {
      type, gameId, text, sort, limit, offset
    } = params;

    gameId = gameId || '';
    type = type || '';

    if (sort) {
      if (Object.keys(sort).length > 0) {
        sort = JSON.stringify(sort)
      } else {
        sort = JSON.stringify({ id: 'desc' })
      }
    } else {
      sort = JSON.stringify({ id: 'desc' })
    }

    const url = Endpoints.api.games.tagsList
      .replace('{text}', text)
      .replace('{type}', type)
      .replace('{gameId}', gameId)
      .replace('{sort}', sort)
      .replace('{limit}', limit)
      .replace('{offset}', offset);
    return this.httpClient.get(url);
  }

  getGameTags (gameId) {
    const url = Endpoints.api.games.getGameTags
      .replace('{gameId}', gameId);
    return this.httpClient.get(url);
  }

  updateGameTag (tagData) {
    const url = Endpoints.api.games.updateGameTag;
    return this.httpClient.post(url, tagData);
  }

  toggleFreeonly (id) {
    const url = Endpoints.api.games.toggleFreeonly.replace('{id}', id);
    return this.httpClient.get(url)
  }

  saveTag (tag) {
    const url = Endpoints.api.games.saveTag;
    return this.httpClient.post(url, tag)
  }

  deleteTag (tagName) {
    const url = Endpoints.api.games.deleteTag;
    return this.httpClient.post(url, { tag_name: tagName })
  }
  updateGameSettings (data) {
    const url = Endpoints.api.user.updateGameSettings;
    return this.httpClient.post(url, data);
  }

  getBannerGameId (filter) {
    let search;
    if (filter.search) {
      search = JSON.stringify(filter.search);

    }
    const url = Endpoints.api.affiliate.getBannerGameId.replace('{search}', search)
    const observable = this.httpClient.get(url)
    return observable;
  }

  getAllCategoryTags (filter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (filter.search) {
      search = JSON.stringify(filter.search);

    }
    // if(filter.sort){
    //   sort = JSON.stringify(filter.sort)
    // }
    if (filter.sort) {
      if (Object.keys(filter.sort).length > 0) {
        sort = JSON.stringify(filter.sort)
      } else {
        sort = JSON.stringify({ index: 'asc' })
      }
    } else {
      sort = JSON.stringify({ index: 'asc' })
    }

    if (filter.pagination) {
      pagination = JSON.stringify(filter.pagination)
    }
    const url = Endpoints.api.games.categoryTagList
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);
    const observable = this.httpClient.get(url);

    return observable;

  }
  saveCategoryTag (categoryTag) {
    const url = Endpoints.api.games.saveCategoryTag;
    return this.httpClient.post(url, categoryTag)
  }
  categoryTagById (id) {
    const url = Endpoints.api.games.categoryTagById.replace('{id}', id);
    const observable = this.httpClient.get(url)
    return observable
  }
  removeCategory (id) {
    const url = Endpoints.api.games.categoryDelete.replace('{id}', id);
    const observable = this.httpClient.delete(url)
    return observable;

  }
  getAllTrendingTags (filter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (filter.search) {
      search = JSON.stringify(filter.search);

    }
    // if(filter.sort){
    //   sort = JSON.stringify(filter.sort)
    // }
    if (filter.sort) {
      if (Object.keys(filter.sort).length > 0) {
        sort = JSON.stringify(filter.sort)
      } else {
        sort = JSON.stringify({ trending_index: 'asc' })
      }
    } else {
      sort = JSON.stringify({ trending_index: 'asc' })
    }

    if (filter.pagination) {
      pagination = JSON.stringify(filter.pagination)
    }
    const url = Endpoints.api.games.trendingTagList
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);
    const observable = this.httpClient.get(url);

    return observable;

  }

  saveTrending (tags) {
    const url = Endpoints.api.games.saveTrendingTag;
    return this.httpClient.post(url, tags)
  }

  removeTrending (id) {
    const url = Endpoints.api.games.trendingDelete.replace('{id}', id);
    const observable = this.httpClient.delete(url)
    return observable;

  }

  toggleBoostGame (id) {
    const url = Endpoints.api.games.toggleBoostGame.replace('{id}', id);
    return this.httpClient.get(url)
  }
  getAllSuperTags (filter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (filter.search) {
      search = JSON.stringify(filter.search);

    }
    // if(filter.sort){
    //   sort = JSON.stringify(filter.sort)
    // }
    if (filter.sort) {
      if (Object.keys(filter.sort).length > 0) {
        sort = JSON.stringify(filter.sort)
      } else {
        sort = JSON.stringify({ tag_index: 'asc' })
      }
    } else {
      sort = JSON.stringify({ tag_index: 'asc' })
    }

    if (filter.pagination) {
      pagination = JSON.stringify(filter.pagination)
    }
    const url = Endpoints.api.games.superTagList
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);
    const observable = this.httpClient.get(url);

    return observable;

  }

  saveSuperTags (tags) {
    const url = Endpoints.api.games.saveSuperTag;
    return this.httpClient.post(url, tags)
  }

  removeSuperTags (id) {
    const url = Endpoints.api.games.removeSuperTag.replace('{id}', id);
    const observable = this.httpClient.delete(url)
    return observable;

  }
  categoriesSearch (data) {

    const url = Endpoints.api.games.categoriesSearch;
    return this.httpClient.post(url, data)
  }
  saveGameSlushFund (data) {
    const url = Endpoints.api.gameData.saveGameSlushFund;
    return this.httpClient.post(url, data);
  }
  toggleBotsForGame (id) {
    const url = Endpoints.api.games.toggleBotsForGame.replace('{id}', id);
    return this.httpClient.get(url)
  }
}
