import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../../config';
@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor (private httpClient: HttpClient) { }
  updatePassword (data) {

    const url = Endpoints.api.adminUpdatePassword.update;
    const observable = this.httpClient.post(url, data)

    return observable;

  }
}
