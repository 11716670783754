import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { InviteCodeService } from '../../../core/services/users/invite-code.service';
import { UsersService } from '../../../core/services/users.service';
import { PromoCode } from '../../../core/models/api/dto/promoCode';
import { TostService } from '../../../core/services/common/tost.service';
@Component({
  selector: 'app-user-promocode-view',
  templateUrl: './user-promocode-view.component.html',
  styleUrls: ['./user-promocode-view.component.scss']
})
export class UserPromocodeViewComponent implements OnInit {
  referral_codes: any;
  defaultReferralCode: any;
  promocodeForm = new FormGroup({

    referral_code: new FormControl('', []),
    invite_code: new FormControl('', []),
  });
  constructor(
    public dialogRef: MatDialogRef<UserPromocodeViewComponent>, private usersService: UsersService, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private inviteCodeService: InviteCodeService, private tostService: TostService) { }

  ngOnInit(): void {

    if (this.data && this.data.id) {
      this.defaultReferralCode = this.data.referral_code
      this.GetData(this.data.id)
    }
  }

  submitData() {
    const data = this.promocodeForm.value;
    let hasErr = "";
    if (!this.promocodeForm.value.invite_code) {
      hasErr = "Please Enter Invite Code";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    let inviteCodeReq = {
      user_id: this.data.id,
      invite_code: this.promocodeForm.value.invite_code
    }
    // this.inviteCodeService.addInviteCode(inviteCodeReq).subscribe(data => {


    // });
    this.inviteCodeService.addInviteCode(inviteCodeReq).subscribe(
      res => {
        this.GetData(this.data.id)
        this.tostService.tostrFun("Promo code created Successfully", 'success');

      }
      ,
      null,
      complete => console.log(''),
    )

  }

  GetData(obj) {

    // this.usersService.getUsersById(obj).subscribe(data => {
    //   const res = JSON.stringify(data);
    //   this.referral_codes = (JSON.parse(res)).user_invite_codes;

    // });
    this.usersService.getUsersById(obj).subscribe(
      data => {
        const res = JSON.stringify(data);
        this.referral_codes = (JSON.parse(res)).user_invite_codes;


      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        // this.dialogRef.close();
      });



  }
  deleteInviteCode(obj) {

    this.usersService.deleteInviteCode(obj).subscribe(
      res => {
        this.GetData(this.data.id)
        this.tostService.tostrFun("PromoCode Deleted  Successfully", 'success');

      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
      }
      //  ,() => console.log('HTTP request completed.')
    )
  }

  close(): void {
    this.dialogRef.close(true);

  }

}
