<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <p-table #dt [columns]="cols" [value]="setting" [responsive]="true" dataKey="id" styleClass="ui-table-cars"
          [loading]="loading">
          <ng-template pTemplate="caption">

            <app-action-button (onRefrashed)="onRefrash($event)" [data]="{data:null,priceOptionList:setting}"
              [width]="'650'" [modaleId]="'SettingPriceOptionComponent'">

            </app-action-button>

          </ng-template>


          <ng-template pTemplate="header" let-columns>
            <tr>

              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                <div class="header-title">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </div>
              </th>

            </tr>

          </ng-template>


          <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
              <td>{{rowData.entry_fee}}</td>
              <td>{{rowData.prize_money}}</td>
              <td>{{rowData.default}}</td>
              <td>{{rowData.color_code}}</td>

              <td style="width: 80px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <app-action-icon (onRefrashed)="onRefrash($event)"
                      [data]="{data:rowData,crsfToken:crsfToken,priceOptionList:setting,index:index}" [width]="'650'"
                      [modaleId]="'SettingPriceOptionComponent'" icon="edit" tooltip="update">
                    </app-action-icon>

                    <app-action-icon [data]="{data:rowData,crsfToken:crsfToken,priceOptionList:setting,index:index}"
                      [width]="'350px'" [modaleId]="'SettingDeleteModalComponent'" icon="remove" tooltip="remove"
                      (onRefrashed)="onRefrash($event)"> </app-action-icon>
                  </div>

                </mat-menu>


              </td>


            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" style="text-align:left">No data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

    </div>
    <div class="row">
      <div class="full-width">
        <div class="ui-g">
          <div class="ui-g-2">
            <label class="nospace"> NUMBER OF GAME PLAY ON LIVE </label>
            <mat-form-field class="example-full-width">
              <input matInput [(ngModel)]="inputValues.maxGameInDayAllow" type="number" class="form-control">
            </mat-form-field>

          </div>
          <div class="ui-g-2">
            <div class="btn-class">
              <button type="button" class="btn btn-primary custom-button-cls" id="maxGameInDayAllow"
                (click)="submitData($event,inputValues.maxGameInDayAllow)"> Save</button>
            </div>
          </div>
          <div class="ui-g-2">
            <label class="nospace"> DEFAULT GAME PER BONUS </label>
            <mat-form-field class="example-full-width">
              <input matInput [(ngModel)]="inputValues.defaultGamePerBonus" id="" type="number" class="form-control">
            </mat-form-field>
            <!-- <button type="button" class="btn btn-primary"> Save</button> -->

          </div>
          <div class="ui-g-2">
            <div class="btn-class">
              <button type="button" class="btn btn-primary custom-button-cls" id="defaultGamePerBonus"
                (click)="submitData($event,inputValues.defaultGamePerBonus)"> Save</button>
            </div>
          </div>
          <div class="ui-g-2">
            <label class="nospace"> WELCOME BONUS </label>
            <mat-form-field class="example-full-width">
              <input matInput [(ngModel)]="inputValues.welcomeBonus" id="" type="number" class="form-control">
            </mat-form-field>
            <!-- <button type="button" class="btn btn-primary"> Save</button> -->

          </div>
          <div class="ui-g-2">
            <div class="btn-class">
              <button type="button" class="btn btn-primary custom-button-cls" id="welcomeBonus"
                (click)="submitData($event,inputValues.welcomeBonus)"> Save</button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="full-width">
        <div class="ui-g">
          <div class="ui-g-2">
            <label class="nospace"> REFER FRIEND REWARD COIN </label>
            <mat-form-field class="example-full-width">
              <input matInput [(ngModel)]="inputValues.referFriendRewardCoin" id="" type="number" class="form-control">
            </mat-form-field>

          </div>
          <div class="ui-g-2">
            <div class="btn-class">
              <button type="button" class="btn btn-primary custom-button-cls" id="referFriendRewardCoin"
                (click)="submitData($event,inputValues.referFriendRewardCoin)"> Save</button>
            </div>
          </div>
          <div class="ui-g-2">
            <label class="nospace"> SELF GAME MAX ALLOWED REPORT </label>
            <mat-form-field class="example-full-width">
              <input matInput [(ngModel)]="inputValues.selfGameMaxAllowedReport" id="" type="number"
                class="form-control">
            </mat-form-field>


          </div>
          <div class="ui-g-2">

            <div class="btn-class">
              <button type="button" class="btn btn-primary custom-button-cls" id="selfGameMaxAllowedReport"
                (click)="submitData($event,inputValues.selfGameMaxAllowedReport)"> Save</button>
            </div>

          </div>

          <div class="ui-g-2">
            <label class="nospace">ALLOW PAID GAMES ONLY</label>
            <div class="checkbox-input">
              <input matCheckbox [(ngModel)]="inputValues.allowPaidGamesOnly" type="checkbox"
                class="form-control">
            </div>
          </div>
          <div class="ui-g-2">
            <div class="btn-class">
              <button type="button" class="btn btn-primary custom-button-cls" id="allowPaidGamesOnly"
                (click)="submitData($event,inputValues.allowPaidGamesOnly)"> Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>