import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CharacterService } from '../../../core/services/character.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TostService } from '../../../core/services/common/tost.service'
export interface ImageTransform {
  scale?: number;
  rotate?: number;
  flipH?: boolean;
  flipV?: boolean;
}
@Component({
  selector: 'app-gallery-image-edit',
  templateUrl: './gallery-image-edit.component.html',
  styleUrls: ['./gallery-image-edit.component.scss']
})
export class GalleryImageEditComponent implements OnInit {
  imageSrc: string;
  uploadValue: File;
  images: any;
  scale = 1;
  isCrop: Boolean = false;
  transform: ImageTransform = {};
  file: any;
  croppedImage: any = '';
  imageChangedEvent: any = '';
  characterObj = {
    image_url: '',
    file: '',
    fileSource: '',
    id: '',
    character_type: '',
    character_name: ''
  }
  myForm = new FormGroup({

    image_url: new FormControl('', []),
    file: new FormControl(''),
    fileSource: new FormControl('')
  });
  constructor(
    public dialogRef: MatDialogRef<GalleryImageEditComponent>, private tostService: TostService, private characterService: CharacterService, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data) {
      this.myForm.patchValue({
        character_name: this.data.character_name,
        id: this.data.id,
        character_type: this.data.character_type,
        image_url: this.data.image_url
      });
      this.myForm.patchValue({
        fileSource: this.data.image_url

      });

      this.characterObj.character_name = this.data.character_name,
        this.characterObj.id = this.data.id,
        this.characterObj.character_type = this.data.character_type,
        this.characterObj.image_url = this.data.image_url
      this.imageSrc = this.data.image_url;
      this.images = { background: 'url(' + this.imageSrc + ')' }

    } else {
      this.myForm.patchValue({
        character_name: '',
        id: '',
        character_type: '',
        image_url: ''
      });
      this.characterObj.character_name = '',
        this.characterObj.id = '',
        this.characterObj.character_type = '',
        this.characterObj.image_url = ''
      this.imageSrc = '';
      this.images = { background: 'url(' + this.imageSrc + ')' }

    }
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.myForm.patchValue({
      fileSource: this.base64ToFile(
        event.base64,
        this.myForm.value.fileSource.name,
      )
    });


    this.images = { background: 'url(' + this.croppedImage + ')' }
  }
  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  zoomIn(num) {
    this.scale = num.value;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  imageChange(e) {
    this.images = { background: 'url(' + e.target.value + ')' }
    this.myForm.patchValue({
      fileSource: e.target.value

    });
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onFileChange(event) {
    const reader = new FileReader();
    this.isCrop = true;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.uploadValue = event.target.files;
      this.characterObj.image_url = event.target.files[0];
      reader.readAsDataURL(file);
      this.imageChangedEvent = event;

      reader.onload = () => {

        this.imageSrc = reader.result as string;
        this.images = { background: 'url(' + this.imageSrc + ')' }

        this.myForm.patchValue({
          fileSource: event.target.files[0],

        });

      };

    }
  }
  uploadPicture() {
    const formData = new FormData();

    formData.append('image_url', this.myForm.value.fileSource);
    formData.append('id', this.data.id)
    this.characterService.uploadCharacterImage(formData).subscribe(data => {
      this.tostService.tostrFun('image updated successfully', 'success');
      this.dialogRef.close(data);
    },
      err => {
        this.tostService.tostrFun('image not updated successfully', 'error');
        this.dialogRef.close();
      }


    )
  }

  submitData() {
    // console.log(this.myForm.value);
  }
  close(): void {
    this.dialogRef.close(true);
  }


}
