<div class="modal-body testclass">
  <div class="row">
    <div class="col-md-12">
      Do you want to delete this tag and remove it from all games?
    </div>
  </div>
  <div class="row" style="margin-top: 15px;">
    <div class="col-md-6">
      <button mat-raised-button (click)="deleteTag()" class="btn  btn-danger pull-right "
        data-dismiss="alert" aria-label="Close">Yes</button>
    </div>
    <div class="col-md-6">
      <button mat-button mat-raised-button (click)="close()" class="btn btn-danger pull-right "
        data-dismiss="alert" aria-label="Close">
        No
      </button>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
