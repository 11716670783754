<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">User Pending Balance Transaction </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content>


  <p-table #dt [columns]="cols" styleClass="ui-table-cars" [loading]="loading" [responsive]="true"
    [value]="userTransaction" [paginator]="true" [rows]="10" dataKey="id">

    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
          {{col.header}}


        </th>
        <!-- <th style="width: 100px; text-align: center;">
                                <button pButton type="button" icon="pi pi-cog"></button>
                            </th> -->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
        <td style="width: 80px;">{{rowData.id}}</td>
        <td style="width: 80px;">{{rowData.game_id}}</td>
        <td style="width: 100px;">{{rowData.game_title}}</td>
        <td style="width: 100px;">{{rowData.amount}}</td>
        <td style="width: 100px;">{{dateCovert(rowData.created_at)}}</td>
        <td style="width: 100px;">{{dateCovert(rowData.when_will_wallet)}}</td>
        <td style="width: 90px;">{{rowData.status}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7" style="text-align:left">No data found.</td>
      </tr>
    </ng-template>
  </p-table>



</mat-dialog-content>
<div class="clearfix"></div>