import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TostService } from '../../core/services/common/tost.service';
import { SystemSettingsService } from '../../core/services/game/system-settings.service'
import { GameBankService } from '../../core/services/game-bank/game-bank.service';
import { RefDataService } from '../../core/services/common/ref-data.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-slush-fund',
  templateUrl: './slush-fund.component.html',
  styleUrls: ['./slush-fund.component.scss']
})
export class SlushFundComponent implements OnInit {

  cols: any;

  isEdit: boolean = false;
  entityId: any;
  game: any = {};
  is_save: any = false;
  slushFund: any = 0;
  globalCutConfig: any;
  percentConfig: any;
  maxPayout: any = 0;
  selectedGlobalConfig: any;
  coinRate: any = 100;
  maxMatchesCount: any;
  loading: Boolean;
  clonedCoin: { [s: string]: any; } = {};
  constructor (private modalService: NgbModal, private tostService: TostService, private refDataService: RefDataService, private gameBankService: GameBankService, private systemSettingsService: SystemSettingsService, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SlushFundComponent>) { }

  ngOnInit (): void {
    this.loading = true;
    this.refDataService.refDataAll().subscribe(data => {
      const res = data['defaultRefData'];
      this.coinRate = data['coinRate'] || 100;
      this.percentConfig = res['SLUSH_FUND_ENTRY_PERCENTAGE_CONFIG'] || 100;
      this.maxMatchesCount = res['SLUSH_PLAY_COUNTS'];
      this.maxMatchesCount = this.maxMatchesCount / 2;
    });
    this.cols = [
      { field: 'entry_fee', header: 'Price Option', width: "75px" },

      { field: 'total_matches', header: 'Total matches (2X users)', width: "90px" },
      // { field: 'brand', header: 'Brand' },
      { field: 'host_cut', header: 'host_cut', width: "100px" },
      { field: 'calculations', header: 'Two pair calculator', width: "90px" },
    ];

    // this.coinRate = JSON.parse(this.coinRateService.getCoinRateData());
    this.getData()

  }

  onRowEditInit (coin: any) {
    this.clonedCoin[coin.id] = { ...coin };
  }
  clickEdit () {
    this.isEdit = true;
  }
  close (): void {
    this.dialogRef.close(true);
  }

  getData () {

    this.gameBankService.getGameById(this.data.id).subscribe(data => {
      this.globalCutConfig = data['cut_config'];
      this.game = data;
      const botConfig = data['bot_config'];
      const freeOptions = {
        "host_cut": 0,
        "entry_fee": 0
      }
      if (this.game.tags.includes('ticket-for-price') || this.game.is_free_only) {
        this.globalCutConfig = [];
      }
      this.globalCutConfig.unshift(freeOptions);
      this.globalCutConfig.map(c => {
        if (botConfig) {
          const a = JSON.parse(botConfig);
          c.matches = a[c.entry_fee] || 0;
        } else {
          c.matches = 0;
        }

        c.calculate = (c.host_cut / this.coinRate) * (c.matches || 0);
        c.host_cut = (c.host_cut / this.coinRate);
        this.slushFund = Number(this.slushFund + c.calculate);

      });

      this.slushFund = Number(this.slushFund.toFixed(2))
      this.maxPayout = data['max_host_payout'];
      this.loading = false;
    });
  }
  customSort (event) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  slushFundChange (e) {
    if (Number(this.slushFund) > Number(this.game.max_host_payout)) {
      this.tostService.tostrFun('Cannot pay more than max payout please configure that first', 'error');
      this.is_save = false;
    } else {
      const cutConfigCopy = this.globalCutConfig.filter(c => Number(c.entry_fee) !== 0);
      const botCount = this.getBotCount({ totalPayout: (this.slushFund * this.coinRate), cutConfig: cutConfigCopy })
      this.globalCutConfig.map(c => {
        if (botCount && c.entry_fee !== 0) {
          c.matches = Number((botCount[c.entry_fee] || 0)) || 0;
        } else {
          if (c.entry_fee !== 0) {
            c.matches = 0;
          }
        }
        c.calculate = Number(c.host_cut * (c.matches || 0)) || 0;
      });
      const matchesCount = this.globalCutConfig.map(item => item.matches).reduce((prev, curr) => prev + curr, 0);
      if (matchesCount > this.maxMatchesCount) {
        this.tostService.tostrFun(`Cannot configure more than ${this.maxMatchesCount} matches. Please update match configuration`, 'error');
        this.is_save = false;
      } else {
        this.is_save = true;
      }

    }
  }

  slushFundChange1 (e) {
    this.globalCutConfig.map(c => {
      c.calculate = c.host_cut * (c.matches || 0);
    })

    this.slushFund = this.globalCutConfig.map(item => item.calculate).reduce((prev, curr) => prev + curr, 0);
    this.slushFund = Number(this.slushFund.toFixed(2));
    const matchesCount = this.globalCutConfig.map(item => item.matches).reduce((prev, curr) => prev + curr, 0);
    if (Number(this.slushFund) > Number(this.game.max_host_payout)) {
      this.tostService.tostrFun('Cannot pay more than max payout please configure that first', 'error');
      this.is_save = false;
    } else if (matchesCount > this.maxMatchesCount) {
      this.tostService.tostrFun(`Cannot configure more than ${this.maxMatchesCount} matches. Please update match configuration`, 'error');
      this.is_save = false;
    } else {
      this.is_save = true;
    }
  }

  getBotCount (params) {
    const botConfig = {};

    const { totalPayout, cutConfig } = params;
    let fee;
    let percent;
    let botCount;
    let cut;
    let totalPercent = 0;
    let payout;
    let extraEntryPayout = 0;
    let extraPercent = 0;
    let nextEntry;

    for (let idx = cutConfig.length - 1; idx >= 0; idx--) {
      const cc = cutConfig[idx];

      fee = Number(cc.entry_fee);
      cut = cc.hc_pool;

      if (!cut) {
        continue;
      }

      percent = Number(this.percentConfig[fee]) || 0;
      totalPercent += percent;

      if (totalPercent > 100) {
        percent -= totalPercent - 100;
      }

      if (percent <= 0) {
        continue;
      }

      payout = (totalPayout * percent) / 100;
      botCount = Math.floor(payout / cut);

      botConfig[fee] = botCount;

      if (idx > 0) {
        extraEntryPayout = payout % cut;
        nextEntry = cutConfig[idx - 1].entry_fee;

        extraPercent = (extraEntryPayout / totalPayout) * 100;
        this.percentConfig[nextEntry] = Number(this.percentConfig[nextEntry]) + extraPercent;
        totalPercent -= extraPercent;
      }
    }

    return botConfig;
  }

  save () {
    const bot_config = {};
    this.globalCutConfig.forEach(m => {
      bot_config[m.entry_fee] = m.matches || 0;
    });
    this.gameBankService.saveGameSlushFund({ bot_config, game_id: this.data.id, globalCutConfig: this.globalCutConfig })
      .subscribe(() => {
        this.tostService.tostrFun("Slush configuration  updated Successfully", 'success');
      });
    this.dialogRef.close();
  }

}
