import { Component, OnInit, Inject } from '@angular/core';
import { CharacterService } from '../../../core/services/character.service';
import { TostService } from '../../../core/services/common/tost.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-gallary-image',
  templateUrl: './remove-gallary-image.component.html',
  styleUrls: ['./remove-gallary-image.component.scss']
})
export class RemoveGallaryImageComponent implements OnInit {
  imageID = '';
  constructor(public dialogRef: MatDialogRef<RemoveGallaryImageComponent>, private characterService: CharacterService,
    @Inject(MAT_DIALOG_DATA) public data: any, private toastService: TostService) { }

  ngOnInit(): void {
  }

  removeImage() {
    const formData = new FormData();
    this.imageID = this.data.id

    formData.append('image_url', '');
    formData.append('id', this.imageID);
    this.characterService.uploadCharacterImage(formData).subscribe(data => {
      // console.log("edit gallary image", data);
      this.toastService.tostrFun('image removed successfully', 'success');
      this.dialogRef.close(data);

    },
      error => {

      }
    )
  }

  close() {
    this.dialogRef.close(true);
  }

}
