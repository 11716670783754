import { Component, OnInit, Inject } from '@angular/core';
import { PromoCodeService } from '../../../core/services/league/promo-code.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../../core/services/common/tost.service'

@Component({
  selector: 'app-common-delete-modal',
  templateUrl: './common-delete-modal.component.html',
  styleUrls: ['./common-delete-modal.component.scss']
})
export class CommonDeleteModalComponent implements OnInit {

  constructor (public dialogRef: MatDialogRef<CommonDeleteModalComponent>, private promoCodeService: PromoCodeService, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService) { }
  id = this.data.id;
  ngOnInit (): void {
    //  console.log("data from other", this.data);
  }
  // getData(){
  //   this.promoCodeService.getPromoCode(null).subscribe(receivedPromocodeData =>{})
  // }
  deleteRecord (id) {
    //    console.log("data from other",this.data);
    // this.promoCodeService.deletePromoCode().subscribe =>();
    this.promoCodeService.deletePromoCode(id).subscribe(data => {
      this.tostService.tostrFun("PromoCode Deleted  Successfully", 'success');

    });
    this.dialogRef.close();
  }

  submitData () {
    // console.log(this.myForm.value);
  }
  close (): void {
    this.dialogRef.close(true);
  }

}
