import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LevelsComponent } from './levels/levels.component';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button'
import { HttpClientModule } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext'
import { MatButtonModule } from '@angular/material/button';
import { MultiSelectModule } from 'primeng/multiselect'
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { LeaugeCategoryComponent } from './leauge-category/leauge-category.component';
import { PromocodeComponent } from './promocode/promocode.component';
import { CoinRateComponent } from './coin-rate/coin-rate.component';
import { NotificationComponent } from './notification/notification.component';
import { FeeAndSsgComponent } from './fee-and-ssg/fee-and-ssg.component';
import { SettingsComponent } from './settings/settings.component';
import { LeagueScreenComponent } from './league-screen/league-screen.component';
import { ActionComponentModule } from "../action-component/action-component.module";
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NotificationTypesComponent } from './notification-types/notification-types.component';
import { PartnerScreenComponent } from './partner-screen/partner-screen.component';
@NgModule({
  declarations: [LevelsComponent, LeaugeCategoryComponent, PromocodeComponent, CoinRateComponent, NotificationComponent, FeeAndSsgComponent, SettingsComponent, LeagueScreenComponent, NotificationTypesComponent, PartnerScreenComponent],
  imports: [
    CommonModule,

    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    InputTextModule,
    MatSelectModule,
    MatTooltipModule,
    HttpClientModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    FormsModule,
    ActionComponentModule,
    MatIconModule,
    MatMenuModule,

  ],
  exports: [LevelsComponent, PromocodeComponent]
})
export class LeaugeModule { }
