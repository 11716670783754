<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Create fee and cut ssg </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="feeCutForm" class="input-size">
        <!-- <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Create fee and cut ssg</label>
            </div>
            
            
        </div> -->

        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Entry Fee</label>
            </div>
            <div class="ui-g-6 form-group">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="entry_fee" id="entry_fee" placeholder="Entry Fee" type="number"
                        class="form-control">
                </mat-form-field>
            </div>

        </div>
        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Cut per Player</label>
            </div>
            <div class="ui-g-6 form-group">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="ugg_cut" id="ugg_cut" placeholder="Cut per player"
                        type="number" class="form-control" step="0.01">
                </mat-form-field>
            </div>

        </div>
        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Prize Money</label>
            </div>
            <div class="ui-g-6 form-group">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="prize_money" id="prize_money" placeholder="Prize Money"
                        type="number" class="form-control">
                </mat-form-field>
            </div>

        </div>
        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Prize Option active</label>
            </div>
            <div class="ui-g-6 form-group">
                <p-checkbox [binary]="true" [formControl]="feeCutForm.controls['is_active']" value="is_active"
                    label="price option active " inputId="ny"></p-checkbox>
            </div>

        </div>
        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Prize Option Locked</label>
            </div>
            <div class="ui-g-6 form-group">
                <p-checkbox [binary]="true" [formControl]="feeCutForm.controls['is_locked']" value="is_locked"
                            label="price option locked" inputId="ny"></p-checkbox>
            </div>

        </div>

        <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" 
        size = "medium" color = "#fff" 
        type = "square-jelly-box" 
        [fullScreen] = "true">
        <p style="color: white" > Loading... </p>
       </ngx-spinner>

        <div class="modal-footer">
            <!-- <button mat-raised-button  class="btn btn-primary pull-right"  (click)="close()">Cancel</button> -->

            <button mat-raised-button type="submit" class="btn  btn-danger pull-right " data-dismiss="alert"
                aria-label="Close" [disabled]="!feeCutForm.valid" (click)="submitData()">Save</button>
        </div>
        <!-- <div class="clearfix"></div> -->


    </form>
</div>
