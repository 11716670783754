<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Transaction Info</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <p-table #dt [columns]="cols" [value]="userTransaction" (sortFunction)="customSort($event)" [customSort]="true"
    dataKey="id" styleClass="ui-table-cars" [rows]="10" [rowsPerPageOptions]="[10,25,50,100,300,500]"
    [responsive]="true" [scrollable]="true" [loading]="loading" [paginator]="true" [totalRecords]="totalRecords"
    selectionMode="single" [(selection)]="selectedTag" [lazy]="true" (onLazyLoad)="customPagination($event)">
    <ng-template pTemplate="caption">
      <form [formGroup]="myForm">
        <div class="row">
          <div class="col-md-4">
            <p-calendar formControlName="start_date" placeholder="Start Date"></p-calendar>
          </div>
          <div class="col-md-4" style="margin-bottom:10px">
            <p-calendar formControlName="end_date" [defaultDate]="defaultDate" placeholder="End Date"></p-calendar>
          </div>

          <div class="col-md-4">
            <button class=" btn btn-primary" style="width: 100%;padding: 0%;text-align: center;" pButton type="button"
              class="ui-button-warning" icon="fa fa-search" style="margin: .2em"
              (click)="clickUserFilter(false)"></button>
            <button class=" btn btn-primary " style="width: 100%;padding: 0%;text-align: center;" pButton type="button"
              (click)="clickRefreshFilter()" class="ui-button-warning" style="margin: .2em"
              icon="fa fa-refresh"></button>

            <button pButton type="button" class="ui-button-raised" icon="fa fa-download" style="margin: .2em"
              (click)="exportToExcel()">
            </button>
          </div>

        </div>
      </form>

    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field"
          [ngStyle]="{'width': col.width,'display': col.display}">

          <div class="header-title">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </div>
        </th>
      </tr>

      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width,'display': col.display}">

          <input *ngSwitchCase="'game_id'" pInputText type=" number" min="1" placeholder="" class="ui-column-filter"
            style="width: 100%;" (keyup)="tagsFilter($event.target.value, col.field)">
          <input *ngSwitchCase="'game_play_id'" pInputText type="number" placeholder="" class="ui-column-filter"
            style="width: 100%;" (keyup)="tagsFilter($event.target.value, col.field)">
          <input *ngSwitchCase="'amount'" pInputText type="number" placeholder="" class="ui-column-filter"
            style="width: 100%;" (keyup)="tagsFilter($event.target.value, col.field)">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
        <td style="width: 1px;display: none">{{rowData.id}}</td>
        <td style=" width: 1px;display: none">{{rowData.user_id}}</td>
        <td style="width: 100px;">{{rowData.game_id}}</td>
        <td style="width: 100px;">{{rowData.game_play_id}}</td>
        <td style="width: 100px;">{{rowData.amount}}</td>
        <td style="width: 110px">{{rowData.amount_type}}</td>
        <td style="width: 160px;">{{rowData.tx_type}}</td>
        <td style="width: 175px;">{{rowData.question}}</td>
        <td style="width: 1px;display: none">{{rowData.game_choices}}</td>
        <td style="width: 1px;display: none">{{rowData.ticket_id}}</td>
        <td style="width: 135px;">{{rowData.tx_date}}</td>
        <td style=" width: 1px;display: none">{{rowData.tx_date_time_gmt}}</td>


      </tr>
    </ng-template>
  </p-table>
</mat-dialog-content>

<mat-dialog-content [ngStyle]="{'display': 'none'}" #epltable>
  <p-table #dt [columns]="cols" [value]="userTransactionCopy">

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field"
          [ngStyle]="{'width': col.width,'display': col.display}">

          <div class="header-title">
            {{col.header}}

          </div>
        </th>
      </tr>


    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
        <td style="width: 1px;display: none">{{rowData.id}}</td>
        <td style=" width: 1px;display: none">{{rowData.user_id}}</td>
        <td style="width: 100px;">{{rowData.game_id}}</td>
        <td style="width: 100px;">{{rowData.game_play_id}}</td>
        <td style="width: 100px;">{{rowData.amount}}</td>
        <td style="width: 100px">{{rowData.amount_type}}</td>
        <td style="width: 160px;">{{rowData.tx_type}}</td>
        <td style="width: 150px;">{{rowData.question}}</td>
        <td style="width: 1px;display: none">{{rowData.game_choices}}</td>
        <td style="width: 1px;display: none">{{rowData.ticket_id}}</td>
        <td style="width: 125px;">{{rowData.tx_date}}</td>
        <td style=" width: 1px;display: none">{{rowData.tx_date_time_gmt}}</td>


      </tr>
    </ng-template>
  </p-table>
</mat-dialog-content>
<!-- <div class=" clearfix">
</div> -->