import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button'
import { HttpClientModule } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext'
import { MatButtonModule } from '@angular/material/button';
import { MultiSelectModule } from 'primeng/multiselect'
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user/user.component';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ActionComponentModule } from '../action-component/action-component.module';
import { ToastrModule } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlacklistCountryStatesComponent } from './blacklist-country-states/blacklist-country-states.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { AdminManagerComponent } from './admin-manager/admin-manager.component';
@NgModule({
  declarations: [UserComponent, BlacklistCountryStatesComponent, UpdatePasswordComponent, AdminManagerComponent],
  imports: [
    CommonModule,
    UserRoutingModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    InputTextModule,
    MatSelectModule,
    MatTooltipModule,
    HttpClientModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    RadioButtonModule,
    MatCheckboxModule,
    ButtonModule,
    ActionComponentModule,
    ToastrModule.forRoot(),
    MatIconModule, FormsModule, ReactiveFormsModule,
    MatMenuModule,
    CheckboxModule,
    FormsModule,
  ],
  exports: [
    UserComponent
  ]
})
export class UserModule { }
