<!-- <p>selfgame-game-performance-modal works!</p> -->
<!-- this is{{alldata.game_name}} -->
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Game Performance</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<mat-dialog-content layout-xs>
    <div class="mat-dialog-content">
        <!-- <div *ngFor="let row of alldata">  -->
        <div>
            <div style='border-bottom: 1px solid #ccc;margin-bottom: 15px;'>

                <div class="ui-g">
                    <div class="ui-g-4">
                        <label> Game id : </label>
                        {{alldata.id}}
                    </div>
                    <div class="ui-g-4">
                        <label> Game Name : </label>
                        {{alldata.game_title}}
                    </div>
                    <div class="ui-g-4">
                        <label> Total Players : </label>
                        {{alldata.total_playing}}
                    </div>
                </div>

                <div class="ui-g">
                    <div class="ui-g-4">
                        <label> Start date : </label>
                        {{alldata.start_time | date:'medium' }}
                    </div>
                    <div class="ui-g-4">
                        <label> End Date : </label>
                        {{alldata.end_time | date:'medium' }}
                    </div>
                    <div class="ui-g-4">
                        <label> Entry fee : </label>
                        {{alldata.entry_fee}}
                    </div>
                </div>

                <div class="ui-g">
                    <div class="ui-g-4">
                        <label> Total Money : </label>
                        {{alldata.total_money_recieved}}
                    </div>
                    <div class="ui-g-4">
                        <label> Paycut : </label>
                        {{alldata.cut_per_player}}

                    </div>
                    <div class="ui-g-4">
                        <label> Status : </label>
                        {{status}}
                    </div>
                </div>

                <p-table [value]="questionData" [loading]="loading">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 80px;">Id</th>
                            <th style="width: 80px;">Game Text</th>
                            <th style="width: 80px;">Player 1</th>
                            <th style="width: 80px;">Player 2</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>

                            <td>{{rowData.id}}</td>
                            <td style="white-space: normal; word-break: break-word;">{{rowData.game_text}}</td>
                            <td *ngIf="rowData.background.type!='single'">
                                <!-- <div *ngFor="let it of descData; index as i"> 
                                <mat-list-item style="padding:0px;" *ngIf="it.type=='url'">
                                    <a [href]= "it.url" target="_blank" >{{ it.url }} </a> &nbsp; &nbsp;
                                    <i class="fa fa-copy" style="cursor: pointer;" copy-to-clipboard="{{ it.url }}"></i>
                                </mat-list-item>
                                
                                <img *ngIf="it.type=='image'" [src]="it.url" target="_blank" class="playerImage"/>
                            </div> -->
                                {{rowData.player1_name}} Votes({{rowData.score1}})
                                <img [src]="rowData.player1_image" target="_blank" class="playerImage" />
                            </td>
                            <td *ngIf="rowData.background.type!='single'">
                                {{rowData.player2_name}} Votes({{rowData.score2}})
                                <!-- 
                                <img [src]="rowData.player2_image" target="_blank" class="playerImage" /> -->
                            </td>
                            <td colspan="2" *ngIf="rowData.background.type=='single'">
                                {{rowData.player1_name}} Votes({{rowData.score1}}) <b>VS</b> {{rowData.player2_name}}
                                Votes({{rowData.score2}})

                                <!-- <img [src]="rowData.background.urls[0].url" target="_blank" class="playerImage" /> -->
                            </td>

                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</mat-dialog-content>