import { Injectable } from '@angular/core';
import { pinnedQuestionData } from '../../data/pinnedQuestion';
import { Endpoints ,SiteConstants} from '../../../../config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PinnedQuestionService {

  constructor(private httpClient: HttpClient) { }

  getPinnedQuestionData() {
    return pinnedQuestionData;
  }
  getPinnedQuestionData1(pinnedQuestionFilter) {
    let search='';
    let pagination ='';
    let sort ='';
    let type={"question_type":"P"}
    if(pinnedQuestionFilter.search == undefined){
      // search= JSON.stringify(pinnedQuestionFilter.search);
      search=JSON.stringify(type)
    }else{
      Object.assign(pinnedQuestionFilter.search,{"question_type":"P"}); 
      search= JSON.stringify(pinnedQuestionFilter.search,);
    }

    // if(pinnedQuestionFilter.sort){
    //   sort = JSON.stringify(pinnedQuestionFilter.sort)
    // }
    if (pinnedQuestionFilter.sort) {
      if (Object.keys(pinnedQuestionFilter.sort).length > 0) {
        sort = JSON.stringify(pinnedQuestionFilter.sort)
      } else {
        sort = JSON.stringify({ id: 'desc' })
      }
    } else {
      sort = JSON.stringify({ id: 'desc' })
    }

    if(pinnedQuestionFilter.pagination){
      pagination = JSON.stringify(pinnedQuestionFilter.pagination)
    }else {
      pagination = SiteConstants.paginationDefault;
    }
         const url = Endpoints.api.pinnedQuestion.getPinnedQuestionList
      .replace('{search}',search )
      .replace('{sort}',sort )
      .replace('{pagination}',pagination);
     
      const observable = this.httpClient.get(url)
     
      return observable; 
    
 
  }
}
