<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Custom Question List</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>  

<mat-dialog-content>

    <form>

        <p-table #dt [columns]="cols" [lazy]="true" (onLazyLoad)="customPagination($event)" scrollHeight="350px" [value]="customQuestion"  [customSort]="true" [(selection)]="selectedCustomQuestion" dataKey="question_text" styleClass="ui-table-customers"
        [rows]="10" [totalRecords]="totalRecords"  [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
        [paginator]="true">
        <!-- <ng-template pTemplate="caption">
            List of Users
           
        </ng-template> -->

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 3em">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field" >
                    <div class="header-title">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </div>
                </th>
               
            </tr>
            <tr>
                <th>

                </th>
                
                <th *ngFor="let col of columns"  [ngSwitch]="col.field">
                    
                    <input *ngSwitchCase="'question_text'" pInputText type="text" placeholder="Question Text" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                    <input *ngSwitchCase="'character_type'" pInputText type="text" placeholder="Character Type" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                    <input *ngSwitchCase="'character_id_1_name'" pInputText type="text" placeholder="character1 Name" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                    <input *ngSwitchCase="'character_id_2_name'" pInputText type="text" placeholder="character2 Name" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">


                    
                </th>
                <!-- <th style="width: 270px; text-align: center;">

                </th> -->
            </tr>
        </ng-template>


        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                <td>
                 <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td>{{rowData.source_type}}</td>
                <td>{{rowData.question_text}}</td>
                <td>{{rowData.character_type}}</td>
                <td>{{rowData.character_id_1_name}}</td>
                <td>{{rowData.character_id_2_name}}</td>

            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8" style="text-align:left">No data found.</td>
            </tr>
        </ng-template>
    </p-table> 
        <!-- <p-table #dt [columns]="cols" scrollHeight="350px" [scrollable]="true" styleClass="ui-table-cars" [value]="customQuestion" [paginator]="true" [rows]="5" dataKey="question_text" [(selection)]="selectedCustomQuestion">

            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngFor="let col of columns" style="width:100px">
                    <col style="width:100px">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3em">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngSwitch]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                        <input *ngSwitchCase="'question_text'" pInputText type="text" placeholder="Question Text" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                        <input *ngSwitchCase="'character_type'" pInputText type="text" placeholder="Character Type" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">

                        <input *ngSwitchCase="'character_id_1_name'" pInputText type="text" placeholder="character1 Name" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                        <input *ngSwitchCase="'character_id_2_name'" pInputText type="text" placeholder="character2 Name" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">

                    </th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td>

                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td>{{rowData.source_type}}</td>
                    <td>{{rowData.question_text}}</td>
                    <td>{{rowData.character_type}}</td>
                    <td>{{rowData.character_id_1_name}}</td>
                    <td>{{rowData.character_id_2_name}}</td>
                </tr>
            </ng-template>

        </p-table> -->

    </form>
</mat-dialog-content>
<div>
    <div class="modal-footer">
        <mat-dialog-actions>
            <button mat-button type="button" (click)="submit(selectedCustomQuestion)">Add</button>
            <button mat-button type="button" (click)="close()">Cancel</button>
        </mat-dialog-actions>

    </div>
    <div class="clearfix"></div>
</div>