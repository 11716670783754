import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { CustomQuestionsViewModalComponent } from '../custom-questions-view-modal/custom-questions-view-modal.component'
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { CustomQuestionService } from '../../../core/services/game/custom-question.service';
import { PinnedGameService } from '../../../core/services/game/pinned-game.service';

@Component({
  selector: 'app-custom-game-edit-modal',
  templateUrl: './custom-game-edit-modal.component.html',
  styleUrls: ['./custom-game-edit-modal.component.scss']
})
export class CustomGameEditModalComponent implements OnInit {

  is_disable: boolean = false;
  alldata: any[];

  tagsOptions: any[];
  leagueCategory: any[];
  customQuestion: any;
  price_options: any[];
  selectedCustomQuestion: any[];
  cGameForm = new FormGroup({
    game_name: new FormControl('', [Validators.required]),
    start_time: new FormControl(new Date()),
    end_time: new FormControl(new Date()),
    last_join_time: new FormControl(new Date()),
    title: new FormControl('', []),
    price_options: new FormControl('', []),
    tags: new FormControl('', []),
  });
  constructor(private customQuestionService: CustomQuestionService, private _fb: FormBuilder,
    private refDataService: RefDataService, private pinnedGameService: PinnedGameService, public dialog: MatDialog,
    public dialogRef: MatDialogRef<CustomGameEditModalComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any) {

    this.tagsOptions = [
      { label: 'Poll', value: 'poll' },
      { label: 'Sample', value: 'sample' },

    ]

    this.leagueCategory = [
      { name: 'company', value: 'company' },
      { name: 'other', value: 'other' },
      { name: 'sports', value: 'sports' },
      { name: 'movies', value: 'movies' },
      { name: 'test', value: 'test' },
      { name: 'music', value: 'music' },
      { name: 'user generated', value: 'user_generated' },
      { name: 'customer', value: 'customer' },
      { name: 'YES/NO', value: 'yes/no' },

    ]

  }

  ngOnInit(): void {
    // this.leagueCategory = JSON.parse(this.refDataService.getRefAttData()).attributes;
    this.customQuestion = this.customQuestionService.getCustomQuestionData();
    if (this.data) {
      this.is_disable = true;
      this.getQuestionApi(this.data.id);


      this.cGameForm.patchValue({
        game_name: this.data.game_name,
        start_time: this.data.start_time,
        end_time: this.data.end_time,
        last_join_time: this.data.last_join_time,
        title: this.data.title,
        price_options: this.data.price_options,
        tags: this.data.tags,
        category_name: this.data.category_name
      });
      // console.log(this.data.price_options)
      this.price_options = this.data.price_options;
    } else {
      this.cGameForm.patchValue({
        game_name: '',
        start_time: new Date(),
        end_time: new Date(),
        last_join_time: new Date(),
        title: '',
        tags: '',
        category_name: '',
        price_options: [{
          entry_fee: 0,
          prize_money: 0,
          default: false
        }]
      });
    }
    this.price_options = [{
      entry_fee: 0,
      prize_money: 0,
      default: true
    }];
  }
  private priceOptions(): FormGroup {
    return this._fb.group({
      entry_fee: [],
      prize_money: [],
      default: []
    });
  }
  close(): void {
    this.price_options = [{
      entry_fee: 0,
      prize_money: 0,
      default: true
    }];
    this.dialogRef.close();
  }
  submitData() {
    //  console.log(this.cGameForm.value);
  }
  addPriceOption() {
    this.price_options.push({
      entry_fee: 0,
      prize_money: 0,
      default: true
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(CustomQuestionsViewModalComponent, {
      width: '1000px',
      data: { data: this.selectedCustomQuestion }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.selectedCustomQuestion = result;
      //  console.log(result);
    });
  }


  getQuestionApi(id) {

    this.pinnedGameService.getGameData(id).subscribe(data => {
      //  console.log("game performance ",data);
      // this.alldata = data.disputesList;
      const res = JSON.stringify(data);

      this.alldata = (JSON.parse(res).questions);
      this.selectedCustomQuestion = this.alldata;
      // const getCharData = JSON.stringify(getData);
      // this.alldata = JSON.parse(getCharData);
      // this.loading = false;
    });
    // this.close();
  }

}
