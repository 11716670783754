import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PayoutService } from '../../core/services/character/payout.service';
import { TostService } from '../../core/services/common/tost.service';
@Component({
  selector: 'app-payout-promocode',
  templateUrl: './payout-promocode.component.html',
  styleUrls: ['./payout-promocode.component.scss']
})
export class PayoutPromocodeComponent implements OnInit {

  constructor(private tostService: TostService, private payoutService: PayoutService, public dialogRef: MatDialogRef<PayoutPromocodeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  close(): void {
    this.dialogRef.close(true);
  }
  cancelPayout() {
    const obj = { 'user_id': this.data.user_id };
    this.payoutService.cancelPayout(obj).subscribe(
      res => {
        this.tostService.tostrFun("Cancel payout successfully", 'success');
        this.dialogRef.close(obj.user_id);

      },
      err => {
        /// this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close(obj.user_id);
      });
  }
}
