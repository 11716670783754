<!-- <div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Game Configurations</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <div class="modal-body"> -->
<div class="row">
  <div class="col-md-4">
    <mat-form-field>
      <mat-label>Show After X Game:</mat-label>
      <input matInput type="number" (change)="showAfterXGame($event)" [(ngModel)]="promoBannerObj.show_after_x_game"
        min="0" name="show_after_x_game" />
    </mat-form-field>
  </div>
  <!-- <div class="col-md-4">
    <mat-form-field>
      <mat-label>Show of the every x game:</mat-label>
      <input matInput type="number" (change)="showEveryXgame($event)" [(ngModel)]="promoBannerObj.show_of_every_x_game"
        min="0" name="show_of_every_ x_game" />
    </mat-form-field>
  </div> -->
  <div class="col-md-4">
    <mat-form-field>
      <mat-label>Show Max:</mat-label>
      <input matInput [(ngModel)]="promoBannerObj.show_max" (change)="showMax($event)" type="number" min="0"
        name="show_max" />
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <mat-form-field>
      <mat-label>Position:</mat-label>
      <input matInput type="number" (change)="position($event)" [(ngModel)]="promoBannerObj.position" min="0"
        name="position" />
    </mat-form-field>
  </div>
</div>

<h6>Game Ids</h6>
<div class="row">
  <div class="col col-12">
    <tag-input [(ngModel)]="promoBannerObj.game_ids" secondaryPlaceholder="Enter game id's" [modelAsStrings]="true"
      [errorMessages]="asyncErrorMessages" [asyncValidators]="asyncValidators" (onAdd)="onTagAdd($event)"
      (onRemove)="onTagRemove($event)" #tagsinput>
      <tag-input-dropdown [autocompleteObservable]="searchTags" [appendToBody]="false">
        <ng-template let-item="item" let-index="index">
          {{ item.display }}
        </ng-template>
      </tag-input-dropdown>
      <ng-template let-item="item" let-index="index">
        <div style="padding: 0 5px;font-size: 15px;">
          {{ item.display }}
          <span style="font-size: 11px; font-style: italic;">{{ item }}</span>
          <delete-icon (click)="tagsinput.removeItem(item, index)"></delete-icon>
        </div>
      </ng-template>
    </tag-input>
  </div>
</div>
<!-- </div>
    <div class="modal-footer">
      <button mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</button>
      <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="saveData()">Save</button>
    </div>
  </div>
</mat-dialog-content> -->


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>