import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { SiteConstants } from '../../../../config/site-constants';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';
import { TostService } from '../../../core/services/common/tost.service';

@Component({
  selector: 'app-super-tag-edit-modal',
  templateUrl: './super-tag-edit-modal.component.html',
  styleUrls: ['./super-tag-edit-modal.component.scss']
})
export class SuperTagEditModalComponent implements OnInit {

  super: any;
  action: any = "create";
  icons = SiteConstants.icons;

  tooltips = SiteConstants.tooltips;



  constructor (
    public dialogRef: MatDialogRef<SuperTagEditModalComponent>,
    private gameBankService: GameBankService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private refDataService: RefDataService,
    private tostService: TostService
  ) { }

  ngOnInit (): void {
    if (this.data.super) {
      this.action = "update"
    } else {
      this.action = "create"
    }
    this.super = this.data.super || {
      title: '',
      sub_titlw: '',
      is_active: false,
      tag_index: ''
    };
  }

  close () {
    this.dialogRef.close(true);
  }

  saveSuperTag () {
    // console.log(this.super)
    this.super.action = this.action;

    var hasErr = '';
    if (!this.super.title) {
      hasErr = "please enter title";
    } else if (!this.super.tag_key) {
      hasErr = "Please enter tag key";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    this.super.tag_key = this.super.tag_key.toLowerCase();
    this.spinner.show();
    this.gameBankService
      .saveSuperTags(this.super)
      .subscribe(res => {
        this.dialogRef.close();
        this.spinner.hide()
        this.tostService.tostrFun(`Super tag ${this.action}d successfully`, 'success');
      }, () => {
        this.spinner.hide()
      });
  }
}
