<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <p-table #dt [columns]="cols" [value]="user" (sortFunction)="customSort($event)" [customSort]="true"
          dataKey="id" styleClass="ui-table-cars" [rows]="10" [rowsPerPageOptions]="[10,25,50]" [responsive]="true"
          [scrollable]="true" [loading]="loading" [paginator]="true" [totalRecords]="totalRecords"
          selectionMode="single" [(selection)]="selectedTag" [lazy]="true" (onLazyLoad)="customPagination($event)">
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="ui-g ui-fluid">
                <div class="ui-g-12 ui-md-3">
                  <app-action-button [data]="{ trending: null }" (onRefrashed)="onRefresh($event)" [width]="'1000px'"
                    [modaleId]="'CreateAdminManagerComponent'">
                  </app-action-button>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                <div class="header-title">
                  {{col.header}}
                  <p-sortIcon *ngIf="col.header !== 'Actions'" [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </div>
              </th>
            </tr>

            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <input *ngSwitchCase="'first_name'" pInputText type="text" min="1" placeholder=""
                  class="ui-column-filter" style="width: 100%;" (keyup)="tagsFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'last_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="tagsFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'user_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="tagsFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'email'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="tagsFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'user_role'" pInputText type="number" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="tagsFilter($event.target.value, col.field)">
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
              <td style="width: 80px;">{{rowData.id}}</td>
              <td style="width: 120px;">{{rowData.first_name}}</td>
              <td style="width: 120px;">{{rowData.last_name}}</td>
              <td style="width: 120px;">{{rowData.user_name}}</td>
              <td style="width: 150px;">{{rowData.email}}</td>
              <td style="width: 90px;">{{rowData.user_role}}</td>
              <td style="width: 80px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <app-action-icon [data]="{ 'user': rowData }" [width]="'1000px'"
                      [modaleId]="'CreateAdminManagerComponent'" visibleIcons="updateadminmanagerKey" icon="edit"
                      (onRefrashed)="onRefresh($event)">
                    </app-action-icon>

                  </div>
                </mat-menu>


              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>