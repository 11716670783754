<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Abuse report</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<mat-dialog-content layout-xs>
    <!-- <h2 mat-dialog-title>Abuse Report</h2>  -->
    <div class="mat-dialog-content">
        <div>
            <div>

                <div class="ui-g">
                    <div class="ui-g-4">
                        <label> Game id : </label>
                        {{data.id}}
                    </div>
                    <div class="ui-g-8">
                        <label> Game name : </label>
                        {{data.game_title}}
                    </div>

                </div>

            </div>

            <p-table [value]="alldata" [loading]="loading">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Reported By</th>
                        <th>Report Reason</th>
                        <th>Report Comment</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>

                        <td><a [routerLink]="['/user', rowData.reported_by]"><span
                                    (click)="redirectToUser()">{{rowData.reported_by}} </span></a></td>
                        <td>{{rowData.report_reason}}</td>
                        <td>{{rowData.description}}</td>
                        <td>
                            <div *ngIf="rowData.is_fake == 1">IGNORED</div>
                            <app-action-icon [data]="{game_id:rowData.game_id,report_id:rowData.id}" [width]="'350px'"
                                *ngIf="rowData.is_fake == 0" [modaleId]="'IgnoreReportComponent'"
                                (onRefrashed)="onRefrash($event)" icon="delete" tooltip="ignorereport">
                            </app-action-icon>

                        </td>

                    </tr>
                </ng-template>
            </p-table>

        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer">
    <app-action-icon [data]="{game_id:data.id,report_id:''}" [width]="'350px'" *ngIf="isStatus(status)"
        [modaleId]="'IgnoreReportComponent'" (onRefrashed)="onRefrash($event)" icon="delete" tooltip="ignoreallreport">
    </app-action-icon>

    <app-action-icon [data]="{id:data.id}" [width]="'350px'" *ngIf="isStatus(status)"
        [modaleId]="'SelfgameCancelModalComponent'" (onRefrashed)="onRefrash($event)" icon="remove"
        tooltip="gamecancel">
    </app-action-icon>
</div>