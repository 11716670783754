import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FollowService } from '../../../core/services/users/follow.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TostService } from '../../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-follow-user-modal',
  templateUrl: './follow-user-modal.component.html',
  styleUrls: ['./follow-user-modal.component.scss']
})
export class FollowUserModalComponent implements OnInit {
  followObj: any = {};

  constructor (private tostService: TostService, private spinner: NgxSpinnerService, private followService: FollowService, public dialogRef: MatDialogRef<FollowUserModalComponent>, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) { }

  ngOnInit (): void {
    if (this.data) {
      this.spinner.show();
      this.followService.getFollowCount(this.data.id).subscribe(data => {
        this.spinner.hide();
        const respData = JSON.stringify(data);
        this.followObj = JSON.parse(respData);
      },
        err => {
          /// this.tostService.tostrFun(err.error.errors[0].message, 'error');
          this.dialogRef.close();
          this.spinner.hide();
        });
    }
  }

  redirectFollowerUserPage () {
    this.dialogRef.close();
    // this.router.navigate(['user/follower', this.data.id]);
    this.router.navigate(['/user']).then(() => { this.router.navigate(['user/follower', this.data.id]); })
  }
  redirectFollowingUserPage () {
    this.dialogRef.close();
    this.router.navigate(['/user']).then(() => { this.router.navigate(['user/following', this.data.id]); })
  }

  close (): void {
    this.dialogRef.close(true);
  }

}

