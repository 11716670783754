import { Component, OnInit, Inject, Output, Input, EventEmitter } from '@angular/core';
import { GameBankService } from '../../core/services/game-bank/game-bank.service';
import { map } from 'rxjs/operators';
import { TostService } from '../../core/services/common/tost.service';
@Component({
  selector: 'app-category-search-modal',
  templateUrl: './category-search-modal.component.html',
  styleUrls: ['./category-search-modal.component.scss']
})
export class CategorySearchModalComponent implements OnInit {
  tags: any;
  tagsLoading = true;
  freeOnlyLoading = false;
  @Output() tagOutput = new EventEmitter<any>();
  @Output() onClearTags = new EventEmitter<any>();
  @Input() tagInputData: string[];
  constructor (

    private gameBankService: GameBankService,

    private tostService: TostService
  ) { }

  ngOnInit (): void {
    if (this.tagInputData && this.tagInputData.length) {
      this.gameBankService
        .categoriesSearch({ tags: this.tagInputData, is_search: false }).subscribe(res => {
          this.tags = res['tags'].map(v => {
            const obj = {
              display: v['display_tag'],
              value: v['tag']
            }
            return obj;
          });

        });
    }
  }

  close () {
    this.onClearTags.emit("p");
    this.tags = []
  }

  public searchTags = (text) => {
    return this.gameBankService
      .categoriesSearch({ tag: text, is_search: true })
      .pipe(map(res => res['tags']['map'](v => ({
        display: v['display_tag'],
        value: v['tag']
      }))));
  }

  public onTagAdd = (tag) => {
    const tags = this.tags.map(tag => tag.value)
    this.tagOutput.emit(tags);
  }

  public onTagRemove = (tag) => {
    const tags = this.tags.map(tag => tag.value);
    this.tagOutput.emit(tags);
  }
}
