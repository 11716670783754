<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">


        <p-table #dt [columns]="cols" [value]="affiliateData" [customSort]="true" [(selection)]="selectedAffiliate"
          dataKey="id" styleClass="ui-table-cars" [rows]="10" [rowsPerPageOptions]="[10,25,50]" [scrollable]="true"
          [responsive]="true" [loading]="loading" [totalRecords]="totalRecords" [lazy]="true"
          (onLazyLoad)="customPagination($event)" [customSort]="true" [paginator]="true" selectionMode="single">
          <ng-template pTemplate="caption">

            <!-- <button type="button" class="btn btn-primary" (click)="openAffiliateDialog(null)"> Create
                            Affiliate</button> -->
            <app-action-button [data]="null" (onRefrashed)="onRefrash($event)" width="900px"
              modaleId="PartnerCreateUpdateModalComponent">
            </app-action-button>

          </ng-template>

          <ng-template pTemplate="header" let-columns>
            <tr>

              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                <div class="header-title">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </div>
              </th>

            </tr>
            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">


                <input *ngSwitchCase="'full_name'" pInputText type="text" class="ui-column-filter" style="width: 100%;"
                  (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'website'" pInputText type="text" class="ui-column-filter" style="width: 100%;"
                  (keyup)="userFilter($event.target.value, col.field)">

                <input *ngSwitchCase="'email'" pInputText type="text" class="ui-column-filter" style="width: 100%;"
                  (keyup)="userFilter($event.target.value, col.field)">


              </th>
              <!-- <th style="width: 270px; text-align: center;">

                        </th> -->
            </tr>
          </ng-template>


          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
              <!-- <td style="width: 120px;">{{rowData.affiliate_code}}</td> -->
              <td style="width: 120px;">{{rowData.full_name}}</td>
              <td style="width: 150px;">{{rowData.website}}</td>
              <td style="width: 150px;">{{rowData.email}}</td>
              <td style="width: 120px;">{{rowData.limit_of_rotate}}</td>
              <td style="width: 120px;">{{rowData.no_of_impressions}}</td>
              <td style="width: 160px;">{{rowData.start_date | date:'medium'}}</td>
              <td style="width: 160px;">{{rowData.end_date | date:'medium'}}</td>

              <td style="text-align: center;width: 80px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <app-action-icon [data]="rowData" width="900px" (onRefrashed)="onRefrash($event)"
                      modaleId="PartnerCreateUpdateModalComponent" visibleIcons="updateAff" icon="edit"
                      tooltip="editaffiliate">
                    </app-action-icon>
                    <!-- <button pButton type="button" (click)="openAffiliateDialog(rowData)" class="ui-button-warning" matTooltip="edit affiliate" icon="pi pi-pencil" style="margin-right: .5em"></button> -->
                    <app-action-icon [data]="rowData" (onRefrashed)="onRefrash($event)" modaleId="AffAnalyticsComponent"
                      icon="analytics" visibleIcons="analyticsAff" tooltip="analytics">
                    </app-action-icon>

                    <app-action-icon [data]="rowData" width="900px" height="450px" (onRefrashed)="onRefrash($event)"
                      modaleId="AffiliateApiKeyScreenComponent" icon="apikey" visibleIcons="updateKey"
                      tooltip="manageapikey">
                    </app-action-icon>
                  </div>

                </mat-menu>


              </td>




            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" style="text-align:left">No data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>