import { Injectable } from '@angular/core';
import { levelsData } from '../../data/levels'; 
import { Endpoints } from '../../../../config';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class LevelsService {

  constructor(private httpClient: HttpClient) { }
  getLevelsData() {
    return levelsData;
  }

   getLevelsData1(levelFilter) { 
    let search='';
    let pagination ={};
    let sort ={};
    if(levelFilter.search){
      search= JSON.stringify(levelFilter.search);
     
    }
    if(levelFilter.sort){
      sort = levelFilter.sort
    }
    if(levelFilter.pagination){
      pagination = JSON.stringify(levelFilter.pagination)
    }
         const url = Endpoints.api.levels.getLevelsList;
      // .replace('{search}',search )
      // .replace('{sort}',sort )
      // .replace('{pagination}',pagination);
     
      const observable = this.httpClient.post(url,{sort})
     
      return observable; 
    
 
  }


  createLevel(formValues,action){

    if(action == "create") {
      const url = Endpoints.api.levels.createLevel;
      const observable = this.httpClient.post(url,formValues);
      return observable;
    } else if(action == "update"){
      const url = Endpoints.api.levels.updateLevel;
      const observable = this.httpClient.put(url,formValues);
      return observable;
    }
    
   
     
    
  }

}
 