import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RefDataService } from '../../core/services/common/ref-data.service';
import { CharacterService } from '../../core/services/character.service';
import { TostService } from '../../core/services/common/tost.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SelectItem } from 'primeng/api';
export interface ImageTransform {
  scale?: number;
  rotate?: number;
  flipH?: boolean;
  flipV?: boolean;
}
@Component({
  selector: 'app-character-edit-modal',
  templateUrl: './character-edit-modal.component.html',
  styleUrls: ['./character-edit-modal.component.scss']
})

export class CharacterEditModalComponent implements OnInit {
  imageSrc: string;
  isUpdate: Boolean = true;
  isCrop: Boolean = false;
  //characterType: any;
  images: any;
  sourceTypeData: any;
  sourceType: any;
  scale = 1;
  transform: ImageTransform = {};
  isCreate: String = "Create"
  closeResult: string;
  sourceValue: string;
  characterVal: string;
  action: string;
  //characterType: any[];
  characterType: SelectItem[];
  selectedCity1: any;
  sourcTypeList: any[];
  uploadedFiles: any[] = [];
  butDisabled: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isUpdateflag: boolean = false;
  characterObj = {
    character_name: '',
    image_url: '',
    character_type: '',
    file: '',
    filename: '',
    source_type: '',
    source_character_id: '',
    id: ''
  }


  myForm = new FormGroup({
    character_name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    image_url: new FormControl('', []),
    character_type: new FormControl('', [Validators.required]),
    file: new FormControl(''),
    filename: new FormControl(''),
    source_type: new FormControl('', [Validators.required]),
    source_character_id: new FormControl('', [Validators.required]),


  });

  constructor(
    public dialogRef: MatDialogRef<CharacterEditModalComponent>, private characterService: CharacterService,
    private modalService: NgbModal, private refDataService: RefDataService, public tostService: TostService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(): void {
    // this.characterType = JSON.parse(this.refDataService.getRefData()).characterTypes;
    this.getCharAndAttribute();

    // if (this.isUpdateflag === true) {
    //   this.isUpdate = true;
    // } else {
    //   this.isUpdate = false;
    // }
    if (this.data) {
      this.butDisabled = true;
      this.isCreate = "Update"

      this.characterObj.character_name = this.data.character_name;
      this.characterObj.id = this.data.id;
      this.characterObj.image_url = this.data.image_url;
      this.characterObj.character_type = this.data.character_type;

      this.characterObj.source_type = this.data.source_type
      this.characterObj.source_character_id = this.data.source_character_id
      this.myForm.patchValue({
        character_name: this.data.character_name,
        id: this.data.id,
        character_type: this.data.character_type,
        image_url: this.data.image_url,
        source_type: this.data.source_type,
        source_character_id: this.data.source_character_id,
      });
      this.imageSrc = this.data.image_url;
      this.images = { background: 'url(' + this.imageSrc + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
      this.selectedCity1 = this.data.character_type;
    } else {
      this.myForm.patchValue({
        character_name: '',
        id: '',
        character_type: '',
        image_url: '',
        source_type: '',
        source_character_id: '',
      });
      this.characterObj.character_name = '';

      this.characterObj.image_url = '';
      this.characterObj.character_type = '';

      this.characterObj.source_type = ''
      this.characterObj.source_character_id = ''
      this.imageSrc = '';
      this.images = { background: 'url(' + this.imageSrc + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
      this.isUpdateflag = false;
    }
  }
  changeWebsite(e) {
    let b = this.sourcTypeList[e.value];
    if (this.sourcTypeList[e.value]) {
      this.sourceType = this.sourceTypeData.filter(function (v) {
        return b.indexOf(v.source_code) >= 0

      });
      // this.sourceType = sourceData.map(obj => {
      //   let rObj = {}
      //   rObj['label'] = obj.source_name;
      //   rObj['value'] = obj.source_code;
      //   return rObj
      // });
    }
    else {
      this.sourceType = [];
    }

  }
  get f() {
    return this.myForm.controls;
  }


  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.myForm.patchValue({
      filename: this.base64ToFile(
        event.base64,
        this.myForm.value.filename.name,
      ),
      image_url: this.base64ToFile(
        event.base64,
        this.myForm.value.filename.name,
      )
    });
    this.images = { background: 'url(' + this.croppedImage + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
  }

  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onFileChange(event) {
    const reader = new FileReader();
    this.isCrop = true;
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.imageChangedEvent = event;
      reader.onload = () => {

        this.imageSrc = reader.result as string;
        this.images = { background: 'url(' + this.imageSrc + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
        this.characterObj.image_url = event.target.files[0];
        this.characterObj.filename = event.target.files[0],
          this.myForm.patchValue({
            filename: event.target.files[0],
            image_url: event.target.files[0]
          });

      };

    }
  }

  onBasicUpload(e) {
    console.log(e)
  }

  onChangeSource(e) {
    this.sourceValue = e.value.source_code;
    this.characterObj.source_type = this.sourceValue;
    this.myForm.patchValue({ source_type: this.sourceValue });
    // console.log('source attribute type val', this.myForm.value);

  }
  onchangeType(e) {
    this.characterVal = e.value.value;
    this.characterObj.character_type = this.characterVal;
    this.myForm.patchValue({ character_type: this.characterVal });
    // console.log('character type val', this.myForm.value);
  }
  zoomIn(num) {
    this.scale = num.value;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }



  submitData() {

    //  this.tostService.tostrFun("character Updated Successfully", 'success');
    // console.log('character form value', this.myForm.value);

    let hasErr = "";
    if (!this.characterObj.character_name) {
      hasErr = "Please enter character Name";
    } else if (!this.characterObj.character_type) {
      hasErr = "Please enter character Type";
    } else if (this.sourceType && this.sourceType.length > 0 && !this.characterObj.source_type) {
      hasErr = "Please enter Source Type";
    }
    else if (this.sourceType && this.sourceType.length > 0 && this.characterObj.source_type && !this.characterObj.source_character_id) {
      hasErr = "Please enter Source Character Id";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }




    const formData = new FormData();
    // formData.append('character_name', this.myForm.value.character_name);
    // formData.append('character_type', this.myForm.value.character_type);
    // formData.append('source_character_id', this.myForm.value.source_character_id);
    // formData.append('source_type', this.myForm.value.source_type);
    // formData.append('filename', this.myForm.value.filename);
    // formData.append('image_url', this.myForm.value.image_url);
    formData.append('character_name', this.characterObj.character_name);
    formData.append('character_type', this.characterObj.character_type);
    formData.append('source_character_id', this.characterObj.source_character_id);
    formData.append('source_type', this.characterObj.source_type);
    formData.append('filename', this.myForm.value.filename);
    formData.append('image_url', this.myForm.value.image_url);

    if (this.data == null) {
      this.action = "create";
      //   console.log("action value on create", this.action);

    } else if (this.data.id) {
      this.action = "update";
      //  console.log("action value on update", this.action);
      formData.append('id', this.data.id);

      // let formValues = this.myForm.value;
      // Object.assign(formValues,{id:this.data.id});

    }


    // formData.append('all_data',this.myForm.value);
    this.characterService.createCharacters(formData, this.action).subscribe(
      res => {
        if (this.action == "update") {
          this.tostService.tostrFun("Character Updated Successfully", 'success');
        }
        if (this.action == "create") {
          this.tostService.tostrFun("Character created Successfully", 'success');
        }
        this.dialogRef.close();
      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close();
      }
      //  ,() => console.log('HTTP request completed.')
    )





  }
  close(): void {
    this.dialogRef.close(true);
  }
  imageChange(e) {
    this.images = { background: 'url(' + e.target.value + ')' }
    this.myForm.patchValue({
      image_url: e.target.value
    });
  }

  getCharAndAttribute() {
    this.refDataService.getRefData1().subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).characterTypes;
      const sourceData = (JSON.parse(res)).attributes;
      const getCharData = JSON.stringify(getData);
      const sourceDataType = JSON.stringify(sourceData);
      // const characteData = JSON.parse(getCharData);
      this.sourceTypeData = JSON.parse(sourceDataType);
      const sourceList = (JSON.parse(res)).attrList;
      const sourceDataList = JSON.stringify(sourceList);
      this.sourcTypeList = JSON.parse(sourceDataList);
      this.characterType = JSON.parse(getCharData);
      // this.characterType = characteData.map(obj => {
      //   let rObj = {}
      //   rObj['label'] = obj.name;
      //   rObj['value'] = obj.value;
      //   return rObj
      // });
    });
  }

}
