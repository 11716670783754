import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { share } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

import { Endpoints } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  isLoggedIn = new BehaviorSubject(false);
  constructor(private HttpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
  login(username: string, password: string) {
    var obj = {
      username: username,
      password: password
    }
    const url = Endpoints.api.adminLogin.login;

    return this.HttpClient.post(url, obj)
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    window.location.reload();
  }
}
