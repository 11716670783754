import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PayoutService } from '../../core/services/character/payout.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TostService } from '../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefDataService } from '../../core/services/common/ref-data.service';

@Component({
  selector: 'app-payout-initiate-payment',
  templateUrl: './payout-initiate-payment.component.html',
  styleUrls: ['./payout-initiate-payment.component.scss']
})
export class PayoutInitiatePaymentComponent implements OnInit {
  initiatePaymentForm = new FormGroup({
    payable_amount_usd: new FormControl('', []),
    communication_email: new FormControl('', []),
    payout_type: new FormControl('Skrill', []),
  });
  balance_amount_usd: any;
  emailList = [];
  payoutTypes = ['Skrill'];
  constructor(private tostService: TostService, private spinner: NgxSpinnerService, private payoutService: PayoutService, public dialogRef: MatDialogRef<PayoutInitiatePaymentComponent>, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private refDataService: RefDataService) { }

  ngOnInit(): void {
    if (this.data) {
      this.getPayoutEmails();
      this.getRefData(this.data.verified_flag);

      this.initiatePaymentForm.patchValue({
        payable_amount_usd: this.data.payable_amount_usd,
        communication_email: this.data.communication_email,
      });
      this.balance_amount_usd = this.data.balance / 100;
    }
  }

  getPayoutEmails() {
    this.spinner.show();
    this.payoutService.getPayoutEmailList(this.data.user_id).subscribe(
      res => {
        this.emailList = [].concat(res);
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      });
  }

  getRefData(verifiedFlag) {
    this.refDataService.refDataAll().subscribe(data => {
      this.payoutTypes = data['defaultRefData']
        .ALLOWED_PAYOUT_METHODS
        .filter(pm => {
          if (pm.verified_only && !Number(verifiedFlag)) {
            return false;
          }

          return true;
        })
        .map(pm => pm.method);
    });
  }

  submitData() {
    let hasErr = "";
    if (!this.initiatePaymentForm.value.payable_amount_usd) {
      hasErr = "Please enter payable amount";
    } else if (this.initiatePaymentForm.value.payable_amount_usd > this.balance_amount_usd) {
      hasErr = "Please enter payable amount less then balance amount";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }


    const obj = {
      amount: this.initiatePaymentForm.value.payable_amount_usd,
      id: this.data.id,
      user_id: this.data.user_id,
      communication_email: this.initiatePaymentForm.value.communication_email,
      payout_type: this.initiatePaymentForm.value.payout_type,
    }
    this.spinner.show();
    this.payoutService.initiatePayout(obj).subscribe(
      res => {
        this.tostService.tostrFun("Initiate Payout successfully", 'success');
        this.dialogRef.close(obj.user_id);
        this.spinner.hide();
      },
      err => {
        /// this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close(obj.user_id);
        this.spinner.hide();
      });

  }
  close(): void {
    this.dialogRef.close(true);
  }

}
