import { Injectable } from '@angular/core';
import { sourceTypeData } from '../data/sourceType';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../config';
@Injectable({
  providedIn: 'root'
})
export class SourceTypeService {

  constructor(private httpClient: HttpClient) { }
  getSourceTypeData() {
    const url = Endpoints.api.sourceType.get;

      const observable = this.httpClient.get(url);
      return observable;
    
 
  }

  updateSourceType(formValues){

     
      const url = Endpoints.api.sourceType.sourceTypeUpdate;
      const observable = this.httpClient.put(url,formValues);
      return observable;
    
   }

}
