<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <div class="modal-body">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="">
                <div class=logicon>
                  <img _ngcontent-qvw-c47="" src="./assets/img/logo.png" style="width: 100%;">
                </div>


              </div>

            </div>
            <div class="col-md-3"></div>
          </div>

        </div>

      </div>

    </div>
  </div>
</div>