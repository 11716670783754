<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <!-- <p-table #dt [columns]="cols" styleClass="ui-table-cars" [value]="charcter" [frozenColumns]="frozenCols"  (sortFunction)="customSort($event)" [paginator]="true" [rows]="10" [scrollable]="true" [style]="{width:'1000px'}" dataKey="character_name" selectionMode="single" [(selection)]="selectedCharacter"> -->
        <p-table #dt [columns]="cols" [loading]="loading" (sortFunction)="customSort($event)" [customSort]="true"
          styleClass="ui-table-cars" [value]="charcter" [paginator]="true" [rows]="rows" [first]="0"
          selectionMode="single" [responsive]="true" [rowsPerPageOptions]="[10,25,50,100]" [totalRecords]="totalRecords"
          [lazy]="true" (onLazyLoad)="customPagination($event)" [(selection)]="selectedCharacter">

                    <ng-template pTemplate="caption">
                        <div class="ui-g ui-fluid">
                            <div class="ui-g-12 ui-md-3">
                <app-action-button [data]="null" [width]="width" height="600px" (onRefrashed)="onRefrash($event)"
                  [modaleId]="modaleId"> </app-action-button>

                            </div>
                            <div class="ui-g-12 ui-md-3">
                                <p-calendar (onSelect)="startDate($event)" [(ngModel)]="startdate" #ctrl="ngModel"
                                    placeholder="Start Date"></p-calendar>
                            </div>



                            <div class="ui-g-12 ui-md-3">
                <p-calendar [(ngModel)]="enddate" #ctrl="ngModel" (onSelect)="endDate($event)" placeholder="End Date">
                </p-calendar>
                            </div>

                            <div class="ui-g-12 ui-md-3">
                                <button type="button" class="btn btn-primary custom-button-cls" (click)="dateFilter()">
                                    Search</button>
                <button type="button" class="btn btn-primary custom-button-cls" (click)="clearDateFilter()">
                  Clear</button>
                            </div>
                        </div>
                    </ng-template>



                    <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                            <!-- style="width:185px" -->


                            <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
                        </colgroup>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>
                            </th>

                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width}">

                <input *ngSwitchCase="'character_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'character_type'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                <input *ngSwitchCase="'source_type'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">



                            </th>

                        </tr>

                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td style="width: 450px;">{{rowData.character_name}}</td>
                            <td style="width: 100px;">{{rowData.character_type}}</td>
                            <td style="width: 100px;">{{rowData.source_type}}</td>
                            <td style="width: 80px;">{{rowData.createdAt | date}}</td>


                            <td style="width: 120px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <app-action-icon [data]="rowData" [width]="width" height="600px" [modaleId]="modaleId" icon="edit"
                      tooltip="update" (onRefrashed)="onRefrash($event)"> </app-action-icon>
                    <app-action-icon [data]="rowData" [width]="'350'" [modaleId]="'RemoveCharacterModalComponent'"
                      icon="remove" tooltip="remove" (onRefrashed)="onRefrash($event)"> </app-action-icon>
                  </div>
                </mat-menu>
                                <!-- <app-action-icon [characterData]="row-              Data" [width]="width" [height]="height" [modaleId]="modaleId" [icon]="'edit'">
                                                    </app-action-icon> -->
                                <!-- <button pButton type="button" (click)="openDialog(rowData)" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em"></button> -->
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>
        </div>
    </div>
</div>