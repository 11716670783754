<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">User Settings</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <div class="modal-body">
      <div class="row">
        <!-- <div class="col-md-6">
          <h6>
            Show verified badge:
            &nbsp;<p-checkbox [style]="{ verticalAlign: 'text-bottom' }" [(ngModel)]="userConfig.verified_flag"
              name="verified_flag" binary="true"></p-checkbox>
          </h6>
        </div> -->
        <div class="col-md-6">
          <h6>
            Allow user to create game:
            &nbsp;<p-checkbox [style]="{ verticalAlign: 'text-bottom' }" [(ngModel)]="userConfig.create_game"
              name="create_game" binary="true"></p-checkbox>
          </h6>
        </div>
      </div>
      <h6>&nbsp;</h6>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Bots per game for UGG games:</mat-label>
            <input matInput [(ngModel)]="userConfig.bots_per_game" type="number" min="0" name="bots_per_game" />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Bots To Follow:</mat-label>
            <input matInput [(ngModel)]="userConfig.bots_to_follow" type="number" min="0" name="bots_to_follow" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label> Min Payout in Coins ({{coinRate}} Coins=$1)</mat-label>
            <input matInput [(ngModel)]="userConfig.min_host_payout" (change)="minAmountChange($event)" type="number"
              min="0" name="min_host_payout" />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Max Payout in Coins ({{coinRate}} Coins=$1)</mat-label>
            <input matInput [(ngModel)]="userConfig.max_host_payout" (change)="maxAmountChange($event)" type="number"
              min="0" name="max_host_payout" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Bot Multiplier:</mat-label>
            <input matInput [(ngModel)]="userConfig.bot_multiplier" type="number" min="0" name="bot_multiplier" />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Dollar Multiplier:</mat-label>
            <input matInput [(ngModel)]="userConfig.dollar_multiplier" type="number" min="0" name="dollar_multiplier" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" style="display: none;">
          <mat-form-field>
            <mat-label>Match Mode:</mat-label>
            <input matInput [(ngModel)]="userConfig.match_mode" type="number" min="0" name="match_mode" />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Host Total Invitations :</mat-label>
            <input matInput [(ngModel)]="userConfig.total_invitations" type="number" min="0" name="total_invitations" />
          </mat-form-field>
        </div>
      </div>
      <p style="color: red;" *ngIf="maxPayoutFlag">Max payout should be greater than min payout</p>
      <h6>&nbsp;</h6>
      <div class="row">
        <div class="col-12">
          <h5>Parent host cut configuration</h5>
        </div>
        <div class="col-12">
          <mat-form-field class="example-full-width">
            <mat-label>Select Parent User</mat-label>
            <input type="text" placeholder="Select parent user" aria-label="Parent user" matInput
              (input)="searchUsers($event)" [(ngModel)]="userConfig.parent_user_name" id="parent_user_name"
              [matAutocomplete]="auto" name="parent_user_name">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setUserName($event)">
              <mat-option *ngFor="let user of userList" [value]="user.user_name" [id]="user.id">
                {{user.user_name}}
                <span style="font-size: 11px;font-style: italic;">{{user.email}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Host cut (percent):</mat-label>
            <input matInput [(ngModel)]="userConfig.host_cut_percent" type="number" min="0" max="100"
              name="host_cut_percent" />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Parent cut (percent):</mat-label>
            <input matInput [(ngModel)]="userConfig.parent_cut_percent" [disabled]="!userConfig.parent_id" type="number"
              min="0" max="100" name="parent_cut_percent" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button mat-raised-button class="btn btn-danger pull-right" (click)="updateConfig()">Save</button>
      <!-- <button mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</button> -->
    </div>
  </div>
</mat-dialog-content>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>