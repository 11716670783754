import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from "lodash";
import { interval } from 'rxjs';
import { TostService } from '../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteConstants } from '../../../config/site-constants';
import { RefDataService } from '../../core/services/common/ref-data.service';
import { AffiliateServiceService } from '../../core/services/affiliate/affiliate-service.service';
@Component({
  selector: 'app-promotion-banners',
  templateUrl: './promotion-banners.component.html',
  styleUrls: ['./promotion-banners.component.scss']
})
export class PromotionBannersComponent implements OnInit {
  public color: string = '#000000';
  ismediaLoadding: boolean = false
  action: string;
  file_type: string = "image";
  video_src: string = "";
  IsWait: boolean = false;
  selected_screen: any = 'discover';
  myVar: any;
  subscribedata: any;
  isd: boolean = false;
  videoFile: any;
  screenName: any = ['discover'];
  bgBtnText: '';
  imageSrc: any = '';
  images: any = "";
  image_url: string = "";
  isVersusgameUrl: boolean = true;
  icons: any = SiteConstants.icons;
  tooltips: any = SiteConstants.gameToolTips;
  bannerData: any;
  bgColor: string = '#ffffff';
  isFile: boolean = false;
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  imagesCharacterSingle: any;
  promotionBannersData: any = {
  }
  isUpdate: boolean = false;
  editorContent: string;
  editorStyle = {
    height: '300px',

  }

  config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']],
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear',
          ],
        ],
      ],
    },
    height: '460px',
    width: '227px',


    uploadImagePath: '/api/upload',
    toolbar: [
      ['edit', ['undo', 'redo']],
      ['headline', ['style']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontface', []],
      ['textsize', ['fontsize']],
      ['fontclr', ['color']],
      ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
      ['height', ['height']],
      ['table', []],
      ['insert', ['link', 'picture']],
      ['view', []]

    ],

    codeviewFilter: true,
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
  };
  secondsCounter: any = interval(5000);
  promotionBannerObj = {
    id: '',
    title: '',
    start_time: new Date(),
    screen_name: ['discover'],
    end_time: new Date(),
    play_duration: 60,
    cta_url: '',
    background: { "file_type": '' || "image", "url": '', bgColor: '#ffffff', bgBtnText: "" },
    status: 1,
    message_text: '',
    show_consent: false,
    promotion_banners_settings: { "show_max": 0, "show_of_every_x_game": 0, "show_after_x_game": 0, "game_ids": [], "position": null }
  };
  constructor (private spinner: NgxSpinnerService, private affiliateServiceService: AffiliateServiceService, private refDataService: RefDataService, public dialogRef: MatDialogRef<PromotionBannersComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private modalService: NgbModal, private tostService: TostService) { }

  ngOnInit (): void {
    // console.log("this is edit data affiliate", this.data)
    // $('.note-editor.note-frame .note-editing-area .note-editable').css('background', this.color);
    this.spinner.hide();

    if (this.data && this.data.id) {

      this.isUpdate = true;
      this.isFile = true;
      this.promotionBannerObj.id = this.data.id;
      this.promotionBannerObj.title = this.data.title;
      this.promotionBannerObj.start_time = new Date(this.data.start_time);
      this.promotionBannerObj.end_time = new Date(this.data.end_time);
      this.promotionBannerObj.play_duration = this.data.play_duration;
      this.promotionBannerObj.show_consent = this.data.show_consent;
      this.promotionBannerObj.cta_url = this.data.cta_url;
      this.promotionBannerObj.status = this.data.status;
      this.promotionBannerObj.screen_name = this.data.screen_name || 'discover';
      this.selected_screen = this.data.screen_name[0] || 'discover';
      this.promotionBannerObj.message_text = this.data.message_text;
      this.promotionBannerObj.background.file_type = this.data.background ? this.data.background.file_type : '';
      this.promotionBannerObj.background.url = this.data.background ? this.data.background.url : '';
      if (this.data && this.data.promotion_banners_settings && this.data.promotion_banners_settings.show_max) {
        this.promotionBannerObj.promotion_banners_settings.show_max = this.data.promotion_banners_settings.show_max || 0;
      }
      if (this.data && this.data.promotion_banners_settings && this.data.promotion_banners_settings.show_of_every_x_game) {
        this.promotionBannerObj.promotion_banners_settings.show_of_every_x_game = this.data.promotion_banners_settings.show_of_every_x_game || 0;
      }
      if (this.data && this.data.promotion_banners_settings && this.data.promotion_banners_settings.show_after_x_game) {
        this.promotionBannerObj.promotion_banners_settings.show_after_x_game = this.data.promotion_banners_settings.show_after_x_game || 0;
      }
      if (this.data && this.data.promotion_banners_settings && this.data.promotion_banners_settings.game_ids) {
        this.promotionBannerObj.promotion_banners_settings.game_ids = this.data.promotion_banners_settings.game_ids || [];
      }
      if (this.data && this.data.promotion_banners_settings && this.data.promotion_banners_settings.position) {
        this.promotionBannerObj.promotion_banners_settings.position = this.data.promotion_banners_settings.position || null;
      }
      if (this.data.background && this.data.background.bgBtnText) {
        this.promotionBannerObj.background.bgBtnText = this.data.background.bgBtnText;
        this.bgBtnText = this.data.background.bgBtnText;
      }

      if (this.promotionBannerObj.background && this.promotionBannerObj.background.bgColor)
        this.promotionBannerObj.background.bgColor = this.data.background ? this.data.background.bgColor : '#ffffff';
      this.onChangeColor(this.promotionBannerObj.background.bgColor);
      if (this.data.background.file_type == 'image') {
        this.image_url = this.data.background.url
        this.imagesCharacterSingle = { background: 'url(' + this.image_url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }

      } else if (this.data.background.file_type == 'video') {
        this.video_src = this.data.background.url;
      }


    } else {
      this.onChangeColor(this.promotionBannerObj.background.bgColor);
    }
  }

  changeWebsite (e) {
    // this.screenName = ['discover', 'my games', 'hosted games', 'Accounts', 'payout']
  }

  summernoteInit (event) {
    //console.log(event);
  }
  onChangeColor (e) {
    this.promotionBannerObj.background.bgColor = e;
    $('.note-editor.note-frame .note-editing-area .note-editable').css('background', this.promotionBannerObj.background.bgColor);

    // console.log('color ', this.affiliateData.background.bgColor)

  }

  onFileChangeVideo (event) {
    const reader = new FileReader();
    let hasError = '';
    if (event.target.files && event.target.files.length) {
      // const [file] = event.target.files;
      this.ismediaLoadding = true;
      let me = this;
      let files = event.target.files;

      const filetype = files[0].type;
      if (filetype != 'video/mp4' && filetype != 'video/quicktime') {
        hasError = `please upload mp4  or mov file`
      }

      if (hasError) {
        this.tostService.tostrFun(hasError, 'error');
        this.ismediaLoadding = false;
        return false;
      }
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      formData.append('folder', 'banner')
      this.refDataService.fileUploadFun(formData).subscribe(data => {

        const FileData = JSON.stringify(data);
        const fileData1 = JSON.parse(FileData);

        if (this.promotionBannerObj.background.file_type == 'video') {

          this.video_src = fileData1.video;
          if (fileData1.jobId) {
            this.subscribedata = this.secondsCounter.subscribe(val => {
              this.refDataService.getFileJobStatus(fileData1.jobId).subscribe(data => {
                const stausData = JSON.stringify(data);
                const currentJob = JSON.parse(stausData).status;
                if (currentJob == "COMPLETE") {
                  this.isd = true;
                  this.videoplayer.nativeElement.src = this.video_src;
                  this.videoplayer.nativeElement
                    .play().then(() => { }).catch((err) => { })
                  this.stopTime();
                  this.ismediaLoadding = false;
                } else if (currentJob === "PROGRESSING" || currentJob === "SUBMITTED") {

                }
                else if (currentJob === "CANCELED" || currentJob === "ERROR") {
                  this.tostService.tostrFun("Conversion of file failed. Please try again.", 'error');
                }

              });
            });

          } else {
            this.videoplayer.nativeElement.src = this.video_src;
            this.videoplayer.nativeElement
              .play().then(() => { }).catch((err) => { })
            this.ismediaLoadding = false;
          }


          // this.spinner.hide();
        }
      },
        err => {
          this.ismediaLoadding = false;
          this.isFile = true;
          this.spinner.hide();
        });
    }

  }

  promotionBannerSettingsFun (e) {
    this.promotionBannerObj.promotion_banners_settings = e
    if (e && e.show_max) {
      this.promotionBannerObj.promotion_banners_settings.show_max = e.show_max || 0;
    }
    if (e && e.show_of_every_x_game) {
      this.promotionBannerObj.promotion_banners_settings.show_of_every_x_game = e.show_of_every_x_game || 0;
    }
    if (e && e.show_after_x_game) {
      this.promotionBannerObj.promotion_banners_settings.show_after_x_game = e.show_after_x_game || 0;
    }
    if (e && e.game_ids) {
      this.promotionBannerObj.promotion_banners_settings.game_ids = e.game_ids || [];
    }
    if (e && e.position) {
      this.promotionBannerObj.promotion_banners_settings.position = e.position || null;
    }
  }

  stopTime () {
    this.subscribedata.unsubscribe()
    // clearTimeout(this.myVar);
  }


  onFileChange (event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      // const [file] = event.target.files;
      const type = event.target.files[0].type;
      if (type != 'image/jpeg' && type != 'image/jpeg' && type != 'image/png') {
        this.tostService.tostrFun("Please select jpg/jpeg/png file", 'error');
        return false;
      }

      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      formData.append('folder', 'banner')
      // this.spinner.show();
      this.ismediaLoadding = true;
      this.refDataService.fileUploadFun(formData).subscribe(data => {
        // this.spinner.hide();
        const FileData = JSON.stringify(data);
        const fileData1 = JSON.parse(FileData);


        if (this.promotionBannerObj.background.file_type == 'image') {
          this.imageSrc = fileData1.picture;
          this.images = { background: 'url(' + this.imageSrc + ')' }
          this.promotionBannerObj.background.url = this.imageSrc;
          this.image_url = this.imageSrc
          this.imagesCharacterSingle = { background: 'url(' + this.imageSrc + ')' }
          this.ismediaLoadding = false;
        }
        this.ismediaLoadding = false;
      },
        err => {
          this.ismediaLoadding = false;
          //   this.spinner.hide();
        });

    }
  }
  getDuration (e) {


  }
  videoChange (e) {
    this.promotionBannerObj.background.url = this.video_src;
    this.videoplayer.nativeElement.src = this.video_src;
    this.videoplayer.nativeElement
      .play().then(() => { }).catch((err) => { })
    this.isFile = true;

  }
  imageChange (e) {
    this.promotionBannerObj.background.url = this.image_url;
    this.images = { background: 'url(' + e.target.value + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
    this.imagesCharacterSingle = { background: 'url(' + this.image_url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }

  }
  fileTypeClick (e) {

  }
  fileChangeEvent (event: any): void {

  }
  urlCheck (e) {
    var url = e.target.value;

    var isVersusgame = url.includes("versusgame");
    if (!isVersusgame) {
      this.isVersusgameUrl = false;
    } else {
      this.isVersusgameUrl = true;
    }

  }
  close (): void {
    this.dialogRef.close(true);
  }
  saveData (): void {

    if (this.data == null) {
      this.action = "create";
    } else {
      this.action = "update";
    }

    if (this.promotionBannerObj.message_text) {
      this.promotionBannerObj.message_text = this.promotionBannerObj.message_text;
    }
    let fileType = this.promotionBannerObj.background.file_type;
    if (fileType == 'image') {
      if (this.image_url != "" && this.image_url) {
        this.promotionBannerObj.background = { "file_type": "image", "url": this.image_url, 'bgColor': this.bgColor, 'bgBtnText': this.bgBtnText }
      }
    } else if (fileType == 'video') {
      if (this.video_src != "" && this.video_src) {
        this.promotionBannerObj.background = { "file_type": "video", "url": this.video_src, 'bgColor': this.bgColor, 'bgBtnText': this.bgBtnText }
      }
    }

    let hasErr = "";

    if (this.promotionBannerObj.background && !this.promotionBannerObj.background.url) {
      if (fileType == 'image') {
        hasErr = "Please select image";
      } else if (fileType == 'video') {
        hasErr = "Please select video";
      }
    }
    if (this.promotionBannerObj.show_consent && this.bgBtnText) {
      this.promotionBannerObj.background.bgBtnText = this.bgBtnText
    }
    var curDate = new Date();
    if (!this.promotionBannerObj.title) {
      hasErr = "Please enter promotion banner title";
    } else if (!this.promotionBannerObj.play_duration) {
      hasErr = "Please enter promotion banner duration";
    } else if (!this.promotionBannerObj.start_time) {
      hasErr = "Please select start date";
    } else if (new Date(this.promotionBannerObj.start_time) > new Date(this.promotionBannerObj.end_time)) {
      hasErr = "End Date should be greater than start date";
    } else if (this.action == "create" && new Date(this.promotionBannerObj.end_time) < new Date()) {
      hasErr = "End Date should not be before today.";
    } else if (this.promotionBannerObj.show_consent && !this.promotionBannerObj.cta_url) {
      hasErr = "Please enter CTA : Click to action URL";
    } else if (this.promotionBannerObj.show_consent && !this.bgBtnText) {
      hasErr = "Please enter button text";
    }
    if (hasErr) {

      this.tostService.tostrFun(hasErr, 'error');
      return;
    }


    this.spinner.show();
    var scname = [];
    scname.push(this.selected_screen)
    this.promotionBannerObj.screen_name = scname;

    this.affiliateServiceService.createUpdateBanner(this.promotionBannerObj).subscribe(
      res => {
        if (this.action == "update") {
          this.tostService.tostrFun("Promotion banner updated successfully", 'success');
        }
        if (this.action == "create") {
          this.tostService.tostrFun("Promotion banner created successfully", 'success');
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
      }
      //  ,() => console.log('HTTP request completed.')
    );
    this.dialogRef.close();

  }

}
