<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Settings</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="priceOptionForm" (ngSubmit)="submitData()">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="entry_fee" id="entry_fee" placeholder="Entry Fee" type="text"
                            value="PinnedQuestion" class="form-control">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="prize_money" id="prize_money" placeholder="Prize Money"
                            type="text" value="PinnedQuestion" class="form-control">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
            [fullScreen]="true">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <input [value]="color" [style.background]="color" (colorPickerChange)="onChangeColor($event)"
                        [(colorPicker)]="color" />
                    <!-- <p-colorPicker (onChange)="change($event)" formControlName="color_code" format="rgb">
                    </p-colorPicker> -->
                    <!-- {{"RGB"+priceOptionForm.color_code}} -->
                    <!-- <mat-form-field class="example-full-width">
                            <input matInput formControlName="color_code" value='color_code' id="prize_money" placeholder="Prize Money" type="text" value="color_code" class="form-control">
                        </mat-form-field> -->
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="default" id="default" placeholder="Default" type="text"
                            value="PinnedQuestion" class="form-control">
                    </mat-form-field>

                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="close()">Save</button>
        </div>
        <div class="clearfix"></div>
    </form>

</div>