<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ trending && trending.id ? 'Update' : 'Create' }} trending</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <form (ngSubmit)="saveTrending()">
      <div class="modal-body">
        <div class="row">
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Title</mat-label>
                <input matInput [(ngModel)]="trending.title" name="title" id="title" class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Sub Titile</mat-label>
                <input matInput name="sub_title" [(ngModel)]="trending.sub_title" id="trending" class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Tag Key</mat-label>
                <input matInput name="tag_key" [(ngModel)]="trending.tag_key" id="tag_key"
                  class="form-control lowercase" [disabled]="trending && trending.id">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-2">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Trending index</mat-label>
                <input matInput name="trending_index" [(ngModel)]="trending.trending_index" type="number" min=1
                  id="trending_index" class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-2">
            <div class="form-group">

              <mat-checkbox [checked]="trending.is_active" [(ngModel)]="trending.is_active"
                [ngModelOptions]="{standalone: true}"> Status
              </mat-checkbox>
            </div>
          </div>
          <div class="col col-3">
            <div class="form-group">
              <label for="file">Select Icon </label>
              <div class="file-field">
                <input style="display: none" type="file" accept="image/*" (change)="onFileChangeActive($event)"
                  #hiddenfileinput>
                <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">
                  Choose file
                </label>
              </div>

            </div>

          </div>

          <div class="col col-5">
            <div class="card-box card-box card-left">
              <div [ngStyle]="imagePreview" class="card-image"></div>
            </div>

          </div>

        </div>


      </div>
      <div class="modal-footer">
        <a mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</a>
        <button mat-raised-button type="submit" class="btn btn-danger pull-right">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>