import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators'
import { throwError, Observable, Subscription } from 'rxjs';
import lodash from 'lodash';
import { TostService } from '../../modules/core/services/common/tost.service';

class CustomHttpResponse {

  obs: Observable<Object>;

  subscription: Subscription;

  tostService: TostService;

  constructor(observable: Observable<Object>, tostService: TostService) {
    this.obs = observable;
    this.tostService = tostService;
  }

  subscribe(next, err, complete) {
    if (!err) {
      err = this.handleError;
    }

    this.subscription = this.obs.subscribe(next, err, complete);
  }

  ubsubscribe() {
    this.subscription && this.subscription.unsubscribe()
  }

  handleError(error: HttpErrorResponse) {
    const errors = lodash.get(error, 'error.errors');
    let err;
    if (!errors || !errors.length) {
      err = [{ message: error.statusText }];
    } else {
      err = errors;
    }
    const msg = lodash.get(err, '0.message');

    // this.tostService.tostrFun(msg, 'error');

    return throwError(err);
  }

}

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private http: HttpClient, private tostService: TostService) { }

  get(url) {
    const observable = this.http.get(url);

    return new CustomHttpResponse(observable, this.tostService);
  }

  post(url, postObj) {
    const observable = this.http.post(url, postObj);

    return new CustomHttpResponse(observable, this.tostService);
  }
}
