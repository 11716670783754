<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Promo Code</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="modal-body">
            <form [formGroup]="promoCodeForm" (ngSubmit)="submitData()">

              <div class="row">

                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput formControlName="name" id="name" placeholder="Character Name" type="text"
                        class="form-control">
                    </mat-form-field>
                    <!-- <div *ngIf="f.character_name.touched && f.character_name.invalid" class="alert alert-danger">
                                            <div *ngIf="f.character_name.errors.required">Name is required.</div>
                                        </div> -->

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput formControlName="promo_code" id="promo_code" placeholder="Coupon Code" type="text"
                        class="form-control">
                    </mat-form-field>

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" style="margin-top: 32px;">
                  <div class="form-group">

                    <mat-form-field appearance="fill">
                      <mat-label>Select promo code type</mat-label>
                      <mat-select formControlName="value_type" id="value_type">
                        <mat-option *ngFor="let promo of coupounType" [value]="promo.value">
                          {{promo.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <!-- <p-dropdown [options]="coupounType" placeholder="coupon type" name="value_type"
                                            formControlName="value_type"></p-dropdown> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">

                    <mat-form-field class="example-full-width">
                      <input matInput formControlName="value" id="value" placeholder="Coupon value{{couponTypeLabel}}"
                        type="number" class="form-control">
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">

                </div>
              </div>

              <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
                [fullScreen]="true">
                <p style="color: white"> Loading... </p>
              </ngx-spinner>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field>
                      <input matInput [ngxMatDatetimePicker]="picker1" formControlName="start_date"
                        placeholder="Start Date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>

                      <!-- <input matInput [matDatepicker]="picker" formControlName="start_date" placeholder="Start Date">
                      <mat-datepicker-toggle matSuffix [for]="picker">

                      </mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker> -->

                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field>
                      <!-- <input matInput [matDatepicker]="picker1" formControlName="end_date" placeholder="End Date">
                      <mat-datepicker-toggle matSuffix [for]="picker1">

                      </mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker> -->

                      <input matInput [ngxMatDatetimePicker]="picker2" formControlName="end_date"
                        placeholder="End Date">
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <ngx-mat-datetime-picker #picker2></ngx-mat-datetime-picker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="form-group">

                      <p-autoComplete formControlName="results1" [suggestions]="searchResult" field="name"
                        (onSelect)="selectedVal($event)" (completeMethod)="search($event)" [size]="30" [minLength]="1"
                        placeholder="select user">
                        <ng-template let-brand pTemplate="item">
                          <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5">

                            <div style="font-size:18px;float:left;margin:10px 10px 0 0">
                              {{brand.first_name}} - {{brand.email}}</div>

                          </div>
                        </ng-template>

                      </p-autoComplete>
                    </div>
                  </div>
                  <div class="row" *ngIf="selectedName?.length > 0 ?true:false">
                    <div class="col-md-12">
                      <p-card>
                        <div class="form-group">
                          <!-- <button (click)="selectedVal(val)">Add</button> -->
                          <div *ngFor="let item of selectedName; index as i;">
                            <li> {{item.first_name}} - {{item.email}}
                              <span class="material-icons" *ngIf="!item.is_used" (click)="removeRecord(i)"
                                style="float:right">clear</span>
                            </li>
                          </div>
                          <div *ngIf="data">
                            <!-- <div *ngFor="let val of data.promCouponUsers; index as i">
                              <li *ngIf="val">
                                {{val.name}}
                                <span class="material-icons" (click)="removeReceivedRecord(i)"
                                  style="float:right">clear</span>
                              </li>
                            </div> -->
                          </div>
                        </div>
                      </p-card>
                    </div>
                  </div>

                </div>
                <!-- <div class="col-md-6">
                                    <div class="form-group">


                                    </div>
                                </div> -->

              </div>

            </form>

          </div>

        </div>

      </div>
    </div>
  </div>
</mat-dialog-content>
<div class="modal-footer">

  <button mat-raised-button type="submit" class="btn btn-danger pull-right" [disabled]="!promoCodeForm.valid"
    (click)="submitData()">Save</button>
</div>
<div class="clearfix"></div>