import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GetTransactionService } from '../../../core/services/users/get-transaction.service';
import { Transaction } from '../../../../modules/core/models/api/transaction';
import { TostService } from '../../../core/services/common/tost.service';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';
@Component({
  selector: 'app-winner-declaration-modal',
  templateUrl: './winner-declaration-modal.component.html',
  styleUrls: ['./winner-declaration-modal.component.scss']
})
export class WinnerDeclarationModalComponent implements OnInit {

  cols: any;
  frozenCols: any[];
  gameData: any[] = [];
  characters: any = {};
  notes: any;
  score2: boolean = false;
  score1: boolean = false;

  vote_char1: number = 0;
  vote_char2: number = 0;
  is_group_declaration: boolean = true;


  constructor(private modalService: NgbModal, private gameBankService: GameBankService, public dialogRef: MatDialogRef<WinnerDeclarationModalComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private transactionService: GetTransactionService) { }

  ngOnInit(): void {
    this.cols = [

      { field: 'game_text', header: 'Game Text', width: '250px' },
      { field: 'character1', header: 'Character 1', width: '200px' },
      { field: 'character2', header: 'Character 2', width: '200px' },
      { field: 'note', header: 'note', width: '100px' },
    ];


    if (this.data && this.data.data.id) {

      this.gameBankService.getGameById(this.data.data.id).subscribe(
        data => {
          const res = JSON.stringify(data);
          let gamedataArr = [];
          gamedataArr.push(JSON.parse(res))
          this.gameData = gamedataArr;
          const characterData = JSON.stringify(this.gameData);
          this.characters = JSON.parse(characterData);
          if (this.characters[0].votes && this.characters[0].votes.char_1) {
            this.vote_char1 = this.characters[0].votes.char_1
          }
          if (this.characters[0].votes && this.characters[0].votes.char_2) {
            this.vote_char2 = this.characters[0].votes.char_2
          }
        },
        err => {
          // this.tostService.tostrFun(err.error.errors[0].message, 'error');
          // this.dialogRef.close();
        });

    }



  }
  submitWinnerData() {
    let now = new Date();
    let hasErr = "";


    if (!this.score1 && !this.score2) {
      hasErr = 'should be select at least one character as winner';

    } else if (this.characters[0].end_time > now) {
      hasErr = "You are declaring the winner before game ends. Are you still want to go ahead and declare winners? Operation can't be undone.";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    let WinnerObj = {};
    let character1_score = 50;
    let character2_score = 50;
    if (this.score1) {
      character1_score = 100;
    }
    if (this.score2) {
      character2_score = 100;
    }



    WinnerObj = { "player1": { "score": character1_score, "character_id": this.characters[0].characters[0].id }, "player2": { "score": character2_score, "character_id": this.characters[0].characters[1].id } }

    const finalObj = { game_id: this.data.data.id, character_scores: WinnerObj, note: this.notes }
    this.gameBankService.declareWinner(finalObj).subscribe(
      data => {
        this.tostService.tostrFun("Winner declared successfully.", 'success');
        this.dialogRef.close();
      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close();
      });
  }
  onRefrash(e) {
    this.notes = e;
  }
  close(): void {
    this.dialogRef.close(true);
  }

}
