<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ category && category.id ? 'Update' : 'Create' }} category</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <form (ngSubmit)="saveCategory()">
      <div class="modal-body">
        <div class="row">
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Display Category Tag Name</mat-label>
                <input matInput [(ngModel)]="category.display_tag" name="display_tag" id="display_tag"
                  class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Category Name</mat-label>
                <input matInput name="category" [(ngModel)]="category.tag" id="category" class="form-control lowercase"
                  [disabled]="category && category.id">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-2">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Category Index</mat-label>
                <input matInput name="index" [(ngModel)]="category.index" type="number" min=1 id="index"
                  class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-2">
            <div class="form-group">

              <mat-checkbox [checked]="category.is_active" [(ngModel)]="category.is_active"
                [ngModelOptions]="{standalone: true}"> Status
              </mat-checkbox>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col col-6">
            <div class="row">
              <div class="col col-12">

                <div class="form-group">
                  <label for="file">Select Active Icon </label>
                  <div class="file-field">
                    <input style="display: none" type="file" accept="image/*" (change)="onFileChangeActive($event)"
                      #hiddenfileinput>
                    <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">
                      Choose file
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col col-12">
                <div class="card-box card-box2 card-left">
                  <div [ngStyle]="imagePreviewActive" class="card-image"></div>
                </div>
              </div>
            </div>

          </div>

          <div class="col col-6">
            <div class="row">
              <div class="col col-12">

                <div class="form-group">
                  <label for="file">Select DeActiveicon Icon</label>
                  <div class="file-field">
                    <input style="display: none" type="file" accept="image/*" (change)="onFileChangeDeActive($event)"
                      #hiddenfileinput1>
                    <label for="modal-1" (click)="hiddenfileinput1.click()" class="modal-label" tabindex="1">
                      Choose file
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col col-12">
                <div class="card-box1 card-box2 card-left1">
                  <div [ngStyle]="imagePreviewDeActive" class="card-image1"></div>
                </div>
              </div>
            </div>

          </div>




        </div>
      </div>
      <div class="modal-footer">
        <a mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</a>
        <button mat-raised-button type="submit" class="btn btn-danger pull-right">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>