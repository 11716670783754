import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoinRate } from '../../core/models/api/coinRate'
import { CoinRateService } from '../../core/services/league/coin-rate.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TostService } from '../../core/services/common/tost.service';
@Component({
  selector: 'app-coin-rate',
  templateUrl: './coin-rate.component.html',
  styleUrls: ['./coin-rate.component.scss']
})
export class CoinRateComponent implements OnInit {
  cols: any;
  closeResult: string;
  coinRate: CoinRate;
  selectedCoinRate: CoinRate;
  frozenCols: any[];
  loading: Boolean;
  clonedCoin: { [s: string]: any; } = {};
  constructor(private modalService: NgbModal, private tostService: TostService, private coinRateService: CoinRateService) { }

  ngOnInit(): void {
    this.loading = true;
    this.cols = [
      { field: 'currency', header: 'Currency' },
      { field: 'coin_rate', header: 'Rate' }
    ];
    // this.coinRate = JSON.parse(this.coinRateService.getCoinRateData());
    this.getData()

  }

  onRowEditInit(coin: any) {
    this.clonedCoin[coin.id] = { ...coin };
  }

  onRowEditSave(coin: any, index: number) {
    this.coinRate[index] = coin;

    this.coinRateService.updateCoinRate(this.coinRate).subscribe(data => {

      this.tostService.tostrFun("Coin rate updated successfully", 'success');
      delete this.clonedCoin[coin.id];
    },
      err => {
        this.coinRate[index].coin_rate = this.clonedCoin[coin.id].coin_rate;
        //    this.tostService.tostrFun("something went wrong", 'error');
        delete this.clonedCoin[coin.id];
      }
    );



  }

  onRowEditCancel(coin: any, index: number) {
    this.coinRate[index].coin_rate = this.clonedCoin[coin.id].coin_rate;
    delete this.clonedCoin[coin.id];
  }





  getData() {
    this.coinRateService.getCoinRateData1().subscribe(data => {

      const resp = JSON.stringify(data);
      this.coinRate = JSON.parse(resp);
      this.loading = false;

    });
  }
  customSort(event) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
