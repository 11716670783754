import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PromoCode } from '../../core/models/api/promoCode';
import { PromoCodeService } from '../../core/services/league/promo-code.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchService } from "../../core/services/common/search.service";
import { PaginationService } from "../../core/services/common/pagination.service";
import { EditPromoCodeModalComponent } from '../../modal/edit-promo-code-modal/edit-promo-code-modal.component';
import { CommonDeleteModalComponent } from '../../modal/common-delete-modal/common-delete-modal/common-delete-modal.component'
import { SiteConstants } from '../../../config/site-constants';
@Component({
  selector: 'app-promocode',
  templateUrl: './promocode.component.html',
  styleUrls: ['./promocode.component.scss']
})
export class PromocodeComponent implements OnInit {
  totalRecords: number;
  globalSort: any = "";
  filter: any = {};
  frozenCols: any[];
  loading: boolean;
  TimeoutSearch: any;
  cols: any;
  defaultPage: number = 10;
  closeResult: string;
  promoCode: any;
  selectedPromoCode: PromoCode;
  cars: any[];
  constructor(private modalService: NgbModal, private paginationService: PaginationService, private searchService: SearchService, private promoCodeService: PromoCodeService, public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'promo_code', header: 'Promo Code' },
      // { field: 'brand', header: 'Brand' },
      { field: 'name', header: 'Promo name' },
      { field: 'value_type', header: 'Promo Type', width: '200px' },
      { field: 'value', header: 'Value', textalign: 'center' },

      { field: 'start_date', header: 'Start Date' },
      { field: 'end_date', header: 'End Date' },
      { field: '', header: 'Action', width: '80px' }

    ];
    // this.promoCode = JSON.parse(this.promoCodeService.getPromoCode());

    this.getPromoCodeData({});

  }
  openDialog(characterData): void {

    const dialogRef = this.dialog.open(CommonDeleteModalComponent, {
      width: '650px',
      data: characterData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getPromoCodeData(this.filter);

    });
  }

  getCouponTypeText(_type) {
    var _text = "";
    if (_type == 'COUPON_TYPE_GAME') {
      _text = "Game";
    } else if (_type == 'COUPON_TYPE_AMOUNT') {
      _text = "Amount";
    } else if (_type == 'percentage') {
      _text = "Percentage";
    }
    return _text;
  };

  userFilter(val, field) {

    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
    this.getPromoCodeData(this.filter);
    }, SiteConstants.FiltertimeOut);
    //   let obj ={};
    //   if(field=='first_name'){
    //     field='name'
    //   }


    //  let pagination={}
    //  if (this.filter.search) {
    //   const searchObj = {};
    //   searchObj[field]=val;
    //   this.filter.search = Object.assign({}, this.filter.search,searchObj);

    // } else {
    //   obj[field] =val;
    //   this.filter.search= obj;
    // }
    //   this.filter['search'] =  obj;
    //   pagination={limit:this.defaultPage,page:1};
    //   this.filter['pagination'] =pagination
    //   setTimeout(() => {
    //   this.getPromoCodeData( this.filter);

    //     }, 1000);
  }


  customSort(e) {
    let obj = {};
    // if(e.field=='first_name'){
    //   // e.field='first_name'
    // }
    if (e.order == 1) {
      obj[e.field] = 'desc'
    } else {
      obj[e.field] = 'asc'
    }

    if (this.globalSort != e.field + " " + e.order) {

      this.filter['sort'] = obj;
      this.getPromoCodeData(this.filter);
      this.globalSort = e.field + " " + e.order

    }
  }
  getPromoCodeData(obj) {
    this.loading = true;
    this.promoCodeService.getPromoCode(obj).subscribe(data => {

      const tempPromo = JSON.stringify(data);
      this.totalRecords = (JSON.parse(tempPromo).totalCount);
      const promoCodeparse = (JSON.parse(tempPromo).list);
      const getpromoCode = JSON.stringify(promoCodeparse);
      this.promoCode = JSON.parse(getpromoCode);
      this.loading = false;
      // this.totalRecords = this.promoCode.length;


    });
  }

  //   customPagination(event) {
  //     // this.loading = true;
  //     if(event){
  //       let pagination ={}
  //       pagination['limit']=event.rows;
  //       if(event.first){
  //       pagination['page']=event.first / event.rows + 1;
  //       }else {
  //       pagination['page']=1;

  //       }
  //      this.filter['pagination'] = pagination;
  //      this.getPromoCodeData(this.filter);
  //     //  this.loading = false;
  //     }

  //     let obj={} ;
  //     // if(e.field=='first_name'){
  //     //   // e.field='first_name'
  //     // }
  //     if(event.sortField){


  //   if(event.sortOrder==1){
  //   obj[event.sortField]='desc'
  //   } else{
  //     obj[event.sortField]='asc'
  //   }
  // }
  //   if(this.globalSort!=event.sortField+" "+event.sortOrder){

  //    this.filter['sort'] =obj;
  //    this.getPromoCodeData( this.filter);
  //    this.globalSort=event.sortField+" "+event.sortOrder
  //     console.log(this.filter);
  //   }
  //     //in a real application, make a remote request to load data using state metadata from event
  //     //event.first = First row offset
  //     //event.rows = Number of rows per page
  //     //event.sortField = Field name to sort with
  //     //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
  //     //filters: FilterMetadata object having field as key and filter value, filter matchMode as value

  //     //imitate db connection over a network
  //     // setTimeout(() => {
  //     //     if (this.promoCode) {
  //     //         // this.cars = this.promoCode.slice(event.first, (event.first + event.rows));

  //     //         this.loading = false;
  //     //     }
  //     // }, 1000);
  // }
  // getPromoCodeData(){
  //   this.promoCodeService.getPromoCode(null).subscribe(receivedPromocodeData =>{})
  // }
  customPagination(event) {
    let data = this.paginationService.paginationFun(event, this.globalSort, this.defaultPage, this.filter)

    this.filter = data.filter;
    this.globalSort = data.globalSort;
    this.defaultPage = data.defaultPage;
    this.getPromoCodeData(this.filter);

    // this.loading = true;
    // if (event) {
    //   let pagination = {}
    //   pagination['limit'] = event.rows;
    //   if (event.first) {
    //     if (this.defaultPage == event.rows) {
    //       this.defaultPage = event.rows;
    //       pagination['page'] = Math.round(event.first / event.rows) + 1;
    //     } else {
    //       this.defaultPage = event.rows;
    //       pagination['page'] = 1;
    //     }

    //   } else {
    //     pagination['page'] = 1;

    //   }
    //   this.filter['pagination'] = pagination;
    //   this.getPromoCodeData(this.filter);
    //   //  this.loading = false;
    // }

    // let obj = {};

    // if (event.sortField) {


    //   if (event.sortOrder == 1) {
    //     obj[event.sortField] = 'desc'
    //   } else {
    //     obj[event.sortField] = 'asc'
    //   }
    // }
    // if (this.globalSort != event.sortField + " " + event.sortOrder) {

    //   this.filter['sort'] = obj;
    //   this.getPromoCodeData(this.filter);
    //   this.globalSort = event.sortField + " " + event.sortOrder
    //   console.log(this.filter);
    // }

  }

  openCreateDialog(characterData): void {

    const dialogRef = this.dialog.open(EditPromoCodeModalComponent, {
      width: '650px',
      data: characterData
    });

    dialogRef.afterClosed().subscribe(result => {

      // this.getPromoCodeData();
      this.getPromoCodeData(this.filter);
    });
  }

  onRefrash(e) {
    setTimeout(() => {
      this.getPromoCodeData(this.filter);
    }, 1500);

  }

}