import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../core/services/authentication.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loading = false;
  submitted = false;
  returnUrl: string;
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),

  });

  constructor (
    private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.user_role == 1) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/home']);
      }



    }
    // if (this.authenticationService.isLoggedIn.getValue()) {
    //   this.router.navigate(['dashboard']);
    // }
    // console.log(this.authenticationService.currentUserValue)
  }

  ngOnInit () {
    // get return url from route parameters or default to '/'
    // this.router.navigate(['/login']);
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f () { return this.loginForm.controls; }

  onSubmit () {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.login(this.f.username.value, this.f.password.value).subscribe(
      val => {
        localStorage.setItem('currentUser', JSON.stringify(val));
        if (val && (val['user_role'] == 1)) {
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/home']);
        }
        //  this.router.navigate(['/dashboard']);
        window.location.reload();
      },
      error => {
        //  this.alertService.error(error);
        console.log(error);
        this.loading = false;
      });
  }
}
