import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LevelsService } from '../../core/services/league/levels.service';
import { TostService } from '../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-level-edit-modal',
  templateUrl: './level-edit-modal.component.html',
  styleUrls: ['./level-edit-modal.component.scss']
})
export class LevelEditModalComponent implements OnInit {
  action: string;

  levelForm = new FormGroup({

    bonus: new FormControl('', [Validators.required]),
    game_num: new FormControl('', [Validators.required]),
    paid_game_num: new FormControl('', [Validators.required]),
    label: new FormControl('', [Validators.required]),
    max_winning_amount: new FormControl('', [Validators.required])



  });

  constructor(
    public dialogRef: MatDialogRef<LevelEditModalComponent>, private modalService: NgbModal, private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any, private levelService: LevelsService, private tostService: TostService) { }

  ngOnInit(): void {

    if (this.data) {
      //  console.log("level edit data", this.data)
      this.levelForm.patchValue({
        bonus: this.data.bonus,
        game_num: this.data.game_num,
        paid_game_num: this.data.paid_game_num,
        label: this.data.label,
        max_winning_amount: this.data.max_winning_amount,
      });

    } else {
      this.levelForm.patchValue({
        bonus: '',
        game_num: '',
        paid_game_num: '',
        label: '',
        max_winning_amount: '',

      });
    }
  }

  submitData() {
    this.spinner.show();
    // console.log(this.levelForm.value);
    // console.log("level form value", this.levelForm.value);
    if (this.data == null) {
      this.action = "create";
      //  console.log("action value on create", this.action);

    } else if (this.data.id) {
      this.action = "update";
      //  console.log("action value on update", this.action);
      // this.levelForm.value.id = this.data.id;
      let formValues = this.levelForm.value;
      Object.assign(formValues, {
        id: this.data.id,
        createdAt: this.data.createdAt,
        is_active: this.data.is_active,
        prev_version_id: this.data.prev_version_id,
        updatedAt: this.data.updatedAt,
        level_num: this.data.level_num
      });

    }

    let formValues = this.levelForm.value;
    this.levelService.createLevel(formValues, this.action).subscribe(data => {
      // console.log("create level data", data);
      if (this.action == 'update') {
        this.tostService.tostrFun("Level updated Successfully", 'success');
        // this.spinner.hide();
        setTimeout(() => {
          this.spinner.hide();
          this.dialogRef.close();
        }, 3000)
      } else {
        this.tostService.tostrFun("Level Created Successfully", 'success');
        setTimeout(() => {
          this.spinner.hide();
          this.dialogRef.close();
        }, 3000)

      }

    },
      err => {
        //   this.tostService.tostrFun("Something Went Wrong", 'error');
        setTimeout(() => {
          this.spinner.hide();
          this.dialogRef.close();
        }, 3000)

      }
    )
    // this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }


}
