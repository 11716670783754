<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Disputed</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<mat-dialog-content layout-xs>
    <div class="mat-dialog-content" style="width:1000px;height:300px;overflow: auto;">
        <div *ngFor="let row of disputesListData">
            <div style='border-bottom: 1px solid #ccc;margin-bottom: 15px;'>
                <!-- <div layout="row" flex layout-xs="column" style='margin-bottom: 6px'>
                 <div flex>
                     <label> Question id : </label>
                     {{row.id}}
                 </div>
                 <div flex>
                     <label> Question text : </label>
                     {{row.question_text}}
                 </div> 
                 <div flex>
                     <label> Game name : </label>
                     {{row.game_name}}
                 </div>
             </div> -->
                <div class="ui-g">
                    <div class="ui-g-4">
                        <label> id : </label>
                        {{row.game_id}}
                    </div>
                    <div class="ui-g-4">
                        <label> game Text : </label>
                        {{row.game_text}}
                    </div>
                    <div class="ui-g-4">
                        <label> Game Name : </label>
                        {{row.game_title}}
                    </div>
                </div>
                <!-- <div layout="row" flex layout-xs="column" style='margin-bottom: 6px'>
                 <div flex>
                     <label> Player name 1 : </label>
                     {{row.player1_name }}
                 </div>
                 <div flex>
                     <label> Player name 2 : </label>
                     {{row.player2_name }}
                 </div>
                 <div flex>
                     <label> Winner : </label>
                     <span *ngIf='row.character_score_1 === row.character_score_2'> 
                             Tie
                     </span>
                     <span *ngIf='row.character_score_1 > row.character_score_2'> 
                         Player 1
                     </span>
                     <span *ngIf='row.character_score_1 < row.character_score_2'> 
                         Palyer 2
                     </span>
                 </div>
             </div> -->
                <div class="ui-g">
                    <div class="ui-g-4">
                        <label> Player Name 1 : </label>
                        {{row.player1_name }}
                    </div>
                    <div class="ui-g-4">
                        <label> Player Name 2 : </label>
                        {{row.player2_name }}
                    </div>
                    <div class="ui-g-4">
                        <label> Winner : </label>
                        <span *ngIf='row.character_score_1 == row.character_score_2'>
                            Tie
                        </span>
                        <span *ngIf='row.character_score_1 > row.character_score_2'>
                            Player 1
                        </span>
                        <span *ngIf='row.character_score_1 < row.character_score_2'>
                            Palyer 2
                        </span>
                    </div>
                </div>
                <!-- <div layout="row" flex layout-xs="column" style='margin-bottom: 6px'>
                 <div flex>
                     <label> Player 1 Image : </label>
                     <preview-player-picture player="{ picture: row.player1_image}" ></preview-player-picture> 
                 </div>
                 <div flex>
                     <label> Player 2 Image : </label>
                     <preview-player-picture player="{ picture: row.player2_image}" ></preview-player-picture> 
                 </div>
                 <div flex>
                     <div  *ngFor="let item for row.declaration_proofs track by $index">
                         <label *ngIf="$index==0"> Player Winner Proof : </label>
                         <preview-player-picture style="display: block" *ngIf="item.type=='image'" player="{ picture: item.url}"></preview-player-picture>
                         <img [src]="item.url" height="200" >
                         <mat-list-item style="padding:0px;" *ngIf="item.type=='url'">
                             <a href="{{ item.url }}" target="_blank" >{{ item.url }} </a> &nbsp; &nbsp;
                             <i class="fa fa-copy" style="cursor: pointer;" copy-to-clipboard="{{ item.url }}"></i>
                         </mat-list-item>
                     </div>
                 </div>
             </div> -->
                <!-- {{row.background.type}} -->
                <div class="ui-g">
                    <div class="ui-g-8" *ngIf="row.background.type!='single'">
                        <div class="ui-g-6">
                            <label> Player 1 Image : </label>
                            <img [src]="row.background.urls[0].url" class="playerImage" />
                        </div>
                        <div class="ui-g-6">
                            <label> Player 2 Image : </label>
                            <img [src]="row.background.urls[1].url" class="playerImage" />

                        </div>
                    </div>
                    <div class="ui-g-8" *ngIf="row.background.type=='single'">
                        <div class="ui-g-12" *ngIf="row.background.urls[0].file_type=='image'">
                            <div class="ui-g-6">
                                <label> Game Background Image : </label>
                            </div>
                            <div class="ui-g-6">
                                <img [src]="row.background.urls[0].url" class="singleTypegameImage" />
                            </div>


                        </div>

                        <div class="ui-g-12" *ngIf="row.background.urls[0].file_type=='video'">
                            <div class="ui-g-6">
                                <label> Game Background Video : </label>
                            </div>
                            <div class="ui-g-6">
                                <video autoplay muted loop id="myVideo" class="singleTypegameImage">
                                    <source src="{{row.background.urls[0].url}}" type="video/mp4">

                                </video>
                            </div>
                        </div>

                    </div>
                    <div class="ui-g-4">
                        <div class="ui-g-12" *ngFor="let item of declarationProof; index as i">
                            <!-- <label> Player Winner Proof : </label> -->

                            <label *ngIf="i==0"> Player Winner Proof : </label>
                            <!-- <preview-player-picture style="display: block" *ngIf="item.type=='image'" player="{ picture: item.url}"></preview-player-picture> -->
                            <img [src]="item.url" *ngIf="item.type=='image'" class="playerImage">
                            <mat-list-item style="padding:0px;" *ngIf="item.type=='url'">
                                <a [href]="item.url" target="_blank" class="breack-line">{{ item.url }} </a> <i
                                    *ngIf="item.url" class="fa fa-clipboard" (click)="callServiceToCopy(item.url)"></i>
                                &nbsp; &nbsp;

                                <!-- <i class="fa fa-copy" style="cursor: pointer;" copy-to-clipboard="{{ item.url }}"></i> -->
                            </mat-list-item>

                        </div>
                    </div>
                </div>
                <!-- <table class="table table-condensed table-bordered table-striped">
                 <thead>
                     <tr style="line-height: 3.428571;border-bottom: 1px solid #ddd;">
                         <th>Disputed by</th>
                         <th>Dispute reason</th>
                         <th>Dispute Proof</th>
                     </tr>
                 </thead>
                 <tbody>   
                     <tr *ngFor="let item for alldata" style="line-height: 3.428571;border-bottom: 1px solid #ddd;">
                         <td><user-link ng-click="close()" user="{id: item.disputed_by}"></user-link></td>
                         <td>{{row.disputed_by}}</td>
                         <td>{{row.dispute_reason}}</td>
                         <td>
                             <div *ngFor="let it for alldata.description track by $index"> 
                                 <mat-list-item style="padding:0px;" *ngIf="it.type=='url'">
                                     <a href="{{ it.url }}" target="_blank" >{{ it.url }} </a> &nbsp; &nbsp;
                                     <i class="fa fa-copy" style="cursor: pointer;" copy-to-clipboard="{{ it.url }}"></i>
                                 </mat-list-item>
                                 <preview-player-picture  *ngIf="it.type=='image'" player="{ picture: it.url}"></preview-player-picture>
                             </div>
                         </td>
                     </tr>
                 </tbody>
             </table> -->
                <p-table [value]="alldata" [loading]="loading">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Disputed By</th>
                            <th>Dispute Reason</th>
                            <th>Dispute Proof</th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td><a [routerLink]="['/user', rowData.disputed_by]"><span
                                        (click)="redirectToUser()">{{rowData.disputed_by}}
                                    </span></a></td>

                            <td class="breack-line">{{rowData.dispute_reason}}</td>
                            <td>
                                <div *ngFor="let it of descData; index as i">
                                    <mat-list-item style="padding:0px;" class="breack-line" *ngIf="it.type=='url'">
                                        <a [href]="it.url" target="_blank"> {{ it.url }} </a> <i
                                            class="fa fa-clipboard " (click)="callServiceToCopy(it.url)"></i> &nbsp;
                                        &nbsp;
                                        <!-- <i class="fa fa-copy" style="cursor: pointer;" copy-to-clipboard="{{ it.url }}"></i> -->
                                    </mat-list-item>
                                    <!-- <preview-player-picture  *ngIf="it.type=='image'" player="{ picture: it.url}"></preview-player-picture> -->
                                    <img *ngIf="it.type=='image'" [src]="it.url" target="_blank" class="playerImage" />
                                </div>
                            </td>

                            <!-- <li *ngFor="let url of rowData.urls"><a [href]="url" target="_blank">{{url}}</a></li> -->
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</mat-dialog-content>

<div class="modal-footer">
    <app-action-icon [data]="{game_id:data.id}" [width]="'350px'" *ngIf="isApproveStatus(status)"
        [modaleId]="'IgnoreDisputeComponent'" (onRefrashed)="onRefrash($event)" icon="approve" tooltip="approvegame">
    </app-action-icon>
    <app-action-icon [data]="this.disputesListData[0]" [width]="'1000px'" [modaleId]="'SwitchWinnerComponent'"
        *ngIf="status != 1 && status != 2 && status != 6" (onRefrashed)="onRefrash($event)" icon="winner_declaration" tooltip="switchwinnerdeclaration">
    </app-action-icon>
    <app-action-icon [data]="{id:data.id}" [width]="'350px'" *ngIf="isStatus(status)"
        [modaleId]="'SelfgameCancelModalComponent'" (onRefrashed)="onRefrash($event)" icon="remove"
        tooltip="gamecancel">
    </app-action-icon>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>