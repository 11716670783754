import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-league',
  templateUrl: './create-league.component.html',
  styleUrls: ['./create-league.component.scss']
})
export class CreateLeagueComponent implements OnInit {
  price_options: any[];



  cLeagueForm = new FormGroup({
    game_name: new FormControl('', [Validators.required]),
    start_time: new FormControl(new Date()),
    end_time: new FormControl(new Date()),
    last_join_time: new FormControl(new Date()),
    title: new FormControl('', []),
    price_options: new FormControl('', []),
    tags: new FormControl('', []),
  });

  constructor(public dialogRef: MatDialogRef<CreateLeagueComponent>, private _fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data) {


      this.cLeagueForm.patchValue({
        game_name: this.data.game_name,
        start_time: this.data.start_time,
        end_time: this.data.end_time,
        last_join_time: this.data.last_join_time,
        title: this.data.title,
        price_options: this.data.price_options,
        tags: this.data.tags,
        category_name: this.data.category_name
      });
      //  console.log(this.data.price_options)
      this.price_options = this.data.price_options;
    } else {
      this.cLeagueForm.patchValue({
        game_name: '',
        start_time: new Date(),
        end_time: new Date(),
        last_join_time: new Date(),
        title: '',
        tags: '',
        category_name: '',
        price_options: [{
          entry_fee: 0,
          prize_money: 0,
          default: false
        }]
      });
    }
    this.price_options = [{
      entry_fee: 0,
      prize_money: 0,
      default: true
    }];
  }

  submitData() {

  }

  close(): void {

    this.dialogRef.close(true);
  }

  addPriceOption() {
    this.price_options.push({
      entry_fee: 0,
      prize_money: 0,
      default: true
    });
  }
  private priceOptions(): FormGroup {
    return this._fb.group({
      entry_fee: [],
      prize_money: [],
      default: []
    });
  }

}
