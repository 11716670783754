<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Levels </h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="levelForm" class="input-size">
        <!-- <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="label" id="label" placeholder="Level Name" type="number" class="form-control">
                    </mat-form-field>
                  
                </div>
                
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="bonus" id="bonus" placeholder="Level bonus coins" type="number" class="form-control">
                    </mat-form-field>
                  
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <mat-form-field class="example-full-width">
                            <input matInput formControlName="game_num" id="game_num" placeholder="Number of games in level" type="number" class="form-control">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div> -->

        <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" 
        size = "medium" color = "#fff" 
        type = "square-jelly-box" 
        [fullScreen] = "true">
        <p style="color: white" > Loading... </p>
       </ngx-spinner>
       
        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Lavel Name</label>
            </div>
            <div class="ui-g-6 form-group">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="label" id="label" placeholder="Level Name" type="text"
                        class="form-control">
                </mat-form-field>
            </div>

        </div>

        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Level Bonus Coin</label>
            </div>
            <div class="ui-g-6 form-group">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="bonus" id="bonus" placeholder="Level bonus coins" type="number"
                        class="form-control">
                </mat-form-field>
            </div>

        </div>
        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Number of games in level</label>
            </div>
            <div class="ui-g-6 form-group">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="game_num" id="game_num" placeholder="Number of games in level"
                        type="number" class="form-control">
                </mat-form-field>
            </div>

        </div>
        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Number of paid games in level</label>
            </div>
            <div class="ui-g-6 form-group">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="paid_game_num" id="paid_game_num"
                        placeholder="Number of paid games in level" type="number" class="form-control">
                </mat-form-field>
            </div>

        </div>
        <div class="ui-g">
            <div class="ui-g-6">
                <label class="alignLabel">Maximum winning amount per game</label>
            </div>
            <div class="ui-g-6 form-group">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="max_winning_amount" id="max_winning_amount"
                        placeholder="Maximum winning amount per game" type="number" class="form-control">
                </mat-form-field>
            </div>

        </div>

        <!-- <div class="row">
            <div class="col-md-8">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="paid_game_num" id="paid_game_num" placeholder="Number of paid games in level" type="number" class="form-control">
                </mat-form-field>
            </div>

            <div class="col-md-8">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="max_winning_amount" id="max_winning_amount" placeholder="Maximum winning amount per game" type="number" class="form-control">
                </mat-form-field>
            </div>
        </div> -->

        <div class="modal-footer">

            <button mat-raised-button type="submit" class="btn  btn-danger pull-right " data-dismiss="alert"
                aria-label="Close" [disabled]="!levelForm.valid" (click)="submitData()">Submit</button>
        </div>
        <div class="clearfix"></div>


    </form>
</div>