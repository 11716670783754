
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { TostService } from '../../core/services/common/tost.service';
import { AdminService } from '../../core/services/common/admin.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  oldPass;
  newPass;
  confirmPass;

  show = false;
  oldPassShow = false;
  newPassShow = false;
  confirmPassShow = false;
  loading = false;
  submitted = false;
  returnUrl: string;
  isOldVisible: "password";
  loginForm = new FormGroup({
    old_password: new FormControl('', []),
    new_password: new FormControl('', []),
    confirm_password: new FormControl('', []),
  });

  constructor (
    private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private tostService: TostService,
    private router: Router,
    private adminService: AdminService
  ) {
    // redirect to home if already logged in
    /*  if (this.authenticationService.currentUserValue) {
        if (this.authenticationService.currentUserValue && this.authenticationService.currentUserValue.user_role == 5) {
          this.router.navigate(['/user']);
        } else {
          this.router.navigate(['/dashboard']);
        }


      }*/
    // if (this.authenticationService.isLoggedIn.getValue()) {
    //   this.router.navigate(['dashboard']);
    // }
    // console.log(this.authenticationService.currentUserValue)
  }

  ngOnInit () {
    this.oldPass = 'password';
    this.newPass = 'password';
    this.confirmPass = 'password';

    // get return url from route parameters or default to '/'
    // this.router.navigate(['/login']);
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f () { return this.loginForm.controls; }

  onSubmit () {
    this.submitted = true;
    const passwordRefEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    // stop here if form is invalid

    let hasErr = ""
    if (!this.f.old_password.value) {
      hasErr = "Old password is required";
    } else if (!this.f.new_password.value) {
      hasErr = "New password is required";
    } else if (!this.f.confirm_password.value) {
      hasErr = "Confirm password is required";
    } else if (!passwordRefEx.test(this.f.new_password.value)) {
      hasErr = "Password must contain at least eight characters, including uppercase, lowercase letters, numbers and  special characters like $#@!_-.";
    } else if (this.f.confirm_password.value !== this.f.new_password.value) {
      hasErr = "Confirm password is not match to new password."
    } else if (!passwordRefEx.test(this.f.confirm_password.value)) {
      hasErr = "Password must contain at least eight characters, including uppercase, lowercase letters, numbers and  special characters like $#@!_-.";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }

    this.loading = true;
    const obj = { "oldPassword": this.f.old_password.value, "newPassword": this.f.new_password.value }
    this.adminService.updatePassword(obj).subscribe(
      val => {
        this.tostService.tostrFun('Password updated successfully !', 'success');
        this.router.navigate(['/dashboard']);
        this.loading = false;
      },
      error => {
        //  this.alertService.error(error);
        console.log(error);
        this.loading = false;
      });
  }
  onClickOldPass () {


    if (this.oldPass === 'password') {
      this.oldPass = 'text';
      this.oldPassShow = true;
    } else {
      this.oldPass = 'password';
      this.oldPassShow = false;
    }
  }

  onClickNewPass () {
    if (this.newPass === 'password') {
      this.newPass = 'text';
      this.newPassShow = true;
    } else {
      this.newPass = 'password';
      this.newPassShow = false;
    }
  }
  onClickConfirmPass () {
    if (this.confirmPass === 'password') {
      this.confirmPass = 'text';
      this.confirmPassShow = true;
    } else {
      this.confirmPass = 'password';
      this.confirmPassShow = false;
    }
  }
}
