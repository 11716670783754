import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserModule } from '../modules/user/user.module';
import { LeaugeModule } from '../modules/leauge/leauge.module';
import { CharctersModule } from '../modules/charcters/charcters.module'
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { GameBankModule } from '../modules/game-bank/game-bank.module'
import { ActionComponentModule } from '../modules/action-component/action-component.module'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UserModule,
    CharctersModule,
    MatInputModule,
    MatFormFieldModule,
    ActionComponentModule,
    LeaugeModule, GameBankModule,
    FormsModule, ReactiveFormsModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    LoginComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ]
})
export class ComponentsModule { }
