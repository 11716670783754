import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../core/services/settings.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActionIconComponent } from "../../action-component/action-icon/action-icon.component";
import { ActionButtonComponent } from "../../action-component/action-button/action-button.component";
import { SettingPriceOptionComponent } from '../../modal/setting-price-option/setting-price-option.component';
import { TostService } from '../../core/services/common/tost.service';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  cols: any;
  setting: any = [];
  loading: boolean;
  inputValues: any = {};
  crsfToken: ''
  constructor(private tostService: TostService, private settingService: SettingsService, private modalService: NgbModal, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loading = true;
    this.cols = [
      { field: 'entry_fee', header: 'Entry Fee' },
      { field: 'prize_money', header: 'Prize Money' },
      { field: 'default', header: 'default' },
      { field: 'color_code', header: 'Color Code' },

      { field: '', header: 'Action', width: "120px" }

    ];
    this.GetData();
  }

  GetData() {

    this.settingService.getSettingsData().subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).priceOptionsList;
      const getUserData = JSON.stringify(getData);
      this.setting = JSON.parse(getUserData);
      const getOuterValues = (JSON.parse(res));
      this.crsfToken = getOuterValues.crsfToken;

      localStorage.setItem('crsf', getOuterValues.crsfToken)
      // console.log("setting", getOuterValues.maxGameInDayAllow);
      // console.log('tokn val', this.crsfToken)
      this.inputValues = getOuterValues;
      this.loading = false;
    });
  }

  openCreateDialog(settingRowData) {
    const dialogRef = this.dialog.open(SettingPriceOptionComponent, {
      width: '650px',
      data: { data: settingRowData }
    });

    dialogRef.afterClosed().subscribe(result => {
      //  console.log('The dialog was closed');
    });
  }

  onRefrash(e) {
    setTimeout(() => {
      this.GetData();
    }, 1500);

  }

  submitData(e, val) {
    let formValues = {}
    // console.log('event target', e.target.id)
    // console.log('singale value ', val)
    var targetval = e.target.id
    if (targetval == "maxGameInDayAllow") {
      formValues = { 'maxGameInDayAllow': val }
    }

    if (targetval == "defaultGamePerBonus") {
      formValues = { 'defaultGamePerBonus': val }
    }

    if (targetval == "welcomeBonus") {
      formValues = { 'welcomeBonus': val }
    }

    if (targetval == "referFriendRewardCoin") {
      formValues = { 'referFriendRewardCoin': val }
    }
    if (targetval == "selfGameMaxAllowedReport") {
      formValues = { 'selfGameMaxAllowedReport': val }
    }

    if (targetval == "allowPaidGamesOnly") {
      formValues = { 'allowPaidGamesOnly': val ? 1 : 0 }
    }
    // console.log("param form val", formValues);

    // let formValues = {}
    var tokenval = localStorage.getItem('crsf');
    this.settingService.createUpdateSetting(formValues, 'update', tokenval).subscribe(data => {
      //   console.log("create setting data", data);
      this.GetData();
      this.tostService.tostrFun("setting updated Successfully", 'success');
    },
      err => {
        //   this.tostService.tostrFun("Something Went Wrong", 'error');
        this.GetData();

      }
    )
  }

}
