<div class="modal-body testclass">
    <div class="row">
        <div class="col-md-12">
            <div *ngIf="isId">

                Do you want to ignore the report?

            </div>
            <div *ngIf="!isId">
                Do you want to ignore all the report?
            </div>
        </div>

    </div>


    <div class="row" style="margin-top: 15px;">
        <div class="col-md-6">
            <button *ngIf="isId" mat-raised-button (click)="IgnoreReportById(gameId,reportId)"
                class="btn  btn-danger pull-right " data-dismiss="alert" aria-label="Close">
                Ok
            </button>
            <button *ngIf="!isId" mat-raised-button (click)="IgnoreReport(gameId)" class="btn  btn-danger pull-right "
                data-dismiss="alert" aria-label="Close">Ok</button>

        </div>
        <div class="col-md-6">
            <button mat-button mat-raised-button (click)="close()" class="btn btn-danger pull-right "
                data-dismiss="alert" aria-label="Close">
                Cancel
            </button>

        </div>
    </div>



</div>