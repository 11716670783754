import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';

import * as xlsx from 'xlsx';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GetTransactionService } from '../../../core/services/users/get-transaction.service';
import { TostService } from '../../../core/services/common/tost.service';
import { PaginationService } from '../../../core/services/common/pagination.service';
import { SearchService } from '../../../core/services/common/search.service';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SiteConstants } from '../../../../config/site-constants';
import * as moment from 'moment';

@Component({
  selector: 'app-user-transaction-modal',
  templateUrl: './user-transaction-modal.component.html',
  styleUrls: ['./user-transaction-modal.component.scss']
})
export class UserTransactionModalComponent implements OnInit {
  cols = [];
  loading = false;
  tagList = [];
  totalRecords = 0;
  TimeoutSearch: any;
  selectedTag = null;
  globalSort = '';
  defaultDate: Date = new Date();
  constPage = 10;
  userTransaction: any;
  userTransactionCopy: any;
  selectedTransaction: any;
  fileName: string = "";
  filter: any = {};
  myForm = new FormGroup({
    start_date: new FormControl('', []),
    end_date: new FormControl('', []),
  });
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  constructor (private modalService: NgbModal, public dialogRef: MatDialogRef<UserTransactionModalComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private transactionService: GetTransactionService
    , private gameBankService: GameBankService,
    private paginationService: PaginationService,
    private searchService: SearchService,
  ) { }

  ngOnInit (): void {
    this.cols = [
      { field: 'id', header: 'Id', width: '1px', display: "none" },
      { field: 'user_id', header: 'User Id', width: '1px', display: "none" },
      { field: 'game_id', header: 'Game Id', width: '100px' },
      { field: 'game_play_id', header: 'Play Id', width: '100px' },
      { field: 'amount', header: 'Amount', width: '100px' },
      { field: 'amount_type', header: 'AMT Type', width: '110px' },
      { field: 'tx_type', header: 'TX Type', width: '160px' },
      { field: 'question', header: 'Question', width: '175px' },
      { field: 'game_choices', header: 'Game Choices', width: '1px', display: "none" },
      { field: 'ticket_id', header: 'Ticket Id', width: '1px', display: "none" },
      { field: 'tx_date', header: 'TX Date', width: '125px' },
      { field: 'tx_date_time_gmt', header: 'TX Date Time GMT', width: '1px', display: "none" }

    ];
    if (this.data && this.data.id) {
      this.fileName = this.data.user_name + "_" + this.data.id;
      this.myForm.patchValue({
        end_date: this.defaultDate
      });
      this.loading = true;

      this.GetData();


    }



  }
  close (): void {
    this.dialogRef.close(true);
  }

  clickUserFilter (isClear) {
    let obj = {}
    if (this.myForm.value.start_date) {
      obj['start_date'] = moment(this.myForm.value.start_date).format('YYYY-MM-DD').toString();
    } else {
      if (this.filter.search) {
        this.filter.search['start_date'] = "";

        delete this.filter.search.start_date;
      }
    }
    if (this.myForm.value.end_date) {
      obj['end_date'] = moment(this.myForm.value.end_date).format('YYYY-MM-DD').toString();
    } else {
      if (this.filter.search) {
        this.filter.search['end_date'] = "";
        delete this.filter.search.end_date;
      }
    }
    if (!isClear) {
      let hasErr = "";
      if (!obj['start_date'] && !obj['end_date']) {
        hasErr = "Please Select Start Date and End Date";
      } else if (obj['start_date'] && !obj['end_date']) {
        hasErr = "Please Select End Date ";
      } else if (!obj['start_date'] && obj['end_date']) {
        hasErr = "Please Select Start Date";
      } else if (new Date(obj['start_date']) > new Date(obj['end_date'])) {
        hasErr = "End Date should be greater than start date";
      }

      if (hasErr) {
        this.tostService.tostrFun(hasErr, 'error');
        return;
      }
    }
    if (this.filter.search) {
      this.filter.search = Object.assign({}, this.filter.search, obj);
    } else {
      this.filter['search'] = obj;
    }

    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData();
    }, SiteConstants.FiltertimeOut);
  }
  clickRefreshFilter () {
    this.myForm.patchValue({
      start_date: '',
      end_date: ''
    });
    this.clickUserFilter(true);
  }

  GetData () {
    //this.loading = true;
    this.transactionService.getTransactionDataNew(this.data.id, this.filter).subscribe(

      data => {
        //const res = JSON.stringify(data);
        // const getData = (JSON.parse(res)).userTransactionList;
        // const getUserTransactionData = JSON.stringify(getData);
        // this.userTransaction = JSON.parse(getUserTransactionData);
        this.userTransaction = data['userTransactionList'] || [];
        this.totalRecords = data['pagination'].total;
        this.loading = false;
      },
      err => {

      });

  }

  customSort (ev) {
    let obj = {};

    if (ev.order == 1) {
      obj[ev.field] = 'desc'
    } else {
      obj[ev.field] = 'asc'
    }

    const sortKey = ev.field + ' ' + ev.order;
    if (this.globalSort !== sortKey) {
      this.filter['sort'] = obj;
      this.GetData();
      this.globalSort = sortKey;
    }
  }

  customPagination (ev) {
    let data = this.paginationService.paginationFun(ev, this.globalSort, this.constPage, this.filter)
    this.filter = data.filter;
    this.globalSort = data.globalSort;
    this.constPage = data.defaultPage;

    this.GetData();
  }

  onRefresh (ev) {
    setTimeout(() => {
      this.GetData();
    }, 100);
  }
  tagsFilter (val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData();
    }, SiteConstants.FiltertimeOut);
  }

  userFilter (val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData();
    }, SiteConstants.FiltertimeOut);
  }

  exportToExcel () {
    this.loading = true;
    this.transactionService.userTransactionCSV(this.data.id, this.filter).subscribe(

      data => {
        this.userTransactionCopy = data['userTransactionList'];
        setTimeout(() => {
          const ws: xlsx.WorkSheet =
            xlsx.utils.table_to_sheet(this.epltable.nativeElement);
          const wb: xlsx.WorkBook = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
          xlsx.writeFile(wb, `${this.fileName}.csv`);
          this.loading = false;
        }, 1000);
      },
      err => {
        this.loading = false;
      });
  }

}
