<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Game Configurations</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <div class="modal-body">
      <h6>

        <div class="row">

          <div class="col-md-4">
            Allow Only Free Game Plays:
            &nbsp;<p-checkbox [style]="{ verticalAlign: 'text-bottom' }" [(ngModel)]="gameConfig.is_free_only"
              name="is_free_only" binary="true" (onChange)="toggleFreeOnly($event)" [disabled]="freeOnlyLoading">
            </p-checkbox>
          </div>
          <div class="col-md-4">
            Boost Game:
            &nbsp;<p-checkbox [style]="{ verticalAlign: 'text-bottom' }" [(ngModel)]="gameConfig.mark_hot"
              name="mark_hot" binary="true" (onChange)="toggleBoostGame($event)" [disabled]="boostGameLoading">
            </p-checkbox>
          </div>
          <div class="col-md-4">
            Allow Bot Creation:
            &nbsp;<p-checkbox [style]="{ verticalAlign: 'text-bottom' }" [(ngModel)]="gameConfig.allow_bots"
              name="allow_bots" binary="true" (onChange)="toggleBotsForGame($event)" [disabled]="allowBotsGameLoading">
            </p-checkbox>
          </div>

        </div>
      </h6>
      <h6>&nbsp;</h6>
      <p-card>
        <div class="row">

          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Min Payout in Coins({{coinRate}} Coins=1$)</mat-label>
              <input matInput [(ngModel)]="payoutConfig.min_host_payout" (change)="minAmountChange($event)"
                type="number" min="0" name="min_host_payout" />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Max Payout in Coins({{coinRate}} Coins=1$)</mat-label>
              <input matInput [(ngModel)]="payoutConfig.max_host_payout" (change)="maxAmountChange($event)"
                type="number" min="0" name="max_host_payout" />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field>
              <mat-label>Match mode:</mat-label>
              <input matInput [(ngModel)]="payoutConfig.match_mode" type="number" min="0" name="match_mode" />
            </mat-form-field>
          </div>
          <div class="col-md-1">
            <button pButton type="button" matTooltip="{{tooltips['save']}}" (click)="updateConfig()"
              class="ui-button-raised" icon="{{icons['save']}}" style="margin: 0 .3em .3em 0"></button>
          </div>

        </div>
        <p style="color: red;" *ngIf="maxPayoutFlag">Max payout should be greater than min payout</p>
      </p-card>
      <h6>&nbsp;</h6>
      <h6>Game Tags</h6>
      <div class="row">
        <div class="col col-12">
          <tag-input [(ngModel)]="gameConfig.tags" [identifyBy]="'tag_name'" secondaryPlaceholder="Enter game id's"
            [displayBy]="'tag_name'" (onAdd)="onTagAdd($event)" (onRemove)="onTagRemove($event)" [disable]="tagsLoading"
            #tagsinput>
            <tag-input-dropdown [autocompleteObservable]="searchTags" [appendToBody]="false">
              <ng-template let-item="item" let-index="index">
                {{ item.display }} <span style="font-size: 11px; font-style: italic;">{{ item.tag_type }}</span>
              </ng-template>
            </tag-input-dropdown>
            <ng-template let-item="item" let-index="index">
              <div style="padding: 0 5px;">
                {{ item.display }}
                <span style="font-size: 11px; font-style: italic;">{{ item.tag_type }}</span>
                <delete-icon (click)="tagsinput.removeItem(item, index)"></delete-icon>
              </div>
            </ng-template>
          </tag-input>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</button>
    </div>
  </div>
</mat-dialog-content>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>