import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
// import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { UserModule } from '../app/modules/user/user.module';
import { LeaugeModule } from '../app/modules/leauge/leauge.module';
import {ActionComponentModule} from './modules/action-component/action-component.module';
import { CharctersModule } from '../app/modules/charcters/charcters.module'
import { DashboardComponent } from './dashboard/dashboard.component';
import { BasicAuthInterceptor } from './core/_helpers/basic-auth.interceptor';

import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { WelcomeComponent } from './welcome/welcome.component';
@NgModule({
  imports: [

    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    UserModule,
    ComponentsModule,
    LeaugeModule,
    RouterModule,
    ActionComponentModule,
    HttpClientModule,
    AppRoutingModule,
    CharctersModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    WelcomeComponent,
  


  ],
  exports: [

  ],
 providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true
    }
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
