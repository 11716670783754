<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">Dollar Payout</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="initiatePaymentForm">
    <div class="modal-body">

        <div class="row">
            <div class="col-md-12">
                <label>Balance amount in USD {{maxAmount | number}}</label>
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                    <mat-label>Select Email For Payout</mat-label>
                      <input type="text"
                        placeholder="Pick one"
                        aria-label="User payout email"
                        matInput
                        formControlName="communication_email"
                        id="communication_email"
                        [matAutocomplete]="auto">
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let emailDt of emailList" [value]="emailDt.email">
                          {{emailDt.email}}
                          <span style="font-size: 11px;font-style: italic;">{{emailDt.type}}</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="payable_amount_usd" id="payable_amount_usd"
                          placeholder="Amount (USD)*" type="number" [max]="maxAmount" min="0" class="form-control">
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-select formControlName="payout_type" id="payout_type">
                        <mat-option *ngFor="let typ of payoutTypes" [value]="typ">
                            {{typ}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button mat-raised-button type="submit" class="btn  btn-danger pull-right " data-dismiss="alert"
            aria-label="Close" (click)="submitData()">Submit</button>

    </div>
</form>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
