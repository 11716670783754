import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { SiteConstants } from '../../../../config/site-constants';
import { UsersService } from '../../../core/services/users.service'
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';
import { TostService } from '../../../core/services/common/tost.service';


@Component({
  selector: 'app-create-admin-manager',
  templateUrl: './create-admin-manager.component.html',
  styleUrls: ['./create-admin-manager.component.scss']
})
export class CreateAdminManagerComponent implements OnInit {

  user: any;
  action: any = "create";
  icons = SiteConstants.icons;
  roles: any;
  gender: any;
  isUpdate = false;
  tooltips = SiteConstants.tooltips;


  imagePreview: any;
  constructor (
    public dialogRef: MatDialogRef<CreateAdminManagerComponent>,
    private gameBankService: GameBankService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private refDataService: RefDataService,
    private tostService: TostService,
    private usersService: UsersService
  ) { }

  ngOnInit (): void {
    this.usersService.getRoles().subscribe(data => {

      this.roles = data['roles'];
      this.gender = data['gender'];


    });
    if (this.data.user) {
      this.action = "update";
      this.isUpdate = true;
    } else {
      this.action = "create"
    }
    this.user = this.data.user || {
      user_name: '',
      first_name: '',
      last_name: '',
      gender: '',
      user_role: '',
      email: '',
      password: ''

    };

  }

  close () {
    this.dialogRef.close(true);
  }

  saveUser () {
    // console.log(this.user)
    this.user.action = this.action;
    const passwordRefEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    var hasErr = '';
    if (!this.user.user_name) {
      hasErr = "please enter user name";
    } else if (!this.user.password && !this.isUpdate) {
      hasErr = "Please enter password";
    } else if (!passwordRefEx.test(this.user.password) && !this.isUpdate) {
      hasErr = "Password must contain at least eight characters, including uppercase, lowercase letters, numbers and  special characters like $#@!_-.";
    } else if (!this.user.user_role) {
      hasErr = "Please select user role ";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    this.user.user_name = this.user.user_name.toLowerCase();
    this.spinner.show();

    this.usersService.saveUser
      (this.user)
      .subscribe(res => {
        this.dialogRef.close();
        this.spinner.hide()
        this.tostService.tostrFun(`User ${this.action} successfully`, 'success');
      }, () => {
        this.spinner.hide()
      });
  }

}
