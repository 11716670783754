<div class="modal-body testclass">
  <div class="row">
    <div class="col-md-12">
      <div>

        {{message}}<br>
        <b> {{subMessgae}} </b>

      </div>

    </div>

  </div>


  <div class="row" style="margin-top: 15px;">
    <div class="col-md-6">
      <button mat-raised-button (click)="save()" class="btn  btn-danger pull-right " data-dismiss="alert"
        aria-label="Close">Ok</button>


    </div>
    <div class="col-md-6">
      <button mat-button mat-raised-button (click)="close()" class="btn btn-danger pull-right " data-dismiss="alert"
        aria-label="Close">
        Cancel
      </button>

    </div>
  </div>



</div>