import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TostService } from '../../../core/services/common/tost.service'
import { FeecutService } from '../../../core/services/league/feecut.service'
@Component({
  selector: 'app-remove-fee-and-ssg-cut',
  templateUrl: './remove-fee-and-ssg-cut.component.html',
  styleUrls: ['./remove-fee-and-ssg-cut.component.scss']
})
export class RemoveFeeAndSSGCutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RemoveFeeAndSSGCutComponent>,
    private modalService: NgbModal, private feecutService: FeecutService,
    @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService) { }

  ngOnInit(): void {
  }
  removeFeeandCut() {
    const obj = { 'id': this.data.id };
    this.feecutService.removeFeeandCut(obj).subscribe(res => {
        this.tostService.tostrFun("Character remove Successfully", 'success');
        this.dialogRef.close();

      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close();
      });

  }
  close(): void {
    this.dialogRef.close(true);
    //  console.log("object", this.data);
  }
}
