import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from '../../../core/services/users.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TostService } from '../../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PayoutService } from '../../../core/services/character/payout.service';
import { RefDataService } from '../../../core/services/common/ref-data.service';
@Component({
  selector: 'app-create-skrill-payout-modal',
  templateUrl: './create-skrill-payout-modal.component.html',
  styleUrls: ['./create-skrill-payout-modal.component.scss']
})
export class CreateSkrillPayoutModalComponent implements OnInit {
  initiatePaymentForm = new FormGroup({

    payable_amount_usd: new FormControl('', []),
    communication_email: new FormControl('', []),
    payout_type: new FormControl('Skrill', []),
  });
  maxAmount: number;
  emailList = [];
  payoutTypes = ['Skrill'];
  constructor(private tostService: TostService, private spinner: NgxSpinnerService, private usersService: UsersService, private payoutService: PayoutService, public dialogRef: MatDialogRef<CreateSkrillPayoutModalComponent>, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private refDataService: RefDataService) { }

  ngOnInit(): void {
    if (this.data) {
      this.maxAmount = this.data.coin_balance_usd;
      this.getPayoutEmails();
      this.getRefData(this.data.verified_flag);
    }
  }
  submitData() {
    let hasErr = "";
    if (!this.initiatePaymentForm.value.payable_amount_usd) {
      hasErr = "Please enter payable amount";
    } else if (this.initiatePaymentForm.value.payable_amount_usd > this.maxAmount) {
      hasErr = "Please enter payable amount less then balance amount";
    } else if (!this.data.email) {
      hasErr = "Without Email ID we can not payout";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }


    const obj = {
      amount: this.initiatePaymentForm.value.payable_amount_usd,
      email: this.initiatePaymentForm.value.communication_email || this.data.email,
      user_id: this.data.id,
      first_name: this.data.first_name,
      last_name: this.data.last_name,
      payout_type: this.initiatePaymentForm.value.payout_type
    }
    this.spinner.show();
    this.usersService.createSkrillPayout(obj).subscribe(
      res => {
        this.tostService.tostrFun("Payout successful", 'success');
        this.dialogRef.close(obj.user_id);
        this.spinner.hide();
      },
      err => {
        /// this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close(obj.user_id);
        this.spinner.hide();
      });

  }
  close(): void {
    this.dialogRef.close();
  }
  getPayoutEmails() {
    this.spinner.show();
    this.payoutService.getPayoutEmailList(this.data.id).subscribe(
      res => {
        this.emailList = [].concat(res);
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      });
  }

  getRefData(verifiedFlag) {
    this.refDataService.refDataAll().subscribe(data => {
      this.payoutTypes = data['defaultRefData']
        .ALLOWED_PAYOUT_METHODS
        .filter(pm => {
          if (pm.verified_only && !Number(verifiedFlag)) {
            return false;
          }

          return true;
        })
        .map(pm => pm.method);
    });
  }

}
