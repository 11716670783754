import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Payout } from '../../../modules/core/models/api/payout';
import { PayoutService } from '../../../modules/core/services/character/payout.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SiteConstants } from '../../../config/site-constants';
import { PaginationService } from '../../core/services/common/pagination.service';
import { SearchService } from "../../core/services/common/search.service";
import { PayoutListPayoutComponent } from '../../modal/payout-list-payout/payout-list-payout.component';
import { PayoutGetTransactionComponent } from '../../modal/payout-get-transaction/payout-get-transaction.component';
import { PayoutPromocodeComponent } from '../../modal/payout-promocode/payout-promocode.component';
import { PayoutBlockComponent } from '../../modal/payout-block/payout-block.component';
import { PayoutInitiatePaymentComponent } from '../../modal/payout-initiate-payment/payout-initiate-payment.component';
@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss']
})
export class PayoutComponent implements OnInit {
  cols: any;
  payout: Payout;
  closeResult: string;
  selectedPayout: Payout;
  payoutdata: any;
  loading: boolean;
  globalSort: any = "";
  filter: any = {};
  frozenCols: any[];
  gameStatusOptions: any[];
  selectedStatus: any[];
  constPage: number = 10;
  TimeoutSearch: any;
  myForm = new FormGroup({
    status: new FormControl('', [])
  });

  constructor(private searchService: SearchService,
    private paginationService: PaginationService, private payoutService: PayoutService, public dialog: MatDialog,) {
    this.getPayoutStatus();
  }

  ngOnInit(): void {
    this.loading = true;
    this.cols = [
      { field: 'country', header: 'Country', width: '120px' },

      { field: 'user_id', header: 'User Id', width: '120px' },
      { field: 'user_name', header: 'User Name', width: '120px' },


      { field: 'first_name', header: 'Name', width: '120px' },
      { field: 'communication_email', header: 'User Email', width: '200px' },
      { field: 'address', header: 'Address', width: '120px' },
      { field: 'balance', header: 'User balance (Points)', width: '120px' },
      { field: 'payable_amount_usd', header: 'Payable amount (USD)', width: '120px' },
      { field: 'payout_status', header: 'Status', width: '120px' },

      { field: 'mobile', header: 'Mobile', width: '120px' },
      { field: 'method_to_reach', header: 'Method to reach', width: '120px' },

      { field: 'created_at', header: 'Created At', width: '120px' },
      { field: '', header: 'Action', width: '80px' }

    ];
    // this.payout = JSON.parse(this.payoutService.getPayoutData());
    // this.getPayoutListStatus();
    this.GetData({});

  }
  onRefrash(e) {
    this.GetData(this.filter);
  }
  userFilter(val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
    this.GetData(this.filter);
    }, SiteConstants.FiltertimeOut);
  }
  customSort(e) {
    let obj = {};
    if (e.field == 'first_name') {
      // e.field='first_name'
    }
    if (e.order == 1) {
      obj[e.field] = 'desc'
    } else {
      obj[e.field] = 'asc'
    }

    if (this.globalSort != e.field + " " + e.order) {

      this.filter['sort'] = obj;
      this.GetData(this.filter);
      this.globalSort = e.field + " " + e.order
      //console.log(this.filter);
    }
  }

  customPagination(event) {
    let data = this.paginationService.paginationFun(event, this.globalSort, this.constPage, this.filter)

    this.filter = data.filter;
    this.globalSort = data.globalSort;
    this.constPage = data.defaultPage;
    this.GetData(this.filter);
  }

  GetData(obj) {


    this.payoutService.getPayoutData1(obj).subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).payoutList;
      const getCharData = JSON.stringify(getData);
      this.payout = JSON.parse(getCharData);
      this.loading = false;



    });
  }
  resetMultiSelect() {
    this.myForm.get("status").setValue([]);
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
    this.GetData({});
    }, SiteConstants.FiltertimeOut);

  }
  selectdOption(e) {
    this.filter['search'] = this.myForm.value;

    this.filter.search = Object.assign({}, this.filter.search, this.myForm.value);
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
    this.GetData(this.filter);
    }, SiteConstants.FiltertimeOut);
  }

  getPayoutStatus() {
    this.payoutService.getPayoutStatus().subscribe(data => {

      const res = JSON.stringify(data);
      const resData = JSON.parse(res);
      this.gameStatusOptions = resData.map(obj => {
        let rObj = {}
        rObj['label'] = obj.text;
        rObj['value'] = obj.value;
        return rObj
      })


    });
  }

  openGetTransactionDialog(transactionData) {
    const dialogRef = this.dialog.open(PayoutGetTransactionComponent, {
      width: '720px',
      height: '300px',
      data: transactionData
    });
    //   console.log("payout transaction data", transactionData);
    dialogRef.afterClosed().subscribe(result => {
      //   console.log('The dialog was closed');
    });
  }

  openPayoutDialog(payOutData) {
    const dialogRef = this.dialog.open(PayoutListPayoutComponent, {
      width: '600px',

      data: payOutData
    });
    //  console.log("payout transaction data", payOutData);
    dialogRef.afterClosed().subscribe(result => {
      //  console.log('The dialog was closed');
    });
  }

  openCancelDialog(promoCodeData) {
    const dialogRef = this.dialog.open(PayoutPromocodeComponent, {
      width: '350px',
      data: promoCodeData
    });
    // console.log("payout transaction data", promoCodeData);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openBlockDialog(blockData) {
    const dialogRef = this.dialog.open(PayoutBlockComponent, {
      width: '350px',
      data: blockData
    });
    // console.log("payout transaction data", blockData);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openInitiatePayoutDialog(initiatePayData) {
    const dialogRef = this.dialog.open(PayoutInitiatePaymentComponent, {
      width: '350px',
      data: initiatePayData
    });
    // console.log("payout transaction data", initiatePayData);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
