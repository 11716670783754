
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AffiliateGameListComponent } from "../../game/affiliate-game-list/affiliate-game-list.component"
import { CreateAffiliateModalComponent } from "../../modal/create-affiliate-modal/create-affiliate-modal.component";
import { AffiliateServiceService } from '../../core/services/affiliate/affiliate-service.service'
import { PaginationService } from '../../core/services/common/pagination.service';
import { SearchService } from "../../core/services/common/search.service";
import { ClipboardService } from 'ngx-clipboard';
import { TostService } from '../../core/services/common/tost.service'
import { SiteConstants } from '../../../config/site-constants';


@Component({
  selector: 'app-partner-screen',
  templateUrl: './partner-screen.component.html',
  styleUrls: ['./partner-screen.component.scss']
})
export class PartnerScreenComponent implements OnInit {
  affiliateData: any[];
  totalRecords: number;
  loading: boolean;
  selectedAffiliate: any;
  cols: any;
  filter: any = {};
  constPage: number = 10;
  globalSort: any = "";
  TimeoutSearch: any;


  constructor (public dialog: MatDialog, private _clipboardService: ClipboardService, private tostService: TostService, private affiliateService: AffiliateServiceService, private searchService: SearchService, private paginationService: PaginationService,) { }

  ngOnInit (): void {
    this.loading = true;
    this.cols = [
      // { field: 'affiliate_code', header: 'Affiliate Code', width: '120px' },

      { field: 'full_name', header: 'Full Name', width: '120px' },
      { field: 'website', header: 'Website', width: '150px' },
      { field: 'email', header: 'Email', width: '150px' },

      { field: 'limit_of_rotate', header: 'Limit Of Rotate', width: '120px' },
      { field: 'no_of_impressions', header: 'No Of Impressions', width: '120px' },
      { field: 'start_date', header: 'Start Date', width: '160px' },
      { field: 'end_date', header: 'End Date', width: '160px' },

      { field: '', header: 'Action', width: '80px' },
    ];
    this.GetData({});
    //  this._clipboardService.copyResponse$.subscribe(re => {
    //   if (re.isSuccess) {
    //     this.tostService.tostrFun('url copy success!', 'success');
    //   }
    // });
  }

  callServiceToCopy (url) {
    this._clipboardService.copyFromContent(url);
    this.tostService.tostrFun('url copy success!', 'success');
  }
  onCopyFailure () {
    alert('copy fail!');
  }

  onRefrash (e) {
    setTimeout(() => {
      this.GetData(this.filter);
    }, 1500);

  }

  openAffiliateDialog (AffiliateData) {
    const dialogRef = this.dialog.open(CreateAffiliateModalComponent, {
      width: '900px',
      data: AffiliateData
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
  openGameListdDialog (AffiliateData) {
    const dialogRef = this.dialog.open(AffiliateGameListComponent, {
      width: '900px',
      data: AffiliateData
    });

    dialogRef.afterClosed().subscribe(result => {
      //  console.log('The dialog was closed');
    });
  }

  userFilter (val, field) {


    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData(this.filter);
    }, SiteConstants.FiltertimeOut);


  }

  customPagination (event) {

    if (event.sortField == "show_concent") {
      this.filter = this.filter;
    }
    else {

      let data = this.paginationService.paginationFun(event, this.globalSort, this.constPage, this.filter)

      this.filter = data.filter;
      this.globalSort = data.globalSort;
      this.constPage = data.defaultPage;
      this.GetData(this.filter);
    }
  }

  GetData (obj) {

    this.affiliateService.getPartnerAffiliateData(obj, '').subscribe(data => {
      const res = JSON.stringify(data);
      this.totalRecords = (JSON.parse(res).total);
      const getData = (JSON.parse(res)).affiliateList;
      const getAffiliateData = JSON.stringify(getData);
      this.affiliateData = JSON.parse(getAffiliateData);
      this.loading = false;
    });
  }

}


