// src/app/services/data.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators'
import { throwError, Subject } from 'rxjs';
import lodash from 'lodash';
import { TostService } from '../services/common/tost.service';
import { takeUntil } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor (private http: HttpClient, private tostService: TostService) { }
  // init the subject
  public stopRequest: Subject<void> = new Subject<void>();
  get (reqObj) {
    const observable = this.http.get(reqObj.url).pipe(takeUntil(this.stopRequest) /* once the subject emits a value, cancel the request*/);
    if (!reqObj.isHandleErr) {
      return observable;
    }
    const observableObj: Subject<Object> = new Subject();

    observable.subscribe(res => {
      observableObj.next(res);
    },
      err => {
        //TODO handle Later


      });


    return observableObj;

  }
  post (reqObj) {
    const observable = this.http.post(reqObj.url, reqObj.postObj);
    if (!reqObj.isHandleErr) {
      return observable;
    }
    const observableObj: Subject<Object> = new Subject();

    observable.subscribe(res => {
      observableObj.next(res);
    },
      err => {
        //TODO handle Later
        this.handleError(err);
      });


    return observableObj;

  }

  handleError (error: HttpErrorResponse) {
    const errors = lodash.get(error, 'error.errors');
    let err;
    if (!errors || !errors.length) {
      err = [{ message: error.statusText }];
    } else {
      err = errors;
    }
    const msg = lodash.get(err, '0.message');
    //    this.tostService.tostrFun(msg, 'error');
    return throwError(err);
  }
}
