<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Slush Fund</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">


        <p-table #dt [columns]="cols" [value]="globalCutConfig" (sortFunction)="customSort($event)" [customSort]="true"
          [(selection)]="selectedGlobalConfig" dataKey="id" styleClass="ui-table-cars" [rows]="10" [responsive]="true"
          [loading]="loading">
          <ng-template pTemplate="caption">
            <div>
              <label> Fund in $:&nbsp; &nbsp;</label>
              <input matInput [(ngModel)]="slushFund" (change)="slushFundChange($event)" type="number" min="0"
                name="min_host_payout" />
              <label>&nbsp; &nbsp; max payout $({{maxPayout}})</label>

            </div>



          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>

              <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                [ngStyle]="{'width': col.width,'text-align': col.textalign}">
                <div class="header-title">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </div>
              </th>

            </tr>

          </ng-template>


          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
              <td style="width: 70px;">{{rowData.entry_fee}}</td>
              <td style="width: 80px;">

                <input *ngIf="isEdit" matInput (change)="slushFundChange1($event)" type="number" min="0"
                  [(ngModel)]="rowData.matches" [ngStyle]="{'width':'100%'}">

                <span *ngIf="!isEdit">{{rowData.matches}}</span>


              </td>
              <td style="width: 90px;">{{rowData.host_cut.toFixed(2)}}</td>
              <td style="width: 80px;">{{rowData.calculate.toFixed(2)}}</td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" style="text-align:left">No data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

    </div>
  </div>
</div>
<div class="modal-footer">

  <button mat-raised-button type="button" class="btn btn-warning pull-right" (click)="clickEdit()">Edit</button>
  <button mat-raised-button type="button" *ngIf="is_save" (click)="save()" class=" btn btn-danger
    pull-right">Save</button>
</div>