<!-- <div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
             
                <p-table #dt [columns]="cols" styleClass="ui-table-cars" [value]="coinRate" [paginator]="true" [rows]="10" dataKey="currency" selectionMode="single" [(selection)]="selectedCoinRate">
                    <ng-template pTemplate="caption">
                        Coin Rate
                        
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngSwitch]="col.field">
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>
                                
                            </th>
                           
                            <th style="width: 8em; text-align: center;">
                                <div class="header-title">
                                   Action
                                </div>
                            </th>
                        </tr>
                       <tr>
                        <th *ngFor="let col of columns"  [ngSwitch]="col.field">
                            <input *ngSwitchCase="'currency'" pInputText type="text" placeholder="Currency" class="ui-column-filter" style="width: 80%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                            <input *ngSwitchCase="'coin_rate'" pInputText type="text" placeholder="Coin Rate" class="ui-column-filter" style="width: 80%;" (input)="dt.filter($event.target.value, col.field, 'contains')">

                        </th>
                    
                   <th>
                       
                   </th>
                       </tr>
                           
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>{{rowData.currency}}</td>

                            <td>{{rowData.coin_rate}}</td>
                            <td style="width: 300px;">
                                <button pButton type="button" (click)="open(content)" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em"></button>
                                <button pButton type="button" (click)="open(content)" class="ui-button-danger" icon="pi pi-pencil"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>


                <ng-template #content let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="row">
                                <div class="col-md-5">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Company (disabled)" disabled>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Username">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Email address" type="email">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Fist Name" type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Last Name" type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Adress" type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="City" type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Country" type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Postal Code" type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>About Me</label>
                                    <mat-form-field class="example-full-width">
                                        <textarea matInput placeholder="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."></textarea>
                                    </mat-form-field>

                                </div>
                            </div>


                        </form>
                    </div>
                    <div class="modal-footer">
                        <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="modal.close('Save click')">Save</button>
                    </div>
                    <div class="clearfix"></div>
                </ng-template>

            </div>

        </div>
    </div>
</div> -->

<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">

                <p-table styleClass="ui-table-cars" [value]="coinRate" dataKey="id" selectionMode="single"
                    [(selection)]="selectedCoinRate" editMode="row">





                    <ng-template pTemplate="header">
                        <tr>

                            <th>currency</th>

                            <th>Coin Rate</th>
                            <th style="width:8em"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="rowData">

                            <td>

                                {{rowData.currency}}

                            </td>

                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="number" min="1" [(ngModel)]="rowData.coin_rate"
                                            [ngStyle]="{'width':'100%'}">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{rowData.coin_rate}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
              <td style="width: 80px;">

                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil"
                      class="ui-button-info" (click)="onRowEditInit(rowData)"></button>
                    <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check"
                      class="ui-button-success" style="margin-right: .5em" (click)="onRowEditSave(rowData,ri)"></button>
                    <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times"
                      class="ui-button-danger" (click)="onRowEditCancel(rowData, ri)"></button>
                  </div>

                </mat-menu>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>

        </div>
    </div>
</div>