<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">Follow Details</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form>
  <div class="modal-body">

    <div class="row rowcls">
      <div class="col-md-12">
        <div class="row rowcls">
          <div class="col-md-4">
            <label>User Followers</label>
          </div>
          <div class="col-md-6 countcls">
            <label>{{followObj.followers_count}}</label>
          </div>
          <div class="col-md-2">
            <button pButton style="margin: .3em" type="button" (click)="redirectFollowerUserPage()"
              class="ui-button-raised" matTooltip="View Usr List" icon="pi pi-list"></button>
          </div>
        </div>
        <div class="row rowcls">
          <div class="col-md-4">
            <label>User Following</label>
          </div>
          <div class="col-md-6 countcls">
            <label>{{followObj.following_count}}</label>
          </div>
          <div class="col-md-2">
            <button pButton style="margin: .3em" type="button" (click)="redirectFollowingUserPage()"
              class="ui-button-raised" matTooltip="View Usr List" icon="pi pi-list"></button>
          </div>
        </div>

        <div class="row rowcls">
          <div class="col-md-4 ">
            <label>Tag Following</label>
          </div>
          <div class="col-md-6 countcls">
            <label>{{followObj.tagFollower_count}}</label>
          </div>
          <!-- <div class="col-md-2 ">
            <button pButton type="button" class="ui-button-raised" icon="fa fa-list"
              style="margin: 0 .3em .3em 0"></button>
          </div> -->
        </div>


      </div>
    </div>
  </div>

</form>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>