import { Component, OnInit, Inject } from '@angular/core';
import { PinnedQuestion } from '../../../core/models/api/pinnedQuestion';
import { PinnedQuestionService } from '../../../core/services/game/pinned-question.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-pinned-questions-view-modal',
  templateUrl: './pinned-questions-view-modal.component.html',
  styleUrls: ['./pinned-questions-view-modal.component.scss']
})
export class PinnedQuestionsViewModalComponent implements OnInit {
  
  globalSort:any ="";
  filter: any = {};
  totalRecords:number;
  loading:boolean;
  cols: any;
  closeResult: string;
  pinnedQuestion: PinnedQuestion;
  selectedPinnedQuestion: any[];
  selectedOldPinnedQuestion: any[];
  constructor(private pinnedQuestionService: PinnedQuestionService, public dialogRef: MatDialogRef<PinnedQuestionsViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log('this is the data from pinned game edit',this.data);
    this.cols = [
      { field: 'source_type', header: 'source type' },
      // { field: 'brand', header: 'Brand' },
      { field: 'question_text', header: 'Question Text' },
      { field: 'character_type', header: 'Character Type' },
      { field: 'character_id_1_name', header: 'Character 1' },
      { field: 'character_id_2_name', header: 'Character 2' }
    ];
    this.GetData({});
    // this.pinnedQuestion = JSON.parse(this.pinnedQuestionService.getPinnedQuestionData());
    this.selectedOldPinnedQuestion = this.data.data;
    console.log('hello wolrd',this.data.data);
  }
  close(): void {
    this.dialogRef.close();
  }
  submit(data) {
    this.dialogRef.close(data);
  }

  GetData(obj) {
    
    if(this.filter.search == undefined){
      let searchObj = {};
      searchObj['character_type']=this.data.formval.game_category.value;
      obj.search = searchObj;
    }

    this.pinnedQuestionService.getPinnedQuestionData1(obj).subscribe(data => {
      const res = JSON.stringify(data);
      this.totalRecords = (JSON.parse(res).total);
      const getData = (JSON.parse(res)).questionList;
      const getCharData = JSON.stringify(getData);
      this.pinnedQuestion = JSON.parse(getCharData);
      this.loading = false;
     

    });
  }


  customPagination(event) {
    // this.loading = true;
    if(event){
      let pagination ={}
      pagination['limit']=event.rows;
      if(event.first){
      pagination['page']=Math.round(event.first/10)+1;
      }else {
      pagination['page']=1;

      }
     this.filter['pagination'] = pagination;
     this.GetData(this.filter);
    //  this.loading = false;
    }

    let obj={} ;
   
    if(event.sortField){

    
  if(event.sortOrder==1){
  obj[event.sortField]='desc'
  } else{
    obj[event.sortField]='asc'
  }
}
  if(this.globalSort!=event.sortField+" "+event.sortOrder){

   this.filter['sort'] =obj;
   this.GetData( this.filter);
   this.globalSort=event.sortField+" "+event.sortOrder
    console.log(this.filter);
  }
   
  }

  userFilter(val, field) {
    let obj ={};
      
      if (this.filter.search) {
        const searchObj = {};
        searchObj[field]=val;
        this.filter.search = Object.assign({}, this.filter.search,searchObj);
  
      } else {
        obj[field] =val;
        this.filter.search= obj;
      }
      if (this.filter.search) {
  
      }
         setTimeout(() => {
          this.GetData( this.filter);
          
          }, 2000);
    }

}
