<mat-form-field class="example-chip-list">
  <mat-label>Favorite tags</mat-label>
  <mat-chip-list #chipList aria-label="tag selection">
    <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable" (removed)="remove(tag)">
      {{tag}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input placeholder="New tag..." #tagInput [formControl]="tagCtrl" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" ng (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredtags | async" [value]="tag">
      {{tag}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>