import { Component, OnInit, Inject } from '@angular/core';
import { CustomQuestion } from '../../../core/models/api/customQuestion';
import { CustomQuestionService } from '../../../core/services/game/custom-question.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-questions-view-modal',
  templateUrl: './custom-questions-view-modal.component.html',
  styleUrls: ['./custom-questions-view-modal.component.scss']
})
export class CustomQuestionsViewModalComponent implements OnInit {
  totalRecords:number;
  globalSort:any ="";
  filter: any = {};
  frozenCols: any[];
  loading: boolean;
  cols: any;
  closeResult: string;
  customQuestion: CustomQuestion;
  selectedCustomQuestion: any[];
  selectedOldCustomQuestion: any[];
  constructor(private customQuestionService: CustomQuestionService, public dialogRef: MatDialogRef<CustomQuestionsViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'source_type', header: 'source type' },
      // { field: 'brand', header: 'Brand' },
      { field: 'question_text', header: 'Question Text' },
      { field: 'character_type', header: 'Character Type' },
      { field: 'character_id_1_name', header: 'Character 1' },
      { field: 'character_id_2_name', header: 'Character 2' }
    ];
    // this.customQuestion = JSON.parse(this.customQuestionService.getCustomQuestionData());
    this.selectedOldCustomQuestion = this.data.data;
    // console.log("LOL Data",this.data.data);
    this.GetData({});
  }

  GetData(obj) {
    if(!this.filter.sort){
      let sortObj = {};
      sortObj['id']='desc';
      obj.sort = sortObj;
    }
    this.customQuestionService.getCustomQuestionData1ForModal(obj).subscribe(data => {
      const res = JSON.stringify(data);
      this.totalRecords = (JSON.parse(res).total);
      const getData = (JSON.parse(res)).questionList;
      const getCharData = JSON.stringify(getData);
      this.customQuestion = JSON.parse(getCharData);
      this.loading = false;
     

    });
  }

  customPagination(event) {
    // this.loading = true;
    if(event){
      let pagination ={}
      pagination['limit']=event.rows;
      if(event.first){
      pagination['page']=Math.round(event.first/10)+1;
      }else {
      pagination['page']=1;

      }
     this.filter['pagination'] = pagination;
     this.GetData(this.filter);
    //  this.loading = false;
    }

    let obj={} ;
   
    if(event.sortField){

    
  if(event.sortOrder==1){
  obj[event.sortField]='desc'
  } else{
    obj[event.sortField]='asc'
  }
}
  if(this.globalSort!=event.sortField+" "+event.sortOrder){

   this.filter['sort'] =obj;
   this.GetData( this.filter);
   this.globalSort=event.sortField+" "+event.sortOrder
    console.log(this.filter);
  }
   
}

// userFilter(val, field) {
    
//   var obj ={};
//   obj[field]=val;

  
//    this.filter['search'] =  obj;
//    setTimeout(() => {
//    this.GetData( this.filter);
        
//      }, 2000);
//  }
userFilter(val, field) {
  let obj ={};
    if (field == 'first_name') {
      field = 'name'
    }
    if (this.filter.search) {
      const searchObj = {};
      searchObj[field]=val;
      this.filter.search = Object.assign({}, this.filter.search,searchObj);

    } else {
      obj[field] =val;
      this.filter.search= obj;
    }
    if (this.filter.search) {

    }
       setTimeout(() => {
        this.GetData( this.filter);
        
        }, 2000);
  }
  
  close(): void {
    this.dialogRef.close();
  }
  submit(data) {
    this.dialogRef.close(data);
  }
}
