import { Component, OnInit, Inject } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { CharacterService } from '../../../core/services/character.service'
import { SelectItem } from 'primeng/api';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-custom-questions-edit-modal',
  templateUrl: './custom-questions-edit-modal.component.html',
  styleUrls: ['./custom-questions-edit-modal.component.scss']
})
export class CustomQuestionsEditModalComponent implements OnInit {
  isUpdate: Boolean = true;
  characterType: SelectItem;
  sourceType: SelectItem[];
  selectedCharacterType: any;
  characterData: SelectItem[];
  searchKeyword: any = {};
  searchResult: any[];
  characterTypeValue: any;

  cQuestionForm = new FormGroup({
    question_text: new FormControl('', [Validators.required]),
    source_type: new FormControl('', []),
    character_type: new FormControl('', []),
    character_id_1_name: new FormControl('', []),
    character_id_2_name: new FormControl('', [])
  });
  constructor(public dialogRef: MatDialogRef<CustomQuestionsEditModalComponent>,
    private modalService: NgbModal, private refDataService: RefDataService,
    private characterService: CharacterService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // console.log('form values',this.cQuestionForm.value)
    this.getCharacterType();
    // this.characterType = JSON.parse(this.refDataService.getRefData()).characterTypes;

    this.sourceType = JSON.parse(this.refDataService.getRefData()).attributes;
    // this.characterData = JSON.parse(this.characterService.getCharacterData()); 
    this.characterService.getCharacterData().subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).list;
      const getCharData = JSON.stringify(getData);
      this.characterData = JSON.parse(getCharData);

    });
    // console.log(this.characterData);
    if (this.isUpdate === true) {
      this.isUpdate = true;
    } else {
      this.isUpdate = false;
    }
    if (this.data) {
      this.cQuestionForm.patchValue({
        question_text: this.data.question_text,
        source_type: this.data.source_type,
        character_id_1_name: this.data.character_id_1_name,
        character_type: this.data.character_type,
        character_id_2_name: this.data.character_id_2_name
      });

    } else {
      this.cQuestionForm.patchValue({
        question_text: '',
        source_type: '',
        character_type: '',
        character_id_1_name: '',
        character_id_2_name: ''
      });
    }
  }
  close(): void {
    this.dialogRef.close();
  }
  submitData() {
    //  console.log(this.cQuestionForm.value);
  }
  onChangeCharacter1(e) {
    const data = this.cQuestionForm.get('character_id_2_name');
    // console.log(data)
  }
  onChangeCharacter2(e) {
    const data = this.cQuestionForm.get('character_id_1_name');
    // console.log(data)
  }

  selectCharacterType(e) {
    var obj = {};
    this.characterTypeValue = e.value.value;

    if (this.searchKeyword.search) {
      const searchObj = {};
      searchObj['character_type'] = this.characterTypeValue;
      this.searchKeyword.search = Object.assign({}, this.searchKeyword.search, searchObj);

    } else {
      obj['character_type'] = this.characterTypeValue;
      this.searchKeyword.search = obj;
    }

    // console.log('event val',e.value.value)
    // this.getCharacterType();
    this.search(this.searchKeyword);
  }

  get f() {
    return this.cQuestionForm.controls;
  }

  getCharacterType() {
    this.refDataService.getRefData1().subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).characterTypes;
      const getCharData = JSON.stringify(getData);
      this.characterType = JSON.parse(getCharData);
      // this.characterType = JSON.parse(this.refDataService.getRefData()).characterTypes;
      console.log("this is char data", this.characterType);
    });
  }

  search(e) {
    var obj = {};
    if (this.searchKeyword.search) {
      const searchObj = {};
      searchObj['character_name'] = e.query;
      this.searchKeyword.search = Object.assign({}, this.searchKeyword.search, searchObj);

    } else {
      obj['character_name'] = e.query;
      this.searchKeyword.search = obj;
    }

    console.log("country", e);
    // this.searchKeyword["character_name"]=e.query;
    //  console.log(this.searchKeyword);
    this.refDataService.seacrhCharacterData(this.searchKeyword).subscribe(data => {
      console.log("search", data)
      const res = JSON.stringify(data);
      const getSearchData = (JSON.parse(res)).list;
      const getuserSearchData = JSON.stringify(getSearchData);
      this.searchResult = JSON.parse(getuserSearchData);
      //  console.log("search resilt", this.searchResult);
    });
    // this.filteredBrands = [];
    // for(let i = 0; i < this.searchResult.length; i++) {
    //     let brand = this.searchResult[i];
    //         this.filteredBrands.push({'name':this.searchResult[i].name});
    //         console.log("filter",this.filteredBrands);
    //      }

  }
}
