<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">

                <p-table #dt [columns]="cols" styleClass="ui-table-cars" [loading]="loading"
                    (sortFunction)="customSort($event)" [customSort]="true" [value]="leagueCategory" [paginator]="true"
                    [rows]="10" dataKey="level_num" selectionMode="single" [(selection)]="selectedLeagueCategory">
                    <ng-template pTemplate="caption">
                        <app-action-button [data]="null" [width]="'650'" [modaleId]="'CreateLeagueCategoryComponent'">

                        </app-action-button>

                        <!-- <button type="button" class="btn btn-primary"
                            (click)="openCreateLeagueCategoryDialog(null)">CREATE LEAGUE CATEGORY</button> -->

                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngSwitch]="col.field">
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>

                            </th>


                        </tr>

                        <th *ngFor="let col of columns" [ngSwitch]="col.field">
                            <input *ngSwitchCase="'category_name'" pInputText type="text" placeholder="Category Name"
                                class="ui-column-filter" style="width: 100%;"
                                (keyup)="userFilter($event.target.value, col.field)">
                            <input *ngSwitchCase="'category_code'" pInputText type="text" placeholder="Category Code"
                                class="ui-column-filter" style="width: 100%;"
                                (keyup)="userFilter($event.target.value, col.field)">
                            <!-- <input *ngSwitchCase="'game_type'" pInputText type="text" placeholder="Game Type" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'active'" pInputText type="text" placeholder="active" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                                <input *ngSwitchCase="'character_types'" pInputText type="text" placeholder="Character Types" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'order_index'" pInputText type="text" placeholder="Order Index" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'is_auto_game'" pInputText type="text" placeholder="Is Auto Game" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                                <input *ngSwitchCase="'show_on_discover'" pInputText type="text" placeholder="Show On Discover" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)"> -->

                        </th>

                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>{{rowData.category_name}}</td>

                            <td>{{rowData.category_code}}</td>
                            <td>{{rowData.game_type}}</td>
                            <td>{{rowData.active}}</td>

                            <td>{{rowData.character_types}}</td>
                            <td>{{rowData.order_index}}</td>
                            <td>{{rowData.is_auto_game}}</td>
                            <td>{{rowData.show_on_discover}}</td>
                            <td style="width: 300px;">
                                <!-- <button pButton type="button" (click)="openCreateLeagueCategoryDialog(rowData)"
                                    class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em"></button> -->
                                <app-action-icon [data]="rowData" [width]="'1000px'" [height]="'600px'"
                                    [modaleId]="'CreateLeagueCategoryComponent'" icon="edit" tooltip="update">
                                </app-action-icon>
                                <button pButton type="button" class="ui-button-danger" icon="pi pi-pencil"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                <!-- <p-table #dt [columns]="cols" styleClass="ui-table-cars" [value]="leagueCategory" [paginator]="true" [rows]="10" dataKey="level_num" selectionMode="single" [(selection)]="selectedLeagueCategory">
                    <ng-template pTemplate="caption">
                        League Category
                        <div>
                           <button  type="button" class="btn btn-link" style="color:#3F406A;"  (click)="openCreateLeagueCategoryDialog(null)" >CREATE LEAGUE CATEGORY</button>
                        </div>
                        <div class="ui-table-globalfilter-container">
                            <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:250px;">
                        </div> 
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngSwitch]="col.field">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                                <input *ngSwitchCase="'category_name'" pInputText type="text" placeholder="Category Name" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                                <input *ngSwitchCase="'category_code'" pInputText type="text" placeholder="Category Code" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                                <input *ngSwitchCase="'game_type'" pInputText type="text" placeholder="Game Type" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                                <input *ngSwitchCase="'active'" pInputText type="text" placeholder="active" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">

                                <input *ngSwitchCase="'character_types'" pInputText type="text" placeholder="Character Types" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                                <input *ngSwitchCase="'order_index'" pInputText type="text" placeholder="Order Index" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                                <input *ngSwitchCase="'is_auto_game'" pInputText type="text" placeholder="Is Auto Game" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                                <input *ngSwitchCase="'show_on_discover'" pInputText type="text" placeholder="Show On Discover" class="ui-column-filter" style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                            </th>
                            <th style="width: 8em; text-align: center;">
                                <button pButton type="button" icon="pi pi-cog"></button>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>{{rowData.category_name}}</td>

                            <td>{{rowData.category_code}}</td>
                            <td>{{rowData.game_type}}</td>
                            <td>{{rowData.active}}</td>

                            <td>{{rowData.character_types}}</td>
                            <td>{{rowData.order_index}}</td>
                            <td>{{rowData.is_auto_game}}</td>
                            <td>{{rowData.show_on_discover}}</td>




                            <td style="width: 300px;">
                                <button pButton type="button" (click)="openCreateLeagueCategoryDialog(rowData)" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em"></button>
                                <button pButton type="button" (click)="openCreateLeagueCategoryDialog(rowData)" class="ui-button-danger" icon="pi pi-pencil"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table> -->


                <ng-template #content let-modal>
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="row">
                                <div class="col-md-5">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Company (disabled)" disabled>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Username">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Email address" type="email">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Fist Name" type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Last Name" type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Adress" type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="City" type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Country" type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="example-full-width">
                                        <input matInput placeholder="Postal Code" type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label>About Me</label>
                                    <mat-form-field class="example-full-width">
                                        <textarea matInput
                                            placeholder="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."></textarea>
                                    </mat-form-field>

                                </div>
                            </div>


                        </form>
                    </div>
                    <div class="modal-footer">
                        <button mat-raised-button type="submit" class="btn btn-danger pull-right"
                            (click)="modal.close('Save click')">Save</button>
                    </div>
                    <div class="clearfix"></div>
                </ng-template>

            </div>

        </div>
    </div>
</div>