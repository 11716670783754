<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Promocode</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<mat-dialog-content layout-xs>
    <div class="mat-dialog-content" style="height:290px;overflow: auto;">

        <form [formGroup]="promocodeForm" (ngSubmit)="submitData()" style="padding-top: 10px;">

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input matInput id="invite_code" formControlName="invite_code" placeholder="Promocode"
                            type="text">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <button mat-raised-button class="btn btn-danger pull-right">ADD PROMOCODE</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">

                    <label>Added Promocode</label>
                </div>
            </div>

            <mat-list>

                <mat-list-item>
                    <div class="col-md-4">
                        <label>1.&nbsp;{{defaultReferralCode}}</label>
                    </div>
                    <div class="col-md-4">
                        <span class="default_circle">Default </span>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>

                <mat-list-item *ngFor="let code of referral_codes; index as i">
                    <mat-divider></mat-divider>
                    <div class="col-md-4">
                        <label>{{i + 2 }}.&nbsp;{{code.invite_code}}</label>
                    </div>
                    <button mat-button type="button" (click)="deleteInviteCode(code.id)" class="close"
                        data-dismiss="alert" aria-label="Close">
                        <i class="material-icons">close</i>
                    </button>
                </mat-list-item>

            </mat-list>


        </form>
    </div>
</mat-dialog-content>
<div class="modal-footer">
    <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="close()">Close</button>
</div>