<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ user && user.id ? 'Update' : 'Create' }} user</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <form (ngSubmit)="saveUser()">
      <div class="modal-body">
        <div class="row">
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>First Name</mat-label>
                <input matInput [(ngModel)]="user.first_name" name="first_name" id="first_name" class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Last Name</mat-label>
                <input matInput name="last_name" [(ngModel)]="user.last_name" id="last_name" class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>User Name</mat-label>
                <input matInput name="user_name" [(ngModel)]="user.user_name" id="user_name"
                  class="form-control lowercase">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Email</mat-label>
                <input matInput name="email" [(ngModel)]="user.email" type="text" id="email" class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Password</mat-label>
                <input matInput name="password" [disabled]="isUpdate" [(ngModel)]="user.password" type="password"
                  id="password" class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-4">
            <div class="form-group">
              <div class="p-field-checkbox">

                <p-checkbox [(ngModel)]="user.is_blocked" name="is_blocked" id="is_blocked" binary="true"
                  inputId="binary">
                </p-checkbox>
                <label for="binary"> &nbsp;&nbsp;<b>Blocked</b></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field appearance="fill">
                <mat-label>Roles</mat-label>
                <mat-select [(ngModel)]="user.user_role" name="role">
                  <mat-option *ngFor="let role of roles" [value]="role.value">
                    {{role.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <div class="col col-4">
            <div class="form-group">

              <mat-form-field appearance="fill">
                <mat-label>Gender</mat-label>
                <mat-select [(ngModel)]="user.gender" name="gender">
                  <mat-option *ngFor="let g of gender" [value]="g.value">
                    {{g.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

        </div>


      </div>
      <div class="modal-footer">
        <a mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</a>
        <button mat-raised-button type="submit" class="btn btn-danger pull-right">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>