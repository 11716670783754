import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AffiliateServiceService } from '../../../core/services/affiliate/affiliate-service.service'
import { TostService } from '../../../core/services/common/tost.service';
import { ConfirmApiKeyUpdateComponent } from '../../confirm-api-key-update/confirm-api-key-update.component'
@Component({
  selector: 'app-affiliate-api-key-screen',
  templateUrl: './affiliate-api-key-screen.component.html',
  styleUrls: ['./affiliate-api-key-screen.component.scss']
})
export class AffiliateApiKeyScreenComponent implements OnInit {

  editDomains: any[] = [];
  isReadOnly: boolean = true;
  apikey: any;
  ApiKeyData: any = {
    editDomains: []
  }
  constructor (
    public dialogRef: MatDialogRef<AffiliateApiKeyScreenComponent>, private modalService: NgbModal, private affiliateServiceService: AffiliateServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private dialog: MatDialog) { }

  ngOnInit (): void {
    this.GetData(this.data.id)

  }

  submitData () {
    let obj = {};
    this.ApiKeyData.allowed_domains = this.ApiKeyData.editDomains.map(function (domain) {
      return domain.text;
    });

    this.affiliateServiceService.updateAffiliateApiKey(this.ApiKeyData).subscribe(
      res => {
        // this.GetData(this.data.id)
        // this.tostService.tostrFun("PromoCode Deleted  Successfully", 'success');
        this.dialogRef.close();
      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
      }
      //  ,() => console.log('HTTP request completed.')
    )

  }

  readonlyFun () {

    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 70; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.ApiKeyData.api_key = text;

    this.isReadOnly = false;
  }

  openDialog (ev): void {



    ev.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmApiKeyUpdateComponent, {
      width: '350px',
      data: { rowData: this.ApiKeyData },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.rowData && result.save) {
        this.submitData();
      } else {
        return;
      }

    });


  }


  removeDomain (e) {
    this.ApiKeyData.editDomains.splice(e, 1);
  }
  addDomain () {
    this.ApiKeyData.editDomains.push({});

  }

  GetData (id) {

    // this.usersService.getUsersById(obj).subscribe(data => {
    //   const res = JSON.stringify(data);
    //   this.referral_codes = (JSON.parse(res)).user_invite_codes;

    // });
    this.affiliateServiceService.getAffiliateApiKey(id).subscribe(
      data => {
        const res = JSON.stringify(data);
        var editDomains = [];
        let ApiKey = (JSON.parse(res));
        if (ApiKey.allowed_domains) {
          editDomains = ApiKey.allowed_domains.split(',').map(function (domain) { return { text: domain } });
        } else {
          ApiKey.allowed_domains = [];
        }



        ApiKey.editDomains = editDomains;
        this.ApiKeyData = ApiKey;




        this.apikey = this.ApiKeyData.api_key;

      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        // this.dialogRef.close();
      });



  }





  close (): void {
    this.dialogRef.close(true);

  }

}
