import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../modal/modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CharctersRoutingModule } from './charcters-routing.module';
// import {  MatDialogModule} from '@angular/material/dialog';
import { CharacterComponent } from './character/character.component';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext'
import { MatButtonModule } from '@angular/material/button';
import { MultiSelectModule } from 'primeng/multiselect'
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { ImagesComponent } from './images/images.component';
import { SourceTypeComponent } from './sourceType/source-type/source-type.component';
import { ActionComponentModule } from '../action-component/action-component.module'
import { PayoutComponent } from './Payout/payout.component';
import { MatCardModule } from '@angular/material/card';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { InstantPayoutComponent } from './instant-payout/instant-payout.component';
@NgModule({
  declarations: [CharacterComponent, ImagesComponent, SourceTypeComponent, PayoutComponent, InstantPayoutComponent],
  imports: [
    CommonModule,
    CharctersRoutingModule,
    MatButtonModule,
    // MatDialogModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    InputTextModule,
    MatSelectModule,
    MatTooltipModule,
    HttpClientModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatCardModule,
    MatCheckboxModule,
    ModalModule,
    CalendarModule,
    InfiniteScrollModule,
    NgxSpinnerModule, ActionComponentModule

  ],
  exports: [CharacterComponent, ImagesComponent, SourceTypeComponent]
})
export class CharctersModule { }
