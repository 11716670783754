<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">

                <p-table #dt [columns]="cols" styleClass="ui-table-cars" [responsive]="true" [loading]="loading"
                    [totalRecords]="totalRecords" [lazy]="true" (onLazyLoad)="customPagination($event)"
                    [rowsPerPageOptions]="[5,10]" (sortFunction)="customSort($event)" [customSort]="true"
                    [value]="notificationData" [paginator]="true" [rows]="10" dataKey="level_num" selectionMode="single"
                    [(selection)]="selectedNotification">
                    <ng-template pTemplate="caption">

                        <app-action-button (onRefrashed)="onRefrash($event)" [data]="null" [width]="'650'"
                            [modaleId]="'CreateEditNotificationComponent'" [height]="'550px'">

                        </app-action-button>

                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngSwitch]="col.field">
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>

                            </th>

                            <th style="width: 8em; text-align: center;">
                                Action
                            </th>
                        </tr>

                        <th *ngFor="let col of columns" [ngSwitch]="col.field">

              <input *ngSwitchCase="'title'" pInputText type="text" placeholder="Title" class="ui-column-filter"
                style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
              <input *ngSwitchCase="'message'" pInputText type="text" placeholder="Message" class="ui-column-filter"
                style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                        </th>

                        <th>

                        </th>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>{{rowData.title}}</td>

                            <td>{{rowData.message}}</td>
                            <td>{{rowData.start_date | date:'medium'}}</td>
                            <td>{{rowData.end_date | date:'medium'}}</td>

                            <td>{{rowData.is_active}}</td>
                            <td>{{rowData.can_close}}</td>
                            <td>{{rowData.alert_type}}</td>

              <td style="text-align: center;width: 80px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <app-action-icon [data]="rowData" [width]="650" [modaleId]="'CreateEditNotificationComponent'"
                      [height]="'550px'" icon="edit" tooltip="update" (onRefrashed)="onRefrash($event)">
                    </app-action-icon>

                    <app-action-icon [data]="rowData" [width]="'350px'" [modaleId]="'RemoveNotificationModalComponent'"
                      icon="remove" tooltip="remove" (onRefrashed)="onRefrash($event)"> </app-action-icon>
                  </div>
                </mat-menu>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>


            </div>

        </div>
    </div>
</div>