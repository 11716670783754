import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../config';
import { HttpHeaders } from '@angular/common/http'

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/json',
//     'Authorization': 'my-auth-token',
//     'CSRF-Token':`${this.token}`
//   })
// };
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  token:''
  constructor(private httpClient: HttpClient) { }

  getSettingsData() {
    const url = Endpoints.api.settingsData.settings;
      const observable = this.httpClient.get(url) 
   
      return observable; 
    }

    createUpdateSetting(formValues,action,tokenval){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'my-auth-token',
          'csrf-token':`${tokenval}`
        })
      };
        // this.token = tokenval;
      if(action == "create") {
        const url = Endpoints.api.settingsData.updateSettings;
        const observable = this.httpClient.post(url,formValues,httpOptions);
        return observable;
      } else if(action == "update"){
        const url = Endpoints.api.settingsData.updateSettings;
        const observable = this.httpClient.post(url,formValues,httpOptions);
        return observable;
      }
      
    }
}
