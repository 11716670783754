import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SiteConstants } from '../../../config//site-constants'
import { UserPromocodeViewComponent } from '../../modal/user/user-promocode-view/user-promocode-view.component';
import { TransactionModalComponent } from '../../modal/user/transaction-modal/transaction-modal.component'
import { PayoutModalComponent } from '../../modal/user/payout-modal/payout-modal.component';
import { BlockUserModalComponent } from '../../modal/user/block-user-modal/block-user-modal.component';
import { SourceTypeEditModalComponent } from '../../modal/source-type-edit-modal/source-type-edit-modal.component'
import { CharacterEditModalComponent } from '../../modal/character-edit-modal/character-edit-modal.component';
import { SelfgameDisputeModalComponent } from '../../modal/selfgame-dispute-modal/selfgame-dispute-modal.component'
import { SelfgameReportedModalComponent } from '../../modal/selfgame-reported-modal/selfgame-reported-modal.component';
import { SelfgameGamePerformanceModalComponent } from '../../modal/selfgame-game-performance-modal/selfgame-game-performance-modal.component'
import { SettingPriceOptionComponent } from '../../modal/setting-price-option/setting-price-option.component';
import { LevelEditModalComponent } from '../../modal/level-edit-modal/level-edit-modal.component';
import { CreateLeagueCategoryComponent } from '../../modal/create-league-category/create-league-category.component'
import { EditPromoCodeModalComponent } from '../../modal/edit-promo-code-modal/edit-promo-code-modal.component';
import { CommonDeleteModalComponent } from '../../modal/common-delete-modal/common-delete-modal/common-delete-modal.component'
import { AffiliateGameListComponent } from "../../game/affiliate-game-list/affiliate-game-list.component"
import { CreateAffiliateModalComponent } from "../../modal/create-affiliate-modal/create-affiliate-modal.component";
import { PayoutListPayoutComponent } from '../../modal/payout-list-payout/payout-list-payout.component';
import { PayoutGetTransactionComponent } from '../../modal/payout-get-transaction/payout-get-transaction.component';
import { PayoutPromocodeComponent } from '../../modal/payout-promocode/payout-promocode.component';
import { PayoutBlockComponent } from '../../modal/payout-block/payout-block.component';
import { PayoutInitiatePaymentComponent } from '../../modal/payout-initiate-payment/payout-initiate-payment.component';
import { GameBankCreateEditModalComponent } from '../../modal/game-Bank/game-bank-create-edit-modal/game-bank-create-edit-modal.component'
import { RemoveCharacterModalComponent } from '../../modal/characters/remove-character-modal/remove-character-modal.component'
import { CreateEditNotificationComponent } from '../../modal/create-edit-notification/create-edit-notification.component';
import { RemoveFeeAndSSGCutComponent } from '../../modal/Fees-and-SSG-Cut/remove-fee-and-ssg-cut/remove-fee-and-ssg-cut.component';
import { CreateEditFeecutModalComponent } from '../../modal/create-edit-feecut-modal/create-edit-feecut-modal.component';
import { SettingDeleteModalComponent } from '../../modal/setting-delete-modal/setting-delete-modal.component'
import { WinnerDeclarationModalComponent } from '../../modal/game-Bank/winner-declaration-modal/winner-declaration-modal.component';
import { SummernoteModalComponent } from '../../modal/game-Bank/summernote-modal/summernote-modal.component';
import { RemoveNotificationModalComponent } from '../../modal/remove-notification-modal/remove-notification-modal.component'
import { SelfgameCancelModalComponent } from '../../modal/selfgame-cancel-modal/selfgame-cancel-modal.component'
import { IgnoreReportComponent } from '../../modal/ignore-report/ignore-report.component';
import { IgnoreDisputeComponent } from '../../modal/ignore-dispute/ignore-dispute.component';
import { SwitchWinnerComponent } from '../../modal/switch-winner/switch-winner.component';
import { AffiliateApiKeyScreenComponent } from '../../modal/affiliate/affiliate-api-key-screen/affiliate-api-key-screen.component'
import { CreateSkrillPayoutModalComponent } from '../../modal/user/create-skrill-payout-modal/create-skrill-payout-modal.component'
import { FollowUserModalComponent } from '../../modal/user/follow-user-modal/follow-user-modal.component'
import { TagModalComponent } from '../../modal/self-game/tag-modal/tag-modal.component';
import { GameConfigModalComponent } from '../../modal/game-Bank/game-config-modal/game-config-modal.component';
import { TagsEditModalComponent } from '../../modal/game-Bank/tags-edit-modal/tags-edit-modal.component';
import { TagsDeleteModalComponent } from '../../modal/game-Bank/tags-delete-modal/tags-delete-modal.component';
import { SettingsModalComponent } from '../../modal/user/settings-modal/settings-modal.component';
import { PromotionBannersComponent } from '../../modal/promotion-banners/promotion-banners.component';
import { RemovePromotionBannerModalComponent } from "../../modal/remove-promotion-banner-modal/remove-promotion-banner-modal.component";
import { SelfGameWinnerDeclarationComponent } from '../../modal/self-game/self-game-winner-declaration/self-game-winner-declaration.component'
import { ImpersonateUserModalComponent } from "../../modal/user/impersonate-user-modal/impersonate-user-modal.component";
import { CategoryTagEditComponent } from "../../modal/game-Bank/category-tag-edit/category-tag-edit.component";
import { RemoveCategoryTagModalComponent } from "../../modal/game-Bank/remove-category-tag-modal/remove-category-tag-modal.component";
import { TrendingTagEditModalComponent } from "../../modal/game-Bank/trending-tag-edit-modal/trending-tag-edit-modal.component"
import { RemoveTrendingTagModalComponent } from "../../modal/game-Bank/remove-trending-tag-modal/remove-trending-tag-modal.component"
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NotificationTypeCreateEditModalComponent } from "../../modal/notification-type-create-edit-modal/notification-type-create-edit-modal.component";
import { BlacklistCountryStateEditComponent } from "../../modal/user/blacklist-country-state-edit/blacklist-country-state-edit.component";
import { SuperTagEditModalComponent } from "../../modal/game-Bank/super-tag-edit-modal/super-tag-edit-modal.component";
import { RemoveSuperTagModalComponent } from "../../modal/game-Bank/remove-super-tag-modal/remove-super-tag-modal.component";
import { UserPendingModalComponent } from '../../modal/user/user-pending-modal/user-pending-modal.component';
import { UserWalletSummeryReportModalComponent } from '../../modal/user/user-wallet-summery-report-modal/user-wallet-summery-report-modal.component';
import { GlobalCutConfigModalComponent } from '../../modal/game/global-cut-config-modal/global-cut-config-modal.component';
import { UserGameCutConfigComponent } from '../../modal/game/user-game-cut-config/user-game-cut-config.component';
import { UserTransactionModalComponent } from '../../modal/user/user-transaction-modal/user-transaction-modal.component';
import { PartnerCreateUpdateModalComponent } from '../../modal/partner-create-update-modal/partner-create-update-modal.component'
import { AffAnalyticsComponent } from '../../modal/aff-analytics/aff-analytics.component';
import { CreateAdminManagerComponent } from '../../modal/user/create-admin-manager/create-admin-manager.component';
import { SlushFundComponent } from '../../modal/slush-fund/slush-fund.component';
@Component({
  selector: 'app-action-icon',
  templateUrl: './action-icon.component.html',
  styleUrls: ['./action-icon.component.scss']
})
export class ActionIconComponent implements OnInit {

  constructor (public dialog: MatDialog, private authenticationService: AuthenticationService,
    private modalService: NgbModal) { }
  @Input() data: any[];
  @Input() height: string;
  @Input() width: string;
  @Input() modaleId: string;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() visibleIcons: string;
  @Output() onRefrashed = new EventEmitter<any>();
  dateFilterObj: any = {};
  componet: any = {};
  isAdmin: boolean = true;
  components: any = {
    "CharacterEditModalComponent": CharacterEditModalComponent,
    "SourceTypeEditModalComponent": SourceTypeEditModalComponent,
    "UserPromocodeViewComponent": UserPromocodeViewComponent,
    "TransactionModalComponent": TransactionModalComponent,
    "PayoutModalComponent": PayoutModalComponent,
    "BlockUserModalComponent": BlockUserModalComponent,
    "SelfgameDisputeModalComponent": SelfgameDisputeModalComponent,
    "SelfgameReportedModalComponent": SelfgameReportedModalComponent,
    "SelfgameGamePerformanceModalComponent": SelfgameGamePerformanceModalComponent,
    "SettingPriceOptionComponent": SettingPriceOptionComponent,
    "LevelEditModalComponent": LevelEditModalComponent,
    "CreateLeagueCategoryComponent": CreateLeagueCategoryComponent,
    "EditPromoCodeModalComponent": EditPromoCodeModalComponent,
    "CommonDeleteModalComponent": CommonDeleteModalComponent,
    "AffiliateGameListComponent": AffiliateGameListComponent,
    "CreateAffiliateModalComponent": CreateAffiliateModalComponent,
    "PayoutListPayoutComponent": PayoutListPayoutComponent,
    "PayoutGetTransactionComponent": PayoutGetTransactionComponent,
    "PayoutPromocodeComponent": PayoutPromocodeComponent,
    "PayoutBlockComponent": PayoutBlockComponent,
    "PayoutInitiatePaymentComponent": PayoutInitiatePaymentComponent,
    "RemoveCharacterModalComponent": RemoveCharacterModalComponent,
    "GameBankCreateEditModalComponent": GameBankCreateEditModalComponent,
    "CreateEditNotificationComponent": CreateEditNotificationComponent,
    "RemoveFeeAndSSGCutComponent": RemoveFeeAndSSGCutComponent,
    "CreateEditFeecutModalComponent": CreateEditFeecutModalComponent,
    "SettingDeleteModalComponent": SettingDeleteModalComponent,
    "WinnerDeclarationModalComponent": WinnerDeclarationModalComponent,
    "SummernoteModalComponent": SummernoteModalComponent,
    "RemoveNotificationModalComponent": RemoveNotificationModalComponent,
    "SelfgameCancelModalComponent": SelfgameCancelModalComponent,
    "IgnoreReportComponent": IgnoreReportComponent,
    "IgnoreDisputeComponent": IgnoreDisputeComponent,
    "TagModalComponent": TagModalComponent,
    "SwitchWinnerComponent": SwitchWinnerComponent,
    "AffiliateApiKeyScreenComponent": AffiliateApiKeyScreenComponent,
    "CreateSkrillPayoutModalComponent": CreateSkrillPayoutModalComponent,
    "FollowUserModalComponent": FollowUserModalComponent,
    "GameConfigModalComponent": GameConfigModalComponent,
    "TagsEditModalComponent": TagsEditModalComponent,
    "TagsDeleteModalComponent": TagsDeleteModalComponent,
    "UserSettingsModalComponent": SettingsModalComponent,
    "PromotionBannersComponent": PromotionBannersComponent,
    "SelfGameWinnerDeclarationComponent": SelfGameWinnerDeclarationComponent,
    "ImpersonateUserModalComponent": ImpersonateUserModalComponent,
    "RemovePromotionBannerModalComponent": RemovePromotionBannerModalComponent,
    "CategoryTagEditComponent": CategoryTagEditComponent,
    "RemoveCategoryTagModalComponent": RemoveCategoryTagModalComponent,
    "TrendingTagEditModalComponent": TrendingTagEditModalComponent,
    "RemoveTrendingTagModalComponent": RemoveTrendingTagModalComponent,
    "NotificationTypeCreateEditModalComponent": NotificationTypeCreateEditModalComponent,
    "BlacklistCountryStateEditComponent": BlacklistCountryStateEditComponent,
    "SuperTagEditModalComponent": SuperTagEditModalComponent,
    "RemoveSuperTagModalComponent": RemoveSuperTagModalComponent,
    "UserPendingModalComponent": UserPendingModalComponent,
    "UserWalletSummeryReportModalComponent": UserWalletSummeryReportModalComponent,
    "GlobalCutConfigModalComponent": GlobalCutConfigModalComponent,
    "UserGameCutConfigComponent": UserGameCutConfigComponent,
    "UserTransactionModalComponent": UserTransactionModalComponent,
    "PartnerCreateUpdateModalComponent": PartnerCreateUpdateModalComponent,
    "AffAnalyticsComponent": AffAnalyticsComponent,
    "CreateAdminManagerComponent": CreateAdminManagerComponent,
    "SlushFundComponent": SlushFundComponent
  };
  icons: any = SiteConstants.icons;
  tooltips: any = SiteConstants.tooltips;
  // icons: any = {
  //   edit: 'fa fa-edit', user: 'pi pi-users', disputed: 'fa fa-gavel',
  //   save: 'fa fa-floppy-o', remove: 'fa fa-remove', block: 'fa fa-lock', unblock: 'fa fa-unlock',
  //   promocode: 'fa fa-code', payout: 'fa fa-money', transaction: 'fa fa-exchange'
  // }
  // tooltips: any = {
  //   update: 'UPDATE', disputed: 'DISPUTED', save: 'SAVE',
  //   remove: 'REMOVE', block: 'BLOCK', unblock: 'UNBLOCK', promocode: 'PROMO CODE',
  //   payout: "PAYOUT", transaction: 'GET TRANSACTIONS', gameperformance: 'GAME PERFORMANCE',
  //   reported: 'REPORTED', editaffiliate: "EDIT AFFILITE", cancel: "CANCEL", initiatepayout: "INITIATE PAYOUT"

  // }
  ngOnInit (): void {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.accessPathKey) {
      this.isAdmin = false;
      let keys = []
      Object.keys(currentUser.accessPathKey).forEach(e => { keys.push(currentUser.accessPathKey[e]) })
      const AllPathkeys = keys.flat()
      if (AllPathkeys.includes(this.visibleIcons)) {
        this.isAdmin = true;
      }
    }
  }
  openDialog (ev, characterData): void {
    ev.stopPropagation();

    let d = this.modaleId;
    const dialogRef = this.dialog.open(this.components[this.modaleId], {
      height: this.height,
      width: this.width,
      data: characterData,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.onRefrashed.emit(result);
      }

      //  console.log('The dialog was closed act icon');
    });
  }
}
