import { Injectable } from '@angular/core';
import { PayoutData } from '../../data/payout';
import { Endpoints } from '../../../../config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PayoutService {

  constructor (private httpClient: HttpClient) { }
  // getPayoutData() {
  //   return PayoutData;
  // }
  getPayoutData () {
    const url = Endpoints.api.payoutList.getPayoutList;
    const observable = this.httpClient.get(url);
    return observable;


  }

  getPayoutData1 (payoutFilter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (payoutFilter.search) {
      search = JSON.stringify(payoutFilter.search);

    }
    if (payoutFilter.sort) {
      sort = JSON.stringify(payoutFilter.sort)
    }
    if (payoutFilter.pagination) {
      pagination = JSON.stringify(payoutFilter.pagination)
    }
    const url = Endpoints.api.payoutList.getPayoutList
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url);
    return observable;


  }

  getPayoutTransaction (id) {

    const url = Endpoints.api.payoutList.getTransaction;
    const observable = this.httpClient.get(url + '=' + id);
    return observable;
  }

  getPayoutStatus () {
    const url = Endpoints.api.payoutList.getPayoutListStatus;
    const observable = this.httpClient.get(url);
    return observable;
  }
  cancelPayout (obj) {
    const url = Endpoints.api.payoutList.payoutCancel;
    const observable = this.httpClient.put(url, obj);
    return observable;

  }
  initiatePayout (obj) {
    const url = Endpoints.api.payoutList.initiatePayout;
    const observable = this.httpClient.post(url, obj);
    return observable;
  }
  getPayoutEmailList (userId) {
    const url = Endpoints.api.payoutList.getPayoutEmailByUserId.replace('{userid}', userId);
    const observable = this.httpClient.get(url);
    return observable;
  }
  getAllInstant (payoutFilter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (payoutFilter.search) {
      search = JSON.stringify(payoutFilter.search);

    }
    if (payoutFilter.sort) {
      sort = JSON.stringify(payoutFilter.sort)
    }
    if (payoutFilter.pagination) {
      pagination = JSON.stringify(payoutFilter.pagination)
    }
    const url = Endpoints.api.payoutList.getInstantPayoutList
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url);
    return observable;


  }
}
