<div class="logo">
  <a [routerLink]="navigate" class="simple-text">
    <div class="logo-img">
      <img class="large-logo" src="./assets/img/logo.png" />
      <img class="mini-logo" src="./assets/img/minilogo.png" />
    </div>
  </a>
</div>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">

    <ul class="nav navbar-nav nav-mobile-menu">

      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)">
          <i class="material-icons">person</i>
          <button *ngIf="this.isLogin" class="btn btn-link d-lg-none d-md-block" (click)="logout()">Logout</button>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav">
    <li routerLinkActive="active" [ngClass]="{'disabled': !isLogin}" *ngFor="let menuItem of menuItems"
      class="{{menuItem.class}} nav-item">
      <a class="nav-link" [routerLink]="[menuItem.path]">
        <i class="material-icons">{{menuItem.icon}}</i>
        <p>{{menuItem.title}}</p>
      </a>
    </li>
  </ul>
</div>