<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title"> Affiliate Analytics</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">


          <p-table #dt [columns]="cols" [value]="affAnalyticsData" (sortFunction)="customSort($event)"
            [customSort]="true" [(selection)]="selectedAffAnalyticsData" dataKey="id" styleClass="ui-table-cars"
            [rows]="10" [rowsPerPageOptions]="[10,25,50]" [totalRecords]="totalRecords" [responsive]="true"
            [loading]="loading" [paginator]="true">


            <ng-template pTemplate="header" let-columns>
              <tr>

                <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                  [ngStyle]="{'width': col.width,'text-align': col.textalign}">
                  <div class="header-title">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </div>
                </th>

              </tr>
            </ng-template>


            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                <td style="width: 250px">{{rowData.req_url}}</td>

                <td style="width: 200px;">{{rowData.origin}}</td>

                <td style="width: 150px;">{{rowData.created_at | date:'medium'}}</td>



              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="8" style="text-align:left">No data found.</td>
              </tr>
            </ng-template>
          </p-table>




        </div>

      </div>
    </div>
  </div>
</mat-dialog-content>