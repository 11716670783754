import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalRoutingModule } from './modal-routing.module';
import { CharacterEditModalComponent } from './character-edit-modal/character-edit-modal.component';
import { InputTextModule } from 'primeng/inputtext'
import { MatButtonModule } from '@angular/material/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClientModule } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { SourceTypeEditModalComponent } from './source-type-edit-modal/source-type-edit-modal.component';
// import {ColorPickerModule} from 'primeng/colorpicker';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { QuillModule } from 'ngx-quill'
import { MatMenuModule } from '@angular/material/menu';
import { ColorPickerModule } from 'ngx-color-picker';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PinnedQuestionsEditModalComponent } from './game/pinned-questions-edit-modal/pinned-questions-edit-modal.component';
import { CustomQuestionsEditModalComponent } from './game/custom-questions-edit-modal/custom-questions-edit-modal.component';
import { CustomGameEditModalComponent } from './game/custom-game-edit-modal/custom-game-edit-modal.component';
import { CustomQuestionsViewModalComponent } from './game/custom-questions-view-modal/custom-questions-view-modal.component';
import { LevelEditModalComponent } from '../modal/level-edit-modal/level-edit-modal.component';
import { TransactionModalComponent } from './user/transaction-modal/transaction-modal.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { UserPromocodeViewComponent } from '../modal/user/user-promocode-view/user-promocode-view.component';
import { PayoutModalComponent } from './user/payout-modal/payout-modal.component';
import { CreateLeagueCategoryComponent } from './create-league-category/create-league-category.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImpersonateUserModalComponent } from './user/impersonate-user-modal/impersonate-user-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';


import { ImageCropperModule } from 'ngx-image-cropper';
import { SliderModule } from 'primeng/slider';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from 'ngx-mat-datetime-picker';
import { PinnedGameEditModalComponent } from './game/pinned-game-edit-modal/pinned-game-edit-modal.component';
// import { PinnedQuestionsViewModalComponent } from './game/pinned-questions-view-modal/pinned-questions-view-modal.component';
import { PinnedQuestionsViewModalComponent } from './game/pinned-questions-view-modal/pinned-questions-view-modal.component';
import { GalleryImageEditComponent } from './gallery-image-edit-modal/gallery-image-edit/gallery-image-edit.component';
import { BlockUserModalComponent } from './user/block-user-modal/block-user-modal.component';
import { CommonDeleteModalComponent } from './common-delete-modal/common-delete-modal/common-delete-modal.component';
import { EditPromoCodeModalComponent } from './edit-promo-code-modal/edit-promo-code-modal.component';
import { SelfgameDisputeModalComponent } from './selfgame-dispute-modal/selfgame-dispute-modal.component';
import { SelfgameReportedModalComponent } from './selfgame-reported-modal/selfgame-reported-modal.component';
import { SelfgameGamePerformanceModalComponent } from './selfgame-game-performance-modal/selfgame-game-performance-modal.component';
import { PayoutGetTransactionComponent } from './payout-get-transaction/payout-get-transaction.component';
import { PayoutPromocodeComponent } from './payout-promocode/payout-promocode.component';
import { PayoutBlockComponent } from './payout-block/payout-block.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PayoutInitiatePaymentComponent } from './payout-initiate-payment/payout-initiate-payment.component';
import { PayoutListPayoutComponent } from './payout-list-payout/payout-list-payout.component';
import { CreateLeagueComponent } from './create-league/create-league.component';
import { SettingPriceOptionComponent } from './setting-price-option/setting-price-option.component';
import { CreateAffiliateModalComponent } from './create-affiliate-modal/create-affiliate-modal.component';
import { CreateEditFeecutModalComponent } from './create-edit-feecut-modal/create-edit-feecut-modal.component';
import { GameBankCreateEditModalComponent } from './game-Bank/game-bank-create-edit-modal/game-bank-create-edit-modal.component';
import { RemoveCharacterModalComponent } from './characters/remove-character-modal/remove-character-modal.component';
import { CreateEditNotificationComponent } from './create-edit-notification/create-edit-notification.component';
import { RemoveFeeAndSSGCutComponent } from './Fees-and-SSG-Cut/remove-fee-and-ssg-cut/remove-fee-and-ssg-cut.component';
import { RemoveGallaryImageComponent } from './gallery-image-edit-modal/remove-gallary-image/remove-gallary-image.component';
import { SettingDeleteModalComponent } from './setting-delete-modal/setting-delete-modal.component';
import { WinnerDeclarationModalComponent } from './game-Bank/winner-declaration-modal/winner-declaration-modal.component';
import { SummernoteModalComponent } from './game-Bank/summernote-modal/summernote-modal.component';
import { ActionComponentModule } from '../action-component/action-component.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RemoveNotificationModalComponent } from './remove-notification-modal/remove-notification-modal.component';
import { SelfgameCancelModalComponent } from './selfgame-cancel-modal/selfgame-cancel-modal.component';
import { IgnoreReportComponent } from './ignore-report/ignore-report.component';
import { IgnoreDisputeComponent } from './ignore-dispute/ignore-dispute.component';
import { SwitchWinnerComponent } from './switch-winner/switch-winner.component';
import { AffiliateApiKeyScreenComponent } from './affiliate/affiliate-api-key-screen/affiliate-api-key-screen.component';
import { CreateSkrillPayoutModalComponent } from './user/create-skrill-payout-modal/create-skrill-payout-modal.component';
import { FollowUserModalComponent } from './user/follow-user-modal/follow-user-modal.component';
import { TagModalComponent } from './self-game/tag-modal/tag-modal.component';
import { SelfGameWinnerDeclarationComponent } from './self-game/self-game-winner-declaration/self-game-winner-declaration.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { GameConfigModalComponent } from './game-Bank/game-config-modal/game-config-modal.component';
import { TagInputModule } from 'ngx-chips';
import { AccordionModule } from 'primeng/accordion';
import { TagsEditModalComponent } from './game-Bank/tags-edit-modal/tags-edit-modal.component';
import { TagsDeleteModalComponent } from './game-Bank/tags-delete-modal/tags-delete-modal.component';
import { SettingsModalComponent } from './user/settings-modal/settings-modal.component';
import { PromotionBannersComponent } from './promotion-banners/promotion-banners.component';
import { RemovePromotionBannerModalComponent } from './remove-promotion-banner-modal/remove-promotion-banner-modal.component';
import { PromotionBannerCustomComponent } from './promotion-banner-custom/promotion-banner-custom.component';
import { CategoryTagEditComponent } from './game-Bank/category-tag-edit/category-tag-edit.component';
import { RemoveCategoryTagModalComponent } from './game-Bank/remove-category-tag-modal/remove-category-tag-modal.component';
import { RemoveTrendingTagModalComponent } from './game-Bank/remove-trending-tag-modal/remove-trending-tag-modal.component';
import { TrendingTagEditModalComponent } from './game-Bank/trending-tag-edit-modal/trending-tag-edit-modal.component';
import { NotificationTypeCreateEditModalComponent } from './notification-type-create-edit-modal/notification-type-create-edit-modal.component';
import { ConfirmationModalComponent } from './user/confirmation-modal/confirmation-modal.component';
import { BlacklistCountryStateEditComponent } from './user/blacklist-country-state-edit/blacklist-country-state-edit.component';
import { ConfirmCountryModalComponent } from './user/confirm-country-modal/confirm-country-modal.component';
import { SuperTagEditModalComponent } from './game-Bank/super-tag-edit-modal/super-tag-edit-modal.component';
import { RemoveSuperTagModalComponent } from './game-Bank/remove-super-tag-modal/remove-super-tag-modal.component';
import { UserPendingModalComponent } from './user/user-pending-modal/user-pending-modal.component';
import { UserWalletSummeryReportModalComponent } from './user/user-wallet-summery-report-modal/user-wallet-summery-report-modal.component';
import { GlobalCutConfigModalComponent } from './game/global-cut-config-modal/global-cut-config-modal.component';
import { SearchUserModalComponent } from './game/search-user-modal/search-user-modal.component';
import { UserGameCutConfigComponent } from './game/user-game-cut-config/user-game-cut-config.component';
import { UserTransactionModalComponent } from './user/user-transaction-modal/user-transaction-modal.component';
import { CategorySearchModalComponent } from './category-search-modal/category-search-modal.component';
import { ConfirmApiKeyUpdateComponent } from './confirm-api-key-update/confirm-api-key-update.component';
import { CreatorSearchModalComponent } from './creator-search-modal/creator-search-modal.component';
import { PartnerCreateUpdateModalComponent } from './partner-create-update-modal/partner-create-update-modal.component';
import { AffAnalyticsComponent } from './aff-analytics/aff-analytics.component';
import { CreateAdminManagerComponent } from './user/create-admin-manager/create-admin-manager.component';
import { SlushFundComponent } from './slush-fund/slush-fund.component';
@NgModule({
  declarations: [CharacterEditModalComponent,
    SourceTypeEditModalComponent,
    PinnedQuestionsEditModalComponent,
    CustomQuestionsEditModalComponent,
    CustomGameEditModalComponent,
    CustomQuestionsViewModalComponent,
    LevelEditModalComponent,
    UserPromocodeViewComponent,
    TransactionModalComponent,
    PayoutModalComponent,
    PinnedGameEditModalComponent,
    PinnedQuestionsViewModalComponent,
    BlockUserModalComponent,
    CreateLeagueCategoryComponent,
    GalleryImageEditComponent,
    CommonDeleteModalComponent,
    EditPromoCodeModalComponent,
    SelfgameDisputeModalComponent,
    SelfgameReportedModalComponent,
    SelfgameGamePerformanceModalComponent,
    PayoutGetTransactionComponent,
    PayoutPromocodeComponent,
    PayoutBlockComponent,
    PayoutInitiatePaymentComponent,
    PayoutListPayoutComponent,
    CreateLeagueComponent,
    SettingPriceOptionComponent,
    CreateAffiliateModalComponent,
    CreateEditFeecutModalComponent,

    GameBankCreateEditModalComponent,
    SelfGameWinnerDeclarationComponent,
    RemoveCharacterModalComponent,
    CreateEditNotificationComponent,
    RemoveFeeAndSSGCutComponent,
    RemoveGallaryImageComponent,
    SettingDeleteModalComponent,
    WinnerDeclarationModalComponent,
    SummernoteModalComponent,
    RemoveNotificationModalComponent,
    SelfgameCancelModalComponent,
    IgnoreReportComponent,
    IgnoreDisputeComponent,
    SwitchWinnerComponent,
    AffiliateApiKeyScreenComponent,
    CreateSkrillPayoutModalComponent,
    FollowUserModalComponent,
    TagModalComponent,
    GameConfigModalComponent,
    TagsEditModalComponent,
    TagsDeleteModalComponent,
    SettingsModalComponent,
    PromotionBannersComponent,
    RemovePromotionBannerModalComponent,
    PromotionBannerCustomComponent,
    ImpersonateUserModalComponent,

    CategoryTagEditComponent,
    RemoveCategoryTagModalComponent,
    RemoveTrendingTagModalComponent,
    TrendingTagEditModalComponent,
    NotificationTypeCreateEditModalComponent,
    ConfirmationModalComponent,
    BlacklistCountryStateEditComponent,
    ConfirmCountryModalComponent,
    SuperTagEditModalComponent,
    RemoveSuperTagModalComponent,
    UserPendingModalComponent,
    UserWalletSummeryReportModalComponent,
    GlobalCutConfigModalComponent,
    SearchUserModalComponent,
    UserGameCutConfigComponent,
    UserTransactionModalComponent,
    CategorySearchModalComponent,
    ConfirmApiKeyUpdateComponent,
    CreatorSearchModalComponent,
    PartnerCreateUpdateModalComponent,
    AffAnalyticsComponent,
    CreateAdminManagerComponent,
    SlushFundComponent


  ],
  imports: [
    CommonModule,
    ModalRoutingModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    ActionComponentModule,
    RadioButtonModule,
    MatInputModule,
    MatChipsModule,
    InputTextModule,
    MatIconModule,
    MatSelectModule,
    MatAutocompleteModule,
    ConfirmDialogModule,
    ColorPickerModule,
    AutoCompleteModule,
    ImageCropperModule,
    NgxUiLoaderModule,
    SliderModule,
    MatTooltipModule,
    HttpClientModule,
    AccordionModule,
    MatMenuModule,
    MatListModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    FormsModule,
    CardModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatMomentDateModule,
    MatDatepickerModule,
    CalendarModule,
    MatCheckboxModule,
    CheckboxModule,
    FileUploadModule,
    NgxSummernoteModule,
    QuillModule.forRoot(),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatProgressSpinnerModule,
    NgxSpinnerModule,
    TagInputModule,
  ],
  exports: [CharacterEditModalComponent,
    SourceTypeEditModalComponent,
    PinnedQuestionsEditModalComponent,
    CustomGameEditModalComponent,
    CustomQuestionsViewModalComponent,
    LevelEditModalComponent,
    UserPromocodeViewComponent,
    TransactionModalComponent,
    PayoutModalComponent,
    CreateLeagueCategoryComponent,
    GalleryImageEditComponent,
    CommonDeleteModalComponent,
    EditPromoCodeModalComponent
  ]
})
export class ModalModule { }
