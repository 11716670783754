import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../core/services/common/tost.service';
import { NotificationService } from '../../core/services/league/notification.service';
import { SelectItem } from 'primeng/api';


@Component({
  selector: 'app-create-edit-notification',
  templateUrl: './create-edit-notification.component.html',
  styleUrls: ['./create-edit-notification.component.scss']
})
export class CreateEditNotificationComponent implements OnInit {
  canCloseOption: any[];
  alertTypeOption: any[];
  notificationValues: any = {};
  canCloseArray: SelectItem[];
  alertTypeOptionArray: SelectItem[];
  isCreate: string = "Create";
  action: any = '';
  // notificationForm = new FormGroup({
  //   title: new FormControl('', [Validators.required, Validators.minLength(3)]),
  //   message: new FormControl('', [Validators.required, Validators.minLength(3)]),
  //   can_close: new FormControl('', [Validators.required]),
  //   alert_type: new FormControl('', [Validators.required]),
  //   is_active: new FormControl('', [Validators.required]),
  //   start_date: new FormControl(new Date(), [Validators.required]),
  //   end_date: new FormControl(new Date(), [Validators.required]),

  // });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private notificationService: NotificationService, public dialogRef: MatDialogRef<CreateEditNotificationComponent>) {

    this.canCloseOption = [
      { name: 'Yes', value: true },
      { name: 'No', value: false },
    ]


    this.alertTypeOption = [
      { name: 'modal', value: 'm' },
      { name: 'Toast', value: 't' },

    ]
  }

  ngOnInit(): void {
    let b = JSON.stringify(this.canCloseOption)
    let a = JSON.parse(b)
    this.canCloseArray = a.map(obj => {
      let rObj = {}
      rObj['label'] = obj.name;
      rObj['value'] = obj.value;
      return rObj
    });

    let alertYpeStingify = JSON.stringify(this.alertTypeOption)
    let alertYpeParsed = JSON.parse(alertYpeStingify)
    this.alertTypeOptionArray = alertYpeParsed.map(obj => {
      let rObj = {}
      rObj['label'] = obj.name;
      rObj['value'] = obj.value;
      return rObj
    });


    // console.log('form data',this.data);
    this.notificationValues = {
      title: '',
      message: '',
      can_close: '',
      alert_type: '',
      is_active: false,
      start_date: new Date(),
      end_date: new Date()
    }

    if (this.data && this.data.id) {
      this.isCreate = "Update"
      this.notificationValues.title = this.data.title;
      this.notificationValues.message = this.data.message;
      this.notificationValues.can_close = this.data.can_close;
      this.notificationValues.alert_type = this.data.alert_type;
      this.notificationValues.is_active = this.data.is_active;
      this.notificationValues.start_date = this.data.start_date;
      this.notificationValues.end_date = this.data.end_date;
    }
  }

  close() {
    this.dialogRef.close(true);
  }

  closeOption(e) {
    if (e.value.value == undefined) {
      this.notificationValues.can_close = e.value;

    } else {
      this.notificationValues.can_close = e.value.value;

    }
  }

  alertTypeChangeOption(e) {
    // this.notificationValues.alert_type = e.value.value;
    if (e.value.value == undefined) {
      this.notificationValues.alert_type = e.value;

    } else {
      this.notificationValues.alert_type = e.value.value;

    }
  }

  submitData() {
    //  console.log('notificaiton val',this.notificationValues)

    // var curDate = new Date();
    // this.IsWait= true;
    // if(new Date(this.notificationValues.start_date) > new Date(this.notificationValues.end_date) ){
    //   this.tostService.tostrFun('End date should not be greate','error')
    //   return ;
    // } else if (new Date(this.notificationValues.end_time) < curDate) {
    //   this.tostService.tostrFun('End date should not before today','error')
    //   return ;
    // }

    var curDate = new Date();
    if (new Date(this.notificationValues.start_date) < curDate) {
      this.tostService.tostrFun("Start Date should not be before today.", 'error');
      return;
    }

    if (new Date(this.notificationValues.start_date) >
      new Date(this.notificationValues.end_date)) {
      this.tostService.tostrFun("End Date should be greater than start date.", 'error');
      return;
    } else if (new Date(this.notificationValues.end_date) < curDate) {

      this.tostService.tostrFun("End Date should not be before today.", 'error');
      return;
    }

    if (this.data == null) {
      this.action = "create"
    } else if (this.data.id) {
      this.action = "update"

      Object.assign(this.notificationValues, {
        id: this.data.id,

      });
    }


    this.notificationService.createUpdateNotification(this.notificationValues, this.action).subscribe(data => {

      if (this.action == 'update') {
        this.tostService.tostrFun("Notification updated successfully", 'success');

      } else {
        this.tostService.tostrFun("Notification created successfully", 'success');

      }
    },
      err => {
        //    this.tostService.tostrFun("something went wrong", 'error');

      }

    )
    this.dialogRef.close();
  }

}
