import { Component, OnInit, Inject } from '@angular/core';
import { SourceTypeService } from '../../../core/services/source-type.service';
import { SystemSettingsService } from '../../../core/services/game/system-settings.service'
import { SelectItem } from 'primeng/api';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-user-game-cut-config',
  templateUrl: './user-game-cut-config.component.html',
  styleUrls: ['./user-game-cut-config.component.scss']
})
export class UserGameCutConfigComponent implements OnInit {

  loading: Boolean;
  cols: any;
  globalCutConfig: any;
  globalCutConfig1: any;
  closeResult: string;
  selectedGlobalConfig: any;
  config: string;
  userId: any;
  gameId: any;
  entityId: any;
  entityType: string;
  isUpdate: Boolean = true;
  characterType: SelectItem;
  width: number = 750;
  height: number = 530;
  modaleId: string = "GlobalCutConfigComponent";
  constructor (private sourceTypeService: SourceTypeService, public dialog: MatDialog, private systemSettingsService: SystemSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserGameCutConfigComponent>,
  ) { }

  ngOnInit (): void {
    this.loading = true;
    if (this.data && this.data.config) {
      this.config = this.data.config;

      if (this.config === 'User') {
        this.userId = this.data.user_id;
        this.entityType = 'user';
        this.entityId = this.data.entityId;
      } else if (this.config === 'Game') {
        this.gameId = this.data.game_id;
        this.entityId = this.data.entityId;
        this.entityType = 'game';
      }
    }

    this.cols = [
      { field: 'entry_fee', header: 'Entry Fee $', width: "75px" },

      { field: 'prize_money', header: 'Player Payout $', width: "90px" },
      // { field: 'brand', header: 'Brand' },
      { field: 'transaction_fee', header: 'Transaction Fee %', width: "100px" },
      { field: 'player_referral_cut', header: 'Player Referral Cut %', width: "90px" },
      { field: 'marketing_cost', header: 'Mrktg Cut %', width: "80px" },
      { field: 'hc_pool', header: 'HC Pool', width: "80px" },
      { field: 'host_referral_cut', header: 'Host Referral Cut %', width: "90px" },
      { field: 'consulting_pc_pool', header: ' Consulting PC Pool %', width: "90px" },

      { field: '', header: 'Action', width: "70px" }
    ];
    this.getData();
  }
  onRefrash (e) {
    setTimeout(() => {
      this.getData();
    }, 1500);

  }

  customSort (event) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });
  }

  getData () {

    this.systemSettingsService.getUserCutSettings(this.entityType, this.entityId).subscribe(data => {
      const res = JSON.stringify(data);
      this.globalCutConfig = JSON.parse(res);

      this.loading = false;

    });



  }
  close () {
    this.dialogRef.close(true);
  }
}
