import { Injectable } from '@angular/core';
import { Endpoints } from '../../../../../../src/app/config';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeecutService {

  constructor(private httpClient: HttpClient) { }
  getFeeCutList(feeCutFilter) {
    let pagination = '';
    let sort = '';
    if (feeCutFilter.sort == undefined) {
      sort = JSON.stringify({ entry_fee: 'asc' })
    } else {
      sort = JSON.stringify(feeCutFilter.sort)
    }
    if (feeCutFilter.pagination) {
      pagination = JSON.stringify(feeCutFilter.pagination)
    }
    const url = Endpoints.api.feeCutAndSSG.getFeeCutList
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url)

    return observable;


  }

  createUpdateFeecut(formValues, action) {

    if (action == "create") {
      const url = Endpoints.api.feeCutAndSSG.createFeeCut;
      const observable = this.httpClient.post(url, formValues);
      return observable;
    } else if (action == "update") {
      const url = Endpoints.api.feeCutAndSSG.updateFeeCut;
      const observable = this.httpClient.put(url, formValues);
      return observable;
    }
  }

  removeFeeandCut(obj) {
   
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: obj
    };
    const url = Endpoints.api.feeCutAndSSG.deleteFeeCut;
    const observable = this.httpClient.delete(url, options)
    return observable;
  }

}
