<div class="modal-header">
    <h4 *ngIf="is_disable == true" class="modal-title" id="modal-basic-title">Pinned Game Update</h4>
    <h4 *ngIf="is_disable == false">Create Pinned Game</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<mat-dialog-content>
    <div class="main-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="modal-body">
                        <form [formGroup]="pGameForm" (ngSubmit)="submitData()">

                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="game_name" id="game_name"
                                                placeholder="Game Name" type="text" class="form-control">
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="game_duration" id="game_duration"
                                                placeholder="Duration" type="number" class="form-control">
                                        </mat-form-field>

                                    </div>
                                </div>

                            </div>



                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field>
                                            <input matInput [ngxMatDatetimePicker]="picker1"
                                                formControlName="start_time" placeholder="Start Date">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field>
                                            <input matInput [ngxMatDatetimePicker]="picker2" formControlName="end_time"
                                                placeholder="End Date">
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <ngx-mat-datetime-picker #picker2></ngx-mat-datetime-picker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>





                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group priceoptioncls">
                                        <h5>Game Price Options. <button *ngIf="is_disable !== true " mat-raised-button
                                                type="submit" class="btn btn-link" (click)="addPriceOption()">+ ADD
                                                PRICE OPTIONS </button></h5>
                                        <ng-template ngFor let-xb [ngForOf]="price_options"
                                            formControlName="price_options">

                                            <!-- <input id="entry_fee" placeholder="entry_fee" value="{{xb.entry_fee}}" type="number" min="0" class="form-control1">

                                            <input id="prize_money" placeholder="Game title" value="{{xb.prize_money}}" type="number" min="1" class="form-control1">
                                            <mat-checkbox style="margin-top: 7px;"> Default</mat-checkbox>
                                            <button type="button" class="close" aria-label="Close" style="margin-top: -8px;" (click)="close()">
                                                <span aria-hidden="true">&times;</span>
                                            </button> -->

                                            <div class="row">

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <mat-form-field class="example-full-width">
                                                            <input [disabled]="is_disable == true" matInput
                                                                id="entry_fee" value="{{xb.entry_fee}}"
                                                                placeholder="Entry fee" type="number"
                                                                class="form-control">
                                                        </mat-form-field>

                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <mat-form-field class="example-full-width">
                                                            <input [disabled]="is_disable == true" matInput
                                                                id="prize money" value="{{xb.prize_money}}"
                                                                placeholder="Prize money" type="number"
                                                                class="form-control">
                                                        </mat-form-field>

                                                    </div>
                                                </div>
                                                <div class="col-md-2" *ngIf="is_disable !== true ">
                                                    <div class="form-group">
                                                        <mat-checkbox checked="{{xb.default}}"> Default</mat-checkbox>

                                                    </div>
                                                </div>
                                                <div class="col-md-2" *ngIf="is_disable !== true ">
                                                    <div class="form-group">
                                                        <button type="button" class="close" aria-label="Close"
                                                            style="margin-top: -8px;" (click)="close()">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>




                        </form>

                    </div>

                </div>

            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer">

    <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="close()">Save</button>
</div>
<div class="clearfix"></div>