<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{config}} Cut Config</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="">
  <div class="main-content">
    <div class="modal-body">

      <div class="row">
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Entry Fee (in coin)</mat-label>
            <input matInput [(ngModel)]="globalCutConfig.entry_fee" [readonly]="true" type="number" min="0"
              name="entry_fee" />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Player Payout (in coin)</mat-label>
            <input matInput [(ngModel)]="globalCutConfig.prize_money" [readonly]="!is_global" type="number" min="0"
              name="prize_money" (change)="prizeMoneyChange($event.target.value)" />
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field>
            <mat-label>NPC (in coin)</mat-label>
            <input matInput [(ngModel)]="npc" [readonly]="true" type="number" min="0" name="prize_money" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <p-checkbox [binary]="true" [(ngModel)]="isSameSettings" value="is_disputed"
            label="Same settings for all price Options " (onChange)="checkValue(isSameSettings)" inputId="ny">
          </p-checkbox>
        </div>

        <!-- <div class="col-md-3">
          <mat-form-field>
            <mat-label>Player Payout (in $)</mat-label>
            <input matInput [(ngModel)]="globalCutConfig.prize_money" type="number"
              (change)="prizeMoneyChange($event.target.value)" min="0" max="{{maxPrizeOp}}" name="bots_to_follow" />
          </mat-form-field>
        </div> -->

      </div>
      <div class="row" style="padding-bottom: 20px;">
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Transaction Cost: (in %)</mat-label>
            <input matInput [(ngModel)]="globalCutConfig.transaction_fee.percentage" type="number" min="0" max="100"
              (change)="transactionFeeChange($event.target.value)" name="transaction_fee" />
          </mat-form-field>
        </div>

        <div class="col-md-9">
          <p-card>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field>
                  <mat-label>Player Referral Cut: (in %)</mat-label>
                  <input matInput [(ngModel)]="globalCutConfig.player_referral_cut.percentage" type="number" min="0"
                    max="100" (change)="playerRefCutChange($event.target.value)" name="player_referral_cut" />
                </mat-form-field>
              </div>
              <div class="col-md-4">

                <mat-form-field>
                  <mat-label>Player Referral Duration</mat-label>
                  <input matInput [(ngModel)]="durationTxt" type="number"
                    (change)="playerReferralDurationChange($event.target.value)" min="0" name="durationTxt" />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Select Duration Type</mat-label>
                  <mat-select [(ngModel)]="durationVal" name="duration" id="source_type"
                    (selectionChange)="changeDurationVal($event)">
                    <mat-option *ngFor="let duration of durationTypes" [value]="duration.value">
                      {{duration.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>
            </div>
          </p-card>
        </div>

      </div>

      <div class="row">
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Marketing Cut: (in %)</mat-label>
            <input matInput [(ngModel)]="globalCutConfig.marketing_cost.percentage" type="number" min="0" max="100"
              name="marketing_cost" (change)="marketingCostChange($event.target.value)" />
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <mat-form-field>
            <mat-label>HC Pool : (in %)</mat-label>
            <input matInput [(ngModel)]="globalCutConfig.hc_pool.percentage"
              (change)="hcPoolChange($event.target.value)" type="number" min="0" name="hc_pool" max="100" />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field>
            <mat-label>Host Referral Cut : (in %)</mat-label>
            <input matInput [(ngModel)]="globalCutConfig.host_referral_cut.percentage" type="number" min="0" max="100"
              name="host_referral_cut" (change)="hostReferralCutChange($event.target.value)" />
          </mat-form-field>
        </div>
        <div class="col-md-3" *ngIf="!is_global">
          <mat-form-field>
            <mat-label>Consulting PC Pool : (in %)</mat-label>
            <input matInput [(ngModel)]="globalCutConfig.consulting_pc_pool.percentage"
              (change)="consultingPCChange($event.target.value)" type="number" min="0" max="100"
              name="player_referral_cut" />
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="!is_global">
        <div class="col-12">
          <p-accordion [multiple]="true">
            <p-accordionTab header="Parents">

              <div class="row" *ngFor="let parent of globalCutConfig.parents ; index as i">
                <div class=" col-12">
                  <app-search-user-modal [index]="i+1" [parent]="parent" [is_parent]="true"
                    [globalCutConfig]="this.globalCutConfig" (changedParent)="changedParentFun($event)"
                    (removedParent)=" removeParents($event)">
                  </app-search-user-modal>
                </div>
              </div>
              <div class="row">
                <div class=" col-6">
                  <h5> </h5>
                </div>
                <div class=" col-6">
                  <h5>
                    <button mat-raised-button type="submit" class="btn btn-link" (click)="addParents()">+ ADD Parents
                    </button>
                  </h5>

                </div>
              </div>
            </p-accordionTab>
            <br>

            <p-accordionTab header="Consulting PC Pool Parents">

              <div class="row" *ngFor="let parent of globalCutConfig.consulting_pc_pool_parents ; index as i">
                <div class=" col-12">
                  <app-search-user-modal [CPCIndex]="i+1" [CPCParent]="parent" [is_cpc_parent]="true"
                    [globalCutConfig]="this.globalCutConfig" (changedCPCParent)="changedCPCParentFun($event)"
                    (removedCPCParent)=" removeCPCParents($event)">
                  </app-search-user-modal>
                </div>
              </div>
              <div class="row">
                <div class=" col-6">
                  <h5> </h5>
                </div>
                <div class=" col-6">
                  <h5>
                    <button mat-raised-button type="submit" class="btn btn-link" (click)="addCPCParents()">+ ADD Parents
                    </button>
                  </h5>

                </div>
              </div>
            </p-accordionTab>

          </p-accordion>
        </div>
      </div>

      <br>
      <div class="row">
        <div class="col-12">
          <table border="1px">
            <tr>
              <th>Entry Fee </th>
              <th>Player Payout </th>
              <th>NPC</th>
              <th>Transaction Cost </th>
              <th>Player Referral Cut </th>
              <th>Marketing Cut </th>
              <th>HC Pool</th>
              <th>Host Referral Cut</th>
              <th *ngIf="!is_global">Consulting PC</th>

            </tr>

            <tr>
              <td>{{globalCutConfig.entry_fee}}</td>
              <td>{{prizeMoneyTotal}}</td>
              <td>{{npc}}</td>
              <td>{{transactionFee.toFixed(3)}}</td>
              <td>{{playerReferralCut.toFixed(3)}}</td>
              <td>{{marketingCost.toFixed(3)}}</td>
              <td> {{hc_pool.toFixed(3)}}</td>
              <td>{{hostReferralCut.toFixed(3)}}</td>
              <td *ngIf="!is_global">{{consultingPC.toFixed(3)}}</td>
            </tr>
            <tr>
              <th *ngFor="let col of globalCutConfig.parents ; index as i">
                Parent User {{i+1}} (user:{{col.user_id}})
              </th>
              <th *ngFor="let col of globalCutConfig.consulting_pc_pool_parents ; index as i">
                CPC Parent User {{i+1}} (user:{{col.user_id}})
              </th>
            </tr>
            <tr>
              <td *ngFor="let col of globalCutConfig.parents ; index as i">
                {{(hc_pool * col.percentage / 100).toFixed(3)}}
              </td>
              <td *ngFor="let col of globalCutConfig.consulting_pc_pool_parents ; index as i">
                {{(consultingPC * col.percentage / 100).toFixed(3)}}
              </td>
            </tr>
            <!-- <tr>

              <th>Host Referral Cut </th>
              <th>CPC1 Cut </th>
              <th>CPC2 Cut </th>
              <th>CPC3 Cut </th>
              <th>CPC4 Cut </th>
            </tr>
            <tr>

              <td>20</td>
              <td>20</td>
              <td>10</td>
              <td>24</td>
              <td>32</td>
            </tr> -->
          </table>
        </div>
      </div>
    </div>

  </div>

</mat-dialog-content>
<div class="modal-footer">
  <button mat-raised-button class="btn btn-danger pull-right" [disabled]="!isSave"
    (click)="updateConfig()">Save</button>
  <!-- <button mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</button> -->
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>