import { Injectable } from '@angular/core';
import { Endpoints, SiteConstants } from '../../../../../../src/app/config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor (private httpClient: HttpClient) { }

  getNotification (notificationFilter) {
    let search = {};
    let pagination = {};
    let sort = {};
    if (notificationFilter.search) {
      search = notificationFilter.search;

    }
    if (notificationFilter.sort) {
      sort = notificationFilter.sort
    }
    // else{
    //   sort={id:'desc'}
    // }
    if (notificationFilter.pagination) {
      pagination = notificationFilter.pagination
    } else {
      pagination = { limit: 10, page: 1 }
    }
    const url = Endpoints.api.notification.getNotificationList;
    // .replace('{search}',search )
    // .replace('{sort}',sort )
    // .replace('{pagination}',pagination);

    const observable = this.httpClient.post(url, { sort, search, pagination })

    return observable;


  }


  createUpdateNotification (formValues, action) {

    if (action == "create") {
      const url = Endpoints.api.notification.createNotification;
      const observable = this.httpClient.post(url, formValues);
      return observable;
    } else if (action == "update") {
      const url = Endpoints.api.notification.updateNotification;
      const observable = this.httpClient.put(url, formValues);
      return observable;
    }
  }

  deleteNotification (id) {

    const url = Endpoints.api.notification.deleteNotification;
    const observable = this.httpClient.delete(url + '/' + id)

    return observable;
  }


  getAllNotifyGroups (filter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (filter.search) {
      search = JSON.stringify(filter.search);
    }

    if (filter.sort && Object.keys(filter.sort).length) {
      sort = JSON.stringify(filter.sort)
    }

    if (filter.pagination) {
      pagination = JSON.stringify(filter.pagination)
    } else {
      pagination = SiteConstants.paginationDefault;
    }
    const url = Endpoints.api.notification.getAllNotifyGroups
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url);
    return observable;


  }

  getAllNotifyTypes (filter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (filter.search) {
      search = JSON.stringify(filter.search);
    }

    if (filter.sort) {
      if (Object.keys(filter.sort).length > 0) {
        sort = JSON.stringify(filter.sort)
      } else {
        sort = JSON.stringify({ id: 'desc' })
      }
    } else {
      sort = JSON.stringify({ id: 'desc' })
    }


    if (filter.pagination) {
      pagination = JSON.stringify(filter.pagination)
    } else {
      pagination = SiteConstants.paginationDefault;
    }
    const url = Endpoints.api.notification.getAllNotifyTypes
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url);
    return observable;


  }

  createUpdateNotifyType (obj) {
    const url = Endpoints.api.notification.getAllNotifyTypeSave;
    const observable = this.httpClient.post(url, obj);
    return observable;
  }
}
