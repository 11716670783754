import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import { Users } from '../models/api/users'
import { from } from 'rxjs';
import { UserData } from '../data/userJson'
import { DataService } from '../services/base-service';
@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor (private httpClient: HttpClient, private dataService: DataService) { }
  getUsersData () {
    const url = Endpoints.api.user.getAllUser;
    const observable = this.httpClient.get(url);
    return observable;


  }

  getUsersData1 (userFilter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (userFilter.search) {
      search = JSON.stringify(userFilter.search);

    }
    // if(userFilter.sort){
    //   sort = JSON.stringify(userFilter.sort)
    // }
    if (userFilter.sort) {
      if (Object.keys(userFilter.sort).length > 0) {
        sort = JSON.stringify(userFilter.sort)
      } else {
        sort = JSON.stringify({ createdAt: 'desc' })
      }
    } else {
      sort = JSON.stringify({ createdAt: 'desc' })
    }

    if (userFilter.pagination) {
      pagination = JSON.stringify(userFilter.pagination)
    }
    const url = Endpoints.api.user.getAllUser
      .replace('{search}', encodeURIComponent(search))
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    //const observable = this.httpClient.get(url)
    const data = this.dataService.get({ url: url, isHandleErr: true })
    return data;


  }
  getUsersById (id) {
    const url = Endpoints.api.user.getUserById.replace('{id}', id);
    const observable = this.httpClient.get(url)

    return observable;
  }
  deleteInviteCode (id) {
    const url = Endpoints.api.user.deleteInviteCodeById.replace('{id}', id);
    const observable = this.httpClient.delete(url)

    return observable;
  }
  createSkrillPayout (obj) {
    const url = Endpoints.api.user.createSkrillPayout;
    const observable = this.httpClient.post(url, obj);
    return observable;
  }
  getUserSettings (userId) {
    const url = Endpoints.api.user.getUserSettings.replace('{user_id}', userId);
    return this.httpClient.get(url);
  }
  updateUserSettings (data) {
    const url = Endpoints.api.user.updateUserSettings;
    return this.httpClient.post(url, data);
  }
  stopRequest () {
    this.dataService.stopRequest.next();
  }
  impersonateUser (data) {
    const url = Endpoints.api.user.impersonateUser;
    return this.httpClient.post(url, data);
  }
  getBlackListCountryState (filter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (filter.search) {
      search = JSON.stringify(filter.search);

    }
    if (filter.sort) {
      if (Object.keys(filter.sort).length > 0) {
        sort = JSON.stringify(filter.sort)
      } else {
        sort = JSON.stringify({ id: 'desc' })
      }
    } else {
      sort = JSON.stringify({ id: 'desc' })
    }

    if (filter.pagination) {
      pagination = JSON.stringify(filter.pagination)
    }
    const url = Endpoints.api.user.getBlackListCountryState
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);
    const observable = this.httpClient.get(url);

    return observable;

  }

  saveBlackListCountryState (data) {
    const url = Endpoints.api.user.saveBlackListCountryState;
    return this.httpClient.post(url, data);
  }

  getCountryList () {
    const url = Endpoints.api.user.getCountryList;
    return this.httpClient.get(url);
  }

  getStateList (countryCode) {
    const url = Endpoints.api.user.getStateList.replace('{country_code}', countryCode);
    return this.httpClient.get(url);
  }
  creatorSearch (data) {

    const url = Endpoints.api.user.creatorSearch;
    return this.httpClient.post(url, data)
  }
  getAdminUser (userFilter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (userFilter.search) {
      search = JSON.stringify(userFilter.search);

    }
    // if(userFilter.sort){
    //   sort = JSON.stringify(userFilter.sort)
    // }
    if (userFilter.sort) {
      if (Object.keys(userFilter.sort).length > 0) {
        sort = JSON.stringify(userFilter.sort)
      } else {
        sort = JSON.stringify({ createdAt: 'desc' })
      }
    } else {
      sort = JSON.stringify({ createdAt: 'desc' })
    }

    if (userFilter.pagination) {
      pagination = JSON.stringify(userFilter.pagination)
    }
    const url = Endpoints.api.user.adminManager.user
      .replace('{search}', encodeURIComponent(search))
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    //const observable = this.httpClient.get(url)
    const data = this.dataService.get({ url: url, isHandleErr: true })
    return data;


  }

  getRoles () {
    const url = Endpoints.api.user.adminManager.userRoles;
    return this.httpClient.get(url);
  }
  saveUser (params) {
    let url = "";
    if (params.action === "update") {
      url = Endpoints.api.user.adminManager.userUpdate;
    } else {
      url = Endpoints.api.user.adminManager.userCreate;
    }

    return this.httpClient.post(url, params);
  }

}
