<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ notificationType && notificationType.id ? 'Update' : 'Create' }}
    notificationType</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <form (ngSubmit)="savenotificationType()">
      <div class="modal-body">
        <div class="row">
          <div class="col col-6">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Title</mat-label>
                <input matInput [(ngModel)]="notificationType.title" name="title" id="title" class="form-control">
              </mat-form-field>
            </div>
          </div>

          <div class="col col-6">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Type</mat-label>
                <input matInput name="type" [(ngModel)]="notificationType.type" id="type" class="form-control"
                  [disabled]="notificationType && notificationType.id">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-6">
            <div class="form-group">
              <mat-form-field>
                <mat-select [(ngModel)]="notificationType.group_id" name="food" (selectionChange)="selectGroup($event)">
                  <mat-option *ngFor="let food of groupTypes" [value]="food.id">
                    {{food.group_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</a>
          <button mat-raised-button type="submit" class="btn btn-danger pull-right">Save</button>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>