import { Injectable } from '@angular/core';
import { Endpoints, SiteConstants } from '../../../../config';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class SelfgameService {

  constructor (private httpClient: HttpClient) { }

  getSelfGameData (selfGameFilter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (selfGameFilter.search) {
      search = JSON.stringify(selfGameFilter.search);

    }
    // if(selfGameFilter.sort){
    //   sort = JSON.stringify(selfGameFilter.sort)
    // }
    if (selfGameFilter.sort) {
      if (Object.keys(selfGameFilter.sort).length > 0) {
        sort = JSON.stringify(selfGameFilter.sort)
      } else {
        sort = JSON.stringify({ id: 'desc' })
      }
    } else {
      sort = JSON.stringify({ id: 'desc' })
    }

    if (selfGameFilter.pagination) {
      pagination = JSON.stringify(selfGameFilter.pagination)
    } else {
      pagination = SiteConstants.paginationDefault;
    }
    const url = Endpoints.api.selfGame.getSelfGameList
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url);
    return observable;


  }

  getDisputedRecorded (id) {

    const url = Endpoints.api.selfGame.selfGameDisputed;
    const observable = this.httpClient.get(url + '/' + id);
    return observable;
  }

  getReportedRecorded (id) {

    const url = Endpoints.api.selfGame.selfGameReported;
    const observable = this.httpClient.get(url + '/' + id);
    return observable;
  }
  cancelGame (id) {

    const url = Endpoints.api.selfGame.selfGameCancel.replace('{id}', id);
    const observable = this.httpClient.post(url, {});
    return observable;
  }
  getSelfGamePerformance (id) {

    const url = Endpoints.api.selfGame.selfGamePerformance;
    const observable = this.httpClient.get(url + '/' + id);
    return observable;
  }
  ReportsIgnore (obj) {
    const url = Endpoints.api.selfGame.selfGamesReportsIgnore;
    const observable = this.httpClient.post(url, obj);
    return observable;
  }
  DisputesIgnore (obj) {
    const url = Endpoints.api.selfGame.selfGamesDisputesIgnore;
    const observable = this.httpClient.post(url, obj);
    return observable;
  }
  SwitchWinner (obj) {
    const url = Endpoints.api.selfGame.selfGamesSwitchWinner;
    const observable = this.httpClient.post(url, obj);
    return observable;
  }
  toggleGOWT (id) {
    const url = Endpoints.api.games.toggleGowt.replace('{id}', id);
    return this.httpClient.get(url)
  }
  selfGameDeclareWinner (Obj) {
    const url = Endpoints.api.selfGame.selfGameDeclareWinner;;
    const observable = this.httpClient.post(url, Obj)
    return observable;
  }

}
