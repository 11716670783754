import { Injectable } from '@angular/core';
import { customQuestionData } from '../../data/customQuestion';
import { Endpoints,SiteConstants } from '../../../../config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomQuestionService {

  constructor(private httpClient: HttpClient) { }

  getCustomQuestionData() {
    return customQuestionData;
  }

  getCustomQuestionData1(customQuestionFiler) {
    let search='';
    let pagination ='';
    let sort ='';
    let type={"question_type":"C"}
    if(customQuestionFiler.search == undefined){
      // search= JSON.stringify(customQuestionFiler.search);
      search=JSON.stringify(type)
    }else{
      // Object.assign(customQuestionFiler.search,{"question_type":"C"}); 
      search= JSON.stringify(customQuestionFiler.search,);
    }
    // if(customQuestionFiler.sort){
    //   sort = JSON.stringify(customQuestionFiler.sort)
    // }
    // if(customQuestionFiler.sort == undefined){
    //   sort =JSON.stringify({id:'desc'})
    //   // customQuestionFiler.sort={id:'desc'}
    //   // customQuestionFiler.sort= Object.assign({},customQuestionFiler.sort,{id:'desc'})
    // }else{
      
    //   // customQuestionFiler.sort= Object.assign({},customQuestionFiler.sort,{id:'desc'})
      
    // }
    if (customQuestionFiler.sort) {
      if (Object.keys(customQuestionFiler.sort).length > 0) {
        Object.assign(customQuestionFiler.sort,{id:'desc'}) 
        sort=JSON.stringify(customQuestionFiler.sort)
      } else {
        sort = JSON.stringify({ id: 'desc' })
      }
    } else {
      sort = JSON.stringify({ id: 'desc' })
    }

    if(customQuestionFiler.pagination){
      pagination = JSON.stringify(customQuestionFiler.pagination)
    }else {
      pagination = SiteConstants.paginationDefault;
    }
         const url = Endpoints.api.customQuestion.getCustomQuestionList
      .replace('{search}',search )
      .replace('{sort}',sort )
      .replace('{pagination}',pagination);
     
      const observable = this.httpClient.get(url)
  
      return observable; 
    
 
  }

  getCustomQuestionData1ForModal(customQuestionFiler) {
    let search='';
    let pagination ='';
    let sort ='';
    let type={"question_type":"C"}
    if(customQuestionFiler.search == undefined){
      // search= JSON.stringify(customQuestionFiler.search);
      search=JSON.stringify(type)
    }else{
      // Object.assign(customQuestionFiler.search,{"question_type":"C"}); 
      search= JSON.stringify(customQuestionFiler.search,);
    }
    // if(customQuestionFiler.sort){
    //   sort = JSON.stringify(customQuestionFiler.sort)
    // }
    if(customQuestionFiler.sort == undefined){
      // sort =JSON.stringify({id:'desc'})
      // customQuestionFiler.sort={id:'desc'}
      // customQuestionFiler.sort= Object.assign({},customQuestionFiler.sort,{id:'desc'})
    }else{
      Object.assign(customQuestionFiler.sort) 
      sort=JSON.stringify(customQuestionFiler.sort)
      // customQuestionFiler.sort= Object.assign({},customQuestionFiler.sort,{id:'desc'})
      
    }
    if(customQuestionFiler.pagination){
      pagination = JSON.stringify(customQuestionFiler.pagination)
    }
         const url = Endpoints.api.customQuestion.getCustomQuestionList
      .replace('{search}',search )
      .replace('{sort}',sort )
      .replace('{pagination}',pagination);
     
      const observable = this.httpClient.get(url)
      return observable; 
    
 
  }
}
