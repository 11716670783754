import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../../config';

@Injectable({
  providedIn: 'root'
})
export class FollowService {

  constructor (private httpClient: HttpClient) { }
  getFollowCount (user_id) {
    const url = Endpoints.api.user.getFollowCount.replace('{user_id}', user_id);

    const observable = this.httpClient.get(url);

    return observable;


  }
  getFollowerFollowingIds (user_id) {
    const url = Endpoints.api.user.getFollowerFollowingIds.replace('{user_id}', user_id);

    const observable = this.httpClient.get(url);

    return observable;
  }
}