import { Component, OnInit, Inject, Output, Input, EventEmitter } from '@angular/core';
import { UsersService } from '../../core/services/users.service';
import { map } from 'rxjs/operators';
import { TostService } from '../../core/services/common/tost.service';
@Component({
  selector: 'app-creator-search-modal',
  templateUrl: './creator-search-modal.component.html',
  styleUrls: ['./creator-search-modal.component.scss']
})
export class CreatorSearchModalComponent implements OnInit {

  users: any;
  tagsLoading = true;
  freeOnlyLoading = false;
  @Output() userOutput = new EventEmitter<any>();
  @Output() onClearTags = new EventEmitter<any>();
  @Input() userInputData: any;
  constructor (

    private usersService: UsersService,

    private tostService: TostService
  ) { }

  ngOnInit (): void {
    if (this.userInputData && this.userInputData.length) {
      this.usersService
        .creatorSearch({ userIds: this.userInputData }).subscribe(res => {
          this.users = res['creators'].map(v => {
            const obj = {
              display: v['user_name'],
              value: v['id']
            }
            return obj;
          });

        });
    }
  }

  close () {
    this.onClearTags.emit("p");
    this.users = []
  }

  public searchTags = (text) => {
    return this.usersService
      .creatorSearch({ user_name: text })
      .pipe(map(res => res['creators']['map'](v => ({
        display: v['user_name'],
        value: v['id']
      }))));
  }

  public onTagAdd = (tag) => {
    const usersData = this.users.map(tag => tag.value)
    this.userOutput.emit(usersData);
  }

  public onTagRemove = (tag) => {
    const usersData = this.users.map(tag => tag.value);
    this.userOutput.emit(usersData);
  }



}
