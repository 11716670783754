import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { LeagueCategory } from '../../core/models/api/league-category';
import { LeagueCategoryService } from '../../core/services/league/league-category.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateLeagueCategoryComponent } from '../../modal/create-league-category/create-league-category.component';

@Component({
  selector: 'app-leauge-category',
  templateUrl: './leauge-category.component.html',
  styleUrls: ['./leauge-category.component.scss']
})

export class LeaugeCategoryComponent implements OnInit {
  loading: boolean;
  globalSort: any = "";
  filter: any = {};
  frozenCols: any[];
  cols: any;
  closeResult: string;
  leagueCategory: LeagueCategory;
  selectedLeagueCategory: LeagueCategory;
  constructor(private modalService: NgbModal, private leagueCategoryService: LeagueCategoryService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'category_name', header: 'Category Name' },
      // { field: 'brand', header: 'Brand' },
      { field: 'category_code', header: 'Category Code' },
      { field: 'game_type', header: 'Game Type' },
      { field: 'active', header: 'Active' },

      { field: 'character_types', header: 'Character Type' },
      { field: 'order_index', header: 'Order Index' },
      { field: 'is_auto_game', header: 'Is Auto Game' },
      { field: 'show_on_discover', header: 'Show On Discover' },
      { field: '', header: 'Action' }
    ];
    // this.leagueCategory = JSON.parse(this.leagueCategoryService.getLeagueCategory());
    this.GetData({})
  }

  userFilter(val, field) {
    if (field == 'first_name') {
      field = 'name'
    }
    var obj = {};
    obj[field] = val;


    this.filter['search'] = obj;
    this.GetData(this.filter);
  }

  customSort(e) {
    let obj = {};
    if (e.field == 'first_name') {
      // e.field='first_name'
    }
    if (e.order == 1) {
      obj[e.field] = 'desc'
    } else {
      obj[e.field] = 'asc'
    }

    if (this.globalSort != e.field + " " + e.order) {

      this.filter['sort'] = obj;
      this.GetData(this.filter);
      this.globalSort = e.field + " " + e.order
      // console.log(this.filter);
    }
  }
  GetData(obj) {

    this.leagueCategoryService.getLeagueCategory1(obj).subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res));
      const getCharData = JSON.stringify(getData);
      this.leagueCategory = JSON.parse(getCharData);
      this.loading = false;


    });
  }

  openCreateLeagueCategoryDialog(levelData): void {
    const dialogRef = this.dialog.open(CreateLeagueCategoryComponent, {
      width: '650px',
      data: levelData
    });

    dialogRef.afterClosed().subscribe(result => {
      //  console.log('The dialog was closed');
    });
  }

}
