import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GetTransactionService } from '../../../core/services/users/get-transaction.service';
import { TostService } from '../../../core/services/common/tost.service';
import { UsersService } from '../../../core/services/users.service';
import lodash from 'lodash';
@Component({
  selector: 'app-user-wallet-summery-report-modal',
  templateUrl: './user-wallet-summery-report-modal.component.html',
  styleUrls: ['./user-wallet-summery-report-modal.component.scss']
})
export class UserWalletSummeryReportModalComponent implements OnInit {
  walletReport: any;
  loading: boolean;
  user: any;
  walletAmtTypeResults: any[];
  isNotUpdate = true;
  walletSummeryResults: any = [];
  pendingAmtTypeResults: any = [];
  pendingSummeryResults: any = [];
  totalPendingAmt: Number;
  isPendingFix: boolean = true;
  isWalletFix: boolean = true;
  constructor (private modalService: NgbModal, public dialogRef: MatDialogRef<UserWalletSummeryReportModalComponent>, private usersService: UsersService,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private transactionService: GetTransactionService) { }


  ngOnInit (): void {
    if (this.data && this.data.id) {
      this.loading = true;
      this.getData();
    }
  }

  getData () {
    this.loading = true;
    this.getUser(this.data.id);
    this.transactionService.getWalletSummaryReport(this.data.id).subscribe(
      data => {
        const res = JSON.stringify(data);
        const walletReport = JSON.parse(res);
        this.walletReport = JSON.parse(res);
        this.walletSummeryResults = walletReport.wallet_summery_result;
        this.walletAmtTypeResults = walletReport.wallet_amt_type_result;
        this.pendingSummeryResults = walletReport.pending_summery_result;
        this.pendingAmtTypeResults = walletReport.pending_amt_type_result;
        this.walletAmtTypeResults = this.walletAmtTypeResults.map(e => {
          if (e.amount_type === 'COIN') {
            e.user_display_balance = this.user.coin_balance;
          }
          if (e.amount_type === 'CREDIT') {
            e.user_display_balance = this.user.credit_balance;
          }
          return e;
        })
        const totalPendingAmt = this.pendingAmtTypeResults.map(item => Number(item.sum)).reduce((prev, curr) => prev + curr, 0);
        const Obj = {
          amount_type: 'Total => ',
          sum: totalPendingAmt,
          user_display_balance: Number(this.user.pending_balance)
        }
        this.pendingAmtTypeResults.push(Obj);
        this.checkFixBal();
      },
      err => {
        this.loading = false;
      });
  }
  checkFixBal () {
    if (this.walletAmtTypeResults && this.walletAmtTypeResults.length) {
      this.walletAmtTypeResults.forEach(w => {
        if (w.amount_type == 'COIN') {
          if (Number(this.user.coin_balance) !== Number(w.sum)) {
            this.isWalletFix = false;
          }
        } else if (w.amount_type == 'CREDIT') {
          if (Number(this.user.credit_balance) !== Number(w.sum)) {
            this.isWalletFix = false;
          }
        }
      })
    }
    if (this.pendingAmtTypeResults) {
      let total = this.pendingAmtTypeResults.map(item => Number(item.sum)).reduce((prev, curr) => prev + curr, 0);
      total = total - this.user.pending_balance;
      if (Number(this.user.pending_balance) !== Number(total)) {
        this.isPendingFix = true;
      }
    }
    this.loading = false;
  }
  close (): void {
    this.dialogRef.close(this.isNotUpdate);
  }
  walletBalFixClick () {
    this.loading = true;
    this.transactionService.fixWalletBal(this.data.id).subscribe(
      data => {
        this.tostService.tostrFun('Wallet balance updated  successfully!', 'success');
        // this.getData();
        this.isNotUpdate = false;
        this.loading = false;
        this.close();
      },
      err => {
        this.loading = false;
        this.close();
      });

  }
  pendingBalFixClick () {
    this.loading = true;
    this.transactionService.fixPendingBal(this.data.id).subscribe(
      data => {
        this.tostService.tostrFun('Wallet balance updated  successfully!', 'success');
        //  this.getData();
        this.isNotUpdate = false;
        this.loading = false;
        this.close();
      },
      err => {
        this.loading = false;
        this.close();
      });
  }
  getUser (userId) {
    if (userId) {
      this.usersService.getUsersData1({
        search: { id: userId }
      }).subscribe(data => {
        const users = data['usersList'];
        this.user = lodash.get(users, '0');

      });
    }
  }
}
