import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GetTransactionService } from '../../../core/services/users/get-transaction.service';
import { Transaction } from '../../../../modules/core/models/api/transaction';
import { TostService } from '../../../core/services/common/tost.service';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';
import { SelfgameService } from '../../../core/services/game/selfgame.service'
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-self-game-winner-declaration',
  templateUrl: './self-game-winner-declaration.component.html',
  styleUrls: ['./self-game-winner-declaration.component.scss']
})
export class SelfGameWinnerDeclarationComponent implements OnInit {

  cols: any;
  frozenCols: any[];
  gameData: any[] = [];
  characters: any = {};
  // notes: any;
  score2: string = 'score2';
  score1: string = 'score1';
  tie: string = 'tie';
  winner: string = '';
  vote_char1: number = 0;
  vote_char2: number = 0;
  is_group_declaration: boolean = true;


  constructor (private modalService: NgbModal, private spinner: NgxSpinnerService, private selfgameService: SelfgameService, private gameBankService: GameBankService, public dialogRef: MatDialogRef<SelfGameWinnerDeclarationComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private transactionService: GetTransactionService) { }

  ngOnInit (): void {
    this.cols = [

      { field: 'game_text', header: 'Game Text', width: '250px' },
      { field: 'character1', header: 'Character 1', width: '170px' },
      { field: 'character2', header: 'Character 2', width: '170px' },
      { field: 'Tie', header: 'Tie', width: '160px' },
      // { field: 'note', header: 'note', width: '100px' },
    ];


    if (this.data && this.data.data.id) {
      this.spinner.show();
      this.gameBankService.getGameById(this.data.data.id).subscribe(
        data => {
          this.spinner.hide();
          const res = JSON.stringify(data);
          let gamedataArr = [];
          gamedataArr.push(JSON.parse(res))
          this.gameData = gamedataArr;
          const characterData = JSON.stringify(this.gameData);
          this.characters = JSON.parse(characterData);
          if (this.characters[0].votes && this.characters[0].votes.char_1) {
            this.vote_char1 = this.characters[0].votes.char_1
          }
          if (this.characters[0].votes && this.characters[0].votes.char_2) {
            this.vote_char2 = this.characters[0].votes.char_2
          }
        },
        err => {
          // this.tostService.tostrFun(err.error.errors[0].message, 'error');
          // this.dialogRef.close();
        });

    }



  }
  submitWinnerData () {
    let now = new Date();
    let hasErr = "";
    this.spinner.show();

    if (this.winner == '') {
      hasErr = 'should be select one character as winner or tie ';
    }
    // } else if (this.score1 && this.score2) {
    //   hasErr = "You can select only one character as a winner.";
    // } else if (this.score1 || this.score2 && this.tie) {
    //   hasErr = "should be select at least one character as winner or tie. not select both tie and character";
    // }
    // else if (this.score1 || this.score2 && this.tie) {
    //   hasErr = "should be select at least one character as winner or tie. not select both tie and character";
    // }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      this.spinner.hide();
      return;
    }
    let WinnerObj = {};
    let character1_score = 50;
    let character2_score = 50;
    if (this.winner == this.score1) {
      character1_score = 100;
    }
    if (this.winner == this.score2) {
      character2_score = 100;
    }
    if (this.winner == this.tie) {
      character1_score = 50;
      character2_score = 50;
    }


    WinnerObj = {
      "character_id_1": this.characters[0].characters[0].id,
      "character_id_2": this.characters[0].characters[1].id,
      "character_score_1": character1_score, "character_score_2": character2_score
    }


    const finalObj = { game_id: this.data.data.id, characters_score: WinnerObj }
    this.selfgameService.selfGameDeclareWinner(finalObj).subscribe(
      data => {
        this.tostService.tostrFun("Winner declared successfully.", 'success');
        this.dialogRef.close();
        this.spinner.hide();
      },
      err => {
        //    this.tostService.tostrFun('Winner declared  unsuccessfully.', 'error');
        this.dialogRef.close();
        this.spinner.hide();
      });
  }
  onRefrash (e) {
    // this.notes = e;
  }
  close (): void {
    this.dialogRef.close(true);
  }

}

