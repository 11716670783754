<div class="modal-body testclass">
  <div class="row">
    <div class="col-md-12">
      <div>

        Do you want to remove trending tag?

      </div>

    </div>

  </div>


  <div class="row" style="margin-top: 15px;">
    <div class="col-md-6">

      <button mat-raised-button (click)="removeTrendingTag()" class="btn  btn-danger pull-right " data-dismiss="alert"
        aria-label="Close">Ok</button>

    </div>
    <div class="col-md-6">
      <button mat-button mat-raised-button (click)="close()" class="btn btn-danger pull-right " data-dismiss="alert"
        aria-label="Close">
        Cancel
      </button>

    </div>
  </div>



</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>