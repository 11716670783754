import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UsersService } from '../../../core/services/users.service';
import lodash from 'lodash';
import { TostService } from '../../../core/services/common/tost.service';
@Component({
  selector: 'app-search-user-modal',
  templateUrl: './search-user-modal.component.html',
  styleUrls: ['./search-user-modal.component.scss']
})
export class SearchUserModalComponent implements OnInit {
  userList = [];
  debounceConfig;
  @Input() index: Number;
  @Input() CPCIndex: Number;
  @Input() parent: any;
  @Input() is_cpc_parent: any;
  @Input() is_parent: any;
  @Input() CPCParent: any;
  @Input() globalCutConfig: any;
  @Output() removedParent = new EventEmitter<any>();
  @Output() changedParent = new EventEmitter<any>();
  @Output() removedCPCParent = new EventEmitter<any>();
  @Output() changedCPCParent = new EventEmitter<any>();
  id: Number;
  parent_user_name: string;
  cpc_parent_user_name: string;
  cpc: Number;
  label: string;
  txt_label: string;
  constructor (private usersService: UsersService, private tostService: TostService) { }

  ngOnInit (): void {

    if (this.is_parent) {
      this.label = `Select Parent User ${this.index}`;
      this.txt_label = ` Cut: %`;
      if (this.parent['user_id']) {
        this.usersService.getUsersData1({
          search: { id: this.parent['user_id'] }
        }).subscribe(data => {
          const user = data['usersList'];
          this.parent_user_name = lodash.get(user, '0.user_name');
        });
      }
    }
    if (this.is_cpc_parent) {
      this.label = `Select CPC Parent User ${this.CPCIndex}`;
      this.txt_label = ` Cut: %`;
      if (this.CPCParent['user_id']) {
        this.usersService.getUsersData1({
          search: { id: this.CPCParent['user_id'] }
        }).subscribe(data => {
          const user = data['usersList'];
          this.cpc_parent_user_name = lodash.get(user, '0.user_name');
        });
      }
    }
  }
  searchUsers (ev) {
    if (this.debounceConfig) {
      clearTimeout(this.debounceConfig);
    }

    this.debounceConfig = setTimeout((username) => {
      this.usersService.getUsersData1({
        search: { user_name: username }
      }).subscribe(data => {
        this.userList = data['usersList'];
      });
    }, 500, ev.target.value);
  }
  close (e) {
    this.removedParent.emit(e);
  }

  setUserName (ev) {
    const userIds = this.globalCutConfig.parents.map(u => Number(u.user_id));
    if (userIds.includes(Number(ev.option.id))) {
      this.parent_user_name = "";
      this.tostService.tostrFun(`You already selected this parent`, 'error');
    } else {
      this.parent.user_id = ev.option.id;
      this.changedParent.emit({ parent: this.parent, index: this.index });
    }
  }
  setCPCUserName (ev) {

    const userIds = this.globalCutConfig.consulting_pc_pool_parents.map(u => Number(u.user_id));
    if (userIds.includes(Number(ev.option.id))) {
      this.cpc_parent_user_name = "";
      this.tostService.tostrFun(`You already selected this CPC parent`, 'error');
    } else {
      this.CPCParent.user_id = ev.option.id;
      this.changedCPCParent.emit({ parent: this.CPCParent, index: this.CPCIndex });
    }

  }
  changePercentage () {
    this.parent.percentage = this.toFixedFun(this.parent.percentage);
    this.changedParent.emit({ parent: this.parent, index: this.index });
  }
  changeCPCPercentage () {
    this.CPCParent.percentage = this.toFixedFun(this.CPCParent.percentage);
    this.changedCPCParent.emit({ parent: this.CPCParent, index: this.CPCIndex });
  }
  toFixedFun (e) {
    e = Number(e);
    e = e.toFixed(3);
    return Number(e);
  }
  closeCPC (e) {
    this.removedCPCParent.emit(e);
  }
}
