import { Component, Inject, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameBankService } from '../../core/services/game-bank/game-bank.service';
import { SelfgameService } from '../../core/services/game/selfgame.service';
import { SiteConstants } from '../../../config/site-constants';
import { map } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TostService } from '../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefDataService } from '../../core/services/common/ref-data.service';

@Component({
  selector: 'app-promotion-banner-custom',
  templateUrl: './promotion-banner-custom.component.html',
  styleUrls: ['./promotion-banner-custom.component.scss']
})
export class PromotionBannerCustomComponent implements OnInit {
  @Output() promotionBannerSettings = new EventEmitter();
  @Input() data: any;
  promoBannerObj = {
    show_after_x_game: 0,
    show_max: 0,
    show_of_every_x_game: 0,
    game_ids: [],
    position: null
  };
  tagsLoading = true;
  freeOnlyLoading = false;

  loading = false;
  icons: any = SiteConstants.icons;
  tooltips: any = SiteConstants.tooltips;
  constructor (
    public dialogRef: MatDialogRef<PromotionBannerCustomComponent>,
    private gameBankService: GameBankService,
    private tostService: TostService,
    private spinner: NgxSpinnerService,
    private refDataService: RefDataService,
    private selfgameService: SelfgameService
  ) { }

  ngOnInit (): void {
    //    this.gameIds = this.promotionBannerSettingsData;
    if (this.data.promotion_banners_settings) {
      this.promoBannerObj.show_after_x_game = this.data.promotion_banners_settings.show_after_x_game;
      this.promoBannerObj.show_max = this.data.promotion_banners_settings.show_max
      this.promoBannerObj.show_of_every_x_game = this.data.promotion_banners_settings.show_of_every_x_game;
      this.promoBannerObj.game_ids = this.data.promotion_banners_settings.game_ids
      this.promoBannerObj.position = this.data.promotion_banners_settings.position
    };

  }

  howAfterXGame () {

  }
  public onTextChange (text) {
    if (typeof text === 'number') {
      return true;
    } else {
      return false;
    }
  }
  close (): void {
    this.dialogRef.close(true);
  }

  public searchTags = (text) => {

    return this.gameBankService
      .getBannerGameId({
        search: {
          id: text, start_date: this.data.start_time, end_date: this.data.end_time
        }
      })
      .pipe(map(res => res['gameIds']['map'](v => v['id'])));

  }

  private validateAsync (control: FormControl): Promise<any> {
    return new Promise(resolve => {
      const value = control.value;
      const result: any = isNaN(value) ? {
        isNan: true
      } : null;

      setTimeout(() => {
        resolve(result);
      }, 1);
    });
  }

  public asyncErrorMessages = {
    isNan: 'Please only add numbers'
  };



  public asyncValidators = [this.validateAsync];

  public onTagAdd = (tag) => {
    const tags = this.promoBannerObj.game_ids.map(tag => tag.value)
    this.promotionBannerSettings.emit(this.promoBannerObj);

  }

  // onTagRemove = (tag) => {

  //   const index = this.promoBannerObj.game_ids.indexOf(tag);
  //   if (index > -1) {
  //     this.promoBannerObj.game_ids.splice(index, 1);
  //     this.promotionBannerSettings.emit(this.promoBannerObj);

  //   }
  // }

  saveData () {
    this.promotionBannerSettings.emit(this.promoBannerObj);
    this.dialogRef.close();
  }
  showAfterXGame (e) {
    this.promotionBannerSettings.emit(this.promoBannerObj);

  }
  showEveryXgame (e) {
    this.promotionBannerSettings.emit(this.promoBannerObj);

  }
  position (e) {
    this.promotionBannerSettings.emit(this.promoBannerObj);
  }
  showMax (e) {
    this.promotionBannerSettings.emit(this.promoBannerObj);

  }

  // public onTagAdd = (tag) => {
  //   const tags = this.promoBannerObj.game_ids.map(tag => tag.value)
  //   this.promotionBannerSettings.emit(this.promoBannerObj);

  // }

  public onTagRemove = (tag) => {
    this.promoBannerObj.game_ids.map(tag => tag)
    this.promotionBannerSettings.emit(this.promoBannerObj);
  }

}
