import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AffiliateServiceService } from '../../core/services/affiliate/affiliate-service.service'
import { PaginationService } from '../../core/services/common/pagination.service';
import { SearchService } from "../../core/services/common/search.service";
import { Endpoints } from '../../../config/endpoints'
import { SiteConstants } from '../../../config/site-constants';
@Component({
  selector: 'app-affiliate-game-list',
  templateUrl: './affiliate-game-list.component.html',
  styleUrls: ['./affiliate-game-list.component.scss']
})
export class AffiliateGameListComponent implements OnInit {
  affiliateGameListData: any[];
  totalRecords: number;
  loading: boolean;
  selectedAffiliate: any;
  cols: any;
  userID: number;
  TimeoutSearch: any;
  filter: any = {};
  constPage: number = 10;
  globalSort: any = "";
  constructor(private activatedRoute: ActivatedRoute, private affiliateService: AffiliateServiceService, private searchService: SearchService, private paginationService: PaginationService) { }

  ngOnInit(): void {
    // console.log('id is', this.activatedRoute.snapshot.params['id'])

    this.userID = this.activatedRoute.snapshot.params['id'];
    this.cols = [
      { field: 'id', header: 'id', width: '70px' },

      { field: 'game_name', header: 'Game Name' },
      { field: 'status', header: 'Status', width: '70px' },
      { field: 'total_playing', header: 'Total Playing', width: '70px' },

      { field: 'start_time', header: 'Start Date' },
      { field: 'end_time', header: 'End Date' },
      { field: 'last_join_time', header: 'Last Join Date' },

      { field: '', header: 'Action', width: '80px' },
    ];

    this.GetData({});
  }

  customPagination(event) {
    let data = this.paginationService.paginationFun(event, this.globalSort, this.constPage, this.filter)

    this.filter = data.filter;
    this.globalSort = data.globalSort;
    this.constPage = data.defaultPage;
    this.GetData(this.filter);
  }


  userFilter(val, field) {


    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
    this.GetData(this.filter);
    }, SiteConstants.FiltertimeOut);

  }

  GetData(obj) {
    this.loading = true;
    this.affiliateService.getAffiliateData(obj, this.userID).subscribe(data => {
      this.loading = false;
      const res = JSON.stringify(data);
      this.totalRecords = (JSON.parse(res).total);
      const getData = (JSON.parse(res)).gameList;
      const getAffiliateGameListData = JSON.stringify(getData);
      this.affiliateGameListData = JSON.parse(getAffiliateGameListData);

    });
  }

  downloadUserList(gameId) {
    var currentUser = localStorage.getItem('currentUser');
    var Token = JSON.parse(currentUser).token;
    var url = Endpoints.api.affiliate.downloadUserList.replace('{gameId}', gameId).replace('{token}', Token);
    window.open(url);
  }

}
