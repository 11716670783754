import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { SiteConstants } from '../../../../config/site-constants';
import { UsersService } from '../../../core/services/users.service';
import { TostService } from '../../../core/services/common/tost.service';
import { ConfirmCountryModalComponent } from "../confirm-country-modal/confirm-country-modal.component";
@Component({
  selector: 'app-blacklist-country-state-edit',
  templateUrl: './blacklist-country-state-edit.component.html',
  styleUrls: ['./blacklist-country-state-edit.component.scss']
})
export class BlacklistCountryStateEditComponent implements OnInit {
  isStateFlag: any = true;
  blackListCountry: any;
  action: any = "create";
  icons = SiteConstants.icons;
  countryList: any;
  stateList: any;
  tooltips = SiteConstants.tooltips;
  selectedCountry: any;
  selectedState: any;

  imagePreview: any;
  constructor (
    public dialogRef: MatDialogRef<BlacklistCountryStateEditComponent>,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private refDataService: RefDataService,
    private tostService: TostService,
    private dialog: MatDialog
  ) { }

  ngOnInit (): void {
    if (this.data.blackListCountry) {
      this.action = "update"
      this.selectedState = {};
    } else {
      this.action = "create"
    }
    this.blackListCountry = this.data.blackListCountry || {
      country_code: '',
      state_code: '',
      is_active: 1,
      country_name: '',
      state_name: ''
    };

    this.usersService
      .getCountryList()
      .subscribe(res => {
        this.countryList = res;
      }, () => {
        this.spinner.hide()
      });
    if (this.data.blackListCountry) {
      this.selectedCountry = this.blackListCountry.country_code;
      this.isStateFlag = false;
      if (this.blackListCountry.state_code) {
        this.usersService
          .getStateList(this.blackListCountry.country_code)
          .subscribe(res => {
            this.stateList = res;
            this.isStateFlag = true;
            this.selectedState = this.blackListCountry.state_code;

          }, () => {
            this.spinner.hide()
          });

      }

    }
  }

  close () {
    this.dialogRef.close(true);
  }

  openDialog (ev): void {


    if (this.blackListCountry.country_code && this.blackListCountry.state_code == "") {
      ev.stopPropagation();
      const dialogRef = this.dialog.open(ConfirmCountryModalComponent, {
        width: '350px',
        data: { rowData: this.blackListCountry },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.rowData && result.save) {
          this.saveblackListCountry();
        } else {
          return;
        }

      });

    } else {
      this.saveblackListCountry();
    }
  }

  saveblackListCountry () {
    // console.log(this.blackListCountry)
    this.blackListCountry.action = this.action;
    this.blackListCountry.is_active = this.blackListCountry.is_active ? 1 : 0;

    var hasErr = '';
    if (!this.blackListCountry.country_code) {
      hasErr = "please select country";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    this.spinner.show();
    this.usersService
      .saveBlackListCountryState(this.blackListCountry)
      .subscribe(res => {
        this.dialogRef.close();
        this.spinner.hide()
        this.tostService.tostrFun(`BlackListed country ${this.action} successfully`, 'success');
        return;
      }, () => {
        this.spinner.hide()
        return;
      });

  }

  selectState (e) {
    if (e.value) {
      this.blackListCountry.state_code = e.value;
      const selectedStateObj = this.stateList.filter(c => c.iso_code == e.value)
      this.blackListCountry.state_name = selectedStateObj[0].name;
    }
  }
  selectCountry (e) {
    if (e.value) {
      this.blackListCountry.country_code = e.value;
      const selectedCountryObj = this.countryList.filter(c => c.iso_code == e.value)
      this.blackListCountry.country_name = selectedCountryObj[0].name;
      this.isStateFlag = selectedCountryObj[0].have_states;
      this.usersService
        .getStateList(e.value)
        .subscribe(res => {
          this.stateList = res;
          this.blackListCountry.state_code = '';
          this.blackListCountry.state_name = '';
        }, () => {
          this.spinner.hide()
        });
    }
  }

}
