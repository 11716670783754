<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Character {{isCreate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form (ngSubmit)="submitData()">
    <div class="modal-body">


        <div class="ui-g">
            <div class="ui-g-6">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="characterObj.character_name" name="character_name" id="character_name"
              placeholder="Character Name" type="text" class="form-control">
                    </mat-form-field>

                </div>
            </div>
            <div class="ui-g-6">
                <div class="form-group">



                    <!-- <p-dropdown [options]="characterType" [disabled]="isCreate=='Update'" name="selectedCar2"
                        formControlName="character_type" (onChange)="changeWebsite($event)"
                        [style]="{'width': '250px'}">

                    </p-dropdown> -->



                    <mat-form-field appearance="fill">
                        <mat-label>Select Character's Type</mat-label>
                        <mat-select [(ngModel)]="characterObj.character_type" name="character_type" id="character_type"
                            (selectionChange)="changeWebsite($event)" [disabled]="isCreate=='Update'">
                            <mat-option *ngFor="let character of characterType" [value]="character.value">
                                {{character.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>




                </div>
            </div>

        </div>


        <div class="ui-g" *ngIf="sourceType?.length > 0 ?true:false && isCreate=='Create'">

            <div class="ui-g-6">

                <div class="form-group">
                    <!-- <p-dropdown [options]="sourceType" [disabled]="isCreate=='Update'" id="source_type"
                        name="source_type" formControlName="source_type" [style]="{'width': '250px'}">
                    </p-dropdown> -->


                    <mat-form-field appearance="fill">
                        <mat-label>Select Source Type</mat-label>
                        <mat-select [(ngModel)]="characterObj.source_type" name="source_type" id="source_type">
                            <mat-option *ngFor="let source of sourceType" [value]="source.source_code">
                                {{source.source_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </div>

            <div class="ui-g-6">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input [(ngModel)]="characterObj.source_character_id" matInput name="source_character_id"
                            placeholder="Social ID/Stock Symbol/Currency Code" type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <input formControlName="image_url" matInput placeholder="Picture Url" type="text">
                </mat-form-field>
            </div>

        </div> -->
        <div class="ui-g">

            <div class="ui-g-6">
                <mat-form-field class="example-full-width">
                    <input [(ngModel)]="characterObj.image_url" name="image_url" matInput placeholder="Picture Url"
                        (change)="imageChange($event)" type="text">
                </mat-form-field>
            </div>
            <div class="ui-g-6">
                <div class="form-group">
                    <label for="file">Select Character's Picture</label>
                    <div>
                        <div class="file-field">
                            <input style="display: none" type="file" (change)="onFileChange($event)" #hiddenfileinput>
                            <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">
                                Choose file</label>


                        </div>
                    </div>
                    <!-- <input formControlName="file" id="file" type="file" class="form-control"
                        (change)="onFileChange($event)"> -->

                    <!-- <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                            <div *ngIf="f.file.errors.required">File is required.</div>
                        </div> -->
                </div>

            </div>


        </div>
        <div class="ui-g">
            <div class="ui-g-5">
                <p-card>
                    <div class="card-container game-card-box">
                        <div class="card-box card-left">
                            <div [ngStyle]="images" class="card-image card-left-image"></div>
                            <!-- <div class="card-player-name card-player-left"><span class="ng-binding"></span></div> -->
                        </div>
                        <div class="card-box card-left">
                            <div [ngStyle]="images" class="card-image card-left-image"></div>
                            <!-- <div class="card-player-name card-player-right"><span class="ng-binding"></span></div> -->
                        </div>
                    </div>


                </p-card>
            </div>
            <div class="ui-g-6">

        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [transform]="transform"
          [aspectRatio]="4 / 3" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" style="text-align: center;">
                </image-cropper>

                <div *ngIf="isCrop==true" style="margin: 2% 30% 0 30%;">

                    <p-slider [(ngModel)]="scale" name="scale" max="10" (onChange)="zoomIn($event)">
                    </p-slider>


                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button mat-raised-button type="submit" class=" btn btn-danger pull-right " data-dismiss=" alert"
            aria-label="Close">Submit</button>
    </div>
</form>