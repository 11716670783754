import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../core/services/common/tost.service';
import { SelfgameService } from '../../core/services/game/selfgame.service'
@Component({
  selector: 'app-ignore-dispute',
  templateUrl: './ignore-dispute.component.html',
  styleUrls: ['./ignore-dispute.component.scss']
})
export class IgnoreDisputeComponent implements OnInit {

  ReportedData: any;


  gameId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private selfgameService: SelfgameService, public dialogRef: MatDialogRef<IgnoreDisputeComponent>) { }

  ngOnInit(): void {
    // console.log('delete priceoption lis', this.data.priceOptionList)
    // console.log('index is', this.data.index)



  }

  ignoreDispute() {

    let obj = {
      game_id: this.data.game_id
    }

    this.selfgameService.DisputesIgnore(obj).subscribe(data => {
      //   console.log("create level data", data);
      this.tostService.tostrFun('Game approve successfuly.', 'success');
    },
      err => {
        // this.tostService.tostrFun("Something Went Wrong", 'error');
      }
    )
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close(true);
  }

}
