<div class="modal-body testclass">
    <div class="row">
        <div class="col-md-12">
            <div>

                Do you want to remove character?

            </div>

        </div>

    </div>


    <div class="row" style="margin-top: 15px;">
        <div class="col-md-6">

            <button mat-raised-button (click)="removeCharacter()" class="btn  btn-danger pull-right "
                data-dismiss="alert" aria-label="Close">Ok</button>

        </div>
        <div class="col-md-6">
            <button mat-button mat-raised-button (click)="close()" class="btn btn-danger pull-right "
                data-dismiss="alert" aria-label="Close">
                Cancel
            </button>

        </div>
    </div>



</div>