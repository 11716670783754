import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelfgameService } from '../../core/services/game/selfgame.service';

@Component({
  selector: 'app-selfgame-game-performance-modal',
  templateUrl: './selfgame-game-performance-modal.component.html',
  styleUrls: ['./selfgame-game-performance-modal.component.scss']
})
export class SelfgameGamePerformanceModalComponent implements OnInit {
  alldata: any = [];
  loading: boolean;
  questionData: any = [];
  status: any = ""
  constructor(private selfGameService: SelfgameService, public dialogRef: MatDialogRef<SelfgameGamePerformanceModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.loading = true;
    this.getSelfGamePerformanceRecord(this.data.data.id);
  }
  getStatusText(e) {

    if (this.data.status && this.data.status.length > 0) {
      var status = this.data.status.filter(status => status.value == e);
      return status[0].label;

    }


  }
  getSelfGamePerformanceRecord(id) {

    this.selfGameService.getSelfGamePerformance(id).subscribe(data => {
      //  console.log("game performance ", data);
      // this.alldata = data.disputesList;
      const res = JSON.stringify(data);

      this.alldata = (JSON.parse(res));
      let status = JSON.stringify(this.alldata)
      let statusdata = JSON.parse(status).status;

      this.status = this.getStatusText(statusdata);
      this.questionData.push(this.alldata);
      // const getCharData = JSON.stringify(getData);
      // this.alldata = JSON.parse(getCharData);
      this.loading = false;
    },
      err => {
        //  this.dialogRef.close();
        this.loading = false;
      }
    );
    // this.close();
  }

  close() {
    this.dialogRef.close(true);
  }

}
