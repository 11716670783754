import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Character } from '../../core/models/api/charcter';
import { CharacterService } from '../../core/services/character.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RefDataService } from '../../core/services/common/ref-data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from '../../../config';

import { GalleryImageEditComponent } from '../../modal/gallery-image-edit-modal/gallery-image-edit/gallery-image-edit.component';
import { RemoveGallaryImageComponent } from '../../modal/gallery-image-edit-modal/remove-gallary-image/remove-gallary-image.component'
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteConstants } from '../../../config/site-constants';
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ImagesComponent implements OnInit {
  cols: any;
  throttle = 10;
  scrollDistance = 1;
  scrollUpDistance = 2;
  TimeoutSearch: any;
  globalSort: any = "";
  filter: any = {};
  frozenCols: any[];
  loading: boolean;
  characterImage: any = [];
  characterImage1: any = [];
  isOpen: boolean;
  sortSelected = '';
  notscrolly = true;
  notEmptyPost = true;
  pagination: any = {
    limit: 50,
    page: 1
  }

  imagesForm = new FormGroup({

    name: new FormControl('', []),

  });


  images: Character;
  constructor(private httpClient: HttpClient, private characterService: CharacterService, private modalService: NgbModal, public dialog: MatDialog, private spinner: NgxSpinnerService) {
    this.isOpen = false;
  }

  ngOnInit(): void {
    //this.characterImage = JSON.parse(this.characterService.getCharacterData());
    // this.characterService.getCharacterData().subscribe(data => {
    //   const res = JSON.stringify(data);
    //   const getData = (JSON.parse(res)).list;
    //   const getCharData = JSON.stringify(getData);
    //   this.characterImage=JSON.parse(getCharData);

    // });
    // this.GetData({});
    this.spinner.show();
    this.customPagination();
  }

  foods: Food[] = [
    { value: 'asc', viewValue: 'Ascending' },
    { value: 'desc', viewValue: 'Descending' },
  ];

  userFilter(val, field) {

    let obj = {};
    this.characterImage = [];
    this.characterImage1 = [];
    let pagiantionObj = { limit: 50, page: 1 };
    this.filter['pagination'] = pagiantionObj;
    // if (field == 'first_name') {
    //   field = 'name'
    // }
    if (val) {
      const searchObj = {};
      let field = 'character_name';
      searchObj[field] = val;
      this.filter.search = Object.assign({}, this.filter.search, searchObj);

    } else if (this.filter.search) {
      let field = 'character_name';
      obj[field] = val;
      this.filter.search = obj;
      // this.filter.search = Object.assign({}, this.filter.search,{question_type:"C"})
    }
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
    this.GetData(this.filter);
    }, SiteConstants.FiltertimeOut);
    setTimeout(() => {
      this.characterImage = this.characterImage1;
    }, 2000);
  }
  reset() {
    this.imagesForm.get("name").setValue('');
    this.sortSelected = '';
    this.GetData({});

  }
  customSort(e) {
    //   let obj={} ;
    //   if(e.field=='first_name'){
    //     e.field='name'
    //   }
    // if(e.order==1){
    // obj[e.field]='desc'
    // } else{
    //   obj[e.field]='asc'
    // }

    // if(this.globalSort!=e.field+" "+e.order){

    //  this.filter['sort'] =obj;
    //  this.GetData( this.filter);
    //  this.globalSort=e.field+" "+e.order
    //   console.log(this.filter);
    // }
    let obj = {};
    let pagiantionObj = { limit: 50, page: 1 };
    this.filter['pagination'] = pagiantionObj;
    if (this.sortSelected) {
      let field = 'character_name';
      obj[field] = this.sortSelected;
      this.filter['sort'] = obj;
      this.GetData(this.filter);
      setTimeout(() => {
        this.characterImage = this.characterImage1;
      }, 2000);
    }

  }
  customPagination() {
    let obj = {};
    if (this.filter.pagination) {
      let pagiantionObj = { limit: 50, page: 1 };
      this.filter['pagination'] = obj;
    } else {
      let pagiantionObj = { limit: 50, page: 1 };
      this.filter['pagination'] = pagiantionObj;
    }
    if (!this.filter.sort) {
      let field = 'character_name';
      obj = {};
      obj[field] = 'desc';
      this.filter['sort'] = obj;
    }
    this.GetData(this.filter);
    setTimeout(() => {

      this.characterImage = this.characterImage1;

    }, 2000);

  }

  GetData(obj) {
    this.spinner.show()
    this.characterService.getCustomCharacterData(obj).subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).list;
      const getCharData = JSON.stringify(getData);
      this.characterImage1 = JSON.parse(getCharData);
      this.spinner.hide();
      if (getData.length === 0) {
        this.notEmptyPost = false;
      }
      // this.characterImage = this.characterImage1;
      // this.pagination.page = this.pagination.page + 1;
      // this.characterImage = this.characterImage.concat(this.characterImage1);
      // this.notscrolly = true;
    });
  }

  onScroll() {
    if (this.notscrolly && this.notEmptyPost) {
      this.spinner.show();
      this.notscrolly = false;
      this.loadNextPost();
    }
  }
  onUp() {

  }

  loadNextPost() {
    let pagiantionObj = this.pagination;
    this.filter['pagination'] = pagiantionObj;
    this.GetData(this.filter);
    this.pagination.page = this.pagination.page + 1;
    this.characterImage = this.characterImage.concat(this.characterImage1);
    this.notscrolly = true;
  }

  openDialog(characterData): void {
    //console.log("edit image data", characterData);
    const dialogRef = this.dialog.open(GalleryImageEditComponent, {
      width: '700px',
      data: characterData
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.customPagination();
    });
  }

  openImageRemoveDialog(imageData): void {
    // console.log("delete image data", imageData);
    const dialogRef = this.dialog.open(RemoveGallaryImageComponent, {
      width: '350px',
      data: imageData
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.customPagination();
    });
  }

}
