import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SelfgameService } from '../../core/services/game/selfgame.service'
import { TostService } from '../../core/services/common/tost.service'

@Component({
  selector: 'app-selfgame-cancel-modal',
  templateUrl: './selfgame-cancel-modal.component.html',
  styleUrls: ['./selfgame-cancel-modal.component.scss']
})
export class SelfgameCancelModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SelfgameCancelModalComponent>,

    private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private selfgameService: SelfgameService) { }

  ngOnInit(): void {
  }
  removeCharacter() {

    this.selfgameService.cancelGame(this.data.id).subscribe(
      res => {
        this.tostService.tostrFun("Game cancelled successfuly.", 'success');
        this.dialogRef.close();

      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close();
      });

  }
  close(): void {
    this.dialogRef.close(true);
    //  console.log("object", this.data);
  }
}
