<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Manage API Key</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form>
  <div class="modal-body">


    <div class="row">
      <div class="col-md-11">
        <div class="form-group">
          <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="ApiKeyData.api_key" [readonly]="true" name="api_key"
              placeholder="WIDGET API KEY:" type="text" class="form-control">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-1">
        <button pButton type="button" class="ui-button-warning" icon="fa fa-refresh" style="margin: 30px .3em .3em 0"
          (click)="readonlyFun()">
        </button>
      </div>
    </div>

  </div>


  <div class="modal-footer">
    <button mat-raised-button type="submit" [disabled]="isReadOnly" class="btn btn-danger pull-right"
      (click)="openDialog($event)">Save</button>
  </div>
</form>