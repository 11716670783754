<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ super && super.id ? 'Update' : 'Create' }} super tag</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <form (ngSubmit)="saveSuperTag()">
      <div class="modal-body">
        <div class="row">
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Title</mat-label>
                <input matInput [(ngModel)]="super.title" name="title" id="title" class="form-control">
              </mat-form-field>
            </div>
          </div>

          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Tag Key</mat-label>
                <input matInput name="tag_key" [(ngModel)]="super.tag_key" id="tag_key" class="form-control lowercase"
                  [disabled]="super && super.id">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-2">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Tag index</mat-label>
                <input matInput name="tag_index" [(ngModel)]="super.tag_index" type="number" min=1 id="tag_index"
                  class="form-control">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-2">
            <div class="form-group">

              <mat-checkbox [checked]="super.is_active" [(ngModel)]="super.is_active"
                [ngModelOptions]="{standalone: true}"> Status
              </mat-checkbox>
            </div>
          </div>


        </div>


      </div>
      <div class="modal-footer">
        <a mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</a>
        <button mat-raised-button type="submit" class="btn btn-danger pull-right">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>