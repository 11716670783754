<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Payout</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="levelForm">
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="amount" id="amount" placeholder="Amount (USD)*" type="number" class="form-control">
                    </mat-form-field>

                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>Playment Type</mat-label>
                            <mat-select>
                                <mat-option *ngFor="let food of foods" [value]="food.value">
                                    {{food.viewValue}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <mat-form-field class="example-full-width">
                    <input matInput formControlName="note" id="note" placeholder="Note" type="textarea" class="form-control">
                </mat-form-field>
            </div>

        </div>

        <div class="modal-footer">

            <button mat-raised-button type="submit" class="btn btn-primary pull-right" (click)="close()">Submit</button>
        </div>
        <div class="clearfix"></div>


    </form>
</div>