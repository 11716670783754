import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TostService } from '../../core/services/common/tost.service'
import { NotificationService } from '../../core/services/league/notification.service'

@Component({
  selector: 'app-remove-notification-modal',
  templateUrl: './remove-notification-modal.component.html',
  styleUrls: ['./remove-notification-modal.component.scss']
})
export class RemoveNotificationModalComponent implements OnInit {
  recordId: number;
  constructor(public dialogRef: MatDialogRef<RemoveNotificationModalComponent>, private modalService: NgbModal,
    private notificationService: NotificationService, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService) { }

  ngOnInit(): void {
    // console.log('not it',this.data.id)
    this.recordId = this.data.id;
  }

  removeNotification() {
    this.notificationService.deleteNotification(this.recordId).subscribe(data => {
      this.tostService.tostrFun('Notification deleted successfully', 'success');
      this.dialogRef.close();
    },
      err => {
        this.tostService.tostrFun('Something went wrong on serve', 'error')

      }
    )
  }

  close() {
    this.dialogRef.close(true);
  }

}
