import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Character } from '../../core/models/api/charcter';
import { CharacterService } from '../../core/services/character.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { ActionButtonComponent } from '../../action-component/action-button/action-button.component';
import { ActionIconComponent } from '../../action-component/action-icon/action-icon.component';
import { from, observable } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RefDataService } from '../../core/services/common/ref-data.service';
import { PaginationService } from '../../core/services/common/pagination.service';
import { SearchService } from "../../core/services/common/search.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../core/services/common/tost.service';
import * as moment from 'moment';
import { SiteConstants } from '../../../config/site-constants';
import { CharacterEditModalComponent } from '../../modal/character-edit-modal/character-edit-modal.component';
// import { start } from 'repl';
@Component({
  selector: 'app-character',
  templateUrl: './character.component.html',
  styleUrls: ['./character.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CharacterComponent implements OnInit {
  characterEditFlag: Boolean = true;
  filter: any = {};
  start_date: Date;
  end_date: Date;
  globalSort: any = "";
  cols: any;
  charcter: any;
  width: number = 900;
  height: number = 600;
  startdate: any;
  enddate: any;
  modaleId: string = "CharacterEditModalComponent";
  closeResult: string;
  selectedCharacter: Character;
  characterDataById: any;
  content: any;
  isUpdateFlag: Boolean = true;
  frozenCols: any[];
  defaultPage: number = 1;
  rows: number = 10;
  loading: boolean;
  TimeoutSearch: any;
  dateFilterObj: any = {};
  totalRecords: number;
  constructor(public dialog: MatDialog, private characterService: CharacterService,
    private modalService: NgbModal, private refDataService: RefDataService,
    private searchService: SearchService, private paginationService: PaginationService, private tostService: TostService) { }

  ngOnInit(): void {
    this.loading = true;
    this.characterEditFlag = false;
    this.cols = [
      // { field: 'is', header: 'id' },
      { field: 'character_name', header: 'Character Name', width: '450px' },
      // { field: 'brand', header: 'Brand' },
      { field: 'character_type', header: 'Character Type', width: '100px' },
      { field: 'source_type', header: 'Source Type', width: '100px' },
      { field: 'createdAt', header: 'Created At', width: '100px' },
      { field: '', header: 'Action', width: '120px' }

    ];
    this.GetData({});
  }
  openDialog(characterData): void {
    const dialogRef = this.dialog.open(CharacterEditModalComponent, {
      width: '650px',
      data: characterData
    });

    dialogRef.afterClosed().subscribe(result => {
      //  console.log('The dialog was closed');
    });
  }
  startDate(e) {
    //console.log("start date", e);
    this.start_date = e;

  }
  dateFilter() {

    if (new Date(this.start_date) < new Date(this.end_date)) {
      this.dateFilterObj = { 'start_date': this.start_date.toDateString(), 'end_date': this.end_date.toDateString() }
      this.filter.search = Object.assign({}, this.filter.search, this.dateFilterObj)
      this.GetData(this.filter);
    }
    else {
      this.tostService.tostrFun("End Date should be greater than start date", 'error');
    }

  }
  clearDateFilter() {
    this.enddate = '';
    this.startdate = '';
    this.filter.search = {};
    this.GetData(this.filter);
  }
  endDate(e) {
    // console.log("end date", e);
    this.end_date = e;
    this.dateFilterObj = { 'start_date': this.start_date.toDateString(), 'end_date': this.end_date.toDateString() }
    // this.filter['search'] = requestObjet;
    // if (new Date(this.start_date) < new Date(this.end_date)) {
    //   this.userFilter(null, null);

    // } else {
    //   return;
    // }
  }
  userFilter(val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
    this.GetData(this.filter);
    }, SiteConstants.FiltertimeOut);
  }
  customPagination(event) {
    // this.loading = true;

    let data = this.paginationService.paginationFun(event, this.globalSort, this.defaultPage, this.filter)

    this.filter = data.filter;
    this.globalSort = data.globalSort;
    this.defaultPage = data.defaultPage;
    this.GetData(this.filter);
  }

  customSort(e) {
    let obj = {};
    if (e.field == 'first_name') {
      e.field = 'name'
    }
    if (e.order == 1) {
      obj[e.field] = 'desc'
    } else {
      obj[e.field] = 'asc'
    }

    if (this.globalSort != e.field + " " + e.order) {

      this.filter['sort'] = obj;
      this.GetData(this.filter);
      this.globalSort = e.field + " " + e.order
      // console.log(this.filter);
    }

  }
  onRefrash(e) {
    setTimeout(() => {
      this.GetData(this.filter);
    }, 1500);

  }

  GetData(obj) {

    this.characterService.getCustomCharacterData(obj).subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).list;
      this.totalRecords = (JSON.parse(res).totalCount);
      //  console.log(this.totalRecords + "test")
      const getCharData = JSON.stringify(getData);
      this.charcter = JSON.parse(getCharData);
      this.loading = false;
      //const res = JSON.stringify(data);
      //   const getData = (JSON.parse(res)).list;
      //   const getCharData = JSON.stringify(getData);
      //   this.charcter=JSON.parse(getCharData);

    });
  }

}
