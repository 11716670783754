<p>create-league works!</p>
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Create League</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
</button>
</div>
<mat-dialog-content>
    <div class="main-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="modal-body">
                        <form [formGroup]="cLeagueForm" (ngSubmit)="submitData()">

                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="" id="league_name" placeholder="League Name" type="text" class="form-control">
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="" id="no_of_players" placeholder="Number of Players" type="number" class="form-control">
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <p-dropdown  formControlName="" [options]="" optionLabel="name" id="category_name" placeholder="League Type" >
                                        </p-dropdown>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <p-dropdown  formControlName="" [options]="" optionLabel="name" id="category_name" placeholder="League Frequency" >
                                        </p-dropdown>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field>
                                            <input matInput [ngxMatDatetimePicker]="picker1" formControlName="" placeholder="Start Date">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
                                        </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <mat-form-field>
                                        <input matInput [ngxMatDatetimePicker]="picker2" formControlName="" placeholder="End Date">
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker #picker2></ngx-mat-datetime-picker>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="" id="title" placeholder="amount" type="text" class="form-control">
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="" id="title" placeholder="winning price" type="text" class="form-control">
                                        </mat-form-field>


                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <p-dropdown  formControlName="" [options]="" optionLabel="choose league attribute" id="category_name" placeholder="choose league attribute" >
                                        </p-dropdown>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                         <p-dropdown  formControlName="" [options]="" optionLabel="swap end time hours" id="category_name" placeholder="swap end time hours" >
                                        </p-dropdown>

                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="" id="title" placeholder="amount" type="text" class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div> -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <mat-form-field class="example-full-width">
                                            <input matInput formControlName="" id="title" placeholder="attribute question" type="text" class="form-control">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-checkbox> No Swap </mat-checkbox>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <mat-checkbox> Minimum Players </mat-checkbox>

                                    </div>
                                </div>
                            </div>

                            

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group priceoptioncls">
                                        <h5>Game Price Options. <button  mat-raised-button type="submit" class="btn btn-link" (click)="addPriceOption()">+ ADD PRICE OPTIONS </button></h5>
                                        <ng-template ngFor let-xb [ngForOf]="price_options" formControlName="price_options">

                                          

                                            <div class="row">

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <!-- <mat-form-field class="example-full-width">
                                                            <input [disabled]="is_disable == true" matInput id="entry_fee" value="{{xb.entry_fee}}" placeholder="Entry fee" type="number" class="form-control">
                                                        </mat-form-field> -->
                                                        <p-dropdown  formControlName="" [options]="" optionLabel="swap end time hours" id="category_name" placeholder="choose league attribute" >
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <mat-form-field class="example-full-width">
                                                            <input  matInput id="prize money" value="{{xb.prize_money}}" placeholder="Attribute question" type="number" class="form-control">
                                                        </mat-form-field>
                                                        <!-- <mat-form-field class="example-full-width">
                                                            <input matInput formControlName="" id="title" placeholder="Attribute question" type="text" class="form-control">
                                                        </mat-form-field> -->

                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <button type="button" class="close" aria-label="Close" style="margin-top: -8px;" (click)="close()">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>

                            

                        </form>

                    </div>

                </div>

            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer">

    <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="close()">Save</button>
</div>
<div class="clearfix"></div>