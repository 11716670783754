import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SiteConstants } from '../../config/site-constants'
declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export let ROUTES: RouteInfo[] = [];
// [
//   { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
//   { path: '/user', title: 'User', icon: 'person', class: '' },
//   // { path: '/table-list', title: 'Characters', icon: 'content_paste', class: '' },
//   { path: '/character', title: 'Characters', icon: 'perm_identity', class: '' },
//   { path: '/images', title: 'Images', icon: 'camera_front', class: '' },
//   { path: '/payout-list', title: 'Payout List', icon: 'payment', class: '' },
//   { path: '/source-type', title: 'Source Type', icon: 'radio_button_unchecked', class: '' },
//   { path: '/game-bank', title: 'GameBank', icon: 'games', class: '' },
//   // { path: '/pinned-questions', title: 'Pinned Questions', icon: 'radio_button_unchecked', class: '' },
//   // { path: '/pinned-game', title: 'Pinned Game', icon: 'radio_button_unchecked', class: '' },
//   // { path: '/custom-questions', title: 'Custom Questions', icon: 'radio_button_unchecked', class: '' },
//   // { path: '/custom-game', title: 'Custom Games', icon: 'radio_button_unchecked', class: '' },
//   { path: '/affiliate', title: 'Affiliate', icon: 'content_paste', class: '' },
//   { path: '/selfgame', title: 'Self Game', icon: 'games', class: '' },
//   { path: '/settings', title: 'Settings', icon: 'settings', class: '' },
//   { path: '/notifications', title: 'Notification', icon: 'notifications', class: '' },
//   { path: '/levels', title: 'Levels', icon: 'radio_button_unchecked', class: '' },
//   // { path: '/league', title: 'league', icon: 'radio_button_unchecked', class: '' },

//   { path: '/fee-cut', title: 'Fee and Cut SSG', icon: 'radio_button_unchecked', class: '' },
//   // { path: '/coin-rate', title: 'Log', icon: 'radio_button_unchecked', class: '' },
//   { path: '/promo-code', title: 'Promo Code', icon: 'radio_button_unchecked', class: '' },
//   // { path: '/leauge-category', title: 'League category', icon: 'category', class: '' },
//   { path: '/coin-rate', title: 'Coin Rate', icon: 'money', class: '' },
//   // { path: '/coin-rate', title: 'Cluvio Dashboard', icon: 'radio_button_unchecked', class: '' }

// ];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  isLogin: boolean = false;
  allowadmin = [];
  ismini: boolean = true;
  navigate: any = '[/dashboard]'
  accessList = [];
  allow: any = SiteConstants.SideBarMenu;
  @Output() onMini = new EventEmitter<any>();
  constructor (private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit () {
    if (this.authenticationService.currentUserValue) {
      this.isLogin = true;
    }
    let MenuAccessList = localStorage.getItem('currentUser');
    if (MenuAccessList) {
      let list = JSON.parse(MenuAccessList);

      let userRole = list && list.user_role;
      if (userRole === 5) {
        this.navigate = '[/user]'
      } else {
        this.navigate = '[/dashboard]'
      }


      if (list && list.accessList) {
        let AccessListKey = Object.keys(list.accessList);
        let arr = []
        //    arr.push(this.allow[0]);
        for (let i = 0; i < this.allow.length; i++) {
          for (let j = 0; j < AccessListKey.length; j++) {
            if (this.allow[i].title == AccessListKey[j]) {
              arr.push(this.allow[i]);
            }
          }
        }
        ROUTES = arr;
      }

      else {
        let arr = [];
        for (let i = 0; i < this.allow.length; i++) {
          if (['Custom Games', 'Pinned Games', 'Affiliate Manager', 'game-bank', 'Affiliate', 'Images', 'Character', 'Admin Manager'].includes(this.allow[i].title)) {

          }
          else {
            arr.push(this.allow[i]);
          }
        }
        ROUTES = arr;
      }


      this.menuItems = ROUTES.filter(menuItem => menuItem);



    }
  }
  isMobileMenu () {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  logout () {
    this.authenticationService.logout();
  }


}
