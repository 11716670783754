<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <p-table #dt [columns]="cols" [value]="user" [(selection)]="selectedCar" dataKey="id" styleClass="ui-table-cars"
          [rows]="10" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true" [scrollable]="true"
          [totalRecords]="totalRecords" [responsive]="true" [loading]="loading" [lazy]="true"
          (onLazyLoad)="customPagination($event)">
          <ng-template pTemplate="caption">
            <div class="ui-g">

              <div class="p-field-checkbox">
                <p-checkbox [(ngModel)]="checked" binary="true" inputId="binary" (onChange)="verifiedFlag($event)">
                </p-checkbox>
                <label for="binary" style="color: black;"> &nbsp; &nbsp;<b>Verified User</b></label>
              </div> &nbsp;&nbsp;
              <div class="p-field-checkbox">
                <p-checkbox [(ngModel)]="checkedInfluencer" binary="true" inputId="binary"
                  (onChange)="isInfluencerFun($event)">
                </p-checkbox>
                <label for="binary" style="color: black;"> &nbsp; &nbsp;<b>Influencer User</b></label>
              </div>&nbsp;&nbsp;
              <div class="p-field-checkbox">
                <p-checkbox [(ngModel)]="checkedCreateGame" binary="true" inputId="binary"
                  (onChange)="isCreateGameFun($event)">
                </p-checkbox>
                <label for="binary" style="color: black;"> &nbsp; &nbsp;<b>Host User</b></label>
              </div>
            </div>
          </ng-template>


          <ng-template pTemplate="header" let-columns>
            <tr>

              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                <div class="header-title">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </div>
              </th>

            </tr>
            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width}">



                <input *ngSwitchCase="'id'" pInputText type="number" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'user_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                <input *ngSwitchCase="'first_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'email'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                <input *ngSwitchCase="'balance'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'social_type'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'referral_code'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                <button type="button" [ngClass]="{ 'isVisibleBtn': isFollowpage }"
                  class="btn  btn-primary custom-button-cls" *ngSwitchCase="''" style="width: 40%;display: none"
                  (click)="clerFilter()">
                  Clear</button>
              </th>
            </tr>
          </ng-template>


          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
              <td style="width:90px;">{{rowData.id}}</td>
              <td style="width:130px;">{{rowData.user_name}}</td>
              <td style="width:120px;" *ngIf="isAdmin">{{rowData.first_name}}</td>
              <td style="word-break: break-all;width:200px;">{{rowData.email}}</td>
              <td style="width:120px;" *ngIf="isAdmin">{{rowData.coin_balance}}</td>
              <td style="width:120px;" *ngIf="isAdmin">{{rowData.pending_balance}}</td>
              <!-- <td style="width:110px;">{{rowData.winning_amount}}</td>
              <td style="width:110px;">{{rowData.payout_amount}}</td> -->
              <td style="width:110px;" *ngIf="isAdmin">{{rowData.referral_code}}</td>
              <td style="width:110px;" *ngIf="isAdmin">{{rowData.social_type}}</td>
              <td style="width:110px;">
                <p-checkbox [style]="{ verticalAlign: 'text-bottom' }" [(ngModel)]="rowData.verified_flag"
                  name="verified_flag" (click)="openDialog($event, rowData,'verifiedFlag')" binary="true">
                </p-checkbox>
              </td>
              <td style="width:110px;">
                <p-checkbox [style]="{ verticalAlign: 'text-bottom' }" [(ngModel)]="rowData.is_influencer"
                  name="is_influencer" (click)="openDialog($event,rowData,'isInfluencer')" binary="true">
                </p-checkbox>
              </td>
              <td style="width:110px;">
                <p-checkbox [style]="{ verticalAlign: 'text-bottom' }" [(ngModel)]="rowData.create_game"
                  name="create_game" (click)="openDialog($event,rowData,'isCreateGame')" binary="true">
                </p-checkbox>
              </td>
              <td style="width: 80px;" *ngIf="isAdmin">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <app-action-icon [data]="rowData" width="650px" height="480px" modaleId="UserPromocodeViewComponent"
                      icon="promocode" tooltip="promocode" (onRefrashed)="onRefrash($event)">
                    </app-action-icon>
                    <app-action-icon *ngIf="rowData.is_blocked == false" [data]="rowData" [width]="'350'"
                      [modaleId]="'BlockUserModalComponent'" icon="block" tooltip="block"
                      (onRefrashed)="onRefrash($event)">
                    </app-action-icon>
                    <app-action-icon *ngIf="rowData.is_blocked == true" [data]="rowData" [width]="'350'"
                      [modaleId]="'BlockUserModalComponent'" icon="unblock" tooltip="unblock"
                      (onRefrashed)="onRefrash($event)">
                    </app-action-icon>
                    <app-action-icon [data]="rowData" [width]="'900px'" height="550px"
                      [modaleId]="'UserTransactionModalComponent'" icon="transaction" tooltip="transaction">
                    </app-action-icon>
                    <app-action-icon [data]={data:rowData,isCredit:true} [width]="'450'"
                      [modaleId]="'PayoutModalComponent'" (onRefrashed)="onRefrash($event)" icon="payout"
                      tooltip="payout">
                    </app-action-icon>
                    <app-action-icon [data]="rowData" [width]="'900px'" [modaleId]="'UserPendingModalComponent'"
                      icon="pending" tooltip="pending_transaction">
                    </app-action-icon>

                  </div>
                  <button mat-menu-item>
                    <app-action-icon [data]="rowData" [width]="'600'" [modaleId]="'CreateSkrillPayoutModalComponent'"
                      (onRefrashed)="onRefrash($event)" icon="initiatepayout" tooltip="initiatepayout">
                    </app-action-icon>
                    <app-action-icon [data]="rowData" width="500px" [modaleId]="'FollowUserModalComponent'"
                      (onRefrashed)="onRefrash($event)" icon="follow" tooltip="follow">
                    </app-action-icon>
                    <app-action-icon [data]="rowData" width="700px" [modaleId]="'UserSettingsModalComponent'"
                      (onRefrashed)="onRefrash($event)" icon="settings" tooltip="settings">
                    </app-action-icon>
                    <app-action-icon *ngIf="rowData.is_blocked == false" [data]="rowData" width="500px"
                      [modaleId]="'ImpersonateUserModalComponent'" (onRefrashed)="onRefrash($event)"
                      icon="impersonateuserpassword" tooltip="impersonateuserpassword">
                    </app-action-icon>
                    <!-- <app-action-icon [data]="rowData" [width]="'900px'"
                      [modaleId]="'UserWalletSummeryReportModalComponent'" (onRefrashed)="onRefrash($event)" icon="list"
                      tooltip="summary">
                    </app-action-icon> -->
                    <app-action-icon [data]="{data:rowData,config:'User',entityId:rowData.id}" width="800px"
                      modaleId="UserGameCutConfigComponent" icon="cut" tooltip="cut" (onRefrashed)="onRefrash($event)">
                    </app-action-icon>
                  </button>
                </mat-menu>
              </td>

            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" style="text-align:left">No data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

    </div>
  </div>
</div>