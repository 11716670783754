<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Pinned Question Update</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="pQuestionForm" (ngSubmit)="submitData()">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <p-dropdown [options]="characterType" optionLabel="name" formControlName="character_type" placeholder="Select character type " dataKey="character_type">
                    </p-dropdown>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <p-dropdown [options]="sourceType" (onChange)="onChangeSource($event)" optionLabel="source_code" formControlName="source_type" placeholder="Select source type " dataKey="source_code">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-12">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="question_text" id="question_text" placeholder="Question text" type="text" value="PinnedQuestion" class="form-control">
                    </mat-form-field>

                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <p-dropdown [options]="characterData" optionLabel="character_name" formControlName="character_id_1_name" placeholder="Select character 1 " [filter]="true" dataKey="character_name">
                    </p-dropdown>

                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <p-dropdown [options]="characterData" optionLabel="character_name" formControlName="character_id_2_name" placeholder="Select character  " [filter]="true" dataKey="character_name">
                    </p-dropdown>

                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="close()">Save</button>
        </div>
        <div class="clearfix"></div>
    </form>

</div>