import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TostService } from '../../core/services/common/tost.service';
import { SelfgameService } from '../../core/services/game/selfgame.service';
import { GameBankService } from '../../core/services/game-bank/game-bank.service';
@Component({
  selector: 'app-switch-winner',
  templateUrl: './switch-winner.component.html',
  styleUrls: ['./switch-winner.component.scss']
})
export class SwitchWinnerComponent implements OnInit {

  cols: any;
  frozenCols: any[];
  gameData: any[] = [];
  characters: any = {};
  notes: any;
  score2: boolean = false;
  score1: boolean = false;

  vote_char1: number = 0;
  vote_char2: number = 0;
  is_group_declaration: boolean = true;


  constructor(private modalService: NgbModal, private gameBankService: GameBankService, private selfgameService: SelfgameService, public dialogRef: MatDialogRef<SwitchWinnerComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService) { }

  ngOnInit(): void {
    this.cols = [

      { field: 'game_text', header: 'Game Text', width: '250px' },
      { field: 'character1', header: 'Character 1', width: '200px' },
      { field: 'character2', header: 'Character 2', width: '200px' }

    ];


    if (this.data) {
      this.gameData = [];
      this.gameData.push(this.data)
      this.vote_char1 = this.data.characters_score.character_score_1;

      this.vote_char2 = this.data.characters_score.character_score_2;

      if (this.vote_char1 > this.vote_char2) {
        this.score1 = true;
        this.score2 = false;
      } else if (this.vote_char1 < this.vote_char2) {
        this.score1 = false;
        this.score2 = true;
      } else if (this.vote_char1 == this.vote_char2) {
        this.score1 = true;
        this.score2 = true;
      }
      // this.selfgameService.getDisputedRecorded(this.data[0].id).subscribe(
      //   data => {
      //     const res = JSON.stringify(data);
      //     let gamedataArr = [];
      //     gamedataArr.push(JSON.parse(res))
      //     this.gameData = gamedataArr;
      //     const characterData = JSON.stringify(this.gameData);
      //     this.characters = JSON.parse(characterData);
      //     if (this.characters[0].votes && this.characters[0].votes.char_1) {
      //       this.vote_char1 = this.characters[0].votes.char_1
      //     }
      //     if (this.characters[0].votes && this.characters[0].votes.char_2) {
      //       this.vote_char2 = this.characters[0].votes.char_2
      //     }
      //   },
      //   err => {
      //     // this.tostService.tostrFun(err.error.errors[0].message, 'error');
      //     // this.dialogRef.close();
      //   });

    }



  }
  submitWinnerData() {

    let hasErr = "";
    if (!this.score1 && !this.score2) {
      hasErr = 'should be select at least one character as winner';

    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    let WinnerObj = {};
    let character1_score = 50;
    let character2_score = 50;
    if (this.score1) {
      character1_score = 100;
    }
    if (this.score2) {
      character2_score = 100;
    }


    WinnerObj = {
      game_id: this.data.game_id,
      characters_score: {
        character_id_1: this.data.characters_score.character_id_1,
        character_id_2: this.data.characters_score.character_id_2,
        character_score_1: character1_score,
        character_score_2: character2_score
      }
    }


    this.selfgameService.SwitchWinner(WinnerObj).subscribe(
      data => {
        this.tostService.tostrFun("Switch Winner declared successfully.", 'success');
        this.dialogRef.close();
      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close();
      });
  }
  onRefrash(e) {
    this.notes = e;
  }
  close(): void {
    this.dialogRef.close(true);
  }

}
