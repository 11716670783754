import { Injectable } from '@angular/core';
import { Endpoints } from '../../../../config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AffiliateServiceService {

  constructor (private httpClient: HttpClient) { }

  getAffiliateData (affiliateSearch, id) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (affiliateSearch.search) {
      search = JSON.stringify(affiliateSearch.search);

    }
    // if(affiliateSearch.sort){
    //   sort = JSON.stringify(affiliateSearch.sort)
    // }
    if (affiliateSearch.sort) {
      if (Object.keys(affiliateSearch.sort).length > 0) {
        sort = JSON.stringify(affiliateSearch.sort)
      } else {
        sort = JSON.stringify({ createdAt: 'desc' })
      }
    } else {
      sort = JSON.stringify({ createdAt: 'desc' })
    }

    if (affiliateSearch.pagination) {
      pagination = JSON.stringify(affiliateSearch.pagination)
    }

    if (id) {
      const url = Endpoints.api.affiliate.affiliateGameList
        .replace('{search}', search)
        .replace('{sort}', sort)
        .replace('{pagination}', pagination)
        .replace('{id}', id);
      const observable = this.httpClient.get(url)

      return observable;
    } else {
      const url = Endpoints.api.affiliate.getAffiliate
        .replace('{search}', search)
        .replace('{sort}', sort)
        .replace('{pagination}', pagination);

      const observable = this.httpClient.get(url)

      return observable;
    }

    // const observable = this.httpClient.get(url)

    // return observable;


  }

  uploadAffiliateImage (formData) {
    const url = Endpoints.api.affiliate.imageAffiliate;
    const observable = this.httpClient.post(url, formData)
    return observable;
  }

  createUpdateAffiliate (affiliateValues, action) {
    if (affiliateValues && affiliateValues.background) {
      var a = JSON.stringify(affiliateValues.background)
    }
    affiliateValues.background = a;
    if (action == "create") {
      const url = Endpoints.api.affiliate.createAffiliate;
      const observable = this.httpClient.post(url, affiliateValues);
      return observable;
    } else if (action == "update") {
      const url = Endpoints.api.affiliate.updateAffiliate;
      const observable = this.httpClient.put(url, affiliateValues);
      return observable;
    }
  }
  getAffiliateAnalytics (id) {

    const url = Endpoints.api.affiliate.getAffiliateAnalytics
      .replace('{id}', id)

    const observable = this.httpClient.get(url)

    return observable;

  }


  createUpdatePartnerAffiliate (affiliateValues, action) {
    if (affiliateValues && affiliateValues.background) {
      var a = JSON.stringify(affiliateValues.background)
    }
    affiliateValues.background = a;
    if (action == "create") {
      const url = Endpoints.api.affiliate.createPartnerAffiliate;
      const observable = this.httpClient.post(url, affiliateValues);
      return observable;
    } else if (action == "update") {
      const url = Endpoints.api.affiliate.updatePartnerAffiliate;
      const observable = this.httpClient.put(url, affiliateValues);
      return observable;
    }
  }

  getPartnerAffiliateData (affiliateSearch, id) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (affiliateSearch.search) {
      search = JSON.stringify(affiliateSearch.search);

    }
    // if(affiliateSearch.sort){
    //   sort = JSON.stringify(affiliateSearch.sort)
    // }
    if (affiliateSearch.sort) {
      if (Object.keys(affiliateSearch.sort).length > 0) {
        sort = JSON.stringify(affiliateSearch.sort)
      } else {
        sort = JSON.stringify({ createdAt: 'desc' })
      }
    } else {
      sort = JSON.stringify({ createdAt: 'desc' })
    }

    if (affiliateSearch.pagination) {
      pagination = JSON.stringify(affiliateSearch.pagination)
    }


    const url = Endpoints.api.affiliate.getPartnerAffiliate
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url)

    return observable;


  }

  affiliateSummary () {
    const url = Endpoints.api.affiliate.affiliateSummary


    const observable = this.httpClient.get(url)

    return observable;
  }

  updateAffiliateApiKey (obj) {
    const url = Endpoints.api.affiliate.updateAffiliateApiKey;
    const observable = this.httpClient.post(url, obj)
    return observable;
  }

  getAffiliateApiKey (id) {
    const url = Endpoints.api.affiliate.getAffiliateApiKey.replace('{id}', id)
    const observable = this.httpClient.get(url)
    return observable;
  }

  getBannerById (id) {
    const url = Endpoints.api.affiliate.getBannerById.replace('{id}', id)
    const observable = this.httpClient.get(url)
    return observable;
  }
  deleteBanner (id) {
    const url = Endpoints.api.affiliate.deleteBanner.replace('{id}', id)
    const observable = this.httpClient.delete(url)
    return observable;
  }

  getBanner (filter) {

    let search = '';
    let pagination = '';
    let sort = '';
    if (filter.search) {
      search = JSON.stringify(filter.search);

    }
    if (filter.sort) {
      if (Object.keys(filter.sort).length > 0) {
        sort = JSON.stringify(filter.sort)
      } else {
        sort = JSON.stringify({ id: 'desc' })
      }
    } else {
      sort = JSON.stringify({ id: 'desc' })
    }

    if (filter.pagination) {
      pagination = JSON.stringify(filter.pagination)
    }
    const url = Endpoints.api.affiliate.getBanner
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);
    const observable = this.httpClient.get(url);

    return observable;


  }

  createUpdateBanner (bannerValues) {
    const url = Endpoints.api.affiliate.createUpdateBanner;
    const observable = this.httpClient.post(url, bannerValues);
    return observable;
  }

}
