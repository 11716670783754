import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../core/services/common/tost.service'

@Component({
  selector: 'app-confirm-api-key-update',
  templateUrl: './confirm-api-key-update.component.html',
  styleUrls: ['./confirm-api-key-update.component.scss']
})
export class ConfirmApiKeyUpdateComponent implements OnInit {

  constructor (public dialogRef: MatDialogRef<ConfirmApiKeyUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService) { }
  userData = this.data;

  rowDataObj: any;
  ngOnInit (): void {
    this.rowDataObj = this.userData && this.userData.rowData;
  }

  save () {
    this.dialogRef.close({ save: true, rowData: this.rowDataObj });
  }

  submitData () {

  }
  close (): void {
    this.dialogRef.close({ rowData: this.rowDataObj, save: false });
  }

}
