import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from "lodash";
import { AffiliateServiceService } from '../../core/services/affiliate/affiliate-service.service'
import { TostService } from '../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';


import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);
@Component({
  selector: 'app-create-affiliate-modal',
  templateUrl: './create-affiliate-modal.component.html',
  styleUrls: ['./create-affiliate-modal.component.scss']
})
export class CreateAffiliateModalComponent implements OnInit {
  public color: string = '#000000';
  action: string;

  IsWait: boolean = false;
  affiliateData: any = {
    display_text: '',
    email: '',
    password: '',
    full_name: '',
    logo_url: '',
    term_url: 'https://versusgame.com/auth/terms',
    website: '',
    consent: 'I agree to the Terms of Use',
    additional_email: '',
    show_consent: false,
    background: {
      bgColor: '' || '#000000',
      bgImage: '',
      bgBtnText: '' || 'Accept'
    },
  }
  isUpdate: boolean = false;
  //  affiliateBg = {
  //   bgColor: '',
  //   bgImage: '',
  //   bgBtnText: ''
  //   };
  editorContent: string;
  editorStyle = {
    height: '300px',
    // backgroundColor:'#000000'
  }
  // config = {

  //   imageResize: true,

  // }

  config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']],
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear',
          ],
        ],
      ],
    },
    height: '460px',


    uploadImagePath: '/api/upload',
    toolbar: [
      ['edit', ['undo', 'redo']],
      ['headline', ['style']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontface', []],
      ['textsize', ['fontsize']],
      ['fontclr', ['color']],
      ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
      ['height', ['height']],
      ['table', []],
      ['insert', ['link', 'picture']],
      ['view', []]

    ],

    codeviewFilter: true,
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
  };

  AffiliateForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    full_name: new FormControl('', [Validators.required]),
    logo_url: new FormControl('', [Validators.required]),
    term_url: new FormControl('', [Validators.required]),
    website: new FormControl('', [Validators.required]),
    consent: new FormControl('', [Validators.required]),
    additional_email: new FormControl('', [Validators.required]),
    show_consent: new FormControl(false, [Validators.required]),
    background: new FormControl('', [Validators.required]),
    editor: new FormControl(''),
    playbtn: new FormControl(''),


  });
  constructor(private spinner: NgxSpinnerService, public dialogRef: MatDialogRef<CreateAffiliateModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private modalService: NgbModal, private affiliateService: AffiliateServiceService, private tostService: TostService) { }

  ngOnInit(): void {
    // console.log("this is edit data affiliate", this.data)
    // $('.note-editor.note-frame .note-editing-area .note-editable').css('background', this.color);


    if (this.data && this.data.id) {

      this.isUpdate = true;
      var affiliateDataClone = _.cloneDeep(this.affiliateData);
      var background = JSON.parse(this.data.background);
      // console.log('back json', background);
      this.affiliateData.email = this.data.email;
      this.affiliateData.password = this.data.password;
      this.affiliateData.full_name = this.data.full_name;
      this.affiliateData.logo_url = this.data.logo_url;
      this.affiliateData.term_url = this.data.term_url;
      this.affiliateData.website = this.data.website;
      this.affiliateData.consent = this.data.consent;
      this.affiliateData.additional_email = this.data.additional_email;
      this.affiliateData.show_consent = this.data.show_consent;
      if (background && background.bgColor) {
        this.affiliateData.background.bgColor = background.bgColor
      } else {
        this.affiliateData.background.bgColor = '#000000'
      }

      if (background && background.bgImage) {
        this.affiliateData.background.bgImage = background.bgImage;
      } else {
        this.affiliateData.background.bgImage = '';
      }

      this.affiliateData.display_text = this.data.display_text;


      setTimeout(() => {
        $('.note-editor.note-frame .note-editing-area .note-editable').css({ 'background': this.affiliateData.background.bgColor, 'caret-color': 'red' });
      }, 1000);

      if (!this.affiliateData.term_url) {
        this.affiliateData.term_url = 'https://versusgame.com/auth/terms'
      }
      if (background && background.bgBtnText) {
        this.affiliateData.background.bgBtnText = background.bgBtnText;
      } else {
        this.affiliateData.background.bgBtnText = "Accept"
      }
      // setTimeout(() => {
      // (<any>$('#summernote')).summernote('editor.pasteHTML', this.affiliateData.display_text);

      // }, 100);

    } else {
      this.affiliateData.background.bgColor = '#000000';
      setTimeout(() => {
        $('.note-editor.note-frame .note-editing-area .note-editable').css({ 'background': this.affiliateData.background.bgColor, 'caret-color': 'red' });
      }, 1000);

    }

  }

  submitData() {
    //  console.log(this.AffiliateForm.get('editor').value)
  }
  summernoteInit(event) {
    //console.log(event);
  }
  onChangeColor(e) {
    this.affiliateData.background.bgColor = e;
    $('.note-editor.note-frame .note-editing-area .note-editable').css('background', this.affiliateData.background.bgColor);

    // console.log('color ', this.affiliateData.background.bgColor)

  }
  clearBgImage() {
    this.affiliateData.background.bgImage = ''
  }

  getValidUrl(url) {
    var newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
      return 'https' + newUrl;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return 'https://' + newUrl;
    }

    return newUrl;
  }

  isEmailValidate(email, multiple) {
    var se = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      valid = true;

    if (!email) {
      return false;
    }

    if (multiple) {
      var emailList = email.split(',');

      for (var i in emailList) {
        valid = se.test(emailList[i].trim());
        if (!valid) {
          break;
        }
      }
    } else {
      valid = se.test(email);
    }

    return valid;
  }

  isEmpty(value) {
    return !(value && value.trim().length);
  }

  isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s);
  }



  saveAffiliateData() {
    // console.log('aff data', this.affiliateData);
    var hasErr = '';

    this.spinner.show();
    //   if(this.affiliateData.background.bgBtnText == "" || this.affiliateData.background.bgBtnText == undefined){
    //       this.affiliateData.background.bgBtnText = "Accept";
    //       this.affiliateData.background.bgColor == ""
    //   }
    //   if(this.affiliateData.background.bgColor == ""  || this.affiliateData.background.bgColor ==  undefined){
    //     this.affiliateData.background.bgColor = "#000000";
    // }
    if (this.affiliateData.term_url == "" || !this.affiliateData.term_url) {
      this.affiliateData.term_url = 'https://versusgame.com/auth/terms'
    }

    if (this.affiliateData.logo_url && !this.isEmpty(this.affiliateData.logo_url)) {
      this.affiliateData.logo_url = this.getValidUrl(this.affiliateData.logo_url)
    }

    if (this.affiliateData.term_url && !this.isEmpty(this.affiliateData.term_url)) {
      this.affiliateData.term_url = this.getValidUrl(this.affiliateData.term_url)
    }

    if (this.affiliateData.website && !this.isEmpty(this.affiliateData.website)) {
      this.affiliateData.website = this.getValidUrl(this.affiliateData.website)
    }

    if (!this.affiliateData.email && this.isEmpty(this.affiliateData.email)) {
      hasErr = 'Please enter your email';
    } else if (!this.isEmailValidate(this.affiliateData.email, false)) {
      hasErr = 'Please enter your correct email';
    } else if (!this.affiliateData.password && this.isEmpty(this.affiliateData.password) && !this.affiliateData.id) {
      hasErr = 'Please enter your password';
    } else if (!this.affiliateData.full_name && this.isEmpty(this.affiliateData.full_name)) {
      hasErr = 'Please enter your full name';
    }

    else if (this.affiliateData.show_consent == true && !this.affiliateData.term_url && this.isEmpty(this.affiliateData.term_url && !this.isUrl(this.affiliateData.term_url))) {
      // (this.affiliateData.show_consent && !this.affiliateData.term_url && this.isEmpty(this.affiliateData.term_url && !this.isUrl(this.affiliateData.term_url)))

      hasErr = 'Please enter your term url';
    } else if (this.affiliateData.show_consent && !this.affiliateData.consent && this.isEmpty(this.affiliateData.consent)) {
      hasErr = 'Please enter your consent text';
    }


    if (hasErr) {
      // showSimpleToast(hasErr);
      this.tostService.tostrFun(hasErr, 'error');
      this.spinner.hide();
      return;

    }
    // if ($scope.isApiLoading) {
    //   return;
    // }

    if (this.data == null) {
      this.action = "create"
    } else if (this.data.id) {
      this.action = "update"

      Object.assign(this.affiliateData, {
        id: this.data.id,
        created_by: this.data.created_by,
        createdAt: this.data.createdAt,
        deletedAt: this.data.deletedAt,
        affiliate_code: this.data.affiliate_code,

        updatedAt: this.data.updatedAt,
      });
    }


    this.affiliateService.createUpdateAffiliate(this.affiliateData, this.action).subscribe(data => {

      if (this.action == 'update') {
        this.tostService.tostrFun("Affiliate updated successfully", 'success');
        // this.IsWait= false;
        this.spinner.hide();
        this.dialogRef.close();
      } else {
        this.tostService.tostrFun("affiliate created successfully", 'success');
        // this.IsWait= false;
        this.spinner.hide();
        this.dialogRef.close();

      }
    },
      err => {
        //     this.tostService.tostrFun("something went wrong", 'error');
        // this.IsWait= false;
        this.spinner.hide();
        this.dialogRef.close();

      }
    )
    // this.dialogRef.close();
  }





  onFileChange(fileData) {
    // this.IsWait= true;
    this.spinner.show();
    const formData = new FormData();


    formData.append('image', fileData.target.files[0]);
    // formData.append('image',fileData.target.files[0]);
    // formData.append('id',this.data.id)
    this.affiliateService.uploadAffiliateImage(formData).subscribe(data => {
      // this.IsWait=false;
      this.spinner.hide();
      //  console.log("affiliate image uploaded", data);
      var imageLink = JSON.stringify(data);
      var imageParse = JSON.parse(imageLink).picture
      if (imageLink && imageParse) {
        this.affiliateData.background.bgImage = imageParse;
      }
      this.tostService.tostrFun('File uploaded successfully', 'success')
    },
      err => {
        // this.tostService.tostrFun('Something went wrong on server', 'error')
        // this.IsWait=false;
        this.spinner.hide();

      }
    )
    // console.log('parsed link', this.affiliateData.background.bgImage)
  }

  close(): void {

    this.dialogRef.close(true);
  }

  // textShow(e){
  //   console.log('event of tesxt show', e);
  //     this.editorContent = this.AffiliateForm.get('editor').value
  //     console.log('editor con',this.editorContent)
  // }
  // consentFunction(consentData){
  //   // console.log('consent data',consentData)
  //   // this.AffiliateForm.patchValue({
  //   //   show_consent:consentData
  //   // })

  //   console.log('show_consent val', this.affiliateData.show_consent)
  // }

}
