import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '../../../core/services/users.service';
import { PayoutModalComponent } from '../payout-modal/payout-modal.component';
import { RefDataService } from '../../../core/services/common/ref-data.service'

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss']
})
export class SettingsModalComponent implements OnInit {

  userConfig = {
    verified_flag: 0,
    bots_per_game: 0,
    create_game: 0,
    user_id: null,
    bots_to_follow: 0,
    min_host_payout: 0,
    max_host_payout: 0,
    match_mode: 0,
    bot_multiplier: 0,
    dollar_multiplier: 0,
    total_invitations: 0,
    parent_id: null,
    host_cut_percent: 100,
    parent_cut_percent: 0,
    parent_user_name: '',
  };
  defaultMaxPayout = 0;
  coinRate: number = 0;
  loading = false;
  isHost: any;
  maxPayoutFlag: boolean = false;
  userList = [];
  debounceConfig;

  constructor (
    public dialogRef: MatDialogRef<PayoutModalComponent>,
    private usersService: UsersService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private refDataService: RefDataService
  ) { }

  ngOnInit (): void {
    this.spinner.show();
    this.getCoins();
    this.usersService
      .getUserSettings(this.data['id'])
      .subscribe(
        (data) => {
          this.userConfig.verified_flag = data['verified_flag'];
          this.userConfig.bots_per_game = data['bots_per_game'];
          this.userConfig.create_game = data['create_game'];
          this.userConfig.user_id = data['user_id'];
          this.userConfig.bots_to_follow = data['bots_to_follow'];
          this.userConfig.min_host_payout = data['min_host_payout'];
          this.userConfig.max_host_payout = data['max_host_payout'];
          this.userConfig.match_mode = data['match_mode'];
          this.userConfig.bot_multiplier = data['bot_multiplier'];
          this.userConfig.dollar_multiplier = data['dollar_multiplier'];
          this.userConfig.total_invitations = data['total_invitations'];

          this.userConfig.parent_id = data['parent_id'];
          this.userConfig.parent_user_name = data['parent_user_name'];
          this.userConfig.host_cut_percent = data['host_cut_percent'];
          this.userConfig.parent_cut_percent = data['parent_cut_percent'];
          this.isHost = data['create_game'];
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  close () {
    this.dialogRef.close(true);
  }

  updateConfig () {
    if (this.maxPayoutFlag) {
      return false
    }
    this.spinner.show();
    const userConfigs = {
      ...this.userConfig,
      create_game: this.userConfig.create_game ? 1 : 0,
      verified_flag: this.userConfig.verified_flag ? 1 : 0,
      settings_form_action: true
    };
    if (this.isHost !== this.userConfig.create_game) {
      userConfigs["is_host_updated"] = true;
      console.log(`Admin updated hosting privileges useId: ${userConfigs.user_id}  before=>  ${this.isHost}  now => ${userConfigs.create_game}`)
    }
    this.usersService
      .updateUserSettings(userConfigs)
      .subscribe(
        () => {
          this.spinner.hide();
          this.dialogRef.close();
        },
        () => {
          this.spinner.hide();
        }
      );
  }
  maxAmountChange (e) {
    const maxval = e.target.value;
    if (maxval < this.userConfig.min_host_payout) {
      this.maxPayoutFlag = true;
    } else {
      this.maxPayoutFlag = false;
    }
  }
  minAmountChange (e) {
    const minval = e.target.value;
    if (minval > this.userConfig.max_host_payout) {
      this.maxPayoutFlag = true;
    } else {
      this.maxPayoutFlag = false;
    }
  }
  getCoins () {
    this.refDataService.refDataAll().subscribe(data => {
      const res = JSON.stringify(data);
      this.coinRate = (JSON.parse(res)).coinRate;
    });
  }

  searchUsers (ev) {
    if (this.debounceConfig) {
      clearTimeout(this.debounceConfig);
    }

    this.debounceConfig = setTimeout((username) => {
      this.usersService.getUsersData1({
        search: { user_name: username }
      }).subscribe(data => {
        this.userList = data['usersList'];
      });
    }, 500, ev.target.value);
  }

  setUserName (ev) {
    this.userConfig.parent_id = ev.option.id;
  }
}
