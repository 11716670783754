import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { CharacterService } from '../../../core/services/character.service';
import { MenuItem } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';
import { SelectItemGroup } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { SiteConstants } from '../../../../config/site-constants'
import { RefDataService } from '../../../core/services/common/ref-data.service'
import { AffiliateServiceService } from '../../../core/services/affiliate/affiliate-service.service';
import { TostService } from '../../../core/services/common/tost.service';
import { characterData } from '../../../core/data/characterJson';
import { ConfirmationService } from 'primeng/api';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-game-bank-create-edit-modal',
  templateUrl: './game-bank-create-edit-modal.component.html',
  styleUrls: ['./game-bank-create-edit-modal.component.scss']
})
export class GameBankCreateEditModalComponent implements OnInit {

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  is_disable: boolean = false;
  durationType: any[];
  sourcTypeList: any[];
  imagesCharacter1: any;
  durationUnits: SelectItem[];;
  selectedName: Array<any> = [];
  gameData: any;
  sourceType: any[];
  sourceTypeData: SelectItem[];
  customGameTag: SelectItem[];
  countries: any[];
  videoFile: any;
  mediaType: any;
  icons: any = SiteConstants.icons;
  tooltips: any = SiteConstants.gameToolTips;
  sourceTypeObj: any = {};
  isbgColor: boolean = true;
  gameType: string = "";
  action: string;
  orignalBackgroundurls: any = [];
  val6: number;
  SelectedCharacter1: any;
  SelectedCharacter2: any;

  isSingle: boolean = true;
  isVertical: boolean = false;
  isStacked: boolean = false;
  file_type: string = "image";
  iscreate: string = 'Create';
  valuesToBePushed: Array<String> = [];
  affiliateSummary: SelectItem[];
  searchResult: any[];
  characterList: any[] = [];
  characterList1: any[] = [];
  character_id_1: string;
  start_time: any;
  backgroundType: any[] = [];
  end_time: Date;
  selectedbackgroundType: any = null;
  last_join_time: any;
  file: any;
  searchKeyword: any = {};
  searchKeyword1: any = {};
  selectedUserValues: any;
  characterId1: any = {};
  characterId2: any = {};
  textSize: number = 16;
  textSizeObj: any = {};
  character1_name: string = '';
  character2_name: string = '';
  imagesCharacter2: any;
  bgBold: boolean = false;
  character1: any = { character_name: '' }
  ischaracter: boolean = false;
  backgroundObj: any = {};
  image_url: string = "";
  video_src: any;
  gameText: string = '';
  duration_unit: string = ''
  character2: any = { character_name: '' }
  price_options: any[];
  imageSrc: any = '';
  images: any = "";
  imageChangedEvent: any;
  states: any;
  croppedImage: any;
  selectedCar1: string = 'Custom'
  imagesCharacterSingle: any;
  singleBackgroundObj: any = {};
  pin_game_to_top = false;
  isFile: boolean = false;
  defaultDuration;
  gameObj: any = {
    game_text: '',
    start_time: new Date(),
    end_time: new Date(),
    last_join_time: new Date(),
    price_options: {},
    locations: [],
    duration: '',
    game_title: '',
    game_type: '',
    tags: [],
    source_url: '',
    not_show_trending: false,
    background: {
      "bg_color": '' || 'transparent',
      "text_color": '' || '#ffffff',
      "text_size": '' || '16px',
      "type": '' || 'single',
      "urls": [{}]
    },
    affiliate_id: '',
    source_type: '',
    character_id_1: '',
    character_id_2: ''

  };


  constructor(public dialogRef: MatDialogRef<GameBankCreateEditModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private affiliateServiceService: AffiliateServiceService, private refDataService: RefDataService,
    private characterService: CharacterService, private sanitizer: DomSanitizer, private spinner: NgxSpinnerService, private gameBankService: GameBankService) {
    // setTimeout(() => {

    // }, 1000);
    this.getCharAndAttribute();
    this.getAffiliateSummary();
    this.allowedCountries();
    this.customGameTagfun();
    this.durationUnits = SiteConstants.durationUnits;

    //  this.getGameData(this.gameData.id);
  }
  ngOnInit(): void {
    this.price_options = [{
      'entry_fee': 0,
      'prize_money': 0,
      'default': 0
    }];


    this.backgroundType = SiteConstants.backgroundType;
    this.selectedbackgroundType = this.backgroundType[1]
    this.mediaType = SiteConstants.mediaType;
    this.gameType = this.data.games;



    // this.characterService.getCharacterData().subscribe(data => {
    //   const res = JSON.stringify(data);
    //   const getData = (JSON.parse(res)).list;
    //   const getCharData = JSON.stringify(getData);
    //   this.characterData = JSON.parse(getCharData);
    // });
    if (this.data && this.data.data) {
      this.spinner.show();
      this.gameBankService.getGameById(this.data.data.id).subscribe(data => {
        this.isFile = true;
        const res = JSON.stringify(data);
        this.gameData = (JSON.parse(res));
        this.gameObj.source_type = this.gameData.source_type;
        this.iscreate = "Update";
        this.gameText = this.gameData.game_text;
        this.gameObj.character_id_1 = this.gameData.character_id_1;
        this.gameObj.character_id_2 = this.gameData.character_id_2;
        this.character1_name = this.gameData.characters[0].character_name;
        this.character2_name = this.gameData.characters[1].character_name;
        this.orignalBackgroundurls = [{ "file_type": "image", "url": this.gameData.characters[0].image_url }, { "file_type": "image", "url": this.gameData.characters[1].image_url }];
        this.searchResult = this.gameData.characters;
        this.SelectedCharacter1 = this.gameData.characters[0];
        this.SelectedCharacter2 = this.gameData.characters[1];
        //  this.characterList = this.gameData.characters;

        this.gameObj.game_text = this.gameData.game_text,
          this.gameObj.id = this.gameData.id,
          this.gameObj.start_time = new Date(this.gameData.start_time),
          this.gameObj.priority_flag = this.gameData.priority_flag || 0,
          this.gameObj.not_show_trending = this.gameData.not_show_trending || false,

          this.gameObj.end_time = new Date(this.gameData.end_time),
          this.gameObj.last_join_time = new Date(this.gameData.last_join_time),
          this.gameObj.game_title = this.gameData.game_title,
          this.gameObj.source_url = this.gameData.source_url;
        this.gameObj.price_options = this.gameData.price_options,
          this.gameObj.duration = this.gameData.duration;





        if (this.gameObj.duration > 0) {
          var isFullHour = ((this.gameObj.duration) % 60 == 0);

          if (isFullHour) {
            var hours = this.gameObj.duration / 60;
            var isFullDay = (hours % 24 == 0);

            if (isFullDay) {
              this.gameObj.duration = hours / 24;
              this.duration_unit = 'days';
            } else {
              this.gameObj.duration = hours;
              this.duration_unit = 'hours';
            }
          }
        }
        this.gameObj.game_type = this.gameData.game_type,
          this.gameObj.background = this.gameData.background,
          this.gameObj.affiliate_id = this.gameData.affiliate_id,
          this.gameObj.character_id_1 = this.gameData.character_id_1,
          this.gameObj.character_id_2 = this.gameData.character_id_2,
          this.gameObj.tags = this.gameData.tags,
          this.gameObj.locations = this.gameData.locations || [];
        this.gameObj.status = this.gameData.status;
        this.backgroundClick(this.gameData.background.type)
        if (this.gameObj.background && this.gameObj.background.bg_color) {
          this.backgroundObj['background-color'] = this.gameObj.background.bg_color;
        }

        if (this.gameObj.background && this.gameObj.background.text_color) {
          this.backgroundObj['color'] = this.gameObj.background.text_color
        }


        for (var i in this.gameObj.locations) {
          var loc = this.gameObj.locations[i];
          this.loadStates(loc.country);
        }

        this.character_id_1 = this.gameData.character_id_1
        this.price_options = this.gameData.price_options;
        if (this.gameObj.priority_flag == 0) {
          this.pin_game_to_top = true
        } else {
          this.pin_game_to_top = false
        }
        if (this.gameData.background && this.gameData.background.type == 'single') {
          this.file_type = this.gameData.background.urls[0].file_type;
          if (this.file_type == 'image') {
            this.image_url = this.gameData.background.urls[0].url;
            this.imagesCharacterSingle = { background: 'url(' + this.gameData.background.urls[0].url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
            this.singleBackgroundObj = { "file_type": "image", "url": this.gameData.background.urls[0].url }
          }
          if (this.file_type == 'video') {
            this.video_src = this.gameData.background.urls[0].url;
            this.singleBackgroundObj = { "file_type": "video", "url": this.gameData.background.urls[0].url }
          }

        }
        if (this.gameData.background && this.gameData.background.urls[0]) {
          this.imagesCharacter1 = { background: 'url(' + this.gameData.background.urls[0].url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
        }
        if (this.gameData.background && this.gameData.background.urls[1]) {
          this.imagesCharacter2 = { background: 'url(' + this.gameData.background.urls[1].url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
        }
        this.textSize = this.gameData.background["text_size"];
        this.spinner.hide();
      },
        err => {
          /// this.tostService.tostrFun(err.error.errors[0].message, 'error');
          this.dialogRef.close();
          this.spinner.hide();
        });
    } else {

      this.gameObj.game_text = '',
        this.gameObj.start_time = new Date(),
        this.gameObj.source_type = '',
        this.gameObj.end_time = new Date(),
        this.gameObj.last_join_time = new Date(),
        this.gameObj.game_title = '',
        this.gameObj.source_url = '',
        this.gameObj.locations = [],
        this.gameObj.status = 0;
      this.gameObj.tags = []
      this.gameObj.priority_flag = 1;
      this.gameObj.not_show_trending = false;
      this.gameObj.price_options = [{
        'entry_fee': 0,
        'prize_money': 0,
        'default': 0
      }],
        this.gameObj.duration = '',
        this.gameObj.game_type = this.gameType,
        this.gameObj.background = {
          "bg_color": '' || 'transparent',
          "text_color": '' || '#ffffff',
          "text_size": '' || '16',
          "type": '' || 'single',
          "urls": [{
            file_type: 'image',
            url: ''
          }]
        }
      this.backgroundObj = { 'background-color': 'transparent', 'color': '#ffffff' }
      this.file_type = 'image';
      this.price_options = this.gameObj.price_options;

      this.gameObj.affiliate_id = '',
        this.gameObj.character_id_1 = '',
        this.gameObj.character_id_2 = ''


    }
  }
  search(e) {
    var obj = {};
    if (this.searchKeyword.search) {
      const searchObj = {};
      searchObj['character_name'] = e.query;
      this.searchKeyword.search = Object.assign({}, this.searchKeyword.search, searchObj);

    } else {
      obj['character_name'] = e.query;
      this.searchKeyword.search = obj;
    }

    // this.searchKeyword["character_name"]=e.query;

    this.datafun(this.searchKeyword)


    // this.filteredBrands = [];
    // for(let i = 0; i < this.searchResult.length; i++) {
    //     let brand = this.searchResult[i];
    //         this.filteredBrands.push({'name':this.searchResult[i].name});
    //         console.log("filter",this.filteredBrands);
    //      }

  }
  searchAuto(e) {
    var obj = {};
    var searchFilter = {}
    if (this.searchKeyword.search) {
      const searchObj = {};
      searchObj['character_name'] = e.target.value;
      this.searchKeyword.search = Object.assign({}, this.searchKeyword.search, searchObj);

    } else {
      obj['character_name'] = e.target.value;
      this.searchKeyword.search = obj;
    }

    // this.searchKeyword["character_name"]=e.query;

    this.datafun(this.searchKeyword)

  }
  searchAuto1(e) {
    var obj = {};

    if (this.searchKeyword1.search) {
      const searchObj = {};
      searchObj['character_name'] = e.target.value;
      this.searchKeyword1.search = Object.assign({}, this.searchKeyword1.search, searchObj);

    } else {
      obj['character_name'] = e.target.value;
      this.searchKeyword1.search = obj;
    }

    // this.searchKeyword["character_name"]=e.query;

    this.datafun1(this.searchKeyword1)

  }
  displayProperty(value) {
    if (value) {
      return value.character_name;
    }
  }
  displayProperty1(value) {
    if (value) {
      return value.character_name;
    }
  }
  onSelectCustomer(e, character) {
    const val = e.source.value;
    let err = "";

    if (character == 'character1') {
      this.characterId1 = val.id;
      this.character1 = val;
      this.character1_name = val.character_name;
      this.gameObj.character_id_1 = val.id
      this.imagesCharacter1 = { background: 'url(' + val.image_url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
      this.gameObj.background.urls[0] = { "file_type": "image", "url": val.image_url }
      this.orignalBackgroundurls[0] = ({ "file_type": "image", "url": val.image_url });
    }
    if (character == 'character2') {
      this.characterId2 = val.id;
      this.character2 = val;
      this.gameObj.character_id_2 = val.id
      this.character2_name = val.character_name;
      this.imagesCharacter2 = { background: 'url(' + val.image_url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
      this.gameObj.background.urls[1] = { "file_type": "image", "url": val.image_url }
      this.orignalBackgroundurls[1] = ({ "file_type": "image", "url": val.image_url });
    }
    if (this.characterId1 == this.characterId2) {
      this.tostService.tostrFun('Character 1 and character 2 not same ', 'error');
      return;
    }
  }
  getGameData(id) {
    this.gameBankService.getGameById(id).subscribe(data => {

      const res = JSON.stringify(data);
      this.gameData = (JSON.parse(res));
    });
  }

  selectdOption(e) {
    this.gameObj.tags = e.value;
  }
  datafun(filter) {
    this.characterService.getCustomCharacterData(filter).subscribe(data => {
      //  console.log("search", data)
      const res = JSON.stringify(data);
      const getSearchData = (JSON.parse(res)).list;
      const getuserSearchData = JSON.stringify(getSearchData);
      this.searchResult = JSON.parse(getuserSearchData);
      this.characterList = JSON.parse(getuserSearchData);
      // console.log("search resilt", this.searchResult);
    });

  }
  datafun1(filter) {
    this.characterService.getCustomCharacterData(filter).subscribe(data => {
      //  console.log("search", data)
      const res = JSON.stringify(data);
      const getSearchData = (JSON.parse(res)).list;
      const getuserSearchData = JSON.stringify(getSearchData);
      this.searchResult = JSON.parse(getuserSearchData);
      this.characterList1 = JSON.parse(getuserSearchData);
      // console.log("search resilt", this.searchResult);
    });

  }
  allowedCountries() {
    this.refDataService.allowedCountries().subscribe(data => {
      const res = JSON.stringify(data);
      this.countries = (JSON.parse(res));

    });
  }

  addLocation() {
    this.gameObj.locations.push({
      'country': null,
      'state': null,
      'city': null,
      'pincode1': null
    });
  }
  loadStates(country_code) {
    this.refDataService.stateList(country_code).subscribe(data => {
      var states = [];
      const res = JSON.stringify(data);
      states = (JSON.parse(res));

      states.unshift({ key: '', text: 'All' });


      if (!this.states) {
        this.states = {};
      }

      this.states[country_code] = states;

    });
  }
  removeLocation(index) {
    this.gameObj.locations.splice(index, 1);
  };

  close(): void {
    this.dialogRef.close();
  }
  startDateOnSetTime(e) {
    this.start_time = e;
  };
  endDateOnSetTime(e) {
    this.end_time = e;
    let hasErr = "";
    var curDate = new Date();
    if (
      new Date(this.start_time) >
      new Date(this.end_time)
    ) {
      hasErr = "End Date should be greater than start date";
    } else if (new Date(this.end_time) < curDate) {
      hasErr = "End Date should not be before today.";
    } else {
      this.last_join_time = moment(this.end_time).subtract(30, 'minutes');
    }

    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }

  };

  setLastJoinDate(e) {
    let hasErr = "";
    var curDate = new Date();
    this.last_join_time = e;
    if (new Date(this.start_time) > new Date(this.last_join_time)) {
      hasErr = "Last Join Date should be greater than start date";
    } else if (new Date(this.last_join_time) > new Date(this.end_time)) {
      hasErr = "Last join date should be less than end date.";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
  };
  selectedVal(val, character) {
    let err = "";

    if (character == 'character1') {
      this.characterId1 = val.id;
      this.character1 = val;
      this.character1_name = val.character_name;
      this.imagesCharacter1 = { background: 'url(' + val.image_url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
      this.gameObj.background.urls[0] = { "file_type": "image", "url": val.image_url }
    }
    if (character == 'character2') {
      this.characterId2 = val.id;
      this.character2 = val;
      this.character2_name = val.character_name;
      this.imagesCharacter2 = { background: 'url(' + val.image_url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
      this.gameObj.background.urls[1] = { "file_type": "image", "url": val.image_url }
    }
    if (this.characterId1 == this.characterId2) {
      this.tostService.tostrFun('Character 1 and character 2 not same ', 'error');
      return;
    }
  }
  customGameTagfun() {
    this.refDataService.tagsList("game,premium").subscribe(data => {
      const res = JSON.stringify(data);
      const resData = JSON.parse(res);
      this.customGameTag = resData
        .filter(obj => obj.tag_name.trim().toLowerCase() !== 'poll')
        .map(obj => {
          let rObj = {
            value: obj.tag_name,
            label: obj.tag_name,
            type: obj.tag_type,
          }
        return rObj
        });


    });
  }
  handleChange(e) {
    this.gameObj.background['text_size'] = e.value;
    this.textSizeObj = { 'font-size': e.value + 'px', 'line-height': e.value + 'px' }
  }
  bgColorClick(e) {
    if (e == 'white') {
      this.gameObj.background['bg_color'] = '#000000';
      this.gameObj.background['text_color'] = '#ffffff';
      this.backgroundObj = { 'background-color': '#000000', 'color': '#ffffff' }
    } else if (e == 'black') {
      this.gameObj.background['bg_color'] = '#ffffff';
      this.gameObj.background['text_color'] = '#000000';
      this.backgroundObj = { 'background-color': '#ffffff', 'color': '#000000' }
    } else if (e == 'transparent') {
      this.gameObj.background['bg_color'] = 'transparent';
      this.gameObj.background['text_color'] = '#ffffff';
      this.backgroundObj = { 'background-color': 'transparent', 'color': '#ffffff' }
    }

  }
  backgroundClick(e) {
    if ('single' == e) {
      this.isSingle = true;
      this.isVertical = false;
      this.isStacked = false;
    }
    else if ('vertical' == e) {
      this.isSingle = false;
      this.isVertical = true;
      this.isStacked = false;
    }
    else if ('stacked' == e) {
      this.isSingle = false;
      this.isVertical = false;
      this.isStacked = true;
    }

  }
  textSizeChange(e) {
    this.val6 = e.value
  }
  // bgcheckCthis.backgroundObjlick(e) {
  //   if (e) {
  //     this.bgBold = true;
  //     if (this.gameObj.background.bg_color == 'black') {
  //       this.backgroundObj['background-color'] = 'black';
  //       this.backgroundObj['color'] = 'white';
  //     }
  //     else {
  //       this.backgroundObj['background-color'] = 'white';
  //       this.backgroundObj['color'] = 'black';
  //     }

  //   }
  //   else {
  //     this.bgBold = false;
  //     this.backgroundObj['background-color'] = '';

  //   }
  // }
  close1(e) {
    this.price_options.splice(e, 1);
  }
  checkDefaultPriceOption(options) {
    for (var i = 0; i < this.price_options.length; i++) {

      if (i == options) {
        this.price_options[i].default = 1
      }
      else {
        this.price_options[i].default = 0
      }
    }
  }
  gameTextChange(e) {
    this.gameText = e.target.value;
  }
  imageChange(e) {
    this.singleBackgroundObj = { "file_type": "image", "url": e.target.value }
    this.images = { background: 'url(' + e.target.value + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
    this.imagesCharacterSingle = { background: 'url(' + this.image_url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
  }
  videoChange(e) {
    this.singleBackgroundObj = { "file_type": "video", "url": e.target.value };
    this.videoplayer.nativeElement.src = this.video_src;
    this.videoplayer.nativeElement
      .play().then(() => { }).catch((err) => { })
    this.isFile = true;
    //  this.images = { background: 'url(' + e.target.value + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
    //   this.imagesCharacterSingle = { background: 'url(' + this.image_url + ')', 'background-position': 'center', 'background-size': 'cover', 'background-repeat': 'no-repeat' }
  }
  submitData() {
    if (this.data.data == null) {
      this.action = "create";
    } else if (this.gameData.id) {
      this.action = "update";
    }
    // this.gameObj.game_type = this.gameObj.game_type.value || this.gameObj.game_type
    var result = [];
    for (var i = 0; i < this.gameObj.price_options.length; i++) {
      if (this.gameObj.price_options[i].prize_money < this.gameObj.price_options[i].entry_fee) {
        this.tostService.tostrFun("Entry fee should be less then prize money", 'error');
        return;
      }
      var name = this.gameObj.price_options[i].entry_fee;
      for (var i2 = 0; i2 < result.length; i2++) {
        if (result[i2] == name) {
          this.tostService.tostrFun("Duplicate Entry Fee", 'error');
          return;
        }

      }
      result.push(name);
    }

    var gameDuration = this.gameObj.duration;

    if (this.duration_unit == 'days') {
      this.gameObj.duration = gameDuration * 24 * 60; // minutes
    } else if (this.duration_unit == 'hours') {
      this.gameObj.duration = gameDuration * 60;
    }


    let hasErr = "";
    if (this.gameObj.affiliate_id && this.gameObj.affiliate_id.key) {
      this.gameObj.affiliate_id = this.gameObj.affiliate_id.key;
    }

    this.gameObj.source_type = this.gameObj.source_type.source_code || this.gameObj.source_type
    this.gameObj.locations = this.gameObj.locations || []
    // this.gameObj.character_id_1 = this.gameObj.character_id_1;
    // this.gameObj.character_id_2 = this.gameObj.character_id_2;
    // this.gameObj.start_time = moment().format('MM DD YYYY, hh:mm');
    if (this.isSingle) {
      let setDefaultBackground = this.gameObj.background.urls;
      if (this.gameObj.background.urls.length < 2) {
        setDefaultBackground = this.orignalBackgroundurls;
      }
      this.gameObj.background.urls = [];
      let urlObj = {};

      let isUrl = false;
      if (this.file_type == 'image') {
        if (this.image_url != "" && this.image_url) {
          urlObj = { "file_type": "image", "url": this.image_url }
        }

      } else if (this.file_type == 'video') {
        if (this.video_src != "" && this.video_src) {
          urlObj = { "file_type": "video", "url": this.video_src }
        }

      }

      this.gameObj.background.urls.push(urlObj);

      if (this.isSingle && this.gameObj.background.urls && !this.gameObj.background.urls[0].url) {
        if (confirm("you are not select image or video for Background otherwise set default background is Virtical")) {
          this.gameObj.background.urls = setDefaultBackground;
          this.gameObj.background['type'] = "vertical";
        } else {
          if (this.file_type == 'image') {
            hasErr = "Please select image";
          } else if (this.file_type == 'video') {
            hasErr = "Please select  video";
          }

        }
      }


    }
    if (this.pin_game_to_top) {
      this.gameObj.priority_flag = 0;
    } else {
      this.gameObj.priority_flag = 1;
    }

    var curDate = new Date();
    if (!this.gameObj.game_text) {
      hasErr = "Please enter game text";
    } else if (!this.gameObj.game_text) {
      hasErr = "Please enter game text";
    } else if (!this.gameObj.game_title && this.gameType != 'ugg') {
      hasErr = "Please enter game title";
    } else if (!this.gameObj.start_time) {
      hasErr = "Please select start date";
    } else if (new Date(this.gameObj.start_time) > new Date(this.gameObj.end_time)) {
      hasErr = "End Date should be greater than start date";
    } else if (this.action == "create" && new Date(this.gameObj.end_time) < new Date()) {
      hasErr = "End Date should not be before today.";
    } else if (this.action == "create" && this.data.games == 'custom' && new Date(this.gameObj.last_join_time) < new Date()) {
      hasErr = "Last Join Date should not be before current time.";
    } else if (this.data.games == 'pinned' && (!this.gameObj.duration || (this.gameObj.duration < this.defaultDuration) || (this.gameObj.duration % 5 != 0))) {
      hasErr = `Game duration should be ${this.defaultDuration} or more minutes and multiples of 5`;
    }
    else if (this.data.games == 'custom' && new Date(this.gameObj.start_time) > new Date(this.gameObj.last_join_time)) {
      hasErr = "Last Join Date should be greater than start date";
    } else if (this.data.games == 'custom' && new Date(this.gameObj.last_join_time) > new Date(this.gameObj.end_time)) {
      hasErr = "Last join date should be less than or eqaul to end date.";
    } else if (!this.gameObj.character_id_1) {
      hasErr = "Please enter character 1";
    } else if (!this.gameObj.character_id_2) {
      hasErr = "Please enter character 2";
    }

    if (hasErr) {

      this.tostService.tostrFun(hasErr, 'error');
      return;
    }

    let gameType;
    let fn;

    switch (this.data.games) {
      case 'custom':
        gameType = 'Custom';
        this.gameObj.game_type = 'C';
        fn = 'createCustomGame';
        break;
      case 'pinned':
        gameType = 'Pinned';
        this.gameObj.game_type = 'P';
        fn = 'createPinnedGame';
        break;
      case 'ugg':
        gameType = 'UGG';
        this.gameObj.game_type = 'U';
        fn = 'createPinnedGame';
        break;
        }

    if (fn) {
      this.spinner.show();
      this.gameBankService[fn](this.gameObj, this.action).subscribe(
        res => {
          if (this.action == 'update') {
            this.tostService.tostrFun(`${gameType} game updated successfully`, 'success');
          } else {
            this.tostService.tostrFun(`${gameType} game created successfully`, 'success');
          }

          this.spinner.hide();
          this.dialogRef.close();
        },
        err => {
          this.spinner.hide();
          this.dialogRef.close();
        }
      );
    }
  }



  test(e) {
    //  console.log(e)
  }
  fileTypeClick(e) {

  }
  addPriceOption() {
    this.price_options.push({
      'entry_fee': 0,
      'prize_money': 0,
      'default': false
    });
  }
  changeCharacterType(e) {
    let obj = {}
    var marvelHeroes = this.sourceType.filter(function (hero) {
      return hero.source_code == e.value;
    });
    if (this.data.games == 'pinned') {
      this.gameText = marvelHeroes[0].question_text;
      this.gameObj.game_text = marvelHeroes[0].question_text;
      this.sourceTypeObj = e.value;

      if (this.searchKeyword1.search && this.searchKeyword1.search) {
        const searchObj = {};
        searchObj['source_type'] = e.value;

        this.searchKeyword1.search = Object.assign({}, this.searchKeyword1.search, searchObj);

      } else {
        obj['source_type'] = e.value;
        this.searchKeyword1.search = obj;
      }
      if (this.searchKeyword.search && this.searchKeyword.search) {
        const searchObj = {};
        searchObj['source_type'] = e.value;

        this.searchKeyword.search = Object.assign({}, this.searchKeyword.search, searchObj);

      } else {
        obj['source_type'] = e.value;
        this.searchKeyword.search = obj;
      }
    }
  }

  getCharAndAttribute() {
    this.refDataService.getRefData1().subscribe(data => {
      const res = JSON.stringify(data);
      const sourceData = (JSON.parse(res)).attributes;
      this.defaultDuration = (JSON.parse(res)).gameDuration || 60;
      this.gameObj.duration = this.defaultDuration;
      const sourceDataType = JSON.stringify(sourceData);
      const sourcetype = JSON.parse(sourceDataType);
      this.sourceType = JSON.parse(sourceDataType);
      this.sourceTypeData = sourcetype.map(obj => {
        let rObj = {}
        rObj['label'] = obj.source_name;
        rObj['value'] = obj.source_code;
        return rObj
      })

    });
  }
  GameGetById(id) {
    // this.gameBankService.getCustomGameById()
  }
  getAffiliateSummary() {
    this.affiliateServiceService.affiliateSummary().subscribe(data => {
      const res = JSON.stringify(data);
      const affiliateSummaryData = (JSON.parse(res));
      this.affiliateSummary = affiliateSummaryData.map(obj => {
        let rObj = {}
        rObj['label'] = obj.full_name + ' (' + obj.affiliate_code + ') ';
        rObj['value'] = obj.id;
        return rObj
      })
    });
  }

  onFileChangeVideo(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      // const [file] = event.target.files;
      this.isFile = false;
      if (this.file_type = "video") {

        let me = this;
        let files = event.target.files;
        const file1 = files[0];
        const filetype = files[0].type;
        let fileSizeInMb = file1.size / 1024 / 1024; // in MB

        let hasError = '';


        if (fileSizeInMb > SiteConstants.videoValidation.VIDEO_MAX_SIZE) {
          hasError = `You can upload max ${SiteConstants.videoValidation.VIDEO_MAX_SIZE} mb video size`
        } else if (filetype != 'video/mp4') {
          hasError = `please upload mp4 file`
        }

        if (hasError) {
          this.tostService.tostrFun(hasError, 'error')
          return false;
        }
        const [file] = event.target.files;
        reader.readAsDataURL(file);

        reader.onload = () => {

          const VideoSrc = reader.result as string;
          this.videoplayer.nativeElement.src = VideoSrc;
          this.videoplayer.nativeElement
            .play().then(() => { }).catch((err) => { })

        };


      }
      this.videoFile = event.target.files[0];


    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      // const [file] = event.target.files;

      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      this.spinner.show();
      this.refDataService.fileUploadFun(formData).subscribe(data => {
        this.spinner.hide();
        const FileData = JSON.stringify(data);
        const fileData1 = JSON.parse(FileData);


        if (this.file_type == 'image') {
          this.imageSrc = fileData1.picture;
          this.images = { background: 'url(' + this.imageSrc + ')' }

          this.image_url = this.imageSrc
          this.imagesCharacterSingle = { background: 'url(' + this.imageSrc + ')' }

        }

      },
        err => {
          this.spinner.hide();
        });

    }
  }




  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.images = { background: 'url(' + this.croppedImage + ')' }
    this.image_url = this.croppedImage;
  }

  getDuration(e) {


    let hasError = "";
    if (e.target.duration < SiteConstants.videoValidation.MIN_VIDEO_DURATION || e.target.duration > SiteConstants.videoValidation.MAX_VIDEO_DURATION) {
      hasError = `You can upload video duration greater than ${SiteConstants.videoValidation.MIN_VIDEO_DURATION}'s and less than 1 min`
    } else if (e.target.videoWidth < SiteConstants.videoValidation.MIN_VIDEO_WIDTH && e.target.videoHeight < SiteConstants.videoValidation.MIN_VIDEO_HEIGHT) {
      hasError = `You can upload video width greater than ${SiteConstants.videoValidation.MIN_VIDEO_WIDTH} and height greater than ${SiteConstants.videoValidation.MIN_VIDEO_HEIGHT}`
    }
    if (hasError) {
      this.tostService.tostrFun(hasError, 'error');
      this.video_src = '';

      var playPromise = this.videoplayer.nativeElement.pause();
      return false;
    }


    if (!this.isFile) {
      this.spinner.show();

      const formData = new FormData();
      formData.append('file', this.videoFile);
      this.refDataService.fileUploadFun(formData).subscribe(data => {
        this.spinner.hide();
        const FileData = JSON.stringify(data);
        const fileData1 = JSON.parse(FileData);

        this.isFile = true;
        if (this.file_type == 'video') {

          this.video_src = fileData1.video;

          this.videoplayer.nativeElement.src = this.video_src;
          this.videoplayer.nativeElement
            .play().then(() => { }).catch((err) => { })


        }


      },
        err => {
          this.isFile = true;
          this.spinner.hide();
        });
    }


  }
}
