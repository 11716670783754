<div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create Category</h4>
        <button type="button" class="close" aria-label="Close" (click)="submitData()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="leagueForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <mat-form-field class="example-full-width">
                            <input matInput formControlName="category_name" id="category_name" placeholder="Category Name*" type="text" class="form-control">
                        </mat-form-field>
                        <!-- <div *ngIf="f.character_name.touched && f.character_name.invalid" class="alert alert-danger">
                            <div *ngIf="f.character_name.errors.required">Name is required.</div>
                        </div> -->
                    </div>
                 
                </div>
                <div  class="col-md-6">
                       
                        <mat-form-field class="example-full-width">
                                <input matInput formControlName="category_code" id="category_code" placeholder="Category Code*" type="text" class="form-control">
                            </mat-form-field> 
                </div>

                <div  class="col-md-6">
                        <p-colorPicker  (onChange)="change($event)" formControlName="color_code" format="rgb"></p-colorPicker>                            
                            {{"RGB"+color_code}}
                 </div>
                       
            <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                            <input matInput formControlName="order_index" id="order_index" placeholder="Order INdex" type="text" class="form-control">
                        </mat-form-field> 
            </div>
            </div>
            <div class="row">
                <div class=" col-md-6">
                        <mat-form-field>
                                <mat-label>Game type*</mat-label>
                                <mat-select>
                                  <mat-option *ngFor="let gametype of gameType" [value]="gametype.value">
                                    {{gametype.name}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>    
                </div>    

                <div class=" col-md-6">
                        <mat-form-field>
                                <mat-label>Player type*</mat-label>
                                <mat-select multiple>
                                  <mat-option *ngFor="let playertype of characterType" [value]="playertype.value">
                                    {{playertype.name}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>    
                </div>    
            </div>
            <div class="row">
                  <div class="col-md-4">
                        <mat-checkbox formControlName="active">Active</mat-checkbox>
                        
                  </div>  
                  <div class="col-md-4">
                        <mat-checkbox formControlName="is_auto_game">Is Auto Game</mat-checkbox>
                  </div> 
                  <div class="col-md-4">
                        <mat-checkbox formControlName="show_on_discover">Show On Discover</mat-checkbox>
                  </div> 
            </div>
            <div class="row">
                <div class="col-md-8">
                    <mat-form-field class="example-full-width">
                        <input formControlName="image_url" matInput placeholder="Category's Profile Picture Url" type="text">
                    </mat-form-field>
                </div>
    
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="file">Select Character's Picture</label>
                        <input  id="file" type="file" class="form-control" (change)="onFileChange($event)">
                        <!-- <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                                <div *ngIf="f.file.errors.required">File is required.</div>
                            </div> -->
                    </div>
    
                    <img [src]="imageSrc" *ngIf="imageSrc" style="height: 147px; width:150px">
                </div>
    
            </div>
           
                <div class="form-group">
                        <div class="col-md-8">
                                <mat-form-field class="example-full-width">
                                    <input  matInput placeholder="Category's Profile Icon Url" type="text">
                                </mat-form-field>
                            </div>
                </div>    
                <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                    <label for="file1">Select Character's Picture</label>
                                    <input  id="file1" type="file" class="form-control" (change)="onFileChange1($event)">
                        
                            </div>
            
                            <img [src]="iconSrc" *ngIf="iconSrc" style="height: 147px; width:150px">
                        </div>
            
                    </div>
            

            <div class="modal-footer">
    
                <button mat-raised-button type="submit" class="btn btn-primary pull-right" (click)="close()">Submit</button>
            </div>
            <div class="clearfix"></div>
    
    
        </form>
    </div>