<div class="main-content">
    <div class="container-fluid">
        <form [formGroup]="imagesForm">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="name" id="name" placeholder="Name" type="text"
                            class="form-control" (keyup)="userFilter($event.target.value, null)">
                    </mat-form-field>
                </div>

                <div class="col-md-4">
                    <!-- <mat-select class="example-full-width">
                                        <input matInput formControlName="name" id="name" placeholder="Name" type="text" class="form-control">
                                    </mat-select> -->
                    <mat-form-field>
                        <mat-label>Sort</mat-label>
                        <!-- <mat-select  class=" ali2">
                                                    <mat-option *ngFor="let food of foods" [value]="food.value">
                                                            {{food.viewValue}}
                                                          </mat-option>
                                            </mat-select> -->
                        <mat-select [(value)]="sortSelected" class=" ali2">
                            <mat-option (click)="customSort($event)" value="asc">Ascending</mat-option>
                            <mat-option (click)="customSort($event)" value="desc">Descending</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="col-md-4">
                    <i class="fa fa-refresh ali" (click)="reset()" role="button" tabindex="0"></i>
                </div>
            </div>
            <!-- below is the image ui layout -->

            <div class="search-results" infinite-scroll [infiniteScrollDistance]="scrollDistance"
                [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle"
                (scrolled)="onScroll()" (scrolledUp)="onUp()" [scrollWindow]="false">
                <div class="row">
                    <mat-card class="example-card size  galleryImage" *ngFor="let item of characterImage;index as i;"
                        (mouseover)="isOpen = true" (mouseout)="isOpen=false">
                        <img mat-card-image class="card-btn" src="{{item.image_url}}">

                        <!-- {{item.image_url}} -->

                        <div class="action-div">
                            <span class="action-span" [hidden]="!item.image_url && item.image_url!=''"
                                (click)="openImageRemoveDialog(item)"><i class="fa fa-trash"></i></span>
                            <span class="action-span"><i class="fa fa-pencil-square-o"
                                    (click)="openDialog(item)"></i></span>
                        </div>
                    </mat-card>
                </div>
            </div>


            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
                [fullScreen]="true">
                <p style="color: white"> Loading... </p>
            </ngx-spinner>


        </form>

    </div>

</div>