import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { CreateLeagueComponent } from '../../modal/create-league/create-league.component';
@Component({
  selector: 'app-league-screen',
  templateUrl: './league-screen.component.html',
  styleUrls: ['./league-screen.component.scss']
})
export class LeagueScreenComponent implements OnInit {
  cols: any;

  constructor(private modalService: NgbModal, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'league_name', header: 'League Name' },
      { field: 'league_frequency', header: 'League Frequency' },
      { field: 'league_type', header: 'League Type' },
      { field: 'no_of_players', header: 'Number of Players' },
      { field: 'attributes', header: 'League Attributes' },
      { field: 'start_time', header: 'Start Date' },
      { field: 'end_time', header: 'End Date' },

      { field: '', header: 'Action' }

    ];
  }

  userFilter(field, val) {

  }

  openCreateLeagueDialog(createLeagueData) {

    const dialogRef = this.dialog.open(CreateLeagueComponent, {
      width: '650px',
      data: createLeagueData
    });

    dialogRef.afterClosed().subscribe(result => {
      //  console.log('The dialog was closed');
    });
  }

}
