import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../core/services/common/tost.service';
import { SelfgameService } from '../../core/services/game/selfgame.service'

@Component({
  selector: 'app-ignore-report',
  templateUrl: './ignore-report.component.html',
  styleUrls: ['./ignore-report.component.scss']
})
export class IgnoreReportComponent implements OnInit {

  ReportedData: any;
  isId: boolean = false;
  reportId: any;
  gameId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private selfgameService: SelfgameService, public dialogRef: MatDialogRef<IgnoreReportComponent>) { }

  ngOnInit(): void {
    // console.log('delete priceoption lis', this.data.priceOptionList)
    // console.log('index is', this.data.index)

    if (this.data.report_id == "") {
      this.isId = false;
      this.gameId = this.data.game_id;
    }
    else {
      this.isId = true;
      this.gameId = this.data.game_id;
      this.reportId = this.data.report_id
    }
    this.isId = this.data.report_id;

  }

  IgnoreReport(gameId) {
    let obj = {};
    obj = {
      id: gameId
    }

    this.selfgameService.ReportsIgnore(obj).subscribe(data => {
      //   console.log("create level data", data);
      this.tostService.tostrFun('Ignored all reports successfully.', 'success');
    },
      err => {
        // this.tostService.tostrFun("Something Went Wrong", 'error');
      }
    )
    this.dialogRef.close();
  }
  IgnoreReportById(gameId, reportId) {
    let obj = {};
    obj = {
      id: gameId, report_id: reportId
    }
    this.selfgameService.ReportsIgnore(obj).subscribe(data => {
      //   console.log("create level data", data);
      this.tostService.tostrFun("Ignored report successfully", 'success');
    },
      err => {
        // this.tostService.tostrFun("Something Went Wrong", 'error');

      }
    )
    this.dialogRef.close();
  }
  close() {
    this.dialogRef.close(true);
  }

}
