import { Component, OnInit, Inject } from '@angular/core';
import { RefDataService } from '../../core/services/common/ref-data.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SourceTypeService } from "../../core/services/source-type.service"
import { TostService } from '../../core/services/common/tost.service';

import { SelectItem } from 'primeng/api';
@Component({
  selector: 'app-source-type-edit-modal',
  templateUrl: './source-type-edit-modal.component.html',
  styleUrls: ['./source-type-edit-modal.component.scss']
})
export class SourceTypeEditModalComponent implements OnInit {
  isUpdate: Boolean = true;
  characterType: SelectItem;
  selectedCharacterType: any;
  isUpdateflag: Boolean = true;
  sourceTypeForm = new FormGroup({
    source_name: new FormControl('', [Validators.required]),
    source_code: new FormControl('', []),
    character_type: new FormControl('', []),
    weight: new FormControl('', [])
  });
  constructor(public dialogRef: MatDialogRef<SourceTypeEditModalComponent>, private tostService: TostService,
    private modalService: NgbModal, private refDataService: RefDataService, private sourcetypeservice: SourceTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.characterType = JSON.parse(this.refDataService.getRefData()).characterTypes;
    if (this.isUpdateflag === true) {
      this.isUpdate = true;
    } else {
      this.isUpdate = false;
    }
    if (this.data) {
      this.sourceTypeForm.patchValue({
        source_name: this.data.source_name,
        source_code: this.data.source_code,
        character_type: this.data.character_type,
        weight: this.data.weight
      });

    } else {
      this.sourceTypeForm.patchValue({
        source_name: '',
        source_code: '',
        character_type: '',
        weight: ''
      });

    }
  }
  get f() {
    return this.sourceTypeForm.controls;
  }

  submitData() {
    // console.log(this.sourceTypeForm.value);
    if (this.data.id) {
      // this.levelForm.value.id = this.data.id;
      let formValues = this.sourceTypeForm.value;
      Object.assign(formValues, {
        id: this.data.id,
        createdAt: this.data.createdAt,
        notes: this.data.notes,
        points: this.data.points,
        updatedAt: this.data.updatedAt,
        question_text: this.data.question_text
      });

    }

    let formValues = this.sourceTypeForm.value;
    this.sourcetypeservice.updateSourceType(formValues).subscribe(data => {
      // console.log("create level data", data);

      this.tostService.tostrFun("Source Type updated Successfully", 'success');


    },
      err => {
        // this.tostService.tostrFun("Something Went Wrong", 'error');

      }
    )
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close(true);
  }
}
