import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelfgameService } from '../../core/services/game/selfgame.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClipboardService } from 'ngx-clipboard';
import { TostService } from '../../core/services/common/tost.service'
@Component({
  selector: 'app-selfgame-dispute-modal',
  templateUrl: './selfgame-dispute-modal.component.html',
  styleUrls: ['./selfgame-dispute-modal.component.scss']
})
export class SelfgameDisputeModalComponent implements OnInit {

  constructor(private selfGameService: SelfgameService, private tostService: TostService, private _clipboardService: ClipboardService, private spinner: NgxSpinnerService, public dialogRef: MatDialogRef<SelfgameDisputeModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
  alldata: any = [];

  disputesListData: any = [];
  descData: any[];
  declarationProof: any[] = [];
  loading: boolean;
  status: any;
  character_score: any[] = [];
  ngOnInit(): void {
    this.loading = true;
    // console.log("dispute modal",this.data);
    // this.status = this.data.status;
    this.getdisputedRecord(this.data.id);
  }

  getdisputedRecord(id) {
    this.spinner.show();
    this.selfGameService.getDisputedRecorded(id).subscribe(data => {
      //  console.log("disputed data ",data);
      // this.alldata = data.disputesList;
      const res = JSON.parse(JSON.stringify(data));
      const getData = (res.disputesList);
      const gameInfo = (res.gameInfo);

      this.declarationProof = [];
      this.disputesListData = [];

      this.disputesListData.push(gameInfo);
      this.status = gameInfo.status;
      this.character_score.push(gameInfo.characters_score);
      this.declarationProof.push(gameInfo.declaration_proofs);
      this.alldata = JSON.parse(JSON.stringify(getData));

      if (this.alldata && this.alldata.length && this.alldata[0].description) {
        this.descData = JSON.parse(this.alldata[0].description);
      }

      this.loading = false;
      this.spinner.hide();
    },
      err => {
        this.loading = false;
        this.spinner.hide();
        // this.dialogRef.close()
      }
    );
    // this.close();
  }
  onRefrash(e) {
    setTimeout(() => {
      this.getdisputedRecord(this.data.id)
    }, 1500)

  }
  callServiceToCopy(url) {
    this._clipboardService.copyFromContent(url);
    this.tostService.tostrFun('url copy success!', 'success');
  }
  isApproveStatus(e) {
    let status = (e == 7) ? true : false;
    return status
  }
  isStatus(e) {
    let status = (e == 0 || e == 4 || e == 7) ? true : false;
    return status
  }
  redirectToUser() {

    //// this.router.navigate(['/user/', id]);
    this.dialogRef.close();
  }
  close() {
    this.dialogRef.close(true);
  }
}
