<div class="modal-header">
  <h5 *ngIf="isUpdate == false" class="modal-title" id="modal-basic-title">Create Partner Affiliate </h5>
  <h4 *ngIf="isUpdate == true" class="modal-title" id="modal-basic-title">Update Partner Affiliate</h4>

</div>

<mat-dialog-content>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="affiliateData.full_name" name="full_name" placeholder="Full Name "
                        type="text" class="form-control">
                    </mat-form-field>

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="affiliateData.email" name="email" placeholder="Email " type="text"
                        class="form-control">
                    </mat-form-field>


                  </div>


                </div>
              </div>
              <!-- <input type="text" name="email" [(ngModel)]="affiliateData.email"> -->
              <div class="row">
                <div class="col-md-6">
                  <label for="start_date">Start Date</label>
                  <div class="form-group">
                    <p-calendar name="start_date" id="start_date" [(ngModel)]="affiliateData.start_date"
                      [showIcon]="true" (onSelect)="startDateOnSetTime($event)">
                    </p-calendar>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="end_date">End Date</label>
                  <div class="form-group">
                    <p-calendar name="end_date" id="end_date" [(ngModel)]="affiliateData.end_date" [showIcon]="true"
                      (onSelect)="startDateOnSetTime($event)">
                    </p-calendar>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="affiliateData.password" name="password" placeholder="Password"
                        type="password" class="form-control">
                    </mat-form-field>

                  </div>
                </div> -->
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="affiliateData.website" name="website" placeholder="website"
                        type="text" class="form-control">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="affiliateData.no_of_impressions" name="no_of_impressions"
                        placeholder="No Of Impressions" type="text" class="form-control">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="affiliateData.limit_of_rotate" name="limit_of_rotate"
                        placeholder="Limit" type="text" class="form-control">
                    </mat-form-field>


                  </div>
                </div>
              </div>
              <div class="row">

              </div>

              <div class="row" style="display: none;">
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="affiliateData.term_url" name="term_url" placeholder="Term Url"
                      type="text" class="form-control">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="affiliateData.logo_url" name="logo_url" placeholder="Logo Url"
                      type="text" class="form-control">
                  </mat-form-field>
                </div>
              </div>


              <div class="row" style="display: none;">
                <div class=" col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="affiliateData.consent" name="consent" placeholder="Consent Text "
                        type="text" class="form-control">
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="affiliateData.additional_email" name="additional_email"
                        placeholder="Additional Email " type="text" class="form-control">
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row" style="display: none;">
                <div class="col-md-6">
                  <div class="form-group">

                    <p-checkbox [binary]="true" [(ngModel)]="affiliateData.show_consent" name="show_consent"
                      value="show_consent" label="Show Consent" inputId="ny">
                    </p-checkbox>

                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input [(ngModel)]="affiliateData.background.bgColor" name="bgColor"
                      [style.background]="affiliateData.background.bgColor" [cpFallbackColor]="'#000'"
                      (colorPickerChange)="onChangeColor($event)" [(colorPicker)]="color" style="width: 40px;" />
                    <input type="text" [(ngModel)]="affiliateData.background.bgColor" name="bgColortxt" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="file">Background Image(Minimun size 375 × 812 px) </label>
                    <div>
                      <div class="file-field">
                        <input style="display: none" type="file" (change)="onFileChange($event)" #hiddenfileinput>
                        <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">Choose
                          file</label>


                      </div>
                    </div>
                    <!-- <p-fileUpload mode="basic" [showCancelButton]="true" [showUploadButton]="true" name="demo[]" cancelLabel="Cancel" [auto]="true" accept="image/*" multiple="multiple" onUpload="onuploadTest($event)" (onSelect)="onFileChange($event)"></p-fileUpload> -->
                    <!-- <input type="file" name="file" id="file"  (change)=onFileChange($event)> -->



                    <div *ngIf="affiliateData.background.bgImage"
                      style="padding: 10px;position: relative;display: flex;margin-top: 45px;">
                      <span style="position: absolute;
                                        top: 0px;
                                        right: -5px;
                                        font-size: 24px;
                                        color: red;
                                        width: 30px;
                                        height: 30px;
                                        text-align: center;" (click)="clearBgImage()"> &times;</span>
                      {{affiliateData.background.bgImage}}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="affiliateData['background'].bgBtnText" name="bgBtnText"
                        placeholder="Button Text" type="text" class="form-control">
                    </mat-form-field>

                  </div>

                </div>
              </div>

              <!-- <mat-progress-spinner mode="indeterminate" style="margin:0 auto;" *ngIf="IsWait">
                            </mat-progress-spinner> -->
              <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
                [fullScreen]="true">
                <p style="color: white"> Loading... </p>
              </ngx-spinner>
              <div class="row" style="display: none;">
                <div class="col-md-6">
                  <div [ngxSummernote]="config" [(ngModel)]="affiliateData.display_text" name="display_text"
                    id="summernote" (summernoteInit)="summernoteInit($event)">
                  </div>

                  <!-- <quill-editor formControlName="editor" [modules]="config" (onContentChanged)="textShow($event)" [styles]="editorStyle" ></quill-editor> -->
                </div>
                <div class="col-md-6">
                  <div flex="100" style="padding-left:5px" class="mobile-container flex-100">
                    <div class="mobile-main"
                      [ngStyle]="{'background-color':affiliateData.background.bgColor ? affiliateData.background.bgColor : '#000000', 'background-image': affiliateData.background.bgImage ? 'url(' + affiliateData.background.bgImage + ')' : ''}"
                      style="background-color: rgb(0, 0, 0); ">
                      <div class="cross-box"> <span> X </span> </div>

                      <div class="header-section ng-scope" *ngIf="affiliateData.logo_url">
                        <img src="{{affiliateData.logo_url}}">
                      </div>
                      <div class="middle-section">
                        <div class="sponsor-info">
                          <div [ngxSummernoteView]="affiliateData.display_text" class="ng-binding">

                          </div>
                        </div>
                      </div> <!-- ngIf: affiliateInfo.show_consent -->
                      <div class="checkConsent ng-scope" *ngIf="affiliateData.show_consent">
                        <input type="checkbox" checked="true" ng-readonly="true" />
                        <label style="color: white; text-decoration: underline;">
                          {{this.affiliateData.consent}} </label>
                      </div>
                      <!-- end ngIf: affiliateInfo.show_consent -->
                      <!-- ngIf: affiliateInfo.show_consent -->
                      <div class="footer-section ng-scope" *ngIf="affiliateData.show_consent">
                        <button
                          class="btn btn-full btn-rounded btn-red ng-binding">{{this.affiliateData.background.bgBtnText}}</button>
                      </div>

                    </div>
                  </div>

                </div>


              </div>
              <div class="row" style=" margin-bottom: 40px;">
                <div class="col-md-12">
                  <label for="category">Search Category</label>
                  <app-category-search-modal [tagInputData]="affiliateData.category" (onClearTags)="onClearTag($event)"
                    (tagOutput)="tagOutputFun($event)"></app-category-search-modal>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label for="creator">Search Creator</label>
                  <app-creator-search-modal [userInputData]="affiliateData.creator" (onClearTags)="onClearTag($event)"
                    (userOutput)="userOutputFun($event)"></app-creator-search-modal>
                </div>
              </div>
            </form>


          </div>

        </div>

      </div>
    </div>
  </div>
</mat-dialog-content>
<div class="modal-footer">

  <button mat-raised-button class="btn btn-danger pull-right" (click)="close()">cancel</button>
  <button mat-raised-button type="submit" class="btn btn-danger pull-right" (click)="saveAffiliateData()">Save</button>
</div>