<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{isCreate}} Notification</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- <form [formGroup]="notificationForm" (ngSubmit)="submitData()"> -->
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="notificationValues.title" id="title" placeholder="Title" type="text"
                        class="form-control">
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="notificationValues.message" id="message" placeholder="message"
                        type="text" class="form-control">
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <mat-form-field>
                    <input matInput [ngxMatDatetimePicker]="picker1" [(ngModel)]="notificationValues.start_date"
                        placeholder="Start Date">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
                </mat-form-field>

            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <mat-form-field>
                    <input matInput [ngxMatDatetimePicker]="picker2" [(ngModel)]="notificationValues.end_date"
                        placeholder="End Date">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker2></ngx-mat-datetime-picker>
                </mat-form-field>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <!-- <p-dropdown [options]="canCloseArray"  optionLabel="label" (onChange)="closeOption($event)" [(ngModel)]="notificationValues.can_close" placeholder="Can Close " dataKey="can_close" >
                    </p-dropdown> -->
                <mat-form-field appearance="fill">
                    <mat-label>Select close options</mat-label>
                    <mat-select [(ngModel)]="notificationValues.can_close" (selectionChange)="closeOption($event)"
                        name="canClose" id="canClose">
                        <mat-option *ngFor="let source of canCloseArray" [value]="source.value">
                            {{source.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <p-dropdown [options]="canCloseArray" name="canClose"
                                            [(ngModel)]="notificationValues.can_close" 
                                            (onChange)="closeOption($event)">
                                            <ng-template let-affiliate pTemplate="selectedItem">
                                                <span
                                                    style="vertical-align:middle; margin-left: .5em">{{affiliate.label}}</span>
                                            </ng-template>
                                            <ng-template let-item pTemplate="affiliate">
                                                <div class="ui-helper-clearfix"
                                                    style="position: relative;height: 25px;">
                                                    <div style="font-size:14px;margin-top:4px">{{item.label}}</div>
                                                </div>
                                            </ng-template>
                     </p-dropdown> -->

            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <!-- <p-dropdown [options]="alertTypeOption"  optionLabel="name" (onChange)="alertTypeChangeOption($event)"  [(ngModel)]="notificationValues.alert_type" placeholder="Alert Type " dataKey="alert_type">
                    </p-dropdown> -->

                <!-- <p-dropdown [options]="alertTypeOptionArray" name="alrtType" [(ngModel)]="notificationValues.alert_type"
                    (onChange)="alertTypeChangeOption($event)">
                    <ng-template let-affiliate pTemplate="selectedItem">
                        <span style="vertical-align:middle; margin-left: .5em">{{affiliate.label}}</span>
                    </ng-template>
                    <ng-template let-item pTemplate="affiliate">
                        <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                            <div style="font-size:14px;margin-top:4px">{{item.label}}</div>
                        </div>
                    </ng-template>
                </p-dropdown> -->
                <mat-form-field appearance="fill">
                    <mat-label>Select close options</mat-label>
                    <mat-select [(ngModel)]="notificationValues.alert_type"
                        (selectionChange)="alertTypeChangeOption($event)" name="alrtType" id="canClose">
                        <mat-option *ngFor="let source of alertTypeOptionArray" [value]="source.value">
                            {{source.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <p-checkbox [binary]="true" [(ngModel)]="notificationValues.is_active" value="is_active"
                    label="Is Active " inputId="ny"></p-checkbox>

            </div>
        </div>

    </div>


    <div class="modal-footer">
        <button mat-raised-button type="submit" class="btn btn-danger pull-right"
            [disabled]="!notificationValues.title || !notificationValues.message || !notificationValues.start_date || !notificationValues.end_date"
            (click)="submitData()">Save</button>
    </div>
    <div class="clearfix"></div>
    <!-- </form> -->

</div>