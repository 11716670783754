<div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Add Versusgame Balance</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form [formGroup]="myForm" (ngSubmit)="submitData()">
    <div class="modal-body">

        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    User Balance: {{userBalance}} coins (${{userBalanceUsd}})
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <mat-label>Amount in coins ({{ coinRate }} coins = $1)</mat-label>
                        <input matInput formControlName="amount" id="amount" placeholder="Amount in coins" type="number"
                          class="form-control" [min]="0">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" [ngStyle]="{ 'margin-top': '24px' }">${{ myForm.controls.amount.value / coinRate }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" *ngIf="isCredit">
                <div class="form-group">
                    <mat-form-field>
                        <mat-label>Payment Type</mat-label>
                        <mat-select formControlName="tx_type">
                            <mat-option *ngFor="let type of paymentType" [value]="type.value">
                                {{type.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                  <mat-form-field>
                      <mat-label>Amount Type</mat-label>
                      <mat-select formControlName="amount_type">
                          <mat-option *ngFor="let type of amountType" [value]="type.value">
                              {{type.viewValue}}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Reason (Description)</mat-label>
                    <input matInput formControlName="notes" id="notes" placeholder="notes" type="textarea"
                        class="form-control">
                </mat-form-field>
            </div>

        </div>


    </div>
    <div class="modal-footer">
        <button mat-raised-button type="submit" class="btn  btn-danger pull-right " data-dismiss="alert"
            aria-label="Close" (click)="submitData()">Submit</button>

    </div>
</form>
