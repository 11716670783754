import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class TostService {

  constructor(private toastr: ToastrService) { }

  tostrFun(message, type) {



    this.toastr.show(message, '', {
      timeOut: 3000,
      closeButton: true

    }, this.toastr.toastrConfig.iconClasses[type]);
  }


}
