import { Component, OnInit } from '@angular/core';
import lodash from 'lodash';
import { PaginationService } from '../../core/services/common/pagination.service';
import { SearchService } from '../../core/services/common/search.service';
import { UsersService } from '../../core/services/users.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SiteConstants } from '../../../config/site-constants';
import * as moment from 'moment';

@Component({
  selector: 'app-blacklist-country-states',
  templateUrl: './blacklist-country-states.component.html',
  styleUrls: ['./blacklist-country-states.component.scss']
})
export class BlacklistCountryStatesComponent implements OnInit {

  cols = [];

  loading = false;

  countryStatesList = [];

  totalRecords = 0;

  TimeoutSearch: any;

  selectedTag = null;

  filters = {};

  globalSort = '';

  constPage = 10;



  myForm = new FormGroup({
    tag_type: new FormControl('', [])
  });
  filter: any = {};
  constructor (
    private usersService: UsersService,
    private paginationService: PaginationService,
    private searchService: SearchService,
  ) {
  }

  ngOnInit (): void {

    this.cols = [
      { field: 'id', header: 'Tag Id', width: '80px' },
      { field: 'country_code', header: 'Country Code', width: '120px' },
      { field: 'country_name', header: 'Country Name', width: '120px' },
      { field: 'state_code', header: 'State Code', width: '120px' },
      { field: 'state_name', header: 'State Name', width: '120px' },
      { field: 'is_active', header: 'Status', width: '90px' },
      { field: 'created_at', header: 'Created At', width: '120px' },
      { field: '', header: 'Actions', width: '80px' },
    ];


    this.GetData();

  }
  localDate (date) {
    return moment(date).format('DD-MM-YYYY hh:mm a');
  }

  GetData () {
    //this.loading = true;
    this.usersService
      .getBlackListCountryState(this.filters).subscribe(data => {
        this.countryStatesList = data['results'];
        this.totalRecords = data['total'];
        this.loading = false;
      });

  }

  customSort (ev) {
    let obj = {};

    if (ev.order == 1) {
      obj[ev.field] = 'desc'
    } else {
      obj[ev.field] = 'asc'
    }

    const sortKey = ev.field + ' ' + ev.order;
    if (this.globalSort !== sortKey) {
      this.filters['sort'] = obj;
      this.GetData();
      this.globalSort = sortKey;
    }
  }

  customPagination (ev) {
    let data = this.paginationService.paginationFun(ev, this.globalSort, this.constPage, this.filters)
    this.filters = data.filter;
    this.globalSort = data.globalSort;
    this.constPage = data.defaultPage;

    this.GetData();
  }

  onRefresh (ev) {
    setTimeout(() => {
      this.GetData();
    }, 100);
  }
  countryStateFilter (val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filters)
    this.filters = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData();
    }, SiteConstants.FiltertimeOut);
  }

  userFilter (val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData();
    }, SiteConstants.FiltertimeOut);
  }

}
