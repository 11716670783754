import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '../../../core/services/users.service';
import lodash from 'lodash';
import { TostService } from '../../../core/services/common/tost.service';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { SystemSettingsService } from '../../../core/services/game/system-settings.service';


@Component({
  selector: 'app-global-cut-config-modal',
  templateUrl: './global-cut-config-modal.component.html',
  styleUrls: ['./global-cut-config-modal.component.scss']
})
export class GlobalCutConfigModalComponent implements OnInit {

  globalCutConfig: any;
  user: any;
  maxPrizeOp: number = 0;
  defaultMaxPayout = 0;
  coinRate: number = 0;
  loading = false;
  npc: number = 0;
  durationTypes: any = [];
  settingType = '';
  NpcPer: any = [];
  isHost: any;
  newSettings: any;
  oldSettings: any;
  maxPayoutFlag: boolean = false;
  userList = [];
  debounceConfig;
  hc_pool: number = 0;
  config: string = '';
  transactionFee: any = 0;
  hostReferralCut: any = 0;
  playerReferralCut: any = 0;
  consultingPC: any = 0;
  marketingCost: any = 0;
  gameId: Number;
  userId: Number;
  isSameSettings: boolean = false;
  entityId: any;
  durationTxt: string;
  durationVal: string;
  isSave: boolean = true;
  transactionFeePer: any = 0;
  is_global: boolean = false;
  prizeMoneyTotal: Number = 0;
  playerReferralCutPer: any = 0;
  marketingCostPer: any = 0;
  constructor (
    public dialogRef: MatDialogRef<GlobalCutConfigModalComponent>,
    private usersService: UsersService,
    private tostService: TostService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private refDataService: RefDataService,
    private systemSettingsService: SystemSettingsService
  ) {
    this.durationTypes = [
      { name: 'Year', value: 'year' },
      { name: 'Month', value: 'month' },
    ]

  }

  ngOnInit (): void {
    if (this.data && this.data.data.length && this.data.entry_fee) {
      const resp = this.data.data.filter(d => d.entry_fee == this.data.entry_fee);
      const resp1 = JSON.parse(JSON.stringify(resp))
      this.oldSettings = JSON.parse(JSON.stringify(this.data.data));
      this.globalCutConfig = lodash.get(resp1, '0');
      this.prizeMoneyChange(Number(this.globalCutConfig.prize_money))
      this.transactionFeeChange(this.globalCutConfig.transaction_fee.percentage);
      this.playerRefCutChange(this.globalCutConfig.player_referral_cut.percentage);
      this.marketingCostChange(this.globalCutConfig.marketing_cost.percentage);
      this.hcPoolChange(this.globalCutConfig.hc_pool.percentage)
      this.hostReferralCutChange(this.globalCutConfig.host_referral_cut.percentage);
      this.consultingPCChange(this.globalCutConfig.consulting_pc_pool.percentage);
      this.is_global = this.data.is_global || false;
      this.config = this.data.config;
      this.entityId = this.data.entityId;


    }
    const parts = this.globalCutConfig.player_referral_cut.duration.split(' ')
    this.durationTxt = parts[0]
    this.durationVal = parts[1]
  }

  close () {
    this.dialogRef.close(true);
  }
  playerReferralDurationChange (e) {
    this.globalCutConfig.player_referral_cut.duration = `${this.durationTxt} ${this.durationVal}`
  }
  changeDurationVal (e) {
    this.durationVal = e.value;
    this.globalCutConfig.player_referral_cut.duration = `${this.durationTxt} ${this.durationVal}`
  }

  updateConfig () {
    this.spinner.show();
    let isError = false;
    let parentArr = [];
    let parentCPCArr = [];

    this.globalCutConfig.parents.forEach(p => {
      if (!p.user_id) {
        isError = true;
        this.tostService.tostrFun('Please select valid parent', 'error');
      } else if (parentArr.includes(p.user_id)) {
        isError = true;
        this.tostService.tostrFun('You cannot select duplicate parents', 'error');
        parentArr = [];
      }
      parentArr.push(p.user_id);
    });
    if (!isError) {
      this.globalCutConfig.consulting_pc_pool_parents.forEach(p => {
        if (!p.user_id) {
          this.tostService.tostrFun('Please select valid CPC parent', 'error');
          isError = true;
        } else if (parentCPCArr.includes(p.user_id)) {
          isError = true;
          this.tostService.tostrFun('You cannot select duplicate parents', 'error');
          parentCPCArr = [];
        }
        parentCPCArr.push(p.user_id);
      });
    }

    if (!isError) {
      const sum = this.globalCutConfig.parents.map(item => item.percentage).reduce((prev, curr) => prev + curr, 0);
      if (sum > 100) {
        this.tostService.tostrFun("Parents cut percentage should not be greater than 100%.", 'error');
        isError = true;
      }
    }
    if (!isError) {
      const sum = this.globalCutConfig.consulting_pc_pool_parents.map(item => item.percentage).reduce((prev, curr) => prev + curr, 0);
      if (sum > 100) {
        this.tostService.tostrFun(" CPC Parents cut percentage should not be greater than 100%.", 'error');
        isError = true;
      }
    }
    if (isError) {
      this.spinner.hide();
      return;
    }

    let resp = this.data.data.map(e => {
      if (e.entry_fee === this.globalCutConfig.entry_fee) {
        e = this.globalCutConfig
      }
      e.transaction_fee.percentage = this.toFixedFun(e.transaction_fee.percentage);
      e.player_referral_cut.percentage = this.toFixedFun(e.player_referral_cut.percentage);
      e.marketing_cost.percentage = this.toFixedFun(e.marketing_cost.percentage);
      e.hc_pool.percentage = this.toFixedFun(e.hc_pool.percentage);
      e.host_referral_cut.percentage = this.toFixedFun(e.host_referral_cut.percentage);
      e.consulting_pc_pool.percentage = this.toFixedFun(e.consulting_pc_pool.percentage);
      return e;
    });
    if (this.isSameSettings) {
      this.setSettingsForAll();
      resp = this.newSettings;
    }
    const obj = {};
    if (this.config === 'User') {
      obj['entity_type'] = 'user';
      obj['entity_id'] = this.entityId;
    } else if (this.config === 'Game') {
      obj['setting_type'] = 'game';
      obj['entity_type'] = 'game'
      obj['entity_id'] = this.entityId;
    } else if (this.config === 'Global') {
      obj['setting_type'] = 'game_payout';
      obj['entity_type'] = null;
      obj['entity_id'] = null;
    }
    obj['value'] = resp;
    this.systemSettingsService
      .saveCutSettings(obj)
      .subscribe(res => {
        this.dialogRef.close();
        this.spinner.hide()

        this.tostService.tostrFun(`${this.config} cut settings updated successfully`, 'success');
      }, () => {
        this.spinner.hide()
      });
  }


  transactionFeeChange (e) {
    const transCost = this.toFixedFun(e);
    this.transactionFee = this.npc * transCost / 100;
    this.transactionFeePer = this.toFixedFun(e);
    this.NPCCalculation();
  }

  prizeMoneyChange (e) {

    const prizeMoney = this.toFixedFun(e);
    this.prizeMoneyTotal = prizeMoney;
    this.npc = (Number(this.globalCutConfig.entry_fee) * 2) - prizeMoney;
    this.transactionFeeChange(this.globalCutConfig.transaction_fee.percentage);
    this.playerRefCutChange(this.globalCutConfig.player_referral_cut.percentage);
    this.marketingCostChange(this.globalCutConfig.marketing_cost.percentage);
    this.hcPoolChange(this.globalCutConfig.hc_pool.percentage)
    this.hostReferralCutChange(this.globalCutConfig.host_referral_cut.percentage);
    this.consultingPCChange(this.globalCutConfig.consulting_pc_pool.percentage);
    this.NPCCalculation();
  }

  playerRefCutChange (e) {
    const playerRefCut = this.toFixedFun(e);;
    this.playerReferralCut = this.npc * playerRefCut / 100;
    this.playerReferralCutPer = this.toFixedFun(e);;
    this.NPCCalculation();
  }

  marketingCostChange (e) {
    const mrktCost = this.toFixedFun(e);;
    this.marketingCost = this.npc * mrktCost / 100;
    this.marketingCostPer = this.toFixedFun(e);;
    this.NPCCalculation();
  }

  hcPoolChange (e) {
    const hcPoolPer = this.toFixedFun(e);;

    this.hc_pool = (this.npc - this.transactionFee - this.playerReferralCut - this.marketingCost) * hcPoolPer / 100
    this.calculateCut();
  }
  hostReferralCutChange (e) {
    const hostReferralCutPer = this.toFixedFun(e);;
    this.hostReferralCut = this.hc_pool * hostReferralCutPer / 100;

    this.calculateCut();
  }
  consultingPCChange (e) {
    const consultingPCPer = this.toFixedFun(e);;
    this.consultingPC = this.hc_pool * consultingPCPer / 100;

    this.calculateCut();
  }

  searchUsers (ev) {
    if (this.debounceConfig) {
      clearTimeout(this.debounceConfig);
    }

    this.debounceConfig = setTimeout((username) => {
      this.usersService.getUsersData1({
        search: { user_name: username }
      }).subscribe(data => {
        this.userList = data['usersList'];
      });
    }, 500, ev.target.value);
  }

  addParents () {
    const obj = { "user_id": null, "percentage": 0 };
    this.globalCutConfig.parents.push(obj);
  }
  removeParents (e) {
    this.globalCutConfig.parents.splice(e - 1, 1);
  }
  changedParentFun (e) {
    this.globalCutConfig.parents[e.index - 1] = e.parent;
    const sum = this.globalCutConfig.parents.map(item => item.percentage).reduce((prev, curr) => prev + curr, 0);
    if (sum > 100) {
      this.tostService.tostrFun("Parents cut percentage should not be greater than 100%.", 'error');
      //   this.globalCutConfig.parents[e.index - 1] = { user_id: e.parent.user_id, percentage: 0 }
    }
  }

  addCPCParents () {
    const obj = { "user_id": null, "percentage": 0 };
    this.globalCutConfig.consulting_pc_pool_parents.push(obj);
  }
  removeCPCParents (e) {
    this.globalCutConfig.consulting_pc_pool_parents.splice(e - 1, 1);
  }
  changedCPCParentFun (e) {
    const userIds = this.globalCutConfig.consulting_pc_pool_parents.map(e => Number(e.user_id));
    if (userIds.includes(e.parent.user_id)) {

    }
    this.globalCutConfig.consulting_pc_pool_parents[e.index - 1] = e.parent;
    const sum = this.globalCutConfig.consulting_pc_pool_parents.map(item => item.percentage).reduce((prev, curr) => prev + curr, 0);
    if (sum > 100) {
      this.tostService.tostrFun("CPC Parents cut percentage should not be greater than 100%.", 'error');
      //   this.globalCutConfig.consulting_pc_pool_parents[e.index - 1] = { user_id: e.parent.user_id, percentage: 0 }
    }
  }
  checkValue (e) {
    if (e) {
      this.newSettings = this.setSettingsForAll();
    } else {
      this.newSettings = this.oldSettings;
    }
  }
  setSettingsForAll () {
    this.newSettings = this.oldSettings.map(e => {
      e.transaction_fee = this.globalCutConfig.transaction_fee;
      e.player_referral_cut = this.globalCutConfig.player_referral_cut;
      e.marketing_cost = this.globalCutConfig.marketing_cost;
      e.hc_pool = this.globalCutConfig.hc_pool;
      e.host_referral_cut = this.globalCutConfig.host_referral_cut;
      e.consulting_pc_pool = this.globalCutConfig.consulting_pc_pool;
      e.parents = this.globalCutConfig.parents;
      e.consulting_pc_pool_parents = this.globalCutConfig.consulting_pc_pool_parents;
      return e;
    });
  }
  NPCCalculation () {

    this.calculateCut();
  }
  calculateCut () {
    const total = this.transactionFee + this.playerReferralCut + this.marketingCost + this.hc_pool
    if (total > this.npc) {
      this.tostService.tostrFun("Cut percentage should not be greater than 100%..", 'error');
      this.isSave = false;
    } else if (this.globalCutConfig.host_referral_cut.percentage > 100) {
      this.tostService.tostrFun("Host referral cut percentage should not be greater than 100%.", 'error');
      this.isSave = false;
    } else if (this.globalCutConfig.consulting_pc_pool.percentage > 100) {
      this.tostService.tostrFun("Consulting PC cut percentage should not be greater than 100%.", 'error');
      this.isSave = false;
    } else {
      this.isSave = true;
    }

  }
  toFixedFun (e) {
    e = Number(e);
    e = e.toFixed(3);
    return Number(e);
  }
}
