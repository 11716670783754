import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TostService } from '../../../core/services/common/tost.service'
import { GameBankService } from "../../../core/services/game-bank/game-bank.service";
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-remove-category-tag-modal',
  templateUrl: './remove-category-tag-modal.component.html',
  styleUrls: ['./remove-category-tag-modal.component.scss']
})
export class RemoveCategoryTagModalComponent implements OnInit {

  id: number;
  constructor (public dialogRef: MatDialogRef<RemoveCategoryTagModalComponent>, private modalService: NgbModal,
    private gameBankService: GameBankService, @Inject(MAT_DIALOG_DATA) public data: any,
    private tostService: TostService,
    private spinner: NgxSpinnerService) { }

  ngOnInit (): void {
    // console.log('not it',this.data.id)
    this.id = this.data.id;
  }

  removeCategorytTag () {
    this.spinner.show();
    this.gameBankService.removeCategory(this.id).subscribe(data => {
      this.tostService.tostrFun('Category tag deleted successfully', 'success');
      this.spinner.hide();
      this.dialogRef.close();

    },
      err => {
        //this.tostService.tostrFun('Something went wrong on serve', 'error')
        this.spinner.hide();
        this.dialogRef.close();
      }
    )
  }

  close () {
    this.dialogRef.close(true);
  }

}

