import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../core/services/users.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PromoCode } from '../../core/models/api/promoCode';
import { PromoCodeService } from '../../core/services/league/promo-code.service';
import { TostService } from '../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';
@Component({
  selector: 'app-edit-promo-code-modal',
  templateUrl: './edit-promo-code-modal.component.html',
  styleUrls: ['./edit-promo-code-modal.component.scss']
})
export class EditPromoCodeModalComponent implements OnInit {

  selectedName: Array<any> = [];
  valuesToBePushed: Array<String> = [];
  selectedUserValues: any;
  couponTypeLabel: any = ''
  filteredCountriesSingle: any[];
  text: string;
  results: any[];
  searchResult: any[];
  filteredBrands: any[];
  brand: string;
  results1: string;
  coupounType: SelectItem[];
  promoCode: PromoCode;
  action: string;
  searchKeyword: any;
  promoCodeForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    promo_code: new FormControl('', [Validators.required, Validators.minLength(3)]),
    value_type: new FormControl('', [Validators.required]),
    value: new FormControl('', [Validators.required]),
    start_date: new FormControl(new Date(), [Validators.required]),
    end_date: new FormControl(new Date(), [Validators.required]),
    results1: new FormControl('', [])
  });
  constructor (
    public dialogRef: MatDialogRef<EditPromoCodeModalComponent>, private promoCodeService: PromoCodeService, private usersService: UsersService, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private spinner: NgxSpinnerService) {
    this.coupounType = [
      // { label: 'Game', value: 'COUPON_TYPE_GAME' },
      // { label: 'Percentage', value: 'percentage' },
      { label: 'Coins', value: 'COUPON_TYPE_AMOUNT' },
    ]
  }

  ngOnInit (): void {

    this.usersService.getUsersData().subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).usersList;
      const getUserData = JSON.stringify(getData);
      this.results = JSON.parse(getUserData);


    });
    this.promoCodeService.SearchUser(null).subscribe(data => {

      const res = JSON.stringify(data);
      const getSearchData = (JSON.parse(res)).list;
      const getuserSearchData = JSON.stringify(getSearchData);
      this.searchResult = JSON.parse(getuserSearchData);

    },
      err => {
        // this.tostService.tostrFun("something went wrong", 'error');

      }
    );
    // this.promoCode = JSON.parse(this.promoCodeService.getPromoCode());

    if (this.data) {
      this.promoCodeForm.patchValue({
        name: this.data.name,
        promo_code: this.data.promo_code,
        value_type: this.data.value_type,
        value: this.data.value,
        start_date: this.data.start_date,
        end_date: this.data.end_date,

      });
      this.selectedName = [];
      this.data.promCouponUsers = this.data.promCouponUsers || [];
      this.data.promCouponUsers.forEach(element => {
        this.selectedVal(element);
      });
      //  this.getCouponCodeLAbel();
    } else {
      this.selectedName = []
      var today = new Date();
      var defaultEndDate = new Date();
      defaultEndDate.setDate(defaultEndDate.getDate() + 7);
      var promoCode = this.randomString();
      this.promoCodeForm.patchValue({
        name: promoCode,
        promo_code: promoCode,
        value_type: 'COUPON_TYPE_AMOUNT',
        value: 500,
        start_date: today,
        end_date: defaultEndDate,
      });

    }
  }
  changecouponTypeLabel (e) {

  }
  search (e) {

    // this.usersService.getUsersData().subscribe(data => {
    //   const res = JSON.stringify(data);
    //   const getData =(JSON.parse(res)).usersList;
    //   const getUserData = JSON.stringify(getData);
    //   this.results=JSON.parse(getUserData);


    // });
    // const queryKeyword = e.query;
    this.searchKeyword = { "keyword": e.query }

    this.promoCodeService.SearchUser(this.searchKeyword).subscribe(data => {

      const res = JSON.stringify(data);
      const getSearchData = (JSON.parse(res)).list;
      const getuserSearchData = JSON.stringify(getSearchData);
      this.searchResult = JSON.parse(getuserSearchData);

    });
    this.filteredBrands = [];
    for (let i = 0; i < this.searchResult.length; i++) {
      let brand = this.searchResult[i];
      this.filteredBrands.push({ 'name': this.searchResult[i].name });



    }

  }
  randomString () {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < 8; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return "MS" + text;
  };
  getCouponCodeLAbel () {
    if (this.promoCodeForm.value.value_type == 'COUPON_TYPE_GAME') {
      this.couponTypeLabel = '';
    } else if (this.promoCodeForm.value.value_type == 'COUPON_TYPE_AMOUNT') {
      this.couponTypeLabel = ' (Points)';
    } else if (this.promoCodeForm.value.value_type == 'COUPON_TYPE_PERCENTAGE') {
      this.couponTypeLabel = ' (%)';
    } else {
      this.couponTypeLabel = '';
    }
  };


  selectedVal (val) {

    this.selectedUserValues = val;

    var douplicatUSer = this.selectedName.filter(function (obj) {
      return obj.id == val.id;
    });
    if (douplicatUSer.length > 0) {
      this.tostService.tostrFun('User Already Selected.', 'error');
    } else {
      this.valuesToBePushed.push((this.selectedUserValues.id));
      if (this.selectedUserValues && this.selectedUserValues.is_used == 1) {
        this.selectedUserValues.is_used = true;
      } else {
        this.selectedUserValues.is_used = false;
      }
      this.selectedName.push(this.selectedUserValues);
    }

  }
  removeRecord (index) {

    this.selectedName.splice(index, 1);
    const users = this.selectedName || [];
    this.selectedName = [];
    this.valuesToBePushed = [];
    users.forEach(e => {
      this.selectedVal(e);
    });
  }
  removeReceivedRecord (index) {

    this.data.promCouponUsers.splice(index, 1);
  }
  close (): void {
    this.dialogRef.close(true);



  }
  hideSpinner () {
    setTimeout(() => {
      this.spinner.hide();
    }, 3000)
  }
  checkErr1 (startDate) {

    if (startDate) {
      var date = new Date();
      var day1 = (date.getFullYear()).toString() + '-' + (('0' + (date.getMonth() + 1)).slice(-2)).toString() + '-' + (('0' + date.getDate()).slice(-2)).toString();
      if (startDate < day1) {

        this.tostService.tostrFun('Start date should not be before today.', 'error');
        return;
      }
    } else {

      this.tostService.tostrFun('Start Date can not be Blank', 'error');
      return;
    }
  };
  checkErr2 (startDate, endDate) {
    if (startDate && endDate) {

      var date = new Date();
      var day1 = (date.getFullYear()).toString() + '-' + (('0' + (date.getMonth() + 1)).slice(-2)).toString() + '-' + (('0' + date.getDate()).slice(-2)).toString();
      if (startDate > endDate) {

        this.tostService.tostrFun('End Date should be greater than start date', 'error');
        return;
      } else if (endDate < day1) {

        this.tostService.tostrFun('End Date should not be before today.', 'error');
        return;
      }
    } else {

      this.tostService.tostrFun('Start Date or End Date can not be Blank', 'error');
      return;
    }
  };
  submitData (): void {
    this.spinner.show();
    let hasError = ""
    var type = this.promoCodeForm.value.value_type;
    var value = this.promoCodeForm.value.value;
    switch (type) {
      case 'COUPON_TYPE_GAME':
        if (value < 0) {
          hasError = "Game Should Less then Zero value.";

        }
        else if (value % 1 != 0) {
          hasError = "Game Should Not be Decimal value.";

        }
        break;
      case 'COUPON_TYPE_AMOUNT':
        if (value < 0) {
          hasError = "Amount Should Less then Zero value.";

        }
        break;
      case 'COUPON_TYPE_PERCENTAGE':
        if (value < 0) {
          hasError = "Percentage Should Less then Zero value.";

        } else if (value > 100) {
          hasError = "Percentage Should Not be Greater than 100.";

        }
        break;
    }
    var sd = moment(this.promoCodeForm.value.start_date).format("DD/MM/YYYY")
    var ed = moment(this.promoCodeForm.value.end_date).format("DD/MM/YYYY")
    var cd = new Date();//moment(new Date()).format("DD/MM/YYYY")
    if (new Date(this.promoCodeForm.value.start_date) > new Date(this.promoCodeForm.value.end_date)) {
      hasError = 'End date should be greater than Start date'
    } else if (new Date(this.promoCodeForm.value.start_date).getTime() == new Date(this.promoCodeForm.value.end_date).getTime()) {
      hasError = 'Start date and End date should not be same';
    } else if (new Date(this.promoCodeForm.value.end_date) < new Date(cd)) {
      hasError = 'End date should not be before today'
    }

    if (hasError) {
      this.tostService.tostrFun(hasError, 'error');
      this.spinner.hide();
      return;
    }



    if (this.data == null) {
      this.action = "create";


    } else if (this.data.id) {
      this.action = "update";

      // this.promoCodeForm.value.id = this.data.id;
      let formValues = this.promoCodeForm.value;
      Object.assign(formValues, {
        id: this.data.id,
        created_by: this.data.created_by,
        createdAt: this.data.createdAt,
        deletedAt: this.data.deletedAt,
        limit: this.data.limit,
        updated_by: this.data.updated_by,
        value_type: this.data.value_type,
        updatedAt: this.data.updatedAt,
      });
      // formValues['promCouponUsers']=[];
      // formValues['promCouponUsers'].push(this.valuesToBePushed.toString());
    }

    let formValues = {
      name: this.promoCodeForm.value.name,
      promo_code: this.promoCodeForm.value.promo_code,
      value_type: this.promoCodeForm.value.value_type,
      value: this.promoCodeForm.value.value,
      start_date: this.promoCodeForm.value.start_date,
      end_date: this.promoCodeForm.value.end_date,
      promCouponUsers: this.valuesToBePushed
    }

    if (this.action == 'update') {
      formValues['id'] = this.data.id;
    }
    // formValues['promCouponUsers'].push(this.valuesToBePushed);
    this.promoCodeService.createPromoCode(formValues, this.action).subscribe(data => {

      if (this.action == 'update') {
        this.tostService.tostrFun("promocode updated", 'success');
        setTimeout(() => {
          this.spinner.hide();
          this.dialogRef.close();
        }, 3000)
      } else {
        this.tostService.tostrFun("promocode created", 'success');
        setTimeout(() => {
          this.spinner.hide();
          this.dialogRef.close();
        }, 3000)
      }
    },
      err => {
        //  this.tostService.tostrFun("something went wrong", 'error');
        setTimeout(() => {
          this.spinner.hide();
          this.dialogRef.close();
        }, 3000)
      }
    )
    // this.dialogRef.close();
  }

}
