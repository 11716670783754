<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <div class="modal-body">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="loginCls">
                <div class=logicon>
                  <img _ngcontent-qvw-c47="" src="./assets/img/logo.png"
                    style="width: 100%;">
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.username.errors }" style="border: none;" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" style="border: none;" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button [disabled]="loading" class="btn btn-primary">Login</button>

                  </div>
                </form>
              </div>

            </div>
            <div class="col-md-3"></div>
          </div>

        </div>

      </div>

    </div>
  </div>
</div>
<!-- 
<body>
    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card card-signin my-5">
            <div class="card-body">
              <h5 class="card-title text-center">Sign In</h5>
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-signin">
                <div class="form-label-group">
                  <input type="email" id="inputEmail" class="form-control" formControlName="username"  required autofocus>
                  <label for="inputEmail">Email address</label>
                </div>
  
                <div class="form-label-group">
                  <input type="password" id="inputPassword" class="form-control" formControlName="password"  required>
                  <label for="inputPassword">Password</label>
                </div>
  
                <div class="custom-control custom-checkbox mb-3">
                  <input type="checkbox" class="custom-control-input" id="customCheck1">
                  <label class="custom-control-label" for="customCheck1">Remember password</label>
                </div>
                <button class="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Sign in</button>
                <hr class="my-4">
                <button class="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i class="fab fa-google mr-2"></i> Sign in with Google</button>
                <button class="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i class="fab fa-facebook-f mr-2"></i> Sign in with Facebook</button> -->
<!-- </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body> -->