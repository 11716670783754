<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Edit Image</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form (ngSubmit)="uploadPicture()">

    <div class="row">
      <div class="ui-g-12">
        <mat-form-field class="example-full-width">
          <input [(ngModel)]="characterObj.image_url" name="image_url" matInput placeholder="Picture Url"
            (change)="imageChange($event)" type="text">
        </mat-form-field>
      </div>
    </div>
    <div class="row">


      <div class="ui-g-6">
        <div class="form-group">

          <div class="file-field">
            <input style="display: none" type="file" name="file" [(ngModel)]="file" (change)="onFileChange($event)"
              #hiddenfileinput>
            <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">Choose
              file</label>


          </div>
        </div>


        <p-card>
          <div class="card-container game-card-box">
            <div class="card-box card-left">
              <div [ngStyle]="images" class="card-image card-left-image"></div>
              <!-- <div class="card-player-name card-player-left"><span class="ng-binding"></span></div> -->
            </div>
            <div class="card-box card-left">
              <div [ngStyle]="images" class="card-image card-left-image"></div>
              <!-- <div class="card-player-name card-player-right"><span class="ng-binding"></span></div> -->
            </div>
          </div>


        </p-card>
      </div>

      <div class="ui-g-6">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" format="png"
          [transform]="transform" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
          style="text-align: center;margin-top: 53px;">
        </image-cropper>
        <div *ngIf="isCrop==true" style="margin: 2% 30% 0 30%;">

          <p-slider [(ngModel)]="scale" name="scale" max="10" (onChange)="zoomIn($event)">
          </p-slider>


        </div>
      </div>

    </div>
    <div class="modal-footer">

      <button mat-raised-button type="submit" class="btn btn-primary pull-right" (click)="close()">Submit</button>
    </div>
    <div class="clearfix"></div>


  </form>
</div>
