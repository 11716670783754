import { Component, OnInit } from '@angular/core';
import { Feecut } from '../../core/models/api/feecut';
import { FeecutService } from '../../core/services/league/feecut.service';
import { CreateEditFeecutModalComponent } from "../../modal/create-edit-feecut-modal/create-edit-feecut-modal.component"

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-fee-and-ssg',
  templateUrl: './fee-and-ssg.component.html',
  styleUrls: ['./fee-and-ssg.component.scss']
})
export class FeeAndSsgComponent implements OnInit {
  totalRecords: number;
  loading: boolean;
  globalSort: any = "";
  filter: any = {};
  frozenCols: any[];
  cols: any[];
  FeeData: Feecut;
  feedataselected: Feecut;

  constructor(private feecutservice: FeecutService, public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.loading = true;
    this.cols = [
      { field: 'entry_fee', header: 'Entry Fee' },
      { field: 'ugg_cut', header: 'Cut Per Player' },
      { field: 'prize_money', header: 'Prize Money' },
      { field: 'is_active', header: 'Active' },
      { field: 'is_locked', header: 'Locked' },
      // { field: '', header: 'Action' },

    ];
    this.GetData({});

  }
  onRefrash(e) {
    setTimeout(() => {
      this.GetData({});
    }, 1500);

  }
  customPagination(event) {
    // this.loading = true;
    if (event) {
      let pagination = {}
      pagination['limit'] = event.rows;
      if (event.first) {
        pagination['page'] = Math.round(event.first / 10) + 1;
      } else {
        pagination['page'] = 1;

      }
      this.filter['pagination'] = pagination;
      this.GetData(this.filter);
      //  this.loading = false;
    }

    let obj = {};

    if (event.sortField) {


      if (event.sortOrder == 1) {
        obj[event.sortField] = 'desc'
      } else {
        obj[event.sortField] = 'asc'
      }
    }
    if (this.globalSort != event.sortField + " " + event.sortOrder) {

      this.filter['sort'] = obj;
      this.GetData(this.filter);
      this.globalSort = event.sortField + " " + event.sortOrder
      // console.log(this.filter);
    }

  }

  customSort(obj) {

  }
  openCreateSSGDialog(ssgData) {
    const dialogRef = this.dialog.open(CreateEditFeecutModalComponent, {
      width: '600px',
      data: ssgData
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.GetData(this.filter);
    });
  }

  GetData(obj) {
    this.feecutservice.getFeeCutList(obj).subscribe(data => {

      const tempPromo = JSON.stringify(data);
      this.totalRecords = (JSON.parse(tempPromo).total);
      const promoCodeparse = (JSON.parse(tempPromo).entryFeesList);
      const getpromoCode = JSON.stringify(promoCodeparse);
      this.FeeData = JSON.parse(getpromoCode);
      this.loading = false;
      // this.totalRecords = this.promoCode.length;
      //  console.log("oromo code", data);

    });
  }

}
