<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">Impersonate User</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-md-11">

      <div class="form-group">
        <mat-form-field class="example-full-width">
          <input matInput id="password" [(ngModel)]="password" placeholder="Password" type="text">
        </mat-form-field>

      </div>

    </div>
    <div class="col-md-1" style="padding-top: 30px;">
      <i class="fa fa-clipboard" (click)="callServiceToCopy(password)"></i>
    </div>

  </div>
  <p style="font-weight: bold;">
    Save this password, it will be encrypted after save and can't be reveal.</p>
</div>
<div class="modal-footer">
  <button mat-raised-button type="submit" class="btn  btn-danger pull-right " data-dismiss="alert" aria-label="Close"
    (click)="submitData()">Submit</button>

</div>



<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>