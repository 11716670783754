import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payout-block',
  templateUrl: './payout-block.component.html',
  styleUrls: ['./payout-block.component.scss']
})
export class PayoutBlockComponent implements OnInit {
  isBlock: any;
  constructor(public dialogRef: MatDialogRef<PayoutBlockComponent>, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close(true);
    //  console.log("object", this.data);
  }

  blockUser() {
    // const obj = { 'id': this.data.id };
    // this.userBlockService.blockUser(obj).subscribe(data => {
    //   this.dialogRef.close();
    // });
  }
  unBlockUser() {
    //   const obj = { 'id': this.data.id };
    //   this.userBlockService.unBlockUser(obj).subscribe(data => {
    //     this.dialogRef.close();
    //   });
    // }

  }
}
