import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../core/services/users.service';
import { Users } from '../../core/models/api/users';
import { PaginationService } from '../../core/services/common/pagination.service';
import { SearchService } from "../../core/services/common/search.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FollowService } from '../../core/services/users/follow.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TostService } from '../../core/services/common/tost.service';
import { SiteConstants } from '../../../config/site-constants';
import { ConfirmationModalComponent } from "../../modal/user/confirmation-modal/confirmation-modal.component";
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserComponent implements OnInit {
  cols: any;
  closeResult: string;
  selectedCar: Users;
  globalSort: any = "";
  TimeoutSearch: any;
  user: any;
  userClone: any;
  filter: any = {};
  subscribedata: any;
  checked = false;
  checkedInfluencer = false;
  checkedCreateGame = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  frozenCols: any[];
  isFollowpage: boolean = false;
  followObj: any;
  loading: boolean;
  constPage: number = 10;
  totalRecords: number;
  affiliateUserID: any = {};
  isAdmin = true;
  matMenu;
  userConfig = {
    verified_flag: 0,
    is_influencer: false,
    create_game: 0,
    user_id: null,
  };
  myForm = new FormGroup({
    status: new FormControl('', [])
  });
  // constructor(private modalService: NgbModal, private usersService: UsersService, private dialog: MatDialog,private activatedRoute: ActivatedRoute) { }
  constructor (private modalService: NgbModal, private searchService: SearchService,
    private paginationService: PaginationService, private usersService: UsersService,
    private dialog: MatDialog, private activatedRoute: ActivatedRoute, private tostService: TostService, private followService: FollowService, private spinner: NgxSpinnerService, private router: Router) { }

  ngOnInit (): void {
    //console.log('id in user', this.activatedRoute.snapshot.params['id'])
    // console.log('id in user', this.activatedRoute.snapshot.params['id'])
    // console.log('id 2 in user', this.activatedRoute.snapshot.params['id1'])
    let getLogged = localStorage.getItem('currentUser');

    if (getLogged) {
      let admin = JSON.parse(getLogged);
      if (admin.user_role === 5) {
        this.isAdmin = false;
        this.cols = [
          { field: 'id', header: 'User Id', width: '90px' },
          { field: 'user_name', header: 'User Name', width: '130px' },
          // { field: 'brand', header: 'Brand' },
          { field: 'email', header: 'Email', width: '200px' },
          { field: 'verified_flag', header: 'Verified', width: '110px' },
          { field: 'is_influencer', header: 'Influencer', width: '110px' },
          { field: 'create_game', header: 'Host User', width: '110px' }
        ];
      } else {
        this.cols = [
          { field: 'id', header: 'User Id', width: '90px' },
          { field: 'user_name', header: 'User Name', width: '130px' },
          // { field: 'brand', header: 'Brand' },
          { field: 'first_name', header: 'First Name', width: '120px' },
          { field: 'email', header: 'Email', width: '200px' },
          { field: 'coin_balance', header: 'Coin Balance (coins)', width: '120px' },
          { field: 'pending_balance', header: 'Pending Balance (coins)', width: '120px' },
          // { field: 'winning_amount', header: 'Total Winning (coins)', width: '110px' },
          // { field: 'payout_amount', header: 'Total Payout (coins)', width: '110px' },
          { field: 'referral_code', header: 'Promo Code', width: '110px' },
          { field: 'social_type', header: 'Social Type', width: '110px' },
          { field: 'verified_flag', header: 'Verified', width: '110px' },
          { field: 'is_influencer', header: 'Influencer', width: '110px' },
          { field: 'create_game', header: 'Host User', width: '110px' },
          { field: '', header: 'Action', width: '80px' }

        ];
      }

    }
    this.loading = true;

    const path = this.activatedRoute.routeConfig.path;
    const isFollow = path.includes("follow");

    if (this.activatedRoute.snapshot.params['id'] && this.activatedRoute.snapshot.params['id1']) {
      this.affiliateUserID = { 'affiliate_id': this.activatedRoute.snapshot.params['id'], 'game_id': this.activatedRoute.snapshot.params['id1'] }
      this.filter.search = Object.assign({}, this.filter.search, this.affiliateUserID);
      this.GetData(this.filter);

    } else if (this.activatedRoute.snapshot.params['id'] && !isFollow) {
      let obj = { 'id': this.activatedRoute.snapshot.params['id'] }
      this.filter.search = Object.assign({}, this.filter.search, obj)
      this.GetData(this.filter);
    } else if (this.activatedRoute.snapshot.params['id'] && isFollow) {
      this.isFollowpage = true;
      this.followService.getFollowerFollowingIds(this.activatedRoute.snapshot.params['id']).subscribe(data => {
        this.spinner.hide();
        const respData = JSON.stringify(data);
        this.followObj = JSON.parse(respData);
        let obj = {};
        if (path.includes("following")) {
          if (this.followObj && this.followObj.following && this.followObj.following.length) {
            obj = { 'id': this.followObj.following }

          }
          else {
            obj = { 'id': [] }
          }
        } if (path.includes("follower")) {
          if (this.followObj && this.followObj.followers && this.followObj.followers.length) {
            obj = { 'id': this.followObj.followers }
          } else {
            obj = { 'id': [] }

          }
        }
        this.filter.search = Object.assign({}, this.filter.search, obj);
        this.GetData(this.filter);

      },
        err => {
          /// this.tostService.tostrFun(err.error.errors[0].message, 'error');
          this.spinner.hide();
        });

    }


  }
  verifiedFlag (e) {
    if (e.checked) {
      let obj = { 'verified_flag': 1 }
      this.filter.search = Object.assign({}, this.filter.search, obj)
      this.GetData(this.filter);
    } else {
      delete this.filter.search['verified_flag'];
      this.GetData(this.filter);
    }
  }
  isInfluencerFun (e) {
    if (e.checked) {
      let obj = { 'is_influencer': true }
      this.filter.search = Object.assign({}, this.filter.search, obj)
      this.GetData(this.filter);
    } else {
      delete this.filter.search['is_influencer'];
      this.GetData(this.filter);
    }
  }

  isCreateGameFun (e) {
    if (e.checked) {
      let obj = { 'create_game': true }
      this.filter.search = Object.assign({}, this.filter.search, obj)
      this.GetData(this.filter);
    } else {
      delete this.filter.search['create_game'];
      this.GetData(this.filter);
    }
  }
  clerFilter () {
    this.filter.search = {};
    this.GetData(this.filter)
    this.router.navigate(['user']);
  }

  userFilter (val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData(this.filter);
    }, SiteConstants.FiltertimeOut);
  }

  customPagination (event) {
    let data;
    if (event.sortField == "winning_amount") {
      this.filter = this.filter;
    } else if (event.sortField == "payout_amount") {
      this.filter = this.filter;
    } else if (event.sortField == "credit_balance") {
      this.filter = this.filter;
    } else {
      data = this.paginationService.paginationFun(event, this.globalSort, this.constPage, this.filter)

      this.filter = data.filter;
      this.globalSort = data.globalSort;
      this.constPage = data.defaultPage;
    }


    this.GetData(this.filter);
  }


  onRefrash (e) {
    setTimeout(() => {
      this.GetData(this.filter);
    }, 1500);

  }
  parseFloatFun (num) {
    return parseFloat(num) || 0
  }
  userConfigChange (user, params) {
    const userConfig = {
      user_id: user.id,
      verified_flag: user.verified_flag,
      is_influencer: user.is_influencer,
      create_game: user.create_game
    }
    if (params === 'verifiedFlag') {
      userConfig['verified_flag'] = user.verified_flag
    } else if (params === 'isInfluencer') {
      userConfig['is_influencer'] = user.is_influencer
    } else if (params === 'isCreateGame') {
      userConfig['create_game'] = user.create_game
    }
    this.userClone.forEach(u => {
      const isHost = user.create_game ? 1 : 0;
      if (u.id === user.id && u.create_game !== isHost) {
        userConfig['is_host_updated'] = true;
        console.log(`Admin updated hosting privileges useId: ${user.id}  before=>  ${u.create_game}  now => ${isHost}`)
      }
    });

    this.usersService
      .updateUserSettings(userConfig)
      .subscribe(
        () => {
          this.GetData(this.filter);
        },
        () => {
        }
      );
  }


  GetData (obj) {
    if (this.subscribedata) {
      this.subscribedata.unsubscribe;
      this.usersService.stopRequest();
    }
    // this.spinner.show();
    this.loading = true;
    this.subscribedata = this.usersService.getUsersData1(obj).subscribe(
      data => {
        const res = JSON.stringify(data);
        this.totalRecords = (JSON.parse(res).total);
        const getData = (JSON.parse(res)).usersList;
        const getUserData = JSON.stringify(getData);
        this.user = JSON.parse(getUserData);
        this.userClone = JSON.parse(getUserData);
        this.loading = false;
      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        // this.dialogRef.close();
        this.loading = false;
      });

    // this.usersService.getUsersData1(obj).subscribe(data => {


    //   });
  }

  openDialog (ev, rowData, key): void {
    ev.stopPropagation();


    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '350px',
      data: { rowData, key },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.rowData && result.key && result.save) {
        this.userConfigChange(result.rowData, result.key)
      } else {

        this.user = this.user.map(u => {
          if (u.id === result.rowData.id) {
            if (result.key == 'verifiedFlag') {
              if (result.rowData.verified_flag == true || result.rowData.verified_flag == 1) {
                u.verified_flag = 0;
              } else {
                u.verified_flag = 1;
              }
            } else if (result.key == 'isInfluencer') {
              if (result.rowData.is_influencer == true || result.rowData.is_influencer == 1) {
                u.is_influencer = false;
              } else {
                u.is_influencer = true;
              }
            } else if (result.key == 'isCreateGame') {
              if (result.rowData.create_game == true || result.rowData.create_game == 1) {
                u.create_game = 1;
              } else {
                u.create_game = 1;
              }
            }
          }
          return u;
        });

      }
      //  console.log('The dialog was closed act icon');
    });
  }
}
