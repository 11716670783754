<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{config}} Cut Config</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">


        <p-table #dt [columns]="cols" [value]="globalCutConfig" (sortFunction)="customSort($event)" [customSort]="true"
          [(selection)]="selectedGlobalConfig" dataKey="id" styleClass="ui-table-cars" [rows]="10"
          [rowsPerPageOptions]="[10,25,50]" [responsive]="true" [loading]="loading" [paginator]="true">


          <ng-template pTemplate="header" let-columns>
            <tr>

              <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                [ngStyle]="{'width': col.width,'text-align': col.textalign}">
                <div class="header-title">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </div>
              </th>

            </tr>
            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <input *ngSwitchCase="'source_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'source_code'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'weight'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">

              </th>

            </tr>
          </ng-template>


          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
              <td style="width: 70px;">{{rowData.entry_fee}}</td>
              <td style="width: 90px;">{{rowData.prize_money}}</td>
              <td style="width: 80px;">{{rowData.transaction_fee.percentage}}</td>
              <td style="width: 80px;">{{rowData.player_referral_cut.percentage}}</td>
              <td style="width: 80px;">{{rowData.marketing_cost.percentage}}</td>
              <td style="width: 80px;">{{rowData.hc_pool.percentage}}</td>
              <td style="width: 90px;">{{rowData.host_referral_cut.percentage}}</td>
              <td style="width: 90px;">{{rowData.consulting_pc_pool.percentage}}</td>
              <!-- <td style="width: 80px;">{{rowData.cpc1}}</td>
              <td style="width: 80px;">{{rowData.cpc2}}</td>
              <td style="width: 80px;">{{rowData.cpc3}}</td>
              <td style="width: 80px;">{{rowData.cpc4}}</td> -->

              <td style="width: 80px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <app-action-icon
                      [data]="{data:globalCutConfig,entry_fee:rowData.entry_fee,config:config,entityId:entityId}"
                      [width]="'920px'" [modaleId]="'GlobalCutConfigModalComponent'" icon="edit" tooltip="update"
                      (onRefrashed)="onRefrash($event)">
                    </app-action-icon>
                  </div>

                </mat-menu>


              </td>

            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" style="text-align:left">No data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

    </div>
  </div>
</div>