<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">

                <p-table #dt [columns]="cols"  dataKey="id" styleClass="ui-table-cars"
                [rows]="10"  [rowsPerPageOptions]="[10,25,50]" 
                [paginator]="false">
                <ng-template pTemplate="caption">
                   League
                   <button type="button" class="btn btn-primary" (click)="openCreateLeagueDialog(null)"> Create</button>

                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                       
                        <th *ngFor="let col of columns" [pSortableColumn]="col.field" >
                            <div class="header-title">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </div>
                        </th>
                       
                    </tr>
                    <tr>
                        <th *ngFor="let col of columns"  [ngSwitch]="col.field">
                              
                                

                            <input *ngSwitchCase="'league_name'" pInputText type="text" placeholder="" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                            <input *ngSwitchCase="'no_of_players'" pInputText type="text" placeholder="" class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                            
                        </th>
                        <!-- <th style="width: 270px; text-align: center;">

                        </th> -->
                    </tr>
                </ng-template>


                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                        <!-- <td>{{rowData.id}}</td>
                        <td>{{rowData.user_name}}</td>
                        <td>{{rowData.first_name}}</td>
                        <td style="word-break: break-all;">{{rowData.email}}</td>
                        <td>{{rowData.balance}}</td>
                        <td>{{rowData.winning_amount}}</td>
                        <td>{{rowData.payout_amount}}</td>
                        <td>{{rowData.referral_code}}</td> -->
 
                        <!-- <td style="width: 110px;">
                            <button matTooltip="Promocode" pButton type="button" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em" (click)="openPromoCodeDialog(rowData)"></button>
                            <button matTooltip="Block" *ngIf="rowData.is_blocked == false" pButton type="button" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em" (click)="openBlockDialog(rowData)"></button>
                            <button matTooltip="Unblock" *ngIf="rowData.is_blocked == true" pButton type="button" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em" (click)="openBlockDialog(rowData)"></button>
                            <button matTooltip="Transaction" pButton type="button" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em" (click)="openTransactionDialog(rowData)"></button>
                            <button matTooltip="Payout" pButton type="button" class="ui-button-warning" icon="pi pi-pencil" style="margin-right: .5em" (click)="openPayoutDialog(rowData)"></button>
                        </td> -->

                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8" style="text-align:left">No Data Found.</td>
                    </tr>
                </ng-template>
            </p-table> 
          </div>

        </div>
    </div>
</div>