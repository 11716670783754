import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { TostService } from '../../../core/services/common/tost.service';
import { UserPayoutService } from '../../../core/services/users/user-payout.service';
interface PaymentType {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-payout-modal',
  templateUrl: './payout-modal.component.html',
  styleUrls: ['./payout-modal.component.scss']
})
export class PayoutModalComponent implements OnInit {
  myForm = new FormGroup({
    amount: new FormControl('', [Validators.required]),
    notes: new FormControl('', []),
    tx_type: new FormControl('credit', []),
    user_id: new FormControl('', []),
    amount_type: new FormControl('coin', []),
  });
  isCredit: boolean;
  paymentType: PaymentType[] = [
    { value: 'debit', viewValue: 'Debit' },
    { value: 'credit', viewValue: 'Credit' },
  ];
  amountType: PaymentType[] = [
    { value: 'coin', viewValue: 'Coin' },
    { value: 'credit', viewValue: 'Credit' },
  ];
  userBalance;
  userBalanceUsd;
  coinRate;
  loading = false;

  constructor (
    public dialogRef: MatDialogRef<PayoutModalComponent>, private refDataService: RefDataService,
    @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private userPayoutService: UserPayoutService) { }

  ngOnInit (): void {
    this.getRefData();

    this.isCredit = this.data.isCredit;
    this.userBalanceUsd = (Number(this.data.data.coin_balance_usd) || 0).toFixed(2);
    this.userBalance = Number(this.data.data.coin_balance) || 0;
  }

  submitData () {
    if (this.loading) {
      return;
    }

    let formData = {};
    let hasErr = "";
    if (!this.myForm.value.amount) {
      hasErr = "Please Enter Amount";
    } else if (!this.myForm.value.tx_type && this.isCredit) {
      hasErr = "Please Enter Playment Type";
    } else if (!this.myForm.value.notes) {
      hasErr = "Please Enter Notes";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    let tx_type = ""
    if (this.isCredit) {
      tx_type = this.myForm.value.tx_type
    } else {
      tx_type = "debit"
    }

    formData = Object.assign(formData, {
      'amount': this.myForm.value.amount,
      'notes': this.myForm.value.notes,
      'tx_type': tx_type,
      'user_id': this.data.data.id,
      'amount_type': this.myForm.value.amount_type
    });

    this.loading = true;

    this.userPayoutService.createPayout(formData).subscribe(
      res => {
        this.tostService.tostrFun("Transaction successfully completed", 'success');
        this.loading = false;
        this.dialogRef.close();
      },
      err => {
        //this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.loading = false;
        this.dialogRef.close();
      }
    )
  }

  close (): void {
    this.dialogRef.close(true);
  }

  getRefData () {
    this.refDataService.refDataAll().subscribe(data => {
      this.coinRate = Number(data['coinRate']) || 1;
    });
  }

}
