import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';
import { SiteConstants } from '../../../../config/site-constants';
import { map } from 'rxjs/operators';
import { TostService } from '../../../core/services/common/tost.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefDataService } from '../../../core/services/common/ref-data.service'
@Component({
  selector: 'app-game-config-modal',
  templateUrl: './game-config-modal.component.html',
  styleUrls: ['./game-config-modal.component.scss']
})
export class GameConfigModalComponent implements OnInit {

  gameConfig = {
    is_free_only: 0,
    tags: [],
    mark_hot: 0,
    allow_bots: 0,
  };
  tagsLoading = true;
  boostGameLoading = false;
  freeOnlyLoading = false;
  allowBotsGameLoading = false;
  payoutConfig = {
    game_id: null,
    match_mode: 0,
    instant_match: 0,
    min_host_payout: 0,
    max_host_payout: 0
  };
  defaultMaxPayout = 0;
  coinRate: number = 100;
  loading = false;
  maxPayoutFlag: boolean = false;
  icons: any = SiteConstants.icons;
  tooltips: any = SiteConstants.tooltips;
  constructor (
    public dialogRef: MatDialogRef<GameConfigModalComponent>,
    private gameBankService: GameBankService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tostService: TostService,
    private spinner: NgxSpinnerService,
    private refDataService: RefDataService
  ) { }

  ngOnInit (): void {
    this.getCoins();
    this.gameConfig.is_free_only = this.data.game.is_free_only;
    this.spinner.show();
    this.gameBankService.getGameById(this.data.game.id).subscribe(data => {
      this.spinner.hide();
      this.payoutConfig.game_id = this.data.game.id;
      this.payoutConfig.match_mode = data['match_mode'] || 0;
      this.payoutConfig.min_host_payout = data['min_host_payout'] || 0;
      this.payoutConfig.max_host_payout = data['max_host_payout'] || 0;
      this.gameConfig.mark_hot = data['mark_hot'];
      this.gameConfig.allow_bots = data['allow_bots'];
    });
    this.gameBankService
      .getGameTags(this.data.game.id)
      .pipe(map(res => {
        this.gameConfig.tags = res['map'](v => ({
          display: v['tag_name'],
          value: v['tag_name'],
          tag_type: v['tag_type'],
        }));
        this.tagsLoading = false;
      })).subscribe();
  }

  close (): void {
    this.dialogRef.close(true);
  }

  public searchTags = (text) => {
    return this.gameBankService
      .getAllTags({
        text,
        type: 'premium,general,game',
        gameId: this.data.game.id
      })
      .pipe(map(res => res['tags']['map'](v => ({
        display: v['tag_name'],
        value: v['tag_name'],
        tag_type: v['tag_type'],
      }))));
  }

  public onTagAdd = (tag) => {
    this.tagsLoading = true;
    this.gameBankService.updateGameTag({
      action: 'add',
      tag_name: tag.value,
      game_id: this.data.game.id,
      tag_type: tag.tag_type,
    }).subscribe(() => {
      this.tagsLoading = false;
      this.tostService.tostrFun(`Tag ${tag.value} added`, 'success');
    });
  }

  public onTagRemove = (tag) => {
    this.tagsLoading = true;
    this.gameBankService.updateGameTag({
      action: 'remove',
      tag_name: tag.value,
      game_id: this.data.game.id,
      tag_type: tag.tag_type,
    }).subscribe(() => {
      this.tagsLoading = false;
      this.tostService.tostrFun(`Tag ${tag.value} removed`, 'success');
    });
  }

  public toggleFreeOnly (ev) {
    this.freeOnlyLoading = true;
    this.gameBankService.toggleFreeonly(this.data.game.id)
      .subscribe(() => {
        this.freeOnlyLoading = false;
        if (ev.checked) {
          this.gameConfig.is_free_only = 1;
        } else {
          this.gameConfig.is_free_only = 0;
        }
      });
  }

  public toggleBoostGame (ev) {
    this.boostGameLoading = true;
    this.gameBankService.toggleBoostGame(this.data.game.id)
      .subscribe(() => {
        this.boostGameLoading = false;
        if (ev.checked) {
          this.gameConfig.mark_hot = 1;
        } else {
          this.gameConfig.mark_hot = 0;
        }
      });
  }

  public toggleBotsForGame (ev) {
    this.allowBotsGameLoading = true;
    this.gameBankService.toggleBotsForGame(this.data.game.id)
      .subscribe(() => {
        this.allowBotsGameLoading = false;
        if (ev.checked) {
          this.gameConfig.allow_bots = 1;
        } else {
          this.gameConfig.allow_bots = 0;
        }
      });
  }

  maxAmountChange (e) {
    const maxval = e.target.value;
    if (maxval < this.payoutConfig.min_host_payout) {
      this.maxPayoutFlag = true;
    } else {
      this.maxPayoutFlag = false;
    }
  }
  minAmountChange (e) {
    const minval = e.target.value;
    if (minval > this.payoutConfig.max_host_payout) {
      this.maxPayoutFlag = true;
    } else {
      this.maxPayoutFlag = false;
    }
  }

  updateConfig () {
    if (this.maxPayoutFlag) {
      return false
    }
    this.spinner.show();
    this.gameBankService
      .updateGameSettings(this.payoutConfig)
      .subscribe(
        () => {
          this.tostService.tostrFun(`Updated Successfully`, 'success');
          this.dialogRef.close();
          this.spinner.hide();
        },
        () => {
          this.dialogRef.close();
          this.spinner.hide();
        }
      );
  }
  getCoins () {
    this.refDataService.refDataAll().subscribe(data => {
      const res = JSON.stringify(data);
      this.coinRate = (JSON.parse(res)).coinRate;
    });
  }
}
