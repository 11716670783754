import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../../core/services/users.service';
import { TostService } from '../../../core/services/common/tost.service';
import { ClipboardService } from 'ngx-clipboard';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-impersonate-user-modal',
  templateUrl: './impersonate-user-modal.component.html',
  styleUrls: ['./impersonate-user-modal.component.scss']
})
export class ImpersonateUserModalComponent implements OnInit {

  referral_codes: any;
  defaultReferralCode: any;
  // userImpersonateForm = new FormGroup({
  //   password: new FormControl('', []),
  //   user_id: new FormControl(''),
  // });
  password: any;
  constructor (
    public dialogRef: MatDialogRef<ImpersonateUserModalComponent>, private usersService: UsersService, private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private spinner: NgxSpinnerService, private tostService: TostService, private clipboardService: ClipboardService) { }

  ngOnInit (): void {
    this.spinner.show();
    if (this.data && this.data.id) {
      //  this.defaultReferralCode = Math.random().toString(36).slice(-8);
      this.spinner.hide();
      this.defaultReferralCode = this.randomString();
      this.password = this.defaultReferralCode;
      // this.userImpersonateForm.patchValue({
      //   password: this.defaultReferralCode,
      //   user_id: this.data.id
      // });
    }
  }
  callServiceToCopy (url) {
    this.clipboardService.copyFromContent(url);
    this.tostService.tostrFun('Password copy success!', 'success');
  }

  submitData () {
    let hasErr = "";
    // if (!this.userImpersonateForm.value.password) {
    //   hasErr = "Please Enter Password";
    // }
    this.spinner.show();
    if (!this.password) {
      hasErr = "Please Enter Password";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    let userImpersonateReq = {
      user_id: this.data.id,
      password: this.password
    }
    this.usersService.impersonateUser(userImpersonateReq).subscribe(
      res => {
        this.tostService.tostrFun("Impersonate User Successfully", 'success');
        this.spinner.hide();
        this.dialogRef.close();
      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.spinner.hide();
        this.dialogRef.close();
      }
      //  ,() => console.log('HTTP request completed.')
    )

  }


  close (): void {
    this.spinner.hide();
    this.dialogRef.close(true);
  }
  randomString () {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@";
    for (var i = 0; i < 8; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

}
