<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <p-table #dt [columns]="cols" [value]="tagList" (sortFunction)="customSort($event)" [customSort]="true"
          dataKey="id" styleClass="ui-table-cars" [rows]="10" [rowsPerPageOptions]="[10,25,50]" [responsive]="true"
          [scrollable]="true" [loading]="loading" [paginator]="true" [totalRecords]="totalRecords"
          selectionMode="single" [(selection)]="selectedPayout" [lazy]="true" (onLazyLoad)="customPagination($event)">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                <div class="header-title">
                  {{col.header}}
                  <p-sortIcon *ngIf="col.header !== 'Actions'" [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </div>
              </th>
            </tr>

            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">

                <input *ngSwitchCase="'register_email'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="payoutFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'user_id'" pInputText type="number" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="payoutFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'amount'" pInputText type="number" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="payoutFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'user_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="payoutFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'payout_email'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="payoutFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'placement_id'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="payoutFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'payout_type'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="payoutFilter($event.target.value, col.field)">

              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
              <!-- <td style="width: 60px;padding-left: 10px;">{{rowData.id}}</td> -->
              <td style="width: 60px;padding-left: 10px;">{{rowData.user_id}}</td>
              <td style="width: 100px;padding-left: 10px;">{{rowData.user_name}}</td>
              <td style="width: 140px;">{{rowData.register_email}}</td>
              <td style="width: 140px;">{{rowData.payout_email}}</td>
              <td style="width: 70px;text-align: center">{{rowData.amount}}</td>
              <td style="width: 90px;padding-left: 20px;">{{rowData.placement_id}}</td>
              <td style="width: 90px;padding-left: 20px;">{{rowData.payout_type}}</td>
              <td style="width: 120px;padding-left: 10px;">{{rowData.created_at | date:'medium'}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>