import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { SiteConstants } from '../../../../config/site-constants';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';

@Component({
  selector: 'app-tags-edit-modal',
  templateUrl: './tags-edit-modal.component.html',
  styleUrls: ['./tags-edit-modal.component.scss']
})
export class TagsEditModalComponent implements OnInit {

  tag: any;
  isRemove: any = false;

  tagTypes = [
    // { value: 'general', label: 'Hashtag', },
    // { value: 'game', label: 'Game', },
    { value: 'premium', label: 'Premium', },
  ];

  icons = SiteConstants.icons;

  tooltips = SiteConstants.tooltips;

  imagePreview: any;

  constructor (
    public dialogRef: MatDialogRef<TagsEditModalComponent>,
    private gameBankService: GameBankService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private refDataService: RefDataService
  ) { }

  ngOnInit (): void {
    this.tag = this.data.tag || {
      tag_name: '',
      tag_type: 'premium',
      rank: 0,
      mdg_flag: 0,
      icon: '',
    };

    if (this.tag.icon) {
      this.imagePreview = { backgroundImage: 'url(' + this.tag.icon + ')' };
      this.isRemove = true;
    }
  }

  close () {
    this.dialogRef.close(true);
  }

  saveTag () {
    this.tag.mdg_flag = this.tag.mdg_flag == true ? 1 : 0;
    this.spinner.show();
    this.gameBankService
      .saveTag(this.tag)
      .subscribe(res => {
        this.dialogRef.close();
        this.spinner.hide()
      }, () => {
        this.spinner.hide()
      });
  }

  onFileChange (ev) {
    if (ev.target.files && ev.target.files.length) {
      const formData = new FormData();
      formData.append('file', ev.target.files[0]);
      this.spinner.show();
      this.refDataService
        .fileUploadFun(formData)
        .subscribe(data => {
          this.tag.icon = data['picture'];
          this.imagePreview = { backgroundImage: 'url(' + this.tag.icon + ')' };
          this.isRemove = true;
          this.spinner.hide()
        }, err => {
          this.spinner.hide()
        });
    }
  }
  removeIcon () {

    this.tag.icon = '';
    this.imagePreview = { backgroundImage: 'url()' };
    this.isRemove = false;

  }
}
