import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GetTransactionService } from '../../../core/services/users/get-transaction.service';
import { Transaction } from '../../../../modules/core/models/api/transaction';
import { TostService } from '../../../core/services/common/tost.service';
@Component({
  selector: 'app-transaction-modal',
  templateUrl: './transaction-modal.component.html',
  styleUrls: ['./transaction-modal.component.scss']
})
export class TransactionModalComponent implements OnInit {
  cols: any;
  frozenCols: any[];
  userTransaction: Transaction;


  constructor (private modalService: NgbModal, public dialogRef: MatDialogRef<TransactionModalComponent>,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private transactionService: GetTransactionService) { }

  ngOnInit (): void {
    this.cols = [
      { field: 'game_id', header: 'Game Id', width: '80px' },
      { field: 'game_play_id', header: 'Game play Id', width: '80px' },
      { field: 'points', header: 'Points', width: '100px' },
      { field: 'descriptions', header: 'Description', width: '350px' },
      { field: 'createdAt', header: 'Created Date' },

    ];
    if (this.data && this.data.id) {

      //   this.transactionService.getTransactionData(this.data.id).subscribe(data => {
      //     const res = JSON.stringify(data);
      //     const getData = (JSON.parse(res)).userTransactionList;
      //     const getUserTransactionData = JSON.stringify(getData);
      //     this.userTransaction = JSON.parse(getUserTransactionData);

      //   });


      this.transactionService.getTransactionData(this.data.id).subscribe(
        data => {
          const res = JSON.stringify(data);
          const getData = (JSON.parse(res)).userTransactionList;
          const getUserTransactionData = JSON.stringify(getData);
          this.userTransaction = JSON.parse(getUserTransactionData);

        },
        err => {
          // this.tostService.tostrFun(err.error.errors[0].message, 'error');
          // this.dialogRef.close();
        });

    }



  }
  close (): void {
    this.dialogRef.close(true);
  }

}
