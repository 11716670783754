<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Source Type Update</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="sourceTypeForm" (ngSubmit)="submitData()">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="source_name" id="source_name" placeholder="Character Name"
                            type="text" class="form-control">
                    </mat-form-field>
                    <div *ngIf="f.source_name.touched && f.source_name.invalid" class="alert alert-danger">
                        <div *ngIf="f.source_name.errors.required">Name is required.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="source_code" readonly="true" id="source_code" placeholder="source code"
                            type="text" class="form-control">
                    </mat-form-field>

                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <mat-form-field class="example-full-width">
                        <input matInput formControlName="weight" id="weight" placeholder="weight" type="number"
                            class="form-control">
                    </mat-form-field>
                    <div *ngIf="f.weight.touched && f.weight.invalid" class="alert alert-danger">
                        <div *ngIf="f.weight.errors.required">weight is required.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <p-dropdown [options]="characterType" [disabled]="isUpdate" optionLabel="name" formControlName="character_type"
                        placeholder="Select character type " dataKey="character_type">
                    </p-dropdown>

                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button mat-raised-button type="submit" class="btn btn-danger pull-right"  (click)="close()">Save</button>
        </div>
        <div class="clearfix"></div>
    </form>

</div>