import { Component, OnInit, ViewEncapsulation } from '@angular/core';
//import { Payout } from '../../../modules/core/models/api/payout';
import { GameBankService } from '../../../modules/core/services/game-bank/game-bank.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaginationService } from '../../core/services/common/pagination.service';
import { SearchService } from '../../core/services/common/search.service';
import { ClipboardService } from 'ngx-clipboard';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { TostService } from '../../core/services/common/tost.service'
import { SiteConstants } from '../../../config/site-constants';
@Component({
  selector: 'app-gamebanklist',
  templateUrl: './gamebanklist.component.html',
  styleUrls: ['./gamebanklist.component.scss']
})
export class GamebanklistComponent implements OnInit {

  cols: any;
  gamesData: any;
  TimeoutSearch: any;
  closeResult: string;
  gamepathCustom: boolean = true;
  gamepathPinned: boolean = true;
  payoutdata: any;
  selectedGame: any;
  loading: boolean;
  globalSort: any = "";
  filter: any = {};
  frozenCols: any[];
  games: string = 'custom'
  payoutStatus: any[] = [];
  isCopied2: boolean;
  constPage: number = 10;
  totalRecords: number;
  constructor(private tostService: TostService, private route: Router, private _clipboardService: ClipboardService, private gameBankService: GameBankService, private searchService: SearchService, public dialog: MatDialog, private paginationService: PaginationService) { }

  ngOnInit(): void {
    this.loading = true;
    this.cols = [
      { field: 'id', header: 'Game Id', width: '80px' },

      { field: 'game_text', header: 'Game Text', width: '190px' },
      { field: 'game_title', header: 'Game Title', width: '140px' },


      // { field: 'game_type', header: 'Game Type' },
      { field: 'character_id_1_name', header: 'Character 1', width: '140px' },
      { field: 'character_id_2_name', header: 'Character 2', width: '140px' },
      { field: 'source_type', header: 'source_type', width: '90px' },

      // { field: 'duration', header: 'duration' },
      { field: 'urls', header: 'urls', width: '300px' },
      { field: 'status', header: 'status', width: '80px' },
      { field: 'start_time', header: 'start time', width: '100px' },
      { field: 'end_time', header: 'end_time', width: '100px' },
      { field: 'is_game_of_week', header: 'Action', width: '80px' }

    ];

    if (this.route.url === '/custom-game') {
      this.gamepathCustom = true;
      this.gamepathPinned = false;
      this.games = 'custom';
    }
    else if (this.route.url === '/pinned-game') {
      this.gamepathCustom = false;
      this.gamepathPinned = true;
      this.games = 'pinned';
    }
    // this.payout = JSON.parse(this.payoutService.getPayoutData());

    this.GetData({});
    // this._clipboardService.copyResponse$.subscribe(re => {
    //   if (re.isSuccess) {
    //     this.tostService.tostrFun('url copy success!', 'success');
    //   }
    // });

  }
  callServiceToCopy(url) {
    this._clipboardService.copyFromContent(url);
    this.tostService.tostrFun('url copy success!', 'success');
  }
  onCopyFailure() {
    alert('copy fail!');
  }
  onRefrash(e) {
    setTimeout(() => {
      this.GetData(this.filter);
    }, 1500)

  }
  customPagination(event) {
    let data;
    if (event.sortField == "character_id_1_name") {
      this.filter = this.filter;
    } else if (event.sortField == "character_id_2_name") {
      this.filter = this.filter;
    } else if (event.sortField == "urls") {
      this.filter = this.filter;
    }
    else {

      let data = this.paginationService.paginationFun(event, this.globalSort, this.constPage, this.filter)
      this.filter = data.filter;
      this.globalSort = data.globalSort;
      this.constPage = data.defaultPage;
      this.GetData(this.filter);

    }


    this.GetData(this.filter);
  }

  localDate(date) {
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
  }
  customSort(e) {
    let obj = {};
    if (e.field == 'first_name') {
      // e.field='first_name'
    }
    if (e.order == 1) {
      obj[e.field] = 'desc'
    } else {
      obj[e.field] = 'asc'
    }

    if (this.globalSort != e.field + " " + e.order) {

      this.filter['sort'] = obj;
      this.GetData(this.filter);
      this.globalSort = e.field + " " + e.order
      //  console.log(this.filter);
    }
  }
  userFilter(val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
    this.GetData(this.filter);
    }, SiteConstants.FiltertimeOut);
  }

  getGameStatusText(game) {
    var _status = game.status;

    if (_status === null) {
      return "";
    } else if (_status === 0) {
      var startTime = new Date(game.start_time);
      var endTime = new Date(game.end_time);
      var now = new Date();

      if (startTime > now) {
        return "Not started yet";
      } else if (endTime < now) {
        return "Ended - waiting for declaration";
      }

      return "Running";
    } else if (_status === 1) {
      return "Error while winner declaration";
    } else if (_status === 2) {
      return "Winners Declared";
    }
  };
  GetData(filter) {


    if (this.games == "custom") {
      this.filter.search = Object.assign({}, this.filter.search, { "game_type": "C" });
      this.getCustomData(filter);
    }
    else if (this.games == "pinned") {
      this.filter.search = Object.assign({}, this.filter.search, { "game_type": "P" });
      this.getPinnedData(filter);
    }
  }

  onClickGame(e) {

    this.GetData(this.filter)
  }

  getCustomData(obj) {

    this.gameBankService.getCustomAll(obj).subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).gameList;
      const getCharData = JSON.stringify(getData);
      this.gamesData = JSON.parse(getCharData);
      this.totalRecords = (JSON.parse(res)).total;
      this.loading = false;


    });
  }
  getPinnedData(obj) {

    this.gameBankService.getPinnedAll(obj).subscribe(data => {
      const res = JSON.stringify(data);
      const getData = (JSON.parse(res)).gameList;
      const getCharData = JSON.stringify(getData);
      this.gamesData = JSON.parse(getCharData);
      this.totalRecords = (JSON.parse(res)).total;
      this.loading = false;
    });
  }
  filterGameofWeek() {
    this.filter.search = this.filter.search || {};
    if (this.filter.search.is_game_of_week) {
      delete this.filter.search.is_game_of_week;
    } else {
      this.filter.search.is_game_of_week = 1;
    }

    this.GetData(this.filter);
  }

  setGameOfWeek(game) {
    this.gameBankService.toggleGOWT(game.id).subscribe(data => {
      if (game.is_game_of_week) {
        game.is_game_of_week = 0;
      } else {
        game.is_game_of_week = 1;
      }
    });
  }
}
