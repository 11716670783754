<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <p-table #dt [columns]="cols" [value]="gamesData" (sortFunction)="customSort($event)" [customSort]="true"
          dataKey="id" styleClass="ui-table-cars" [rows]="10" [rowsPerPageOptions]="[10,25,50]" [responsive]="true"
          [scrollable]="true" [loading]="loading" [paginator]="true" [totalRecords]="totalRecords"
          selectionMode="single" [(selection)]="selectedGame" [lazy]="true" (onLazyLoad)="customPagination($event)">
          <ng-template pTemplate="caption">
            <div class="row">
              <div class="ui-g ui-fluid">
                <div class="ui-g-12 ui-md-3">
                  <app-action-button [data]="{data:null,games:games}" (onRefrashed)="onRefrash($event)"
                    [width]="'1000px'" [modaleId]="'GameBankCreateEditModalComponent'">
                  </app-action-button>

                </div>
              </div>
              <div class="radiobu">
                <div class="p-col-12 radiopadding">
                  <p-radioButton name="group2" value="custom" (onClick)="onClickGame('custom')" label="Custom"
                    [(ngModel)]="games" inputId="opt1">
                  </p-radioButton>
                </div>
                <!-- <div class="p-col-12 radiopadding">
                  <p-radioButton name="group2" value="pinned" label="Pinned" (onClick)="onClickGame('pinned')"
                    [(ngModel)]="games" inputId="opt1">
                  </p-radioButton>
                </div> -->

                <span (click)="filterGameofWeek()" class="gotw-btn">
                  <img src="assets/img/GOTW-gary.svg" *ngIf="!filter.search || !filter.search.is_game_of_week" />
                  <img src="assets/img/GOTW-gold.svg" *ngIf="filter.search && filter.search.is_game_of_week" />
                </span>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                <div class="header-title">
                  {{col.header}}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </div>
              </th>
            </tr>
            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <input *ngSwitchCase="'game_text'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'game_title'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'id'" pInputText type="number" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
              <td style="width: 80px;">{{rowData.id}}</td>
              <td style="width: 190px;">{{rowData.game_text}}</td>
              <td style="width: 140px;">{{rowData.game_title}}</td>
              <td style="width: 140px;">{{rowData.character_id_1_name}}</td>
              <td style="width: 140px;">{{rowData.character_id_2_name}}</td>
              <td style="width: 90px;">{{rowData.source_type}}</td>
              <td style="width: 300px;">
                <li *ngFor="let url of rowData.urls"><a [href]="url" target="_blank">{{url}} <span
                      class="input-group-append" #inputTarget>
                    </span></a>
                  <i class="fa fa-clipboard" (click)="callServiceToCopy(url)"></i>
                </li>
              </td>
              <td style="width: 80px;">{{getGameStatusText(rowData)}}</td>
              <td style="width: 100px;">{{rowData.start_time}}</td>
              <td style="width: 100px;">{{rowData.end_time}}</td>
              <td style="width: 80px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                <app-action-icon [data]="{'data':rowData,'games':games}" [width]="'1000px'"
                  [modaleId]="'GameBankCreateEditModalComponent'" icon="edit" tooltip="update"
                  (onRefrashed)="onRefrash($event)"> </app-action-icon>
                <app-action-icon [data]="{'data':rowData,'games':games}" [width]="'1000px'" *ngIf="games=='custom'"
                      [modaleId]="'WinnerDeclarationModalComponent'" icon="winner_declaration"
                      tooltip="winnerdeclaration" (onRefrashed)="onRefrash($event)">
                </app-action-icon>
                    <button type="button" class="btn" (click)="setGameOfWeek(rowData)"
                      matTooltip="Set game of the week">
                  <img src="assets/img/GOTW-gary.svg" *ngIf="!rowData.is_game_of_week" />
                  <img src="assets/img/GOTW-gold.svg" *ngIf="rowData.is_game_of_week" />
                </button>
                  </div>
                </mat-menu>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
