import { Injectable } from '@angular/core';
import { characterData } from '../../core/data/characterJson';
import { HttpClient } from '@angular/common/http';
import { Endpoints, SiteConstants } from '../../../config';
@Injectable({
  providedIn: 'root'
})
export class CharacterService {

  constructor(private httpClient: HttpClient) { }

  getCharacterData() {
    const url = Endpoints.api.characters.getGallaryImage;
    const observable = this.httpClient.get(url)

    return observable;


  }

  getCustomCharacterData(characterFilter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (characterFilter.search) {
      search = JSON.stringify(characterFilter.search);

    }
    // if (characterFilter.sort) {
    //   sort = JSON.stringify(characterFilter.sort)
    // }

    if (characterFilter.sort) {
      if (Object.keys(characterFilter.sort).length > 0) {
        sort = JSON.stringify(characterFilter.sort)
      } else {
        sort = JSON.stringify({ createdAt: 'desc' })
      }
    } else {
      sort = JSON.stringify({ createdAt: 'desc' })

    }
    if (characterFilter.pagination) {
      pagination = JSON.stringify(characterFilter.pagination)
    }
    else {
      pagination = SiteConstants.paginationDefault;
    }
    const url = Endpoints.api.characters.get
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url)

    return observable;


  }


  uploadCharacterImage(formData) {
    const url = Endpoints.api.characters.characterImageUpload;
    const observable = this.httpClient.put(url, formData)
    return observable;
  }


  createCharacters(characterFormData, action) {

    if (action == "create") {
      const url = Endpoints.api.characters.createCharacter;
      const observable = this.httpClient.post(url, characterFormData)

      return observable;
    } else if (action == "update") {
      const url = Endpoints.api.characters.updateCharacter;
      const observable = this.httpClient.put(url, characterFormData)
      return observable;
    }
  }
  removeChacter(obj) {
    const url = Endpoints.api.characters.removeCharacter + '/' + obj.id;
    const observable = this.httpClient.delete(url)
    return observable;
  }

}
