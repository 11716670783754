import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import {PinnedQuestionsViewModalComponent  } from '../pinned-questions-view-modal/pinned-questions-view-modal.component';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { PinnedQuestionService } from '../../../core/services/game/pinned-question.service'

import {PinnedGameService} from '../../../core/services/game/pinned-game.service';

@Component({
  selector: 'app-pinned-game-edit-modal',
  templateUrl: './pinned-game-edit-modal.component.html',
  styleUrls: ['./pinned-game-edit-modal.component.scss']
})
export class PinnedGameEditModalComponent implements OnInit {
  is_disable:boolean = false;
  durationType:any[];
  alldata:any[];
  leagueCategory: any[];
  pinnedQuestion: any;
  price_options: any[];
  selectedPinnedQuestion: any[];
  pGameForm = new FormGroup({
    game_name: new FormControl('', [Validators.required]),
    start_time: new FormControl(new Date()),
    end_time: new FormControl(new Date()),
    last_join_time: new FormControl(new Date()),
    title: new FormControl('', []),
    price_options: new FormControl('', []),
    game_duration: new FormControl('', []),
    game_category: new FormControl('', []),
  });
  constructor(private pinnedQuestionService: PinnedQuestionService,private pinnedGameService: PinnedGameService, private _fb: FormBuilder,
    private refDataService: RefDataService, public dialog: MatDialog,
    public dialogRef: MatDialogRef<PinnedGameEditModalComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any) {

      this.durationType = [ 
        {name: 'Minute', value: 'Minute'},
        {name: 'Hours', value: 'Hours'},
        {name: 'Days', value: 'Days'},
          ]

      this.leagueCategory = [
        {name: 'Music', value: 'M'},
        {name: 'Company', value: 'C'},
        {name: 'Movies', value: 'MV'},
        {name: 'Sports', value: 'S'},

      ]    
     }

  ngOnInit(): void {
    // this.leagueCategory = JSON.parse(this.refDataService.getRefAttData()).attributes;
    this.pinnedQuestion = this.pinnedQuestionService.getPinnedQuestionData();
      
      
    if (this.data) {
      this.is_disable = true;
      this.getQuestionApi(this.data.id);
      this.pGameForm.patchValue({
        game_name: this.data.game_name,
        start_time: this.data.start_time,
        end_time: this.data.end_time,
        last_join_time: this.data.last_join_time,
        title: this.data.title,
        price_options: this.data.price_options,
        game_duration: this.data.game_duration,
        game_category:this.data.game_category
      });
      console.log('price option',this.data.price_options)
      this.price_options = this.data.price_options;
    } else {
      this.pGameForm.patchValue({
        game_name: '',
        start_time: new Date(),
        end_time: new Date(),
        last_join_time: new Date(),
        title: '',
        game_category:'',
        game_duration:60,
        price_options: [{
          entry_fee: 0,
          prize_money: 0,
          default: false
        }]
      });
    }
    this.price_options = [{
      entry_fee: 0,
      prize_money: 0,
      default: true
    }];
  }
  close(): void {
    this.price_options = [{
      entry_fee: 0,
      prize_money: 0,
      default: true
    }];
    this.dialogRef.close();
  }
  submitData() {
    console.log('submit fun',this.pGameForm.value);
  }
  addPriceOption() {
    this.price_options.push({
      entry_fee: 0,
      prize_money: 0,
      default: true
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(PinnedQuestionsViewModalComponent, {
      width: '1000px',
      data: { data: this.selectedPinnedQuestion, formval: this.pGameForm.value }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.selectedPinnedQuestion = result;
      console.log('this is res',result);
    });
  }

  getQuestionApi(id){
   
    this.pinnedGameService.getGameData(id).subscribe(data => {
      console.log("game performance ",data);
      // this.alldata = data.disputesList;
       const res = JSON.stringify(data);
      
       this.alldata = (JSON.parse(res).questions);
       this.selectedPinnedQuestion = this.alldata;
      // const getCharData = JSON.stringify(getData);
      // this.alldata = JSON.parse(getCharData);
      // this.loading = false;
    });
    // this.close();
  }
}
