<div class="modal-header">
  <h4 *ngIf="is_disable == true" class="modal-title" id="modal-basic-title">Pinned Game Update</h4>
  <h4 *ngIf="is_disable == false">{{iscreate}} {{gameType}} games</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="modal-body">
            <form (ngSubmit)="submitData()">
              <div class="row">
                <!-- <div class="col-md-4">
                                    <label for="game_type">Select Game Type</label>
                                    <div class="form-group">

                                        <p-dropdown [options]="gameType" optionLabel="name"
                                            [(ngModel)]="gameObj.game_type" name="game_type"
                                            placeholder="Select Game Type " [style]="{'width': '250px'}"
                                            dataKey="game_type" id="game_type">
                                        </p-dropdown>

                                    </div>
                                </div> -->


                <div class="col-md-4">
                  <label for="source_type">Select Source Type </label>
                  <i class="{{icons['info']}} tooltipcls" matTooltip="{{tooltips['sourcetype']}}"></i>

                  <div class="form-group">


                    <!-- <p-dropdown [options]="sourceTypeData" name="selectedCar3"
                                            [(ngModel)]="gameObj.source_type" filter="true"
                                            (onChange)="changeCharacterType($event)" [style]="{'width': '250px'}">
                                            <ng-template let-affiliate pTemplate="selectedItem">
                                                <span
                                                    style="vertical-align:middle; margin-left: .5em">{{affiliate.label}}</span>
                                            </ng-template>
                                            <ng-template let-item pTemplate="affiliate">
                                                <div class="ui-helper-clearfix"
                                                    style="position: relative;height: 25px;">
                                                    <div style="font-size:14px;margin-top:4px">{{item.label}}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown> -->

                    <mat-form-field appearance="fill">
                      <mat-label>Select Source Type</mat-label>
                      <mat-select [(ngModel)]="gameObj.source_type" (selectionChange)="changeCharacterType($event)"
                        name="source" id="source_type">
                        <mat-option *ngFor="let source of sourceType" [value]="source.source_code">
                          {{source.source_name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                </div>
                <div class="col-md-4">
                  <label for="character_type">Select affiliate</label>
                  <i class="{{icons['info']}} tooltipcls" matTooltip="{{tooltips['affiliate']}}"></i>
                  <div class="form-group">

                    <!-- <p-dropdown [options]="affiliateSummary" placeholder="Select affiliate"
                                            name="selectedCar2" [(ngModel)]="gameObj.affiliate_id" filter="true"
                                            [style]="{'width': '250px'}">
                                            <ng-template let-affiliate pTemplate="selectedItem1">
                                                <span
                                                    style="vertical-align:middle; margin-left: .5em">{{affiliate.label}}</span>
                                            </ng-template>
                                            <ng-template let-item pTemplate="affiliate">
                                                <div class="ui-helper-clearfix"
                                                    style="position: relative;height: 25px;">
                                                    <div style="font-size:14px;margin-top:4px">{{item.label}}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown> -->

                    <mat-form-field appearance="fill">
                      <mat-label>Select affiliate</mat-label>
                      <mat-select [(ngModel)]="gameObj.affiliate_id" name="affiliate" id="source_type1">
                        <mat-option *ngFor="let source of affiliateSummary" [value]="source.value">
                          {{source.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>



                  </div>
                </div>

                <div class="col-md-2" *ngIf="data.games == 'pinned'">
                  <label for="duration">Game duration </label>
                  <i class="{{icons['info']}} " matTooltip="{{tooltips['duration']}}"></i>
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="gameObj.duration" name="duration" id="duration" type="number"
                        class="form-control">
                    </mat-form-field>

                  </div>
                </div>

                <div class="col-md-2" *ngIf="data.games == 'pinned'">
                  <label for="duration_unit">Duration unit</label>
                  <i class="{{icons['info']}} " matTooltip="{{tooltips['durationunit']}}"></i>
                  <div class="form-group">

                    <p-dropdown [options]="durationUnits" name="duration_unit" [(ngModel)]="duration_unit"
                      [style]="{'width': '150px'}"></p-dropdown>


                  </div>
                </div>

              </div>
              <div class="row">

                <div class="col-md-4">
                  <label for="duration_unit">Game Text</label>
                  <i class="{{icons['info']}} tooltipcls" matTooltip="{{tooltips['gametext']}}"></i>
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="gameObj.game_text" name="game_text" id="game_text" placeholder=""
                        (change)="gameTextChange($event)" type="text" class="form-control">
                    </mat-form-field>

                  </div>

                </div>
                <div class="col-md-4">
                  <label for="duration_unit">Game Title</label>
                  <i class="{{icons['info']}} tooltipcls" matTooltip="{{tooltips['gametitle']}}"></i>
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="gameObj.game_title" name="game_title" id="game_title" placeholder=""
                        type="text" class="form-control">
                    </mat-form-field>

                  </div>

                </div>
                <div class="col-md-4">
                  <label for="duration_unit">Source url</label>
                  <i class="{{icons['info']}} tooltipcls" matTooltip="{{tooltips['sourceurl']}}"></i>
                  <div class="form-group">
                    <mat-form-field class="example-full-width">
                      <input matInput [(ngModel)]="gameObj.source_url" name="source_url" id="source_url" placeholder=""
                        type="text" class="form-control">
                    </mat-form-field>

                  </div>
                </div>
              </div>
              <!-- <div class="row" *ngIf="data.games == 'custom2' ">

                                <div class="col-md-4"><label for="tags">Pin game on top</label> <i
                                        class="{{icons['info']}} tooltipcls"
                                        matTooltip="{{tooltips['pingametotop']}}"></i>
                                    <div class="form-group">
                                        <p-checkbox [(ngModel)]="gameObj.pin_game_to_top" name="pin_game_to_top"
                                            binary="true"></p-checkbox>
                                    </div>
                                </div>
                                <div class="col-md-4"><label for="tags">Skip from trending</label> <i
                                        class="{{icons['info']}} tooltipcls"
                                        matTooltip="{{tooltips['notshowtrending']}}"></i>
                                    <div class="form-group">
                                        <p-checkbox [(ngModel)]="gameObj.not_show_trending" name="notshowtrending"
                                            binary="true"></p-checkbox>
                                    </div>
                                </div>
                            </div> -->
              <div class="row">


                <div class="col-md-4">
                  <label for="character_id_1">Select Character 1</label> <i class="{{icons['info']}} tooltipcls"
                    matTooltip="{{tooltips['character1']}}"></i>
                  <div class="form-group">
                    <input [(ngModel)]="SelectedCharacter1" name="character_id_1" style="width: 250px;"
                      id="character_id_1" (keyup)="searchAuto($event)" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="250" [displayWith]="displayProperty">
                      <mat-option (onSelectionChange)="onSelectCustomer($event,'character1')"
                        *ngFor="let option of characterList" [value]="option">
                        <img class="img-rounded" width="35px" height="35px" src="{{option.image_url}}" /> <span>
                          {{option.character_name}}</span>
                      </mat-option>
                    </mat-autocomplete>

                    <!-- <p-autoComplete [(ngModel)]="gameObj.character_id_1"
                                            [suggestions]="searchResult" (click)="search($event)"
                                            (completeMethod)="search($event)" field="character_name"
                                            (onSelect)="selectedVal($event,'character1')" name="character_id_1"
                                            [size]="30" [minLength]="2" datakey="id" placeholder="Select character 1">
                                            <ng-template let-brand pTemplate="item">
                                                <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
                                                    <img [src]="brand.image_url"
                                                        style="width:32px;display:inline-block;margin:5px 0 2px 5px" />
                                                    <div style="font-size:18px;float:right;margin:10px 10px 0 0">
                                                        {{brand.character_name}}</div>
                                                </div>
                                            </ng-template>
                                        </p-autoComplete> -->
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="character_id_2">Select Character 2</label> <i class="{{icons['info']}} tooltipcls"
                    matTooltip="{{tooltips['character2']}}"></i>
                  <div class="form-group">
                    <input [(ngModel)]="SelectedCharacter2" name="character_id_2" style="width: 250px;"
                      id="character_id_2" (keyup)="searchAuto1($event)" [matAutocomplete]="autot">
                    <mat-autocomplete #autot="matAutocomplete" [panelWidth]="250" [displayWith]="displayProperty1">
                      <mat-option (onSelectionChange)="onSelectCustomer($event,'character2')"
                        *ngFor="let option of characterList1" [value]="option">
                        <img class="img-rounded" width="35px" height="35px" src="{{option.image_url}}" /> <span>
                          {{option.character_name}}</span>
                      </mat-option>
                    </mat-autocomplete>
                    <!-- <p-autoComplete [(ngModel)]="gameObj.character_id_2"
                                            [suggestions]="searchResult" (click)="search($event)"
                                            (completeMethod)="search($event)" field="character_name"
                                            (onSelect)="selectedVal($event,'character2')" name="character_id_2"
                                            [size]="30" [minLength]="2" placeholder="Select character  2">
                                            <ng-template let-character pTemplate="item">
                                                <div class="ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
                                                    <img [src]="character.image_url"
                                                        style="width:32px;display:inline-block;margin:5px 0 2px 5px" />
                                                    <div style="font-size:18px;float:right;margin:10px 10px 0 0">
                                                        {{character.character_name}}</div>
                                                </div>
                                            </ng-template>
                                        </p-autoComplete> -->



                  </div>
                </div>
                <div class="col-md-4">
                  <label for="tags">Tags</label> <i class="{{icons['info']}} tooltipcls"
                    matTooltip="{{tooltips['tags']}}"></i>
                  <div class="form-group">
                    <p-multiSelect [options]="customGameTag" [(ngModel)]="gameObj.tags" [panelStyle]="{minWidth:'12em'}"
                      maxSelectedLabels="1" placeholder="Tags" name="Tags" [filter]="false"
                      (onChange)="selectdOption($event)" [style]="{'width': '248px'}">
                      <ng-template let-tag pTemplate="item">
                        <div style="display: inline-block;">
                          {{tag.label}}
                          <span style="font-size: 11px; font-style: italic;vertical-align: top;">{{tag.type}}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group priceoptioncls">
                    <h5>Game Price Options. <i class="{{icons['info']}} tooltipcls"
                        matTooltip="{{tooltips['priceoptions']}}"></i>
                      <button *ngIf="is_disable !== true " [disabled]="iscreate=='Update'" mat-raised-button
                        type="button" class="btn btn-link" (click)="addPriceOption()">+ ADD
                        PRICE OPTIONS </button></h5>
                    <ng-template ngFor let-xb [ngForOf]="price_options" let-index="index" *ngIf="iscreate!='Update'">

                      <!-- <input id="entry_fee" placeholder="entry_fee" value="{{xb.entry_fee}}" type="number" min="0" class="form-control1">

                                            <input id="prize_money" placeholder="Game title" value="{{xb.prize_money}}" type="number" min="1" class="form-control1">
                                            <mat-checkbox style="margin-top: 7px;"> Default</mat-checkbox>
                                            <button type="button" class="close" aria-label="Close" style="margin-top: -8px;" (click)="close()">
                                                <span aria-hidden="true">&times;</span>
                                            </button> -->

                      <div class="row">

                        <div class="col-md-4">
                          <div class="form-group">
                            <mat-form-field class="example-full-width">
                              <input [disabled]="is_disable == true" matInput id="entry_fee" [(ngModel)]="xb.entry_fee"
                                value="{{xb.entry_fee}}" placeholder="Entry fee" [ngModelOptions]="{standalone: true}"
                                type="number" class="form-control">
                            </mat-form-field>

                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <mat-form-field class="example-full-width">
                              <input [disabled]="is_disable == true" matInput id="prize money"
                                [(ngModel)]="xb.prize_money" [ngModelOptions]="{standalone: true}"
                                value="{{xb.prize_money}}" placeholder="Prize money" type="number" class="form-control">
                            </mat-form-field>

                          </div>
                        </div>
                        <div class="col-md-4" *ngIf="is_disable !== true ">
                          <div class="form-group">
                            <mat-checkbox [checked]="xb.default" (change)="checkDefaultPriceOption(index)"
                              [(ngModel)]="xb.default" [ngModelOptions]="{standalone: true}"> Default
                            </mat-checkbox>
                            <button type="button" class="close" aria-label="Close" style="margin-top: -8px;"
                              (click)="close1(index)">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        </div>
                        <!-- <div class="co-md-2" *ngIf="is_disable !== true ">
                                                    <div class="form-group">


                                                    </div>
                                                </div> -->
                      </div>
                    </ng-template>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-4">
                  <label for="start_time">Start Date</label> <i class="{{icons['info']}} tooltipcls"
                    matTooltip="{{tooltips['startdate']}}"></i>

                  <div class="form-group">
                    <!-- <mat-form-field>
                                            <input matInput [ngxMatDatetimePicker]="picker1"
                                                [(ngModel)]="gameObj.start_time" placeholder="Start Date">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
                                        </mat-form-field> -->
                    <p-calendar [showTime]="true" name="start_time" [(ngModel)]="gameObj.start_time" id="start_time"
                      [showIcon]="true" (onSelect)="startDateOnSetTime($event)">
                    </p-calendar>
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="end_time">End Date</label> <i class="{{icons['info']}} tooltipcls"
                    matTooltip="{{tooltips['enddate']}}"></i>
                  <div class="form-group">
                    <!-- <mat-form-field>
                                            <input matInput [ngxMatDatetimePicker]="picker2" [(ngModel)]="gameObj.end_time"
                                                placeholder="End Date">
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <ngx-mat-datetime-picker #picker2></ngx-mat-datetime-picker>
                                        </mat-form-field> -->
                    <p-calendar [showTime]="true" name="end_time" (onSelect)="endDateOnSetTime($event)"
                      [showIcon]="true" [(ngModel)]="gameObj.end_time" id="end_time">
                    </p-calendar>
                  </div>
                </div>
                <div class="col-md-4" *ngIf="data.games == 'custom'">
                  <label for="last_join_time">Last Join Time</label> <i class="{{icons['info']}} tooltipcls"
                    matTooltip="{{tooltips['lastjoindate']}}"></i>
                  <div class="form-group">
                    <!-- <mat-form-field>
                                            <input matInput [ngxMatDatetimePicker]="picker3"
                                                [(ngModel)]="gameObj.last_join_time" placeholder="Last Join Time">
                                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                            <ngx-mat-datetime-picker #picker3></ngx-mat-datetime-picker>
                                        </mat-form-field> -->
                    <p-calendar [showTime]="true" name="last_join_time" [(ngModel)]="gameObj.last_join_time"
                      (onSelect)="setLastJoinDate($event)" id="last_join_time" [showIcon]="true">
                    </p-calendar>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="backgroundType">Select Background Color</label> <i
                        class="{{icons['info']}} tooltipcls" matTooltip="{{tooltips['bgcolor']}}"></i>
                      <div class="form-group">

                        <p-card>


                          <div class="radiobu">

                            <div class="p-col-12 radiopadding">
                              <p-radioButton name="group2" value="#ffffff" label="White"
                                (onClick)="bgColorClick('black')" [(ngModel)]="gameObj.background.bg_color"
                                inputId="opt1">
                              </p-radioButton>
                            </div>
                            <div class="p-col-12 radiopadding">
                              <p-radioButton name="group2" value="#000000" label="Black"
                                [(ngModel)]="gameObj.background.bg_color" (onClick)="bgColorClick('white')"
                                inputId="opt1">
                              </p-radioButton>
                            </div>
                            <div class="vl"></div>
                            <div class="p-col-12 radiopadding">
                              <p-radioButton name="group2" value="transparent" label="[A]"
                                [(ngModel)]="gameObj.background.bg_color" (onClick)="bgColorClick('transparent')"
                                inputId="opt1">
                              </p-radioButton>

                            </div>
                          </div>

                        </p-card>


                      </div>
                    </div>

                    <div class="col-md-6">
                      <label for="backgroundType">Select Background </label> <i class="{{icons['info']}} tooltipcls"
                        matTooltip="{{tooltips['background']}}"></i>
                      <div class="form-group">

                        <p-card>


                          <div *ngFor="let category of backgroundType" class="radiobu">

                            <div class="p-col-12 radiopadding">
                              <p-radioButton name="group1" [value]="category.type" [label]="category.type"
                                [(ngModel)]="gameObj.background.type" name="type"
                                (onClick)="backgroundClick(category.type)" inputId="opt1">
                              </p-radioButton>
                            </div>

                          </div>

                        </p-card>


                      </div>
                    </div>

                  </div>
                  <div class="row" *ngIf="isVertical">
                    <div class="col-md-5">
                      <div class="card-container game-card-box">

                        <div [ngStyle]="backgroundObj" class="vs-text"><span [ngStyle]="textSizeObj"
                            class="vs-box">{{gameText}}
                          </span></div>

                        <div class="vs-line">
                          <div class="player-select-box">
                            <div class="player-name player-1"> {{character1_name}}

                            </div>
                            <div class="v-box">
                              <img src="assets/img/v-logo.svg" />
                            </div>
                            <div class="player-name player-2">

                              {{character2_name}}
                            </div>
                          </div>
                        </div>
                        <div class="card-box card-left">
                          <div [ngStyle]="imagesCharacter1" class="card-image card-left-image">
                          </div>

                        </div>
                        <div class="card-box card-left">
                          <div [ngStyle]="imagesCharacter2" class="card-image card-left-image">
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-md-3" style="margin-left:40px;">
                      <label for="text_size">Background text size
                        {{textSize}}</label> <i class="{{icons['info']}} " matTooltip="{{tooltips['textsize']}}"></i>
                      <div class="form-group" style="margin-left:70px;">

                        <p-slider [(ngModel)]="textSize" (onChange)="handleChange($event)" [style]="{'height':'14em'}"
                          id="text_size" [min]="16" [max]="26" [ngModelOptions]="{standalone: true}"
                          orientation="vertical">
                        </p-slider>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="isStacked">
                    <div class="col-md-5">
                      <div class="card-container stacked game-card-box game-card-box1 ">
                        <div [ngStyle]="backgroundObj" class="vs-text"><span [ngStyle]="textSizeObj"
                            class="vs-box">{{gameText}}
                          </span></div>
                        <div class="vs-line">
                          <div class="player-select-box player-select-box1">
                            <div class="player-name player-1">
                              {{character1_name}}
                            </div>
                            <div class="v-box">
                              <img src="assets/img/v-logo.svg" />
                            </div>
                            <div class="player-name player-2">
                              {{character2_name}}
                            </div>
                          </div>
                        </div>
                        <div class="card-box card-box1 card-left">
                          <div [ngStyle]="imagesCharacter1" class="card-image card-left-image"></div>

                        </div>
                        <div class="card-box card-box1 card-left">
                          <div [ngStyle]="imagesCharacter2" class="card-image card-left-image"></div>

                        </div>
                      </div>

                    </div>
                    <div class="col-md-3" style="margin-left:40px;">
                      <label for="text_size">Background text size
                        {{textSize}}</label>
                      <i class="{{icons['info']}} " matTooltip="{{tooltips['textsize']}}"></i>
                      <div class="form-group" style="margin-left:70px;">

                        <p-slider [(ngModel)]="textSize" (onChange)="handleChange($event)" [style]="{'height':'14em'}"
                          id="text_size" [min]="16" [max]="26" [ngModelOptions]="{standalone: true}"
                          orientation="vertical">
                        </p-slider>
                      </div>
                    </div>

                  </div>
                  <div class="row" *ngIf="isSingle">
                    <div class="col-md-5">

                      <div class="card-container game-card-box game-card-box1 ">
                        <div [ngStyle]="backgroundObj" class="vs-text"><span [ngStyle]="textSizeObj"
                            class="vs-box">{{gameText}}
                          </span></div>
                        <div class="vs-line">
                          <div class="player-select-box player-select-box">
                            <div class="player-name player-1">
                              {{character1_name}}
                            </div>
                            <div class="v-box">
                              <img src="assets/img/v-logo.svg" />
                            </div>
                            <div class="player-name player-2">
                              {{character2_name}}
                            </div>
                          </div>
                        </div>
                        <div class="card-box card-box2 card-left">
                          <div *ngIf="file_type=='image'" [ngStyle]="imagesCharacterSingle"
                            class="card-image card-left-image">
                          </div>
                          <div *ngIf="file_type=='video'" class="card-image card-left-image"
                            style="background-color: #7b7b7b;">
                            <video autoplay muted loop id="myVideo" width="358" height="566" #videoPlayer
                              (loadedmetadata)="getDuration($event)">
                              <source src="{{video_src}}" type="video/mp4">

                            </video>

                          </div>

                        </div>

                      </div>

                    </div>
                    <div class="col-md-2">
                      <label for="text_size" style="padding-left: 26px;"> text size
                        {{textSize}}</label>
                      <i class="{{icons['info']}} " matTooltip="{{tooltips['textsize']}}"></i>
                      <div class="form-group" style="padding-left: 66px;">

                        <p-slider [(ngModel)]="textSize" (onChange)="handleChange($event)" [style]="{'height':'14em'}"
                          id="text_size" [min]="16" [max]="26" [ngModelOptions]="{standalone: true}"
                          orientation="vertical">
                        </p-slider>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="row">
                        <div class="form-group">
                          <label for="file_type">File Type
                          </label>
                          <i class="{{icons['info']}} " matTooltip="{{tooltips['filetype']}}"></i>
                          <div>
                            <p-card id="file_type">


                              <div class="radiobu">

                                <div class="p-col-12 radiopadding">
                                  <p-radioButton name="grou" value="image" label="Image"
                                    (onClick)="fileTypeClick('image')" [(ngModel)]="file_type">
                                  </p-radioButton>
                                </div>
                                <div class="p-col-12 radiopadding">
                                  <p-radioButton name="grou" value="video" label="Video" [(ngModel)]="file_type"
                                    (onClick)="fileTypeClick('video')">
                                  </p-radioButton>
                                </div>

                              </div>
                            </p-card>
                          </div>




                        </div>


                      </div>
                      <div class="row" *ngIf="file_type=='image'">


                        <mat-form-field style="width: 100%;" class="example-full-width">
                          <input matInput [(ngModel)]="image_url" name="image_url" id="image_url"
                            placeholder="Image url" (change)="imageChange($event)" type="text" class="form-control">
                        </mat-form-field>


                      </div>
                      <div class="row" *ngIf="file_type=='video'">


                        <mat-form-field style="width: 100%;" class="example-full-width">
                          <input matInput [(ngModel)]="video_src" name="video_src" id="video_src"
                            placeholder="Video Src" (change)="videoChange($event)" type="text" class="form-control">
                        </mat-form-field>


                      </div>
                      <div class="row">
                        <div class="form-group">
                          <label for="file">Select {{file_type}}
                          </label>
                          <div>
                            <div class="file-field" *ngIf="file_type=='image'">
                              <input style="display: none" type="file" (change)="onFileChange($event)" #hiddenfileinput>
                              <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">
                                Choose
                                file</label>


                            </div>
                            <div class="file-field" *ngIf="file_type=='video'">
                              <input style="display: none" type="file" (change)="onFileChangeVideo($event)"
                                #hiddenfileinput>
                              <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">
                                Choose
                                file</label>


                            </div>
                          </div>




                        </div>
                      </div>


                    </div>

                  </div>
                </div>


              </div>
              <div class="row" *ngIf="data.games == 'custom'  ">
                <div class="col-md-12">
                  <div *ngIf="(!gameObj.status|| gameObj.status == 0) && countries && countries.length">
                    <div class="clearfix">
                      <h4>Only Allow From. <i class="{{icons['info']}} " matTooltip="{{tooltips['locations']}}"></i>
                        <button mat-raised-button type="button" class="btn btn-link" (click)="addLocation()">+
                          Add Allowed Location</button>
                      </h4>
                    </div>

                    <mat-list-item *ngFor="let loc of gameObj.locations ; let index = index" style="padding:0px;">
                      <div class="row">
                        <div class="col-md-6">
                          <p-card class="mat-block" flex="40">
                            <label>Country {{loc.country}}</label>
                            <mat-select [(value)]="loc.country" name="country" flex
                              (selectionChange)="loadStates(loc.country)">
                              <mat-option *ngFor="let c of countries" [value]="c.key">
                                {{ c.text }} </mat-option>
                            </mat-select>
                          </p-card>
                        </div>


                        <div class="col-md-5">

                          <p-card class="mat-block" flex="40"
                            *ngIf="states && states[loc.country] && states[loc.country].length > 1">
                            <label>State</label>
                            <mat-select [(value)]="loc.state" name="state" flex>
                              <mat-option *ngFor="let c of states[loc.country]" [value]="c.value">
                                {{ c.text }} </mat-option>
                            </mat-select>
                          </p-card>
                        </div>
                        <i flex="10" class="fa fa-remove ctm-close-icon" (click)="removeLocation(index)"></i>

                      </div>



                    </mat-list-item>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button mat-raised-button type="submit" class="btn btn-danger pull-right">Save</button>

              </div>
            </form>

          </div>

        </div>

      </div>
    </div>
  </div>
</mat-dialog-content>



<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>