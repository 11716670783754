import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RefDataService } from '../../../core/services/common/ref-data.service';
import { SiteConstants } from '../../../../config/site-constants';
import { GameBankService } from '../../../core/services/game-bank/game-bank.service';
import { TostService } from '../../../core/services/common/tost.service';
@Component({
  selector: 'app-trending-tag-edit-modal',
  templateUrl: './trending-tag-edit-modal.component.html',
  styleUrls: ['./trending-tag-edit-modal.component.scss']
})
export class TrendingTagEditModalComponent implements OnInit {

  trending: any;
  action: any = "create";
  icons = SiteConstants.icons;

  tooltips = SiteConstants.tooltips;


  imagePreview: any;
  constructor (
    public dialogRef: MatDialogRef<TrendingTagEditModalComponent>,
    private gameBankService: GameBankService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private refDataService: RefDataService,
    private tostService: TostService
  ) { }

  ngOnInit (): void {
    if (this.data.trending) {
      this.action = "update"
    } else {
      this.action = "create"
    }
    this.trending = this.data.trending || {
      title: '',
      sub_titlw: '',
      is_active: false,
      icon: 'https://cdn.versusgame.com/assets-v4/hashtag.png',
      trending_index: ''

    };

    if (this.trending.icon) {
      this.imagePreview = { backgroundImage: 'url(' + this.trending.icon + ')' };

    }
  }

  close () {
    this.dialogRef.close(true);
  }

  saveTrending () {
    // console.log(this.trending)
    this.trending.action = this.action;

    var hasErr = '';
    if (!this.trending.title) {
      hasErr = "please enter title";
    } else if (!this.trending.tag_key) {
      hasErr = "Please enter tag key";
    } else if (!this.trending.icon) {
      hasErr = "Please add icon";
    }
    if (hasErr) {
      this.tostService.tostrFun(hasErr, 'error');
      return;
    }
    this.trending.tag_key = this.trending.tag_key.toLowerCase();
    this.spinner.show();
    this.gameBankService
      .saveTrending(this.trending)
      .subscribe(res => {
        this.dialogRef.close();
        this.spinner.hide()
        this.tostService.tostrFun(`Tag trending ${this.action} successfully`, 'success');
      }, () => {
        this.spinner.hide()
      });
  }

  onFileChangeActive (ev) {
    if (ev.target.files && ev.target.files.length) {
      const formData = new FormData();
      formData.append('file', ev.target.files[0]);
      this.spinner.show();
      this.refDataService
        .fileUploadFun(formData)
        .subscribe(data => {
          this.trending.icon = data['picture'];
          this.imagePreview = { backgroundImage: 'url(' + this.trending.icon + ')' };
          this.spinner.hide()
        }, err => {
          this.spinner.hide()
        });
    }
  }

}
