<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <div class="modal-body">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <div class="loginCls">

                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-9">
                        <label for="old_password">Old Password</label>
                        <input [type]="oldPass" formControlName="old_password" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }" style="border: none;" />
                      </div>
                      <div class="col-md-3">
                        <i class="fa fa-eye" aria-hidden="true" style="margin-top: 35px; " (click)="onClickOldPass()"
                          *ngIf="!oldPassShow"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" (click)="onClickOldPass()"
                          style="margin-top: 35px; " *ngIf="oldPassShow"></i>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-9">
                        <label for="new_password">New Password</label>
                        <input [type]="newPass" formControlName="new_password" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }" style="border: none;" />
                      </div>
                      <div class="col-md-3">
                        <i class="fa fa-eye" aria-hidden="true" style="margin-top: 35px; " (click)="onClickNewPass()"
                          *ngIf="!newPassShow"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" (click)="onClickNewPass()"
                          style="margin-top: 35px; " *ngIf="newPassShow"></i>
                      </div>
                    </div>
                  </div>


                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-9">
                        <label for="confirm_password">Confirm Password</label>
                        <input [type]="confirmPass" formControlName="confirm_password" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" style="border: none;" />
                      </div>
                      <div class="col-md-3">
                        <i class="fa fa-eye" aria-hidden="true" style="margin-top: 35px; "
                          (click)="onClickConfirmPass()" *ngIf="!confirmPassShow"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" (click)="onClickConfirmPass()"
                          style="margin-top: 35px; " *ngIf="confirmPassShow"></i>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <button class="btn btn-primary">Save</button>

                  </div>
                </form>
              </div>

            </div>
            <div class="col-md-3"></div>
          </div>

        </div>

      </div>

    </div>
  </div>
</div>