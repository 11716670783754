import { Component, OnInit } from '@angular/core';
import lodash from 'lodash';
import { PaginationService } from '../../core/services/common/pagination.service';
import { SearchService } from '../../core/services/common/search.service';
import { GameBankService } from '../../core/services/game-bank/game-bank.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SiteConstants } from '../../../config/site-constants';
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  cols = [];

  loading = false;

  tagList = [];

  totalRecords = 0;

  TimeoutSearch: any;

  selectedTag = null;

  filters = {};

  globalSort = '';

  constPage = 10;

  gameStatusOptions: any[];

  myForm = new FormGroup({
    tag_type: new FormControl('', [])
  });
  filter: any = {};
  constructor (
    private gameBankService: GameBankService,
    private paginationService: PaginationService,
    private searchService: SearchService
  ) {
    this.gameStatusOptions = [
      { value: 'general', label: 'Hashtag', },
      { value: 'game', label: 'Game', },
      { value: 'premium', label: 'Premium', },
    ];

  }

  ngOnInit (): void {
    this.cols = [
      { field: 'id', header: 'Tag Id', width: '80px' },
      { field: 'tag_name', header: 'Tag name', width: '190px' },
      { field: 'tag_type', header: 'Tag type', width: '140px' },
      { field: 'mdg_flag', header: 'MDG flag ', width: '140px' },
      { field: 'rank', header: 'Tag rank', width: '90px' },
      { field: '', header: 'Actions', width: '80px' },
    ];
    this.myForm.get("tag_type").setValue(['premium']);
    this.GetData();

    this.GetData();
  }
  selectdOption (e) {
    if (!this.filters['search']) {
      this.filters['search'] = { tag_type: 'premium' };
    } else {
      this.filters['search'] = Object.assign({}, this.filter.search, this.myForm.value);
    }
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData();
    }, SiteConstants.FiltertimeOut);
  }
  resetMultiSelect () {
    this.myForm.get("tag_type").setValue([]);
    this.GetData();

  }
  GetData () {
    if (this.filters && !this.filters['search']) {
      this.filters['search'] = Object.assign({}, this.filters['search'], { tag_type: 'premium' });
      this.myForm.get("tag_type").setValue(['premium']);
    } else if (this.filters && this.filters['search'] && this.filters['search'].tag_type && !this.filters['search'].tag_type.length) {
      this.filters['search'] = Object.assign({}, this.filter.search, { tag_type: 'premium' });
      this.myForm.get("tag_type").setValue(['premium']);
    }
    const type = lodash.get(this.filters, 'search.tag_type');
    const text = lodash.get(this.filters, 'search.tag_name', '');
    const sort = lodash.get(this.filters, 'sort', '');
    const limit = lodash.get(this.filters, 'pagination.limit', 10);
    const page = lodash.get(this.filters, 'pagination.page', 1);
    const offset = (page - 1) * limit;

    this.loading = true;
    this.gameBankService
      .getAllTags({
        text,
        type,
        sort,
        limit,
        offset
      })
      .subscribe(data => {
        this.tagList = data['tags'];
        this.totalRecords = data['total'];
        this.loading = false;
      });
  }

  customSort (ev) {
    let obj = {};

    if (ev.order == 1) {
      obj[ev.field] = 'desc'
    } else {
      obj[ev.field] = 'asc'
    }

    const sortKey = ev.field + ' ' + ev.order;
    if (this.globalSort !== sortKey) {
      this.filters['sort'] = obj;
      this.GetData();
      this.globalSort = sortKey;
    }
  }

  customPagination (ev) {
    let data = this.paginationService.paginationFun(ev, this.globalSort, this.constPage, this.filters)
    this.filters = data.filter;
    this.globalSort = data.globalSort;
    this.constPage = data.defaultPage;

    this.GetData();
  }

  onRefresh (ev) {
    setTimeout(() => {
      this.GetData();
    }, 100);
  }

  tagsFilter (val, field) {
    let searchDataFilter = this.searchService.searchFun(val, field, this.filters)
    this.filters = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
      this.GetData();
    }, SiteConstants.FiltertimeOut);
  }

}
