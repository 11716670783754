<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ tag && tag.id ? 'Update' : 'Create' }} Tag</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="gamecreationpopup">
  <div class="main-content">
    <form (ngSubmit)="saveTag()">
      <div class="modal-body">
        <div class="row">
          <div class="col col-12">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Tag name</mat-label>
                <input matInput [(ngModel)]="tag.tag_name" name="tag_name" id="tag_name" class="form-control"
                  [disabled]="tag && tag.id">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-6">
            <label for="duration">Tag type</label>
            <i class="{{icons['info']}} " matTooltip="{{tooltips['tag_type']}}"></i>
            <div class="form-group">
              <p-dropdown [options]="tagTypes" name="tag_type" [(ngModel)]="tag.tag_type" [style]="{'width': '100%'}">
              </p-dropdown>
            </div>
          </div>
          <div class="col col-4">
            <div class="form-group">
              <mat-form-field class="example-full-width">
                <mat-label>Rank</mat-label>
                <input matInput [(ngModel)]="tag.rank" name="rank" id="rank" class="form-control" type="number" min="0">
              </mat-form-field>
            </div>
          </div>
          <div class="col col-2">
            <div class="form-group">
              <mat-checkbox [checked]="tag.mdg_flag" [(ngModel)]="tag.mdg_flag" [ngModelOptions]="{standalone: true}">
                MDG Flag
              </mat-checkbox>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-6">
            <div class="card-box card-box2 card-left">
              <div [ngStyle]="imagePreview" class="card-image"></div>
            </div>
          </div>
          <div class="col col-2">
            <span [hidden]="!isRemove" (click)="removeIcon()">
              <i class="fa fa-times fa-2x">
              </i>
            </span>
          </div>
          <div class="col col-4">
            <div class="form-group">
              <label for="file">Select icon (Max size 68 × 18 px)</label>
              <div class="file-field">
                <input style="display: none" type="file" accept="image/*" (change)="onFileChange($event)"
                  #hiddenfileinput>
                <label for="modal-1" (click)="hiddenfileinput.click()" class="modal-label" tabindex="1">
                  Choose file
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a mat-raised-button class="btn btn-danger pull-right" (click)="close()">Close</a>
        <button mat-raised-button type="submit" class="btn btn-danger pull-right">Save</button>
      </div>
    </form>
  </div>
</mat-dialog-content>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>