export class SiteConstants {
  static FiltertimeOut = 500;
  static phonePattern = '^[0-9]{10,11}|[0-9]{3}-[0-9]{3}-[0-9]{4}$';
  static smsCodePattern = '^[0-9]{4}$';
  static cvcPattern = '^[0-9]{3}$';
  static bankCardNumberPattern = '^[0-9]{16}$';
  static bankCardValidToPattern = '^(0[1-9]|1[0-2]|[1-9])\/(1[4-9]|[2-9][0-9]|20[1-9][1-9])$';
  static paginationDefault = ' {"limit":10,"page":1}';
  static backgroundType = [{ type: 'vertical' }, { type: 'single' }, { type: 'stacked' }];
  static mediaType = [{ type: 'image' }, { type: 'video' }];
  static icons = {
    edit: 'fa fa-edit', user: 'pi pi-users', disputed: 'fa fa-gavel', approve: 'fa fa-check', impersonateuserpassword: 'fa fa-key',
    flag: 'fa fa-flag', chart: 'fa fa-line-chart', analytics: 'fa fa-bar-chart',
    save: 'fa fa-floppy-o', remove: 'fa fa-remove', delete: 'fa fa-trash-o', block: 'fa fa-lock', unblock: 'fa fa-unlock', apikey: 'fa fa-square',
    promocode: 'fa fa-code', payout: 'fa fa-money', transaction: 'fa fa-exchange', dashboard: 'dashboard', notifications: 'notifications',
    person: 'person', perm_identity: 'perm_identity', camera_front: 'camera_front', payment: 'payment', money: 'money',
    radio_button_unchecked: 'radio_button_unchecked', info: 'pi pi-info-circle', games: 'games', content_paste: 'content_paste', settings: 'fa fa-cogs', winner_declaration: 'fa fa-trophy',
    winner_declaration_notes: 'fa fa-info-circle', follow: 'fa fa-user-plus', list: 'pi pi-list', key: 'key',
    bookmark: 'fa fa-bookmark', BlockCountry: 'block', SlushFund: 'fa fa-asterisk', tags: 'tags', settingsMenu: 'settings', initiatepayout: 'fa fa-usd', pending: 'fa fa-exclamation-triangle', cut: 'fa fa-cut', cut_: 'content_cut',
  }
  static tooltips = {
    update: 'UPDATE', disputed: 'DISPUTED', save: 'SAVE', switchwinnerdeclaration: 'Switch Winner',
    remove: 'REMOVE', block: 'BLOCK', unblock: 'UNBLOCK', promocode: 'PROMO CODE', manageapikey: 'Manage Api Key',
    payout: "PAYOUT TO VERSUSGAME ACCOUNT", transaction: 'GET TRANSACTIONS', gameperformance: 'GAME PERFORMANCE', skrillpayout: 'Skrill Payout',
    reported: 'REPORTED', editaffiliate: "EDIT AFFILITE", approvegame: 'APPROVE GAME ', cancel: "CANCEL", initiatepayout: "DOLLAR PAYOUT", ignoreallreport: "IGNORE ALL REPORTS", ignorereport: "IGNORE  REPORTS", winnerdeclaration: 'WINNER DECLARATION'
    , winnerdeclarationnotes: 'Declare Winner Note', gamecancel: "GAME CANCEL", follow: 'FOLLOW', summary: 'WALLET SUMMARY REPORT',
    is_free_only: 'ALLOW ONLY FREE GAME PLAYS', analytics: 'AFF ANALYTICS ', settings: 'Settings', impersonateuserpassword: 'IMPERSONATE USER PASSWORD', pending_transaction: 'PENDING TRANSACTION', cut: "Cut Components", SlushFund: "Slush Fund"
  }

  static gameToolTips = {
    sourcetype: "Game source type ",
    affiliate: "Select affiliate Id",
    duration: "Duration of the game",
    durationunit: "Duration unit of the game  like hours/minutes/days",
    tags: "Game event type like poll/sample",
    pingametotop: "Pin game to top",
    notshowtrending: "Not show trending",
    gametext: "Game question.   eg. Who is going to have most likes?",
    gametitle: "Game title",
    character1: "Game character 1",
    character2: "Game character 2",
    priceoptions: "Game prices",
    bgcolor: "Game background color",
    startdate: " Game start time",
    enddate: " Game end time",
    lastjoindate: " Game play has to stop",
    background: "Game background type",
    locations: "For which country allow",
    textsize: "Game text size",
    filetype: "Background file type",
    sourceurl: "Game proof"
  }
  static durationUnits = [
    { value: 'minutes', label: 'Minutes' },
    { value: 'hours', label: 'Hours' },
    { value: 'days', label: 'Days' }
  ];
  static SideBarMenu = [
    { path: '/dashboard', title: 'Dashboard', icon: SiteConstants.icons.dashboard, class: '' },
    { path: '/user', title: 'User', icon: 'person', class: '' },
    // { path: '/table-list', title: 'Characters', icon: 'content_paste', class: '' },
    { path: '/character', title: 'Character', icon: SiteConstants.icons.perm_identity, class: '' },
    { path: '/images', title: 'Images', icon: SiteConstants.icons.camera_front, class: '' },
    { path: '/payout-list', title: 'Payout List', icon: SiteConstants.icons.payment, class: '' },
    { path: '/instant-payout-list', title: 'GCOW Payout List', icon: SiteConstants.icons.payment, class: '' },
    { path: '/source-type', title: 'Source Type', icon: SiteConstants.icons.radio_button_unchecked, class: '' },
    { path: '/game-bank', title: 'game-bank', icon: SiteConstants.icons.games, class: '' },
    { path: '/selfgame', title: 'Self Games', icon: SiteConstants.icons.games, class: '' },
    { path: '/global-cut', title: 'Global Cut Config', icon: SiteConstants.icons.cut_, class: '' },
    { path: '/tags', title: 'Manage Tags', icon: SiteConstants.icons.tags, class: '' },
    { path: '/category-tags', title: 'Category Tags', icon: SiteConstants.icons.tags, class: '' },
    { path: '/trending-tags', title: 'Trending Tags', icon: SiteConstants.icons.tags, class: '' },
    { path: '/super-tags', title: 'Super Tags', icon: SiteConstants.icons.tags, class: '' },
    { path: '/settings', title: 'Settings', icon: SiteConstants.icons.settingsMenu, class: '' },
    { path: '/fee-cut', title: 'Fee and Cut SSG', icon: SiteConstants.icons.radio_button_unchecked, class: '' },
    { path: '/custom-game', title: 'Custom Games', icon: SiteConstants.icons.games, class: '' },
    { path: '/pinned-game', title: 'Pinned Games', icon: SiteConstants.icons.games, class: '' },
    // { path: '/pinned-game', title: 'Pinned Game', icon: 'radio_button_unchecked', class: '' },
    // { path: '/custom-questions', title: 'Custom Questions', icon: 'radio_button_unchecked', class: '' },
    // { path: '/custom-game', title: 'Custom Games', icon: 'radio_button_unchecked', class: '' },
    { path: '/affiliate', title: 'Affiliate', icon: SiteConstants.icons.content_paste, class: '' },
    { path: '/promotion-banners', title: 'Promotion Banners', icon: SiteConstants.icons.money, class: '' },
    { path: '/notifications', title: 'Notification', icon: SiteConstants.icons.notifications, class: '' },
    { path: '/notifications-types', title: 'Notification Types', icon: SiteConstants.icons.notifications, class: '' },
    { path: '/levels', title: 'Levels', icon: SiteConstants.icons.radio_button_unchecked, class: '' },
    { path: '/blacklist-country-states', title: 'BlackListed Countries', icon: SiteConstants.icons.BlockCountry, class: '' },
    { path: '/admin-manager', title: 'Admin Manager', icon: SiteConstants.icons.radio_button_unchecked, class: '' },
    // { path: '/league', title: 'league', icon: 'radio_button_unchecked', class: '' },
    // { path: '/coin-rate', title: 'Log', icon: 'radio_button_unchecked', class: '' },
    { path: '/promo-code', title: 'Promo Code', icon: SiteConstants.icons.radio_button_unchecked, class: '' },
    // { path: '/leauge-category', title: 'League category', icon: 'category', class: '' },
    { path: '/affiliate-manager', title: 'Affiliate Manager', icon: SiteConstants.icons.radio_button_unchecked, class: '' },
    { path: '/coin-rate', title: 'Coin Rate', icon: SiteConstants.icons.money, class: '' },
    // { path: '/coin-rate', title: 'Cluvio Dashboard', icon: 'radio_button_unchecked', class: '' }
    { path: '/update-password', title: 'Update Password', icon: SiteConstants.icons.key, class: '' },
  ];
  static videoValidation = {
    VIDEO_MAX_SIZE: 75,
    MIN_VIDEO_DURATION: 10,
    MAX_VIDEO_DURATION: 60,
    MIN_VIDEO_WIDTH: 180,
    MIN_VIDEO_HEIGHT: 320,
  }
  static customGameTag = [{ label: "sample", value: "sample" }, { label: "poll", value: "poll" }]
}
