import { Injectable } from '@angular/core';
import { transactionData } from '../../data/getTransactionData';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../../config';
@Injectable({
  providedIn: 'root'
})
export class GetTransactionService {

  constructor (private httpClient: HttpClient) { }
  getTransactionData (id) {
    const url = Endpoints.api.user.transaction
      .replace('{userId}', id);
    const observable = this.httpClient.get(url);

    return observable;


  }
  getPendingTransactionData (id) {
    const url = Endpoints.api.user.pendingTransaction
      .replace('{userId}', id);
    const observable = this.httpClient.get(url);
    return observable;
  }
  getWalletSummaryReport (id) {
    const url = Endpoints.api.user.walletSummaryReport
      .replace('{userId}', id);
    const observable = this.httpClient.get(url);

    return observable;
  }
  fixWalletBal (id) {
    const url = Endpoints.api.user.fixWalletBalance
      .replace('{userId}', id);
    const observable = this.httpClient.get(url);

    return observable;
  }
  fixPendingBal (id) {
    const url = Endpoints.api.user.fixPendingBalance
      .replace('{userId}', id);
    const observable = this.httpClient.get(url);

    return observable;
  }
  getTransactionDataNew (id, userFilter) {
    let search = '';
    let pagination = '';
    let sort = '';
    if (userFilter.search) {
      search = JSON.stringify(userFilter.search);

    }
    // if(userFilter.sort){
    //   sort = JSON.stringify(userFilter.sort)
    // }
    if (userFilter.sort) {
      if (Object.keys(userFilter.sort).length > 0) {
        sort = JSON.stringify(userFilter.sort)
      }
    }
    if (userFilter.pagination) {
      pagination = JSON.stringify(userFilter.pagination)
    }
    const url = Endpoints.api.user.userTransaction
      .replace('{userId}', id)
      .replace('{search}', encodeURIComponent(search))
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url);

    return observable;

  }
  userTransactionCSV (id, userFilter) {
    let search = '';

    if (userFilter.search) {
      search = JSON.stringify(userFilter.search);

    }

    const url = Endpoints.api.user.userTransactionCSV
      .replace('{userId}', id)
      .replace('{search}', encodeURIComponent(search));
    const observable = this.httpClient.get(url);

    return observable;

  }

}
