import { Component, OnInit } from '@angular/core';
import { Notifications } from '../../core/models/api/notification';
import { NotificationService } from '../../core/services/league/notification.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchService } from "../../core/services/common/search.service";
import { PaginationService } from "../../core/services/common/pagination.service";
import { SiteConstants } from '../../../config/site-constants';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  totalRecords: number;
  loading: boolean;
  globalSort: any = "";
  filter: any = {};
  frozenCols: any[];
  TimeoutSearch: any;
  defaultPage: number = 10;
  cols: any;
  notificationData: Notifications;
  selectedNotification: Notifications;
  constructor(private searchService: SearchService, private notificaationService: NotificationService, private paginationService: PaginationService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'title', header: 'Title' },

      { field: 'message', header: 'Message' },
      { field: 'start_date', header: 'Start Date' },
      { field: 'end_date', header: 'End Date' },

      { field: 'is_active', header: 'Active' },
      { field: 'can_close', header: 'Can close' },
      { field: 'alert_type', header: 'Alert Type' },

    ];
    this.GetData({})
  }

  customSort(e) {

  }
  customPagination(event) {
    let data = this.paginationService.paginationFun(event, this.globalSort, this.defaultPage, this.filter)

    this.filter = data.filter;
    this.globalSort = data.globalSort;
    this.defaultPage = data.defaultPage;
    this.GetData(this.filter);
  }

  // customPagination(event) {
  //   // this.loading = true;
  //   if (event) {
  //     let pagination = {}
  //     pagination['limit'] = event.rows;
  //     if (event.first) {
  //       pagination['page'] = Math.round(event.first / 10) + 1;
  //     } else {
  //       pagination['page'] = 1;

  //     }
  //     this.filter['pagination'] = pagination;
  //     this.GetData(this.filter);
  //     //  this.loading = false;
  //   }

  //   let obj = {};

  //   if (event.sortField) {


  //     if (event.sortOrder == 1) {
  //       obj[event.sortField] = 'desc'
  //     } else {
  //       obj[event.sortField] = 'asc'
  //     }
  //   }
  //   if (this.globalSort != event.sortField + " " + event.sortOrder) {

  //     this.filter['sort'] = obj;
  //     this.GetData(this.filter);
  //     this.globalSort = event.sortField + " " + event.sortOrder
  //     //  console.log(this.filter);
  //   }

  // }
  // userFilter(val, field) {

  //   var obj = {};
  //   obj[field] = val;


  //   this.filter['search'] = obj;

  //   this.GetData(this.filter);


  // }
  onRefrash(e) {
    setTimeout(() => {
      this.GetData(this.filter)
    }, 1500);

  }

  userFilter(val, field) {

    let searchDataFilter = this.searchService.searchFun(val, field, this.filter)
    this.filter = searchDataFilter.filter;
    clearTimeout(this.TimeoutSearch);
    this.TimeoutSearch = setTimeout(() => {
    this.GetData(this.filter);
    }, SiteConstants.FiltertimeOut);
  }
  GetData(obj) {
    this.notificaationService.getNotification(obj).subscribe(data => {

      const tempPromo = JSON.stringify(data);
      this.totalRecords = (JSON.parse(tempPromo).total);
      const promoCodeparse = (JSON.parse(tempPromo).list);
      const getpromoCode = JSON.stringify(promoCodeparse);
      this.notificationData = JSON.parse(getpromoCode);

      // this.totalRecords = this.promoCode.length;
      // console.log("oromo code", data);

    });
  }

}
