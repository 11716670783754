import { Injectable } from '@angular/core';
import { pinnedGameData } from '../../data/pinnedgame';
import { Endpoints, SiteConstants } from '../../../../config';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PinnedGameService {

  constructor(private httpClient: HttpClient) { }

  getPinnedGameData() {
    return pinnedGameData;
  }

  getPinnedGameData1(customQuestionFiler) {
    let search = '';
    let pagination = '';
    let sort = '';

    let type = { "game_type": "P", "priority_flag": "1" }
    if (customQuestionFiler.search == undefined) {
      // search= JSON.stringify(customQuestionFiler.search);
      search = JSON.stringify(type)
    } else {
      // Object.assign(customQuestionFiler.search,{"question_type":"C"}); 
      search = JSON.stringify(customQuestionFiler.search,);
    }
    if (customQuestionFiler.sort) {
      sort = JSON.stringify(customQuestionFiler.sort)
    }

    if (customQuestionFiler.sort) {
      if (Object.keys(customQuestionFiler.sort).length > 0) {
        sort = JSON.stringify(customQuestionFiler.sort)
      }
      else {
        sort = JSON.stringify({ id: 'desc' })
      }

      // customGameFilter.sort={id:'desc'}
      // customGameFilter.sort= Object.assign({},customGameFilter.sort,{id:'desc'})
    } else {
      // Object.assign(customGameFilter.sort,{id:'desc'}) 
      sort = JSON.stringify({ id: 'desc' })
      // customGameFilter.sort= Object.assign({},customGameFilter.sort,{id:'desc'})

    }

    // if(customQuestionFiler.sort == undefined){
    //   sort =JSON.stringify({id:'desc'})
    //   // customQuestionFiler.sort={id:'desc'}
    //   // customQuestionFiler.sort= Object.assign({},customQuestionFiler.sort,{id:'desc'})
    // }else{
    //   Object.assign(customQuestionFiler.sort,{id:'desc'}) 
    //   sort=JSON.stringify(customQuestionFiler.sort)
    //   // customQuestionFiler.sort= Object.assign({},customQuestionFiler.sort,{id:'desc'})

    // }
    if (customQuestionFiler.pagination) {
      pagination = JSON.stringify(customQuestionFiler.pagination)
    } else {
      pagination = SiteConstants.paginationDefault;
    }
    const url = Endpoints.api.pinnedGame.getPinnedGameList
      .replace('{search}', search)
      .replace('{sort}', sort)
      .replace('{pagination}', pagination);

    const observable = this.httpClient.get(url);
    return observable;


  }

  getGameData(id) {

    const url = Endpoints.api.gameData.games;
    const observable = this.httpClient.get(url + '/' + id)
 
    return observable;
  }
}
