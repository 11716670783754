import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CharacterService } from '../../../core/services/character.service'
import { TostService } from '../../../core/services/common/tost.service'
@Component({
  selector: 'app-remove-character-modal',
  templateUrl: './remove-character-modal.component.html',
  styleUrls: ['./remove-character-modal.component.scss']
})
export class RemoveCharacterModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RemoveCharacterModalComponent>,

    private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService, private characterService: CharacterService) { }

  ngOnInit(): void {
  }
  removeCharacter() {
    const obj = { 'id': this.data.id };
    this.characterService.removeChacter(obj).subscribe(
      res => {
        this.tostService.tostrFun("Character remove Successfully", 'success');
        this.dialogRef.close();

      },
      err => {
        // this.tostService.tostrFun(err.error.errors[0].message, 'error');
        this.dialogRef.close();
      });

  }
  close(): void {
    this.dialogRef.close(true);
    //  console.log("object", this.data);
  }
}
