<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">



        <p-table #dt [columns]="cols" [value]="payout" (sortFunction)="customSort($event)" [customSort]="true"
          [(selection)]="selectedPayout" dataKey="id" styleClass="ui-table-cars" [rows]="10"
          [rowsPerPageOptions]="[10,25,50]" [responsive]="true" [scrollable]="true" [loading]="loading"
          [paginator]="true">
          <ng-template pTemplate="caption">
            <div class="ui-g">
              <div class="ui-g-6">

              </div>
              <form [formGroup]="myForm">

                <div class="row">
                  <div class="col-md-8">
                    <p-multiSelect [options]="gameStatusOptions" formControlName="status"
                      [panelStyle]="{minWidth:'12em'}" maxSelectedLabels="1" placeholder="Game Status" [filter]="false"
                      [style]="{'width': '318px'}" (onChange)="selectdOption($event)">
                    </p-multiSelect>
                  </div>
                  <div class="col-md-4">

                    <button class="btn btn-primary" pButton type="button" class="ui-button-raised" style="float: right;"
                      icon="fa fa-refresh" (click)="resetMultiSelect()"></button>
                  </div>

                </div>

              </form>
            </div>
          </ng-template>

          <ng-template pTemplate="header" let-columns>
            <tr>

              <th *ngFor="let col of columns" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                <div class="header-title">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </div>
              </th>

            </tr>
            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field" [ngStyle]="{'width': col.width}">


                <input *ngSwitchCase="'country'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'user_id'" pInputText type="number" min="1" placeholder=""
                  class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                <input *ngSwitchCase="'user_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                <input *ngSwitchCase="'first_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'communication_email'" pInputText type="text" placeholder=""
                  class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'address'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'balance'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'payable_amount_usd'" pInputText type="text" placeholder=""
                  class="ui-column-filter" style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'payout_status'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                <input *ngSwitchCase="'mobile'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">
                <input *ngSwitchCase="'method_to_reach'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

                <input *ngSwitchCase="'created_at'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (keyup)="userFilter($event.target.value, col.field)">

              </th>
              <!-- <th style="width: 270px; text-align: center;">

                        </th> -->
            </tr>
          </ng-template>


          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
              <td style="width: 120px;">{{rowData.country}}</td>
              <td style="width: 120px;"> {{rowData.user_id}}</td>
              <td style="width: 120px;">{{rowData.user_name}}</td>

              <td style="width: 120px;">{{rowData.first_name}}</td>
              <td style="width: 200px;">{{rowData.communication_email}}</td>
              <td style="width: 120px;">{{rowData.address}}</td>

              <td style="width: 120px;">{{rowData.balance}}</td>
              <td style="width: 120px;">{{rowData.payable_amount_usd}}</td>
              <td style="width: 120px;">
                {{ rowData.payout_status=='0' ? 'Requested' : rowData.payout_status }}</td>

              <td style="width: 120px;">{{rowData.mobile}}</td>
              <td style="width: 120px;">{{rowData.method_to_reach}}</td>

              <td style="width: 120px;">{{rowData.created_at | date}}</td>

              <td style="width: 80px;">
                <div style="min-width: 80px;">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <div mat-menu-item>
                      <app-action-icon [data]="rowData" width="300" modaleId="PayoutPromocodeComponent" icon="remove"
                        tooltip="cancel" (onRefrashed)="onRefrash($event)">
                      </app-action-icon>
                      <app-action-icon [data]="{id:rowData.user_id,is_blocked:false}" [width]="'350'"
                        [modaleId]="'BlockUserModalComponent'" icon="block" tooltip="block"
                        (onRefrashed)="onRefrash($event)">
                      </app-action-icon>

                      <app-action-icon [data]="rowData" [width]="'650px'" [modaleId]="'PayoutGetTransactionComponent'"
                        icon="transaction" tooltip="transaction">
                      </app-action-icon>
                      <app-action-icon [data]="{data:{id:rowData.user_id,isCredit:false}}" [width]="'350'"
                        [modaleId]="'PayoutModalComponent'" (onRefrashed)="onRefrash($event)" icon="payout"
                        tooltip="payout">
                      </app-action-icon>
                    </div>
                    <button mat-menu-item>
                      <app-action-icon [data]="rowData" [width]="'350'" [modaleId]="'PayoutInitiatePaymentComponent'"
                        (onRefrashed)="onRefrash($event)" icon="initiatepayout" tooltip="initiatepayout">
                      </app-action-icon>
                    </button>

                  </mat-menu>






                </div>

              </td>

            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" style="text-align:left">No data found.</td>
            </tr>
          </ng-template>
        </p-table>





      </div>

    </div>
  </div>
</div>