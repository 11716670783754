<div class="modal-body">
    <div class="row">
          <div class="col-md-12">
              <div  *ngIf="isBlock">
    
          Do you want to Unblock this user ?
      
              </div>
        <div *ngIf="!isBlock">
           Do you want to Block this user ?  
        </div>
  </div>
        
      </div>
        
             
              <div class="row">
                  <div class="col-md-6">
                      <button *ngIf="isBlock" mat-raised-button  (click)="unBlockUser()" class="btn close btn-danger pull-right"  data-dismiss="alert" aria-label="Close">
                          Ok
                       </button>
                       <button *ngIf="!isBlock" mat-raised-button (click)="blockUser()" class="btn close btn-danger pull-right"   data-dismiss="alert" aria-label="Close" >Ok</button>
                       
                  </div>
                  <div class="col-md-6">
                      <button mat-button mat-raised-button (click)="close()" class="btn btn-danger pull-right"  data-dismiss="alert" aria-label="Close">
                          Cancel
                      </button>
                            
                  </div>
              </div>
           
            
      
  </div>