<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">


        <p-table #dt [columns]="cols" [value]="sourceType" (sortFunction)="customSort($event)" [customSort]="true"
          [(selection)]="selectedSourceType" dataKey="id" styleClass="ui-table-cars" [rows]="10"
          [rowsPerPageOptions]="[10,25,50]" [responsive]="true" [loading]="loading" [paginator]="true">


                    <ng-template pTemplate="header" let-columns>
                        <tr>

                            <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                                [ngStyle]="{'width': col.width,'text-align': col.textalign}">
                                <div class="header-title">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </div>
                            </th>

                        </tr>
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <input *ngSwitchCase="'source_name'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'source_code'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">
                <input *ngSwitchCase="'weight'" pInputText type="text" placeholder="" class="ui-column-filter"
                  style="width: 100%;" (input)="dt.filter($event.target.value, col.field, 'contains')">

                            </th>

                        </tr>
                    </ng-template>


                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td style="width: 500px;">{{rowData.source_name}}</td>
                            <td style="width: 100px;text-align: center;">{{rowData.source_code}}</td>
                            <td style="width: 100px;text-align: center;">{{rowData.weight}}</td>



                            <td style="text-align: center;width: 80px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item>
                    <app-action-icon [data]="rowData" [width]="width" [height]="height" [modaleId]="modaleId"
                      icon="edit" tooltip="update" (onRefrashed)="onRefrash($event)">
                                </app-action-icon>
                  </div>
                </mat-menu>

                            </td>

                        </tr>
                    </ng-template>

                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" style="text-align:left">No data found.</td>
                        </tr>
                    </ng-template>
                </p-table>




            </div>

        </div>
    </div>
</div>