import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  constructor() { }
paginationFun(event,globalSort,defaultPage,filter){
  if(event){
    let pagination ={}
    pagination['limit']=event.rows;
    if(event.first){
      if( defaultPage==event.rows){
       defaultPage=event.rows;
        pagination['page']=Math.round(event.first/event.rows)+1;
      }else{
        defaultPage=event.rows;
        pagination['page']=1;
        event.first=0;
      }

    }else {
    pagination['page']=1;

    }
  filter['pagination'] = pagination;

  let obj={} ;
 
  if(event.sortField){

  
if(event.sortOrder==1){
obj[event.sortField]='desc'
} else{
  obj[event.sortField]='asc'
}
}
if(globalSort!=event.sortField+" "+event.sortOrder){

 filter['sort'] =obj;
 globalSort=event.sortField+" "+event.sortOrder
      }
}
return {event:event,globalSort:globalSort,defaultPage:defaultPage,filter:filter}

}

}
