import { Injectable } from '@angular/core';
import { Endpoints, SiteConstants } from '../../../../config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {

  constructor (private httpClient: HttpClient) { }

  getGlobalCutSettings () {
    const url = Endpoints.api.systemSettings.getGlobalCutSettings;
    const observable = this.httpClient.get(url);
    return observable;
  }
  getUserCutSettings (entityType, entityId) {
    const url = Endpoints.api.systemSettings.getUserGameCutSettings + '?entity_type=' + entityType + '&entity_id=' + entityId;
    const observable = this.httpClient.get(url);
    return observable;
  }
  saveCutSettings (obj) {
    const url = Endpoints.api.systemSettings.saveCutSettings;
    const observable = this.httpClient.post(url, obj)
    return observable;
  }
}
