import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TostService } from '../../../core/services/common/tost.service'
@Component({
  selector: 'app-confirm-country-modal',
  templateUrl: './confirm-country-modal.component.html',
  styleUrls: ['./confirm-country-modal.component.scss']
})
export class ConfirmCountryModalComponent implements OnInit {
  constructor (public dialogRef: MatDialogRef<ConfirmCountryModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private tostService: TostService) { }
  userData = this.data;

  rowDataObj: any;
  ngOnInit (): void {
    this.rowDataObj = this.userData && this.userData.rowData;
  }

  save () {

    this.dialogRef.close({ save: true, rowData: this.rowDataObj });
  }

  submitData () {

  }
  close (): void {
    this.dialogRef.close({ rowData: this.rowDataObj, save: false });
  }

}
